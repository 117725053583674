import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { Trans, useTranslation } from "react-i18next";
import clsx from "clsx";
import { useNotification } from "components";
import { useSetProjectQueueMutation } from "api/graphql";
import { Reference } from "@apollo/client";

import styles from "./styles.module.scss";

type FormData = { confirm: string };

type Props = {
  projectId: string;
  title: string;
  onClose: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const PrioritiseProject = memo<Props>(
  ({ projectId, title, onClose, className, ...props }) => {
    const { t } = useTranslation("projects");
    const { setNotification, notificationTypes } = useNotification();

    const [setProjectQueue, { loading, error }] = useSetProjectQueueMutation({
      variables: {
        projectId,
        newPosition: 1,
      },
      onCompleted() {
        setNotification({
          message: t("prioritise.prioritised"),
        });
      },
      onError(error) {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      optimisticResponse({ projectId }) {
        return {
          moveProjectInQueue: {
            projectId,
            queueOrder: 1,
            __typename: "Project",
          },
        };
      },
      update(cache, { data }) {
        if (!data?.moveProjectInQueue) return;

        cache.modify<{
          getProjects: {
            __typename: "GetProjectsResponsePage";
            nodes: Reference[];
          };
        }>({
          fields: {
            getProjects(existingProjects, { readField }) {
              if ("nodes" in existingProjects) {
                existingProjects.nodes.forEach((project) => {
                  if (
                    readField("queueOrder", project) === 1 &&
                    readField("projectId", project) !==
                      data?.moveProjectInQueue?.projectId
                  ) {
                    cache.modify({
                      id: cache.identify(project),
                      fields: {
                        queueOrder() {
                          return 2;
                        },
                      },
                    });
                  }
                });
              }

              return existingProjects;
            },
          },
          optimistic: false,
        });
      },
    });

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
    } = useFormik<FormData>({
      async onSubmit() {
        setProjectQueue();
        onClose();
      },
      validationSchema: object({
        confirm: string()
          .required("common:validation.required")
          .lowercase()
          .oneOf([t("prioritise.validationWord")]),
      }),
      initialErrors: {
        confirm: "",
      },
      initialValues: {
        confirm: "",
      },
    });

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(styles.prioritiseProject, className)}
        {...props}
      >
        <h3 className={styles.title}>
          <Trans t={t} i18nKey="prioritise.title">
            {{ span: <span />, title }}
          </Trans>
        </h3>
        <h4 className="text-base text-blackberry">
          {t("prioritise.explanation")}
        </h4>
        {error && (
          <p className={styles.error}>{t("prioritise.errors.common")}</p>
        )}
        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full mt-7 mb-6"
          placeholder={t("prioritise.placeholder")}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t("prioritise.actions.cancel")}
          </Button>
          <Button
            loading={loading}
            type="submit"
            disabled={!isValid || loading}
          >
            {t("prioritise.actions.prioritise")}
          </Button>
        </section>
      </form>
    );
  }
);
