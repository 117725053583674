import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Alert, Button, Loader, Modal } from "@CreativelySquared/uikit";
import plusIcon from "images/plus.svg";
import { Pagination, Search } from "components";
import { useCallback, useMemo } from "react";
import { links } from "App";
import { Link } from "react-router-dom";
import { usePagination } from "utils/hooks/pagination";
import { OrganizationUser } from "api/types";
import { useUsersQuery } from "api/graphql";
import { getFullName } from "utils/users";

import { DeleteUser } from "../components/DeleteUser";
import { UserCard } from "../components/UserCard";

import styles from "./styles.module.scss";

export const CustomerUsersRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      userId: Route.params.regex(idRegEx).optional,
      mode: Route.params.oneOf("delete").optional,
      page: Route.query.number,
      search: Route.query.string,
    },
    ({ id, userId, mode }) => `/customers/${id}/users/${mode}/${userId}`
  )
);

export const CustomerUsers = CustomerUsersRoute(
  ({
    match: {
      params: { id, userId, mode },
      query: { search, page = 1 },
      query,
    },
    history: { replace },
    link,
  }) => {
    const pagination = usePagination({ page });
    const { data: { getUsers } = {}, loading: getUsersLoading } = useUsersQuery(
      {
        variables: {
          organizationId: id,
          pagination,
          search: {
            fullName: search || undefined,
          },
        },
        fetchPolicy: "cache-and-network",
      }
    );
    const { t } = useTranslation("customers");
    const loading = getUsersLoading && !getUsers?.nodes?.length;

    const members = useMemo(() => {
      return getUsers?.nodes?.map((member) => ({
        ...member,
        userId: member?.userId,
        fullName: getFullName(member),
      }));
    }, [getUsers?.nodes, search]);

    const onSearch = useCallback(
      (value: string) =>
        replace(link({ ...query, id, page: 1, search: value })),
      [replace, query]
    );

    const onCloseDelete = () =>
      replace(
        link({
          ...query,
          id,
        })
      );

    return (
      <main className="mt-10">
        <Helmet title={t("users.title")} />

        <section className="flex mb-10">
          <Link to={links.CustomerUserManagement({ id: id, mode: "create" })}>
            <Button size={Button.sizes.Large} className="mr-7">
              <img src={plusIcon} className="mr-4" />
              {t("users.create.label")}
            </Button>
          </Link>

          <Search
            className="w-full"
            placeholder={t("users.search.placeholder")}
            onSearch={onSearch}
            value={search}
          />
        </section>
        {loading && (
          <div className="flex justify-center">
            <Loader radius={50} className="mt-6" />
          </div>
        )}
        {getUsers?.pageInfo?.totalItems === 0 && !search && !loading && (
          <section className={styles.customerUsersEmpty}>
            <h2>{t("users.empty.title")}</h2>
            <span>{t("users.empty.description")}</span>
          </section>
        )}
        {members && (
          <section>
            {members.map(
              (member) =>
                member && (
                  <UserCard
                    user={member as OrganizationUser}
                    className="mb-7"
                    key={member.userId}
                  />
                )
            )}
          </section>
        )}
        {getUsers?.pageInfo?.totalItems === 0 && search && !loading && (
          <Alert
            className="inline-block"
            title={t("users.notFound.title")}
            variant={Alert.variants.Error}
          />
        )}
        <Pagination
          className="mt-11"
          page={page}
          total={getUsers?.pageInfo?.totalPages}
        />
        <Modal visible={userId && mode === "delete"} onClose={onCloseDelete}>
          <DeleteUser userId={userId!} onClose={onCloseDelete} />
        </Modal>
      </main>
    );
  }
);
