import clsx from "clsx";
import { Helmet } from "react-helmet";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { useTranslation } from "react-i18next";
import { Button } from "@CreativelySquared/uikit";
import { Link } from "react-router-dom";
import { ProjectCard } from "components";
import { ProjectSort, ProjectStatuses } from "Projects/utils/types";
import { BriefCategory } from "api/enums";
import { getRange } from "utils/time";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { links } from "App";
import {
  ProjectsView,
  ProjectsViewType,
} from "Projects/components/ProjectsView";
import { useProjectsRouteSettings } from "Projects/hooks/useProjectsRouteSettings";
import { BriefShareableField } from "Brief/utils/types";

import { HeaderInjector } from "../components/HeaderInjection";

export const CustomerProjectsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      view: Route.query.oneOf(...Object.values(ProjectsViewType)),
      search: Route.query.string,
      page: Route.query.number,
      sort: Route.query.oneOf(...Object.values(ProjectSort)),
      statuses: Route.query.array(
        Route.query.oneOf(...Object.values(ProjectStatuses))
      ),
      categories: Route.query.array(
        Route.query.oneOf(...Object.values(BriefCategory))
      ),
      brands: Route.query.array(Route.query.string),
      users: Route.query.array(Route.query.string),
      submittedAt: Route.query.array(Route.query.string),
      shareable: Route.query.oneOf(...Object.values(BriefShareableField)),
      shareableOn: Route.query.string,
    },
    ({ id }) => `/customers/${id}/projects`
  )
);

export const CustomerProjects = CustomerProjectsRoute(
  ({
    match: {
      params: { id },
      query: {
        view = ProjectsViewType.List,
        search,
        statuses,
        categories,
        brands,
        users,
        sort,
        submittedAt,
        page = 1,
        shareable,
        shareableOn,
      },
      params,
      query,
    },
    link,
  }) => {
    const { t } = useTranslation("customers");
    const { onFilter, onSearch, onSort, onViewChange } =
      useProjectsRouteSettings({ link, query, params });
    const { startDate, endDate } = getRange(submittedAt);

    const filters = {
      briefCategory: categories as BriefCategory[],
      status: statuses as ProjectStatuses[],
      brandProfileId: brands as string[],
      userId: users as string[],
      ...(startDate &&
        endDate && {
          submittedAt: {
            startDate,
            endDate,
          },
        }),
      isDeleted: !!statuses?.includes(ProjectStatuses.deleted),
      title: search || undefined,
      shareable,
      isShareableOnDate: shareableOn,
    };

    return (
      <main>
        <Helmet title={t("projects.title")} />

        <HeaderInjector>
          <Link to={links.CreateBrief({ organizationId: id })}>
            <Button size={Button.sizes.Large}>
              <PlusIcon className="mr-4" />
              {t("projects:actions.create")}
            </Button>
          </Link>
        </HeaderInjector>

        <ProjectsView
          sort={sort}
          onSort={onSort}
          submittedAt={submittedAt as string[]}
          onFilter={onFilter}
          organizationId={id}
          search={search}
          onSearch={onSearch}
          view={view}
          onViewChange={onViewChange}
          page={page}
          filters={filters}
          className="mt-8"
          containerClassName={clsx({
            "gap-7": view === ProjectsViewType.Grid,
          })}
          renderCard={({ project, view }) =>
            !!project?.projectId && (
              <Link
                key={project.projectId}
                to={links.ProjectDetails({ id: project.projectId })}
              >
                <ProjectCard
                  className={clsx({ "mb-7": view === "list" })}
                  variant={
                    view === "grid"
                      ? ProjectCard.variants.Card
                      : ProjectCard.variants.List
                  }
                  project={project}
                />
              </Link>
            )
          }
        />
      </main>
    );
  }
);
