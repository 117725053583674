import isEqual from "react-fast-compare";
import {
  BaseHTMLAttributes,
  DetailedHTMLProps,
  memo,
  PropsWithChildren,
} from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type Props = DetailedHTMLProps<
  BaseHTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  loading?: boolean;
};

export const Skeleton = memo<PropsWithChildren<Props>>(
  ({ children, className, loading, ...props }) => {
    return (
      <div
        className={clsx(className, {
          [styles.skeletonLoading]: loading,
        })}
        {...props}
      >
        {children}
      </div>
    );
  },
  isEqual
);

Skeleton.displayName = "Skeleton";
