import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { getOperationName } from "@apollo/client/utilities";
import {
  AssetDocument,
  AssetLibraryDetailsDocument,
  BriefCommentsDocument,
  CustomerNotesDocument,
  ProjectCommentsDocument,
  ProjectNotesDocument,
  useDeleteAssetCommentMutation,
  useDeleteBriefCommentMutation,
  useDeleteCommentMutation,
  useDeleteCustomerNoteMutation,
  useDeleteProjectNoteMutation,
} from "api/graphql";
import clsx from "clsx";
import { NoteType } from "components/Note";

import styles from "./styles.module.scss";

type FormData = { confirm: string };

type Props = {
  id: string;
  noteType: NoteType;
  onClose: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const DeleteNote = memo<Props>(
  ({ className, id, noteType, onClose, ...props }) => {
    const { t } = useTranslation("common");

    const [deleteAssetComment, { loading: assetCommentLoading }] =
      useDeleteAssetCommentMutation({
        variables: {
          noteId: id,
        },
        refetchQueries: [
          getOperationName(AssetDocument)!,
          getOperationName(ProjectCommentsDocument)!,
          getOperationName(AssetLibraryDetailsDocument)!,
        ],
        awaitRefetchQueries: true,
      });

    const [deleteBriefComment, { loading: briefCommentLoading }] =
      useDeleteBriefCommentMutation({
        variables: {
          noteId: id,
        },
        refetchQueries: [getOperationName(BriefCommentsDocument)!],
        awaitRefetchQueries: true,
      });

    const [deleteComment, { loading: commentLoading }] =
      useDeleteCommentMutation({
        variables: {
          noteId: id,
        },
        refetchQueries: [
          getOperationName(AssetDocument)!,
          getOperationName(ProjectCommentsDocument)!,
          getOperationName(BriefCommentsDocument)!,
          getOperationName(AssetLibraryDetailsDocument)!,
        ],
        awaitRefetchQueries: true,
      });

    const [deleteProjectInternalNote, { loading: projectNoteLoading }] =
      useDeleteProjectNoteMutation({
        variables: {
          noteId: id,
        },
        refetchQueries: [getOperationName(ProjectNotesDocument)!],
        awaitRefetchQueries: true,
      });

    const [deleteCustomerInternalNote, { loading: customerNoteLoading }] =
      useDeleteCustomerNoteMutation({
        variables: {
          noteId: id,
        },
        refetchQueries: [getOperationName(CustomerNotesDocument)!],
        awaitRefetchQueries: true,
      });

    const actions = {
      [NoteType.AssetComment]: deleteAssetComment,
      [NoteType.BriefComment]: deleteBriefComment,
      [NoteType.OrganizationInternalNote]: deleteCustomerInternalNote,
      [NoteType.ProjectInternalNote]: deleteProjectInternalNote,
      [NoteType.Comment]: deleteComment,
    };

    const itemType = [
      NoteType.AssetComment,
      NoteType.BriefComment,
      NoteType.Comment,
    ].includes(noteType)
      ? "comments"
      : "notes";

    const validationSchema = object({
      confirm: string()
        .required("validation.required")
        .lowercase()
        .oneOf([t(`${itemType}.delete.validationWord`)]),
    });

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
    } = useFormik<FormData>({
      async onSubmit() {
        try {
          await actions[noteType]({
            variables: { noteId: id },
          });

          onClose();
        } catch (error) {
          console.log(error);
        }
      },
      validationSchema,
      initialErrors: {
        confirm: "",
      },
      initialValues: {
        confirm: "",
      },
    });

    const loading =
      assetCommentLoading ||
      projectNoteLoading ||
      customerNoteLoading ||
      briefCommentLoading ||
      commentLoading;

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(styles.deleteNote, className)}
        {...props}
      >
        <h3 className={styles.title}>{t(`${itemType}.delete.title`)}</h3>
        <h4 className={styles.explanation}>
          {t(`${itemType}.delete.explanation`)}
        </h4>

        <Input
          autoComplete="off"
          className="w-full mt-7 mb-6"
          name="confirm"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={t(`${itemType}.delete.placeholder`)}
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
        />

        <section className="flex justify-end">
          <Button
            className="mr-5"
            onClick={onClose}
            variant={Button.variants.Cancel}
            type="button"
          >
            {t("actions.cancel")}
          </Button>
          <Button
            disabled={!isValid || loading}
            type="submit"
            loading={loading}
          >
            {t(`${itemType}.delete.submit`)}
          </Button>
        </section>
      </form>
    );
  }
);
