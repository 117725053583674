import { Button, Dropdown, Loader, Modal } from "@CreativelySquared/uikit";
import { memo, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Project } from "api/types";
import { ProjectStatuses } from "Projects/utils/types";
import {
  useAcceptProjectMutation,
  useEnqueueProjectMutation,
  useProjectQuery,
  useRemoveProjectFromQueueMutation,
  useRequestProjectClarificationMutation,
  useValidateBriefQuery,
} from "api/graphql";
import { BriefErrorCodes } from "Brief/utils/errors";
import { ApolloError } from "@apollo/client";
import { ModalDescription, ModalTitle } from "components";
import { GetProps } from "react-router-hoc/lib/types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { links } from "App";

type Props = {
  projectId: Project["projectId"];
} & GetProps<typeof Modal>;

const statuses = [
  ProjectStatuses.in_progress,
  ProjectStatuses.queued,
  ProjectStatuses.pending_review,
  ProjectStatuses.clarification_requested,
];

export const UpdateStatus = memo<Props>(
  ({ onClose, className, projectId, ...props }) => {
    const { t } = useTranslation("projects");
    const { data: { getProject } = {} } = useProjectQuery({
      variables: {
        projectId,
      },
      skip: !projectId,
      fetchPolicy: "cache-first",
    });
    const { data: { isProjectSubmittable } = {}, loading: validating } =
      useValidateBriefQuery({
        variables: { projectId },
        fetchPolicy: "network-only",
      });
    const [status, setStatus] = useState(ProjectStatuses.in_progress);
    const [acceptProject, { loading: acceptLoading, error: acceptError }] =
      useAcceptProjectMutation({
        variables: {
          projectId,
        },
        onCompleted() {
          onClose?.();
        },
      });

    const [
      removeProjectFromQueue,
      { loading: startLoading, error: startError },
    ] = useRemoveProjectFromQueueMutation({
      variables: {
        projectId,
      },
      onCompleted() {
        onClose?.();
      },
    });

    const [enqueueProject, { loading: enqueueLoading, error: enqueueError }] =
      useEnqueueProjectMutation({
        variables: {
          projectId,
        },
        onCompleted() {
          onClose?.();
        },
      });

    const [
      requestClarification,
      {
        loading: projectClarificationLoading,
        error: projectClarificationError,
      },
    ] = useRequestProjectClarificationMutation({
      variables: {
        projectId,
      },
      onCompleted() {
        onClose?.();
      },
    });

    const loading =
      acceptLoading ||
      enqueueLoading ||
      startLoading ||
      projectClarificationLoading;
    const error =
      acceptError || enqueueError || startError || projectClarificationError;

    const isCreditError =
      (error as ApolloError)?.graphQLErrors?.[0]?.extensions?.code ===
      BriefErrorCodes.InsufficientOrganizationCreditsError;

    const onUpdate = useCallback(() => {
      switch (status) {
        case ProjectStatuses.in_progress:
          return acceptProject();
        case ProjectStatuses.queued:
          return enqueueProject();
        case ProjectStatuses.pending_review:
          return removeProjectFromQueue();
        case ProjectStatuses.clarification_requested:
          return requestClarification();
        default:
          return;
      }
    }, [status, acceptProject]);

    const actions = useMemo(() => {
      const currentStatus = getProject?.status;
      return statuses.filter(
        (status) =>
          !(
            status === currentStatus ||
            (status === ProjectStatuses.pending_review &&
              currentStatus === ProjectStatuses.clarification_requested) ||
            (status === ProjectStatuses.clarification_requested &&
              currentStatus !== ProjectStatuses.pending_review)
          )
      );
    }, [status, getProject?.status]);

    const UnableMessage = () => (
      <>
        <ModalTitle>{t("brief.updateStatus.unable.title")}</ModalTitle>
        <ModalDescription>
          {t("brief.updateStatus.unable.description")}
        </ModalDescription>
        <section className="flex justify-end mt-8">
          <Button
            className="mr-6"
            onClick={onClose}
            outlined
            type="button"
            variant={Button.variants.Cancel}
          >
            {t("common:actions.cancel")}
          </Button>
          <Link to={links.Brief({ id: projectId })}>
            <Button type="button">{t("brief.updateStatus.unable.edit")}</Button>
          </Link>
        </section>
      </>
    );

    const UpdateForm = () => (
      <form noValidate>
        <h3 className="mt-4">{t("brief.updateStatus.title")}</h3>
        <p className="text-base text-blackberry">
          {t("brief.updateStatus.explanation")}
        </p>
        {error && (
          <p className="my-5 text-red">
            {isCreditError
              ? t("brief.updateStatus.errors.credits")
              : error.message ?? t("brief.updateStatus.errors.common")}
          </p>
        )}
        <ul className="list-disc ml-8">
          <li className="text-base text-blackberry">
            {actions.map((status) => (
              <li key={status} className="text-base text-blackberry">
                <Trans
                  t={t}
                  i18nKey={`brief.updateStatus.items.${status}`}
                  components={{ span: <strong /> }}
                />
              </li>
            ))}
          </li>
        </ul>
        <section className="my-7 relative">
          <Dropdown
            className="w-full justify-between"
            popoverClassName="w-full"
            variant={Button.variants.Soft}
            value={status}
            onSelect={setStatus}
          >
            {actions.map((item) => (
              <Dropdown.Item className="w-full" key={item} value={item}>
                {t(`brief.updateStatus.options.${item}`)}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </section>

        <section className="flex justify-end mt-9">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t("brief.updateStatus.actions.cancel")}
          </Button>
          <Button
            loading={loading}
            onClick={onUpdate}
            variant={Button.variants.Primary}
            type="button"
          >
            {t("brief.updateStatus.actions.confirm")}
          </Button>
        </section>
      </form>
    );

    return (
      <Modal
        visible
        onClose={onClose}
        className={clsx("w-[545px]", className)}
        {...props}
      >
        {validating && <Loader radius={50} className="mx-auto my-10" />}
        {!validating &&
          (isProjectSubmittable ? <UpdateForm /> : <UnableMessage />)}
      </Modal>
    );
  }
);
