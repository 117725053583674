import React, { DetailedHTMLProps, memo, PropsWithChildren } from "react";
import clsx from "clsx";
import { ReactComponent as UncheckedIcon } from "images/unchecked.svg";
import { ReactComponent as CheckedIcon } from "images/checked.svg";

import { ReactComponent as UnstarredIcon } from "./images/unstarred.svg";
import { ReactComponent as StarredIcon } from "./images/starred.svg";

import styles from "./styles.module.scss";

type Props = {
  selected: boolean;
  starred?: boolean;
  onSelect?: () => void;
  onStar?: () => void;
  selectClassName?: string;
  starClassName?: string;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const ItemSelector = memo<PropsWithChildren<Props>>(
  ({
    selected,
    starred,
    onSelect,
    onStar,
    className,
    children,
    selectClassName,
    starClassName,
    ...props
  }) => {
    return (
      <div
        className={clsx(
          styles.itemSelector,
          {
            [styles.active]: selected,
            [styles.starred]: starred,
            [styles.noop]: !onSelect && !onStar,
          },
          className
        )}
        {...props}
      >
        {children}
        {!!onSelect && (
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect();
            }}
            type="button"
            className={clsx(styles.select, selectClassName)}
          >
            {selected ? <CheckedIcon /> : <UncheckedIcon />}
          </button>
        )}
        {!!onStar && (
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onStar();
            }}
            type="button"
            className={clsx(styles.star, starClassName)}
          >
            {starred ? <StarredIcon /> : <UnstarredIcon />}
          </button>
        )}
      </div>
    );
  }
);
