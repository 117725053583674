import {
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  useEffect,
  useState,
} from "react";
import { Media } from "components/Media";
import type { Object } from "ts-toolbelt";
import clsx from "clsx";
import { fileToBase64 } from "utils/file";
import { Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { useProtect } from "utils/hooks/protection";
import { getFullName, User } from "utils/users";
import { Roles } from "utils/roles";

import { ReactComponent as RemovedUserIcon } from "./images/removed-user.svg";

import styles from "./styles.module.scss";

type Props = {
  user?: Object.Partial<User, "deep"> | null;
  highlight?: boolean;
  imagePlaceholder?: boolean;
  onUpdate?: (data: File) => Promise<any>;
  loading?: boolean;
} & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  "ref" | "onSelect"
>;

const MAX_SIZE = 10000000;
const imageFormats = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

export const Avatar = memo<Props>(
  ({
    user,
    className,
    loading,
    onUpdate,
    highlight,
    imagePlaceholder,
    ...props
  }) => {
    const isProtected = useProtect({ user });
    const advanced = isProtected([Roles.admin, Roles.account_manager]);
    const { t } = useTranslation();
    const [image, setImage] = useState<string | null>();
    const [error, setError] = useState<string | null>();
    const isUserExists = user?.userId;
    const fullName = getFullName(user);

    useEffect(() => {
      setImage(null);
    }, [user?.avatar]);

    return (
      <section
        className={clsx(
          styles.avatar,
          { [styles.avatarEditable]: !!onUpdate },
          className
        )}
      >
        {(image || loading) && (
          <div className={styles.avatarLoader}>
            <Loader radius={50} />
          </div>
        )}

        {!image && (
          <label htmlFor={onUpdate && "upload-avatar"}>
            {!user?.avatar && (
              <div
                className={clsx(styles.avatarPlaceholder, {
                  [styles.imagePlaceholder]: imagePlaceholder,
                  "bg-grey": !isUserExists,
                })}
              >
                {!imagePlaceholder &&
                  (isUserExists ? (
                    `${user?.name?.slice(0, 1)} ${user?.surname?.slice(0, 1)}`
                  ) : (
                    <RemovedUserIcon />
                  ))}
              </div>
            )}
            {user?.avatar && (
              <Media
                {...props}
                alt={user && fullName}
                className={clsx(styles.avatarPicture, {
                  [styles.avatarPictureAdmin]: highlight && advanced,
                })}
                url={user.avatar.thumbnail?.downloadUrl?.url}
              />
            )}
          </label>
        )}
        {image && (
          <img className={styles.avatarPicture} src={image} alt={fullName} />
        )}
        {error && <p className={styles.avatarUploadError}>{error}</p>}
        {onUpdate && (
          <section className={styles.avatarUpload}>
            <label htmlFor="upload-avatar">Change photo</label>
            <input
              id="upload-avatar"
              multiple={false}
              accept={imageFormats.join()}
              onChange={async (event) => {
                if (!event.target.files?.length) return;
                if (event.target.files[0]?.size > MAX_SIZE) {
                  return setError(
                    t("user.profile.avatar.errors.size", {
                      size: (MAX_SIZE / 1000000).toFixed(2),
                    })
                  );
                }
                if (!imageFormats.includes(event.target.files[0]?.type)) {
                  return setError(t("user.profile.avatar.errors.type"));
                }
                setError(null);
                const image = await fileToBase64(event.target.files[0]);
                setImage(image);

                onUpdate(event.target.files[0]);
              }}
              className={styles.avatarUpload}
              type="file"
              name="avatar"
            />
          </section>
        )}
      </section>
    );
  }
);
