import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { idRegEx } from "utils/regex";
import { CollectionCardVariants } from "components";
import { lazy } from "react";
import { BriefCategory, CollectionSortingField } from "api/enums";
import { AssetViewType } from "DAM/components/AssetListItem";
import { SortFields, SortOrder } from "utils/order";
import { Services } from "utils/services";
import { AssetDetails } from "DAM/AssetDetails";
import { AssetRatingType } from "utils/types";

export const CustomerCollectionsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      organizationId: Route.params.regex(idRegEx),
      view: Route.query.oneOf(...Object.values(CollectionCardVariants)),
      page: Route.query.number,
      sort: Route.query.oneOf(...Object.values(CollectionSortingField)),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      createdAt: Route.query.array(Route.query.string),
      users: Route.query.array(Route.query.string),
      search: Route.query.string,
    },
    ({ organizationId }) => `/customers/${organizationId}/collections`
  )
);

export const CustomerCollections = CustomerCollectionsRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "CustomerCollections" */ "./CustomerCollections"
      )
  )
);

const params = {
  customerId: Route.params.regex(idRegEx),
  id: Route.params.regex(idRegEx),
  view: Route.query.oneOf(...Object.values(AssetViewType)),
  brands: Route.query.array(Route.query.string),
  projects: Route.query.array(Route.query.string),
  organizations: Route.query.array(Route.query.string),
  rating: Route.query.array(
    Route.query.oneOf(...Object.values(AssetRatingType))
  ),
  tags: Route.query.array(Route.query.string),
  search: Route.query.string,
  sort: Route.query.oneOf(...Object.values(SortFields)),
  sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
  assetTypes: Route.query.array(Route.query.oneOf(...Object.values(Services))),
  categories: Route.query.array(
    Route.query.oneOf(...Object.values(BriefCategory))
  ),
  createdAt: Route.query.array(Route.query.string),
  page: Route.query.number,
};

export const CustomerCollectionDetailsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    params,
    ({ id, customerId }) => `/customers/${customerId}/collections/${id}`
  )
);

export const CustomerCollectionDetails = CustomerCollectionDetailsRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "CustomerCollectionDetails" */ "./CustomerCollectionDetails"
      )
  )
);

export const CustomerCollectionDetailsAssetRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      ...params,
      assetId: Route.params.regex(idRegEx),
      versionId: Route.query.regex(idRegEx),
    },
    ({ id, assetId, customerId }) =>
      `/customers/${customerId}/collections/${id}/assets/${assetId}`
  )
);

export const CustomerCollectionDetailsAsset =
  CustomerCollectionDetailsAssetRoute(AssetDetails);
