import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useProjectNotesQuery } from "api/graphql";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { useMemo } from "react";
import { ProtectedRoute } from "utils/route";
import { Note, NoteForm } from "components/Note";
import { orderBy } from "lodash";
import { withSuspense } from "utils/hooks/suspense";

import styles from "./styles.module.scss";

export const ProjectNotesRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
    },
    ({ id }) => `/projects/${id}/notes`
  )
);

const useProjectNotesSuspenseQuery = withSuspense(useProjectNotesQuery);

export const ProjectNotes = ProjectNotesRoute(
  ({
    match: {
      params: { id },
    },
  }) => {
    const { t } = useTranslation("projects");

    const { data: { getProjectInternalNotes } = {} } =
      useProjectNotesSuspenseQuery({
        variables: { projectId: id, includeDeleted: false },
        suspense: true,
      });

    const notes = useMemo(
      () =>
        getProjectInternalNotes &&
        orderBy(getProjectInternalNotes, ["createdAt"], ["desc"]),
      [getProjectInternalNotes]
    );

    return (
      <>
        <Helmet title={t("notes.title")} />

        <section className={styles.projectNotes}>
          {notes?.length === 0 && (
            <section className={styles.projectNotesEmpty}>
              <h2>{t("notes.empty.title")}</h2>
              <span>{t("notes.empty.description")}</span>
            </section>
          )}

          {notes?.length !== 0 && (
            <section>
              {notes?.map(
                (note) =>
                  note && (
                    <Note
                      border
                      data={note}
                      key={note?.projectInternalNoteId}
                      enableMentioning={false}
                      enableAttaching={false}
                    />
                  )
              )}
            </section>
          )}

          <NoteForm
            targetId={id}
            noteType={Note.types.ProjectInternalNote}
            enableMentioning={false}
            enableAttaching={false}
          />
        </section>
      </>
    );
  }
);
