import { memo, SVGProps } from "react";

type Props = {
  unlocked?: boolean;
};

const PasswordIcon = memo<SVGProps<SVGSVGElement> & Props>(
  ({ unlocked, ...props }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        {...props}
      >
        {unlocked ? (
          <path
            d="M4.66667 7.33326V5.33326C4.66667 3.49231 6.15905 1.99992 8 1.99992C9.61264 1.99992 10.9578 3.1451 11.2667 4.66659M5.2 13.9999H10.8C11.9201 13.9999 12.4802 13.9999 12.908 13.7819C13.2843 13.5902 13.5903 13.2842 13.782 12.9079C14 12.4801 14 11.92 14 10.7999V10.5333C14 9.41315 14 8.8531 13.782 8.42528C13.5903 8.04895 13.2843 7.74299 12.908 7.55124C12.4802 7.33326 11.9201 7.33326 10.8 7.33326H5.2C4.0799 7.33326 3.51984 7.33326 3.09202 7.55124C2.71569 7.74299 2.40973 8.04895 2.21799 8.42528C2 8.8531 2 9.41315 2 10.5333V10.7999C2 11.92 2 12.4801 2.21799 12.9079C2.40973 13.2842 2.71569 13.5902 3.09202 13.7819C3.51984 13.9999 4.0799 13.9999 5.2 13.9999Z"
            stroke="#B5C1D6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : (
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M11.3333 7.33326V5.33326C11.3333 3.49231 9.84095 1.99992 8 1.99992C6.15905 1.99992 4.66667 3.49231 4.66667 5.33326V7.33326M5.2 13.9999H10.8C11.9201 13.9999 12.4802 13.9999 12.908 13.7819C13.2843 13.5902 13.5903 13.2842 13.782 12.9079C14 12.4801 14 11.92 14 10.7999V10.5333C14 9.41315 14 8.8531 13.782 8.42528C13.5903 8.04895 13.2843 7.74299 12.908 7.55124C12.4802 7.33326 11.9201 7.33326 10.8 7.33326H5.2C4.0799 7.33326 3.51984 7.33326 3.09202 7.55124C2.71569 7.74299 2.40973 8.04895 2.21799 8.42528C2 8.8531 2 9.41315 2 10.5333V10.7999C2 11.92 2 12.4801 2.21799 12.9079C2.40973 13.2842 2.71569 13.5902 3.09202 13.7819C3.51984 13.9999 4.0799 13.9999 5.2 13.9999Z"
            stroke="#B5C1D6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    );
  }
);

PasswordIcon.displayName = "PasswordIcon";

export default PasswordIcon;
