import { useState } from "react";
import { filterFilesByFormats } from "utils/file";
import { MediaFormats } from "utils/types";

interface Props {
  onUpload: (data: File[]) => void;
  formats?: Array<MediaFormats>;
}

export const useDragAndDrop = <T extends HTMLElement>({
  onUpload,
  formats,
}: Props) => {
  const [dragOver, setDragOver] = useState(false);

  // Disable drag-and-drop functionality if the browser does not support webkitGetAsEntry.
  // This ensures that only files (not directories) are processed during drag-and-drop.
  const disabled = !DataTransferItem.prototype.webkitGetAsEntry;

  const onDrop = (event: React.DragEvent<T>) => {
    event.preventDefault();
    if (disabled) return setDragOver(false);
    const files = [...event.dataTransfer.items]?.flatMap((item) => {
      if (item?.webkitGetAsEntry?.()?.isFile) {
        const file = item.getAsFile();
        return file ? [file] : [];
      }
      return [];
    });

    if (files.length) {
      onUpload(formats ? filterFilesByFormats(files, formats) : files);
    }

    setDragOver(false);
  };

  const onDragOver = (event: React.DragEvent<T>) => {
    if (disabled) return setDragOver(false);

    event.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = (event: React.DragEvent<T>) => {
    event.preventDefault();
    setDragOver(false);
  };

  return { dragOver, onDrop, onDragOver, onDragLeave };
};
