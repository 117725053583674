enum Regions {
  Worldwide = "Worldwide",
  Africa = "Africa",
  Asia = "Asia",
  Europe = "Europe",
  Oceania = "Oceania",
  "Central America" = "Central America",
  "North America" = "North America",
  "South America" = "South America",
  "The Caribbean" = "The Caribbean",
}

export const countries = {
  [Regions.Africa]: [
    {
      name: "Algeria",
      code: "DZ",
    },
    {
      name: "Angola",
      code: "AO",
    },
    {
      name: "Benin",
      code: "BJ",
    },
    {
      name: "Botswana",
      code: "BW",
    },
    {
      name: "British Indian Ocean Territory",
      code: "IO",
    },
    {
      name: "Burkina Faso",
      code: "BF",
    },
    {
      name: "Burundi",
      code: "BI",
    },
    {
      name: "Cabo Verde",
      code: "CV",
    },
    {
      name: "Cameroon",
      code: "CM",
    },
    {
      name: "Central African Republic",
      code: "CF",
    },
    {
      name: "Chad",
      code: "TD",
    },
    {
      name: "Comoros",
      code: "KM",
    },
    {
      name: "Congo",
      code: "CG",
    },
    {
      name: "Congo, Democratic Republic of the",
      code: "CD",
    },
    {
      name: "Côte d'Ivoire",
      code: "CI",
    },
    {
      name: "Djibouti",
      code: "DJ",
    },
    {
      name: "Egypt",
      code: "EG",
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
    },
    {
      name: "Eritrea",
      code: "ER",
    },
    {
      name: "Eswatini",
      code: "SZ",
    },
    {
      name: "Ethiopia",
      code: "ET",
    },
    {
      name: "French Southern Territories",
      code: "TF",
    },
    {
      name: "Gabon",
      code: "GA",
    },
    {
      name: "Gambia",
      code: "GM",
    },
    {
      name: "Ghana",
      code: "GH",
    },
    {
      name: "Guinea",
      code: "GN",
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
    },
    {
      name: "Kenya",
      code: "KE",
    },
    {
      name: "Lesotho",
      code: "LS",
    },
    {
      name: "Liberia",
      code: "LR",
    },
    {
      name: "Libya",
      code: "LY",
    },
    {
      name: "Madagascar",
      code: "MG",
    },
    {
      name: "Malawi",
      code: "MW",
    },
    {
      name: "Mali",
      code: "ML",
    },
    {
      name: "Mauritania",
      code: "MR",
    },
    {
      name: "Mauritius",
      code: "MU",
    },
    {
      name: "Mayotte",
      code: "YT",
    },
    {
      name: "Morocco",
      code: "MA",
    },
    {
      name: "Mozambique",
      code: "MZ",
    },
    {
      name: "Namibia",
      code: "NA",
    },
    {
      name: "Niger",
      code: "NE",
    },
    {
      name: "Nigeria",
      code: "NG",
    },
    {
      name: "Réunion",
      code: "RE",
    },
    {
      name: "Rwanda",
      code: "RW",
    },
    {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      code: "SH",
    },
    {
      name: "Sao Tome and Principe",
      code: "ST",
    },
    {
      name: "Senegal",
      code: "SN",
    },
    {
      name: "Seychelles",
      code: "SC",
    },
    {
      name: "Sierra Leone",
      code: "SL",
    },
    {
      name: "Somalia",
      code: "SO",
    },
    {
      name: "South Africa",
      code: "ZA",
    },
    {
      name: "South Sudan",
      code: "SS",
    },
    {
      name: "Sudan",
      code: "SD",
    },
    {
      name: "Tanzania, United Republic of",
      code: "TZ",
    },
    {
      name: "Togo",
      code: "TG",
    },
    {
      name: "Tunisia",
      code: "TN",
    },
    {
      name: "Uganda",
      code: "UG",
    },
    {
      name: "Western Sahara",
      code: "EH",
    },
    {
      name: "Zambia",
      code: "ZM",
    },
    {
      name: "Zimbabwe",
      code: "ZW",
    },
  ],
  [Regions.Asia]: [
    {
      name: "Afghanistan",
      code: "AF",
    },
    {
      name: "Armenia",
      code: "AM",
    },
    {
      name: "Azerbaijan",
      code: "AZ",
    },
    {
      name: "Bahrain",
      code: "BH",
    },
    {
      name: "Bangladesh",
      code: "BD",
    },
    {
      name: "Bhutan",
      code: "BT",
    },
    {
      name: "Brunei Darussalam",
      code: "BN",
    },
    {
      name: "Cambodia",
      code: "KH",
    },
    {
      name: "China",
      code: "CN",
    },
    {
      name: "Cyprus",
      code: "CY",
    },
    {
      name: "Georgia",
      code: "GE",
    },
    {
      name: "Hong Kong",
      code: "HK",
    },
    {
      name: "India",
      code: "IN",
    },
    {
      name: "Indonesia",
      code: "ID",
    },
    {
      name: "Iran (Islamic Republic of)",
      code: "IR",
    },
    {
      name: "Iraq",
      code: "IQ",
    },
    {
      name: "Israel",
      code: "IL",
    },
    {
      name: "Japan",
      code: "JP",
    },
    {
      name: "Jordan",
      code: "JO",
    },
    {
      name: "Kazakhstan",
      code: "KZ",
    },
    {
      name: "Korea (Democratic People's Republic of)",
      code: "KP",
    },
    {
      name: "Korea, Republic of",
      code: "KR",
    },
    {
      name: "Kuwait",
      code: "KW",
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
    },
    {
      name: "Lao People's Democratic Republic",
      code: "LA",
    },
    {
      name: "Lebanon",
      code: "LB",
    },
    {
      name: "Macao",
      code: "MO",
    },
    {
      name: "Malaysia",
      code: "MY",
    },
    {
      name: "Maldives",
      code: "MV",
    },
    {
      name: "Mongolia",
      code: "MN",
    },
    {
      name: "Myanmar",
      code: "MM",
    },
    {
      name: "Nepal",
      code: "NP",
    },
    {
      name: "Oman",
      code: "OM",
    },
    {
      name: "Pakistan",
      code: "PK",
    },
    {
      name: "Palestine, State of",
      code: "PS",
    },
    {
      name: "Philippines",
      code: "PH",
    },
    {
      name: "Qatar",
      code: "QA",
    },
    {
      name: "Saudi Arabia",
      code: "SA",
    },
    {
      name: "Singapore",
      code: "SG",
    },
    {
      name: "Sri Lanka",
      code: "LK",
    },
    {
      name: "Syrian Arab Republic",
      code: "SY",
    },
    {
      name: "Taiwan",
      code: "TW",
    },
    {
      name: "Tajikistan",
      code: "TJ",
    },
    {
      name: "Thailand",
      code: "TH",
    },
    {
      name: "Timor-Leste",
      code: "TL",
    },
    {
      name: "Turkey",
      code: "TR",
    },
    {
      name: "Turkmenistan",
      code: "TM",
    },
    {
      name: "United Arab Emirates",
      code: "AE",
    },
    {
      name: "Uzbekistan",
      code: "UZ",
    },
    {
      name: "Vietnam",
      code: "VN",
    },
    {
      name: "Yemen",
      code: "YE",
    },
  ],
  [Regions.Europe]: [
    {
      name: "Åland Islands",
      code: "AX",
    },
    {
      name: "Albania",
      code: "AL",
    },
    {
      name: "Andorra",
      code: "AD",
    },
    {
      name: "Austria",
      code: "AT",
    },
    {
      name: "Belarus",
      code: "BY",
    },
    {
      name: "Belgium",
      code: "BE",
    },
    {
      name: "Bosnia and Herzegovina",
      code: "BA",
    },
    {
      name: "Bulgaria",
      code: "BG",
    },
    {
      name: "Croatia",
      code: "HR",
    },
    {
      name: "Czechia",
      code: "CZ",
    },
    {
      name: "Denmark",
      code: "DK",
    },
    {
      name: "Estonia",
      code: "EE",
    },
    {
      name: "Faroe Islands",
      code: "FO",
    },
    {
      name: "Finland",
      code: "FI",
    },
    {
      name: "France",
      code: "FR",
    },
    {
      name: "Germany",
      code: "DE",
    },
    {
      name: "Gibraltar",
      code: "GI",
    },
    {
      name: "Greece",
      code: "GR",
    },
    {
      name: "Guernsey",
      code: "GG",
    },
    {
      name: "Holy See",
      code: "VA",
    },
    {
      name: "Hungary",
      code: "HU",
    },
    {
      name: "Iceland",
      code: "IS",
    },
    {
      name: "Ireland",
      code: "IE",
    },
    {
      name: "Isle of Man",
      code: "IM",
    },
    {
      name: "Italy",
      code: "IT",
    },
    {
      name: "Jersey",
      code: "JE",
    },
    {
      name: "Latvia",
      code: "LV",
    },
    {
      name: "Liechtenstein",
      code: "LI",
    },
    {
      name: "Lithuania",
      code: "LT",
    },
    {
      name: "Luxembourg",
      code: "LU",
    },
    {
      name: "Malta",
      code: "MT",
    },
    {
      name: "Moldova, Republic of",
      code: "MD",
    },
    {
      name: "Monaco",
      code: "MC",
    },
    {
      name: "Montenegro",
      code: "ME",
    },
    {
      name: "Netherlands",
      code: "NL",
    },
    {
      name: "North Macedonia",
      code: "MK",
    },
    {
      name: "Norway",
      code: "NO",
    },
    {
      name: "Poland",
      code: "PL",
    },
    {
      name: "Portugal",
      code: "PT",
    },
    {
      name: "Romania",
      code: "RO",
    },
    {
      name: "Russian Federation",
      code: "RU",
    },
    {
      name: "San Marino",
      code: "SM",
    },
    {
      name: "Serbia",
      code: "RS",
    },
    {
      name: "Slovakia",
      code: "SK",
    },
    {
      name: "Slovenia",
      code: "SI",
    },
    {
      name: "Spain",
      code: "ES",
    },
    {
      name: "Svalbard and Jan Mayen",
      code: "SJ",
    },
    {
      name: "Sweden",
      code: "SE",
    },
    {
      name: "Switzerland",
      code: "CH",
    },
    {
      name: "Ukraine",
      code: "UA",
    },
    {
      name: "United Kingdom of Great Britain and Northern Ireland",
      code: "GB",
    },
  ],
  [Regions.Oceania]: [
    {
      name: "American Samoa",
      code: "AS",
    },
    {
      name: "Australia",
      code: "AU",
    },
    {
      name: "Christmas Island",
      code: "CX",
    },
    {
      name: "Cocos (Keeling) Islands",
      code: "CC",
    },
    {
      name: "Cook Islands",
      code: "CK",
    },
    {
      name: "Fiji",
      code: "FJ",
    },
    {
      name: "French Polynesia",
      code: "PF",
    },
    {
      name: "Guam",
      code: "GU",
    },
    {
      name: "Heard Island and McDonald Islands",
      code: "HM",
    },
    {
      name: "Kiribati",
      code: "KI",
    },
    {
      name: "Marshall Islands",
      code: "MH",
    },
    {
      name: "Micronesia (Federated States of)",
      code: "FM",
    },
    {
      name: "Nauru",
      code: "NR",
    },
    {
      name: "New Caledonia",
      code: "NC",
    },
    {
      name: "New Zealand",
      code: "NZ",
    },
    {
      name: "Niue",
      code: "NU",
    },
    {
      name: "Norfolk Island",
      code: "NF",
    },
    {
      name: "Northern Mariana Islands",
      code: "MP",
    },
    {
      name: "Palau",
      code: "PW",
    },
    {
      name: "Papua New Guinea",
      code: "PG",
    },
    {
      name: "Pitcairn",
      code: "PN",
    },
    {
      name: "Samoa",
      code: "WS",
    },
    {
      name: "Solomon Islands",
      code: "SB",
    },
    {
      name: "Tokelau",
      code: "TK",
    },
    {
      name: "Tonga",
      code: "TO",
    },
    {
      name: "Tuvalu",
      code: "TV",
    },
    {
      name: "United States Minor Outlying Islands",
      code: "UM",
    },
    {
      name: "Vanuatu",
      code: "VU",
    },
    {
      name: "Wallis and Futuna",
      code: "WF",
    },
  ],
  [Regions["Central America"]]: [
    {
      name: "Belize",
      code: "BZ",
    },
    {
      name: "Costa Rica",
      code: "CR",
    },
    {
      name: "El Salvador",
      code: "SV",
    },
    {
      name: "Guatemala",
      code: "GT",
    },
    {
      name: "Honduras",
      code: "HN",
    },
    {
      name: "Mexico",
      code: "MX",
    },
    {
      name: "Nicaragua",
      code: "NI",
    },
    {
      name: "Panama",
      code: "PA",
    },
  ],
  [Regions["North America"]]: [
    {
      name: "Bermuda",
      code: "BM",
    },
    {
      name: "Canada",
      code: "CA",
    },
    {
      name: "Greenland",
      code: "GL",
    },
    {
      name: "Saint Pierre and Miquelon",
      code: "PM",
    },
    {
      name: "United States of America",
      code: "US",
    },
  ],
  [Regions["South America"]]: [
    {
      name: "Argentina",
      code: "AR",
    },
    {
      name: "Bolivia (Plurinational State of)",
      code: "BO",
    },
    {
      name: "Bouvet Island",
      code: "BV",
    },
    {
      name: "Brazil",
      code: "BR",
    },
    {
      name: "Chile",
      code: "CL",
    },
    {
      name: "Colombia",
      code: "CO",
    },
    {
      name: "Ecuador",
      code: "EC",
    },
    {
      name: "Falkland Islands (Malvinas)",
      code: "FK",
    },
    {
      name: "French Guiana",
      code: "GF",
    },
    {
      name: "Guyana",
      code: "GY",
    },
    {
      name: "Paraguay",
      code: "PY",
    },
    {
      name: "Peru",
      code: "PE",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      code: "GS",
    },
    {
      name: "Suriname",
      code: "SR",
    },
    {
      name: "Uruguay",
      code: "UY",
    },
    {
      name: "Venezuela (Bolivarian Republic of)",
      code: "VE",
    },
  ],
  [Regions["The Caribbean"]]: [
    {
      name: "Anguilla",
      code: "AI",
    },
    {
      name: "Antigua and Barbuda",
      code: "AG",
    },
    {
      name: "Aruba",
      code: "AW",
    },
    {
      name: "Bahamas",
      code: "BS",
    },
    {
      name: "Barbados",
      code: "BB",
    },
    {
      name: "Bonaire, Sint Eustatius and Saba",
      code: "BQ",
    },
    {
      name: "Cayman Islands",
      code: "KY",
    },
    {
      name: "Cuba",
      code: "CU",
    },
    {
      name: "Curaçao",
      code: "CW",
    },
    {
      name: "Dominica",
      code: "DM",
    },
    {
      name: "Dominican Republic",
      code: "DO",
    },
    {
      name: "Grenada",
      code: "GD",
    },
    {
      name: "Guadeloupe",
      code: "GP",
    },
    {
      name: "Haiti",
      code: "HT",
    },
    {
      name: "Jamaica",
      code: "JM",
    },
    {
      name: "Martinique",
      code: "MQ",
    },
    {
      name: "Montserrat",
      code: "MS",
    },
    {
      name: "Puerto Rico",
      code: "PR",
    },
    {
      name: "Saint Barthélemy",
      code: "BL",
    },
    {
      name: "Saint Kitts and Nevis",
      code: "KN",
    },
    {
      name: "Saint Lucia",
      code: "LC",
    },
    {
      name: "Saint Martin (French part)",
      code: "MF",
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "VC",
    },
    {
      name: "Sint Maarten (Dutch part)",
      code: "SX",
    },
    {
      name: "Trinidad and Tobago",
      code: "TT",
    },
    {
      name: "Turks and Caicos Islands",
      code: "TC",
    },
    {
      name: "Virgin Islands (British)",
      code: "VG",
    },
    {
      name: "Virgin Islands (U.S.)",
      code: "VI",
    },
  ],
};

export const getCountryByCode = (code: string) =>
  Object.values(countries)
    .flat()
    .find((el) => el.code === code)?.name;

export const getCodeByCountry = (country: string) =>
  Object.values(countries)
    .flat()
    .find((el) => el.name === country)?.code;

export const allCountriesCode = Object.values(countries)
  .flat()
  .map((country) => country.code);

export const groupCountries = (value: string[]) => {
  if (allCountriesCode.every((country) => value.includes(country))) {
    return [Regions.Worldwide];
  }

  const groupedCountries = Object.entries(countries).reduce(
    (acc, [region, countries]) => {
      const regionCountriesCode = countries.map((country) => country.code);
      if (regionCountriesCode.every((country) => value.includes(country))) {
        return [
          region,
          ...acc.filter((country) => !regionCountriesCode.includes(country)),
        ];
      }
      return acc;
    },
    value
  );

  return groupedCountries.map(
    (country) => getCountryByCode(country) ?? country
  );
};

export const ungroupCountries = (value: string[]) => {
  if (value.includes(Regions.Worldwide)) {
    return allCountriesCode;
  }

  return value.flatMap(
    (country) =>
      countries[country as keyof typeof countries]?.map((item) => item.code) ??
      getCodeByCountry(country)
  );
};

export const filterCountries = (search: string) =>
  Object.entries(countries).reduce((acc, [region, countries]) => {
    const filteredValue = countries.filter((country) =>
      country.name.toLowerCase().includes(search.toLowerCase())
    );
    if (filteredValue.length) {
      return {
        ...acc,
        [region]: filteredValue,
      };
    }
    return acc;
  }, {}) as typeof countries;
