import { memo, useMemo } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Textarea, Tooltip } from "@CreativelySquared/uikit";
import {
  ProjectAssetsDocument,
  GalleryProjectDocument,
  useCreateProjectFeedbackMutation,
  useEditProjectFeedbackMutation,
} from "api/graphql";
import { Project } from "api/types";
import { Object as ObjectType } from "ts-toolbelt";
import { getOperationName } from "@apollo/client/utilities";
import { ReactComponent as CrossIcon } from "images/cross.svg";

import { ReactComponent as Mark5 } from "./images/mark_5.svg";
import { ReactComponent as Mark4 } from "./images/mark_4.svg";
import { ReactComponent as Mark3 } from "./images/mark_3.svg";
import { ReactComponent as Mark2 } from "./images/mark_2.svg";
import { ReactComponent as Mark1 } from "./images/mark_1.svg";

import styles from "./styles.module.scss";

const options = {
  5: Mark5,
  4: Mark4,
  3: Mark3,
  2: Mark2,
  1: Mark1,
};

type Props = {
  project: ObjectType.Partial<
    Pick<Project, "projectId" | "myFeedback">,
    "deep"
  >;
  onClose?: () => void;
};

type FormData = {
  isFeedbackCallRequested?: boolean;
  note?: string;
  vote: number;
};

export const ProjectGalleryRating = memo<Props>(({ project, onClose }) => {
  const { t } = useTranslation("projects");

  const initialValues = useMemo(
    () => ({
      isFeedbackCallRequested:
        project.myFeedback?.isFeedbackCallRequested ?? false,
      vote: project.myFeedback?.vote ?? 0,
      note: project.myFeedback?.note ?? "",
    }),
    [project.myFeedback]
  );

  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    values: { vote, note, isFeedbackCallRequested },
  } = useFormik<FormData>({
    initialValues,
    onSubmit: async ({ vote, ...values }) => {
      if (!vote || !project.projectId) return;

      if (project.myFeedback?.projectFeedbackId) {
        await editFeedback({
          variables: {
            projectFeedbackId: project.myFeedback?.projectFeedbackId,
            projectFeedback: {
              vote: Number(vote),
              ...values,
            },
          },
        });
      } else {
        await createFeedback({
          variables: {
            projectId: project?.projectId,
            projectFeedback: {
              vote: Number(vote),
              ...values,
            },
          },
        });
      }

      onClose?.();
    },
  });

  const [createFeedback] = useCreateProjectFeedbackMutation({
    refetchQueries: [
      getOperationName(GalleryProjectDocument)!,
      getOperationName(ProjectAssetsDocument)!,
    ],
    awaitRefetchQueries: true,
  });
  const [editFeedback] = useEditProjectFeedbackMutation();

  return (
    <section className={styles.galleryRating}>
      <Button
        onClick={onClose}
        variant={Button.variants.Icon}
        className={styles.galleryRatingClose}
      >
        <CrossIcon />
      </Button>
      <section className="flex gap-9 grow">
        <section className={clsx("grow flex flex-col w-full")}>
          <h3>{t("rating.form.title", { count: +!!vote })}</h3>

          <p>{t("rating.form.description", { count: +!!vote })}</p>

          <ul className={styles.galleryRatingOptions}>
            {Object.entries(options)
              .reverse()
              .map(([option, Icon]) => (
                <Tooltip
                  key={option}
                  placement={Tooltip.placements.Bottom}
                  variant={Tooltip.variants.Secondary}
                  title={t(`rating.form.options.${option}.description`)}
                >
                  <li
                    onClick={() => setFieldValue("vote", option)}
                    className={clsx(styles.galleryRatingOptionsItem, {
                      [styles.galleryRatingOptionsItemActive]:
                        +option === +vote,
                    })}
                  >
                    <Icon />
                  </li>
                </Tooltip>
              ))}
          </ul>
        </section>

        {!!vote && (
          <form onSubmit={handleSubmit} className="grow self-end w-full">
            <Textarea
              name="note"
              className="flex"
              inputClassName="h-[130px]"
              rows={5}
              onChange={handleChange}
              onBlur={handleBlur}
              value={note}
            />

            <Checkbox
              name="isFeedbackCallRequested"
              checked={isFeedbackCallRequested}
              onChange={handleChange}
              onBlur={handleBlur}
              className="my-5"
            >
              <span>{t("rating.form.feedback.call.label")}</span>
            </Checkbox>

            <Button loading={isSubmitting} type="submit" className="w-full">
              {t("rating.form.feedback.actions.submit", {
                count: +!!project.myFeedback?.projectFeedbackId,
              })}
            </Button>
          </form>
        )}
      </section>
    </section>
  );
});
