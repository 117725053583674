import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { ProtectedRoute } from "utils/route";

export const CreateProfileRoute = compose(
  ProtectedRoute({
    access: ({ draftStatus: { profileModificationPending } }) =>
      profileModificationPending,
  }),
  Route("/registration/create-profile")
);

export const CreateProfile = CreateProfileRoute(
  lazy(() => import(/* webpackChunkName: "CreateProfile" */ "./CreateProfile"))
);
