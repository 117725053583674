import { Avatar } from "components/Avatar";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  PropsWithChildren,
} from "react";
import { Object } from "ts-toolbelt";
import { getFullName, User } from "utils/users";
import clsx from "clsx";

import styles from "./styles.module.scss";

type Props = {
  user?: Object.Partial<User, "deep"> | null;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Profile = memo<PropsWithChildren<Props>>(
  ({ children, user, className, ...props }) => (
    <div className={clsx([styles.profile, className])} {...props}>
      <div className={styles.profileContainer}>
        <Avatar className={styles.avatar} user={user} />

        <div className={styles.details}>
          <div className={styles.name}>{getFullName(user)}</div>
          <div className={styles.footer}>{children}</div>
        </div>
      </div>
    </div>
  )
);
