import React, { createContext, useState, useContext, ReactNode } from "react";

type PreviewProps = {
  previewURL?: string;
  mediaType?: string;
  onDownload?: () => void;
  loading?: boolean;
} | null;

interface PreviewContextType {
  preview: PreviewProps;
  setPreview: (preview: PreviewProps) => void;
  setPreviewLoading: (loading?: boolean) => void;
}

const PreviewContext = createContext<PreviewContextType>({
  preview: null,
  setPreview: () => {},
  setPreviewLoading: () => {},
});

export const PreviewProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [preview, setPreview] = useState<PreviewProps>(null);

  const setPreviewLoading = (loading?: boolean) =>
    setPreview((prev) => ({ ...prev, loading }));

  return (
    <PreviewContext.Provider value={{ preview, setPreview, setPreviewLoading }}>
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreview = () => useContext(PreviewContext);
