import memoize from "lodash/memoize";

export const conceptNumberGenerator = () =>
  memoize(
    (() => {
      let id = "";
      let count = 0;
      return (value: string) => {
        if (!value) return value;
        if (value !== id) {
          id = value;
          return ++count;
        }
        return count;
      };
    })()
  );
