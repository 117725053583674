import { Card } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { FC } from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
}

export const MediaCardSkeleton: FC<Props> = ({ className }) => {
  return (
    <Card
      className={clsx(styles.mediaCard, className)}
      variant={Card.variants.List}
    >
      <Card.Image className={styles.image}>
        <Skeleton className="w-full h-full" loading />
      </Card.Image>
      <Card.Description>
        <Skeleton className="w-full h-9 mt-[7px]" loading />
      </Card.Description>
    </Card>
  );
};
