import {
  BriefCategory,
  GetProjectsFilter,
  ProjectNodeFragment,
} from "api/types";
import { useCallback, useState } from "react";
import {
  ProjectFilter,
  ProjectSort,
  ProjectStatuses,
} from "Projects/utils/types";
import { getRange } from "utils/time";
import { ProjectsViewType } from "Projects/components/ProjectsView";
import { useProfileQuery } from "api/graphql";
import { ItemSelector, ProjectCard } from "components";
import { xorBy } from "lodash";

type Props = {
  multiple?: boolean;
};

export const useProjectsSettings = ({ multiple }: Props = {}) => {
  const { data: { me: profile } = {} } = useProfileQuery();
  const [view, setView] = useState(ProjectsViewType.Grid);
  const [search, setSearch] = useState<undefined | string>(undefined);
  const [sort, setSort] = useState<ProjectSort | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [selectedProjects, setSelectedProjects] = useState<
    Array<ProjectNodeFragment>
  >([]);
  const [filters, setFilters] =
    useState<{ [key in ProjectFilter]: string[] }>();

  const organizationId =
    profile?.__typename === "OrganizationUser"
      ? profile?.organization?.organizationId ?? ""
      : undefined;

  const { startDate, endDate } = getRange(filters?.submittedAt);
  const projectsFilters: Omit<GetProjectsFilter, "status"> & {
    status?: ProjectStatuses[];
  } = {
    briefCategory: filters?.categories as BriefCategory[],
    status: filters?.statuses as ProjectStatuses[],
    brandProfileId: filters?.brands,
    userId: filters?.users,
    ...(startDate &&
      endDate && {
        submittedAt: {
          startDate,
          endDate,
        },
      }),
    isDeleted: !!filters?.statuses?.includes(ProjectStatuses.deleted),
    title: search || undefined,
    shareable: filters?.shareable?.[0],
    isShareableOnDate: filters?.shareableOn?.[0],
  };

  const onSearch = (value?: string) => {
    setSearch(value);
    setPage(1);
  };

  const onSort = (value: ProjectSort) => {
    setSort(value);
    setPage(1);
  };

  const onFilter = (value: Record<ProjectFilter, string[]>) => {
    setFilters(value);
    setPage(1);
  };

  const onSelectProject = useCallback(
    (project: ProjectNodeFragment) => {
      if (multiple) {
        setSelectedProjects(xorBy(selectedProjects, [project], "projectId"));
      } else {
        setSelectedProjects(
          project?.projectId === selectedProjects?.[0]?.projectId
            ? []
            : [project]
        );
      }
    },
    [selectedProjects]
  );

  const renderCard = ({
    project,
    selected,
    onSelect,
    view,
  }: {
    project?: ProjectNodeFragment | null;
    selected?: boolean;
    onSelect?: (project: ProjectNodeFragment) => void;
    view?: ProjectsViewType;
  }) =>
    !!project?.projectId && (
      <div
        onClick={onSelect as unknown as React.MouseEventHandler<HTMLDivElement>}
        key={project.projectId}
        className="w-full h-full mb-4 cursor-pointer"
      >
        <ItemSelector
          selected={!!selected}
          onSelect={onSelect ? () => onSelect(project) : undefined}
          className="after:rounded-[10px] hover:after:bg-none"
        >
          <ProjectCard
            variant={
              view === ProjectsViewType.Grid
                ? ProjectCard.variants.Card
                : ProjectCard.variants.List
            }
            project={project}
            hideActions
          />
        </ItemSelector>
      </div>
    );

  return {
    view,
    filters: projectsFilters,
    search,
    sort,
    submittedAt: filters?.submittedAt,
    page,
    onViewChange: setView,
    onPageChange: setPage,
    onSearch,
    onSort,
    onFilter,
    renderCard,
    organizationId,
    selectedProjects,
    onSelectProject,
  };
};
