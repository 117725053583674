import { Project } from "api/types";
import { FC, useCallback, useEffect, useState } from "react";

import { AddCommentModal } from "./AddCommentModal";
import { SuccessModal } from "./SuccessModal";

interface Props {
  visible: boolean;
  onClose: () => void;
  onFinish: () => void;
  assetIds: string[];
  projectId?: Project["projectId"];
}

export const Comment: FC<Props> = ({
  visible,
  onClose,
  onFinish,
  assetIds,
  projectId,
}) => {
  const [isAddCommentVisible, setAddComment] = useState(false);
  const [isSuccessVisible, setSuccess] = useState(false);

  const handleClose = useCallback(() => {
    setAddComment(false);
    onClose();
  }, [onClose]);

  const toggleSuccessModal = useCallback(() => setSuccess((prev) => !prev), []);

  const handleSubmit = useCallback(() => {
    setAddComment(false);
    toggleSuccessModal();
  }, []);

  const handleSuccessClose = useCallback(() => {
    toggleSuccessModal();
    onFinish();
  }, [onFinish]);

  useEffect(() => {
    if (visible !== isAddCommentVisible && !isSuccessVisible) {
      setAddComment(visible);
    }
  }, [visible]);

  return (
    <>
      <AddCommentModal
        visible={isAddCommentVisible}
        onClose={handleClose}
        onSubmit={handleSubmit}
        assetIds={assetIds}
        projectId={projectId}
      />
      <SuccessModal visible={isSuccessVisible} onClose={handleSuccessClose} />
    </>
  );
};
