import { ProjectNodeFragment } from "api/types";
import { memo, useMemo, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Label,
  Modal,
  Tooltip,
} from "@CreativelySquared/uikit";
import { format } from "date-fns";
import { AssetLabel, Media, DividedList, useNotification } from "components";
import { GetProps } from "react-router-hoc/lib/types";
import { Protect } from "components/Protect";
import clsx from "clsx";
import { cn } from "utils/styles";
import { useTranslation } from "react-i18next";
import { links } from "App";
import { Roles } from "utils/roles";
import { isProjectEditable, ProjectStatuses } from "Projects/utils/types";
import { useProtect } from "utils/hooks/protection";
import { groupBy, isEqual, pick } from "lodash";
import { deliverableMedia, DeliverableMedias, Services } from "utils/services";
import CopyToClipboard from "react-copy-to-clipboard";
import { accesses } from "utils/accesses";
import { INTERNAL_ACCESS } from "Shared/utils/permissions";
import { CamelCase } from "utils/types";
import { DuplicateProject } from "Brief/components/DuplicateProject";
import { PrioritiseProject } from "Projects/components/PrioritiseProject";
import { DeleteProject } from "Projects/components/DeleteProject";
import { ReactComponent as ActionsIcon } from "images/actions.svg";
import { ReactComponent as ShareIcon } from "images/share.svg";
import { ReactComponent as PinIcon } from "images/pin.svg";
import { ProjectLabels } from "components/ProjectLabels/ProjectLabels";

import styles from "./styles.module.scss";

type Props = {
  project: ProjectNodeFragment;
  hideActions?: boolean;
} & GetProps<typeof Card>;

export const ProjectCard = Object.assign(
  memo<Props>(
    ({
      project: {
        projectId,
        title,
        createdAt,
        coverImage,
        status,
        organization,
        deliveredAssetsCount,
        sharingLinks,
        outcomes,
        deletedAt,
        submittedAt,
        brief,
        queueOrder,
      },
      project,
      hideActions,
      className,
      variant = Card.variants.Card,
      ...rest
    }) => {
      const { t } = useTranslation("");
      const isProtected = useProtect();
      const { setNotification } = useNotification();
      const image = coverImage?.file?.thumbnail?.downloadUrl?.url;
      const [copied, setCopied] = useState(false);
      const [mode, setMode] = useState<
        "duplicate" | "prioritise" | "delete" | null
      >(null);
      const isAdmin = isProtected([Roles.admin, Roles.account_manager]);

      const link = useMemo(() => {
        const sharedLink = sharingLinks?.find((link) =>
          isEqual(pick(link, Object.keys(INTERNAL_ACCESS)), INTERNAL_ACCESS)
        );
        if (!sharedLink) return sharedLink;
        return `${location.origin}${links.SharedGallery({
          sharedCode: sharedLink.linkCode ?? "",
        })}`;
      }, [sharingLinks]);

      const delivered = useMemo(() => {
        if (!outcomes) return;
        const medias = groupBy(
          outcomes,
          (item) =>
            item?.serviceId && deliverableMedia(item?.serviceId as Services)
        );
        return Object.entries(medias).reduce(
          (acc, [media, outcomes]) => ({
            ...acc,
            [media]: outcomes.reduce(
              (acc, item) => acc + (item?.assetsCount ?? 0),
              0
            ),
          }),
          {} as { [P in DeliverableMedias]?: number }
        );
      }, [outcomes]);

      const date = useMemo(() => {
        const date = submittedAt ?? createdAt;
        return date && new Date(+date);
      }, [createdAt, submittedAt]);

      const categoryIcon = useMemo(() => {
        if (!project.brief?.category) return null;
        return require(`./images/${project.brief?.category.toLowerCase()}.svg`);
      }, [project.brief?.category]);

      const Actions = () => {
        if (!brief?.category || hideActions) return null;

        return (
          <section
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Dropdown
              render={() => (
                <Button
                  variant={Button.variants.Icon}
                  type="button"
                  className={styles.projectCardActions}
                >
                  <ActionsIcon />
                </Button>
              )}
              placement={
                Dropdown.placements[
                  variant === Card.variants.Card ? "Bottom" : "BottomEnd"
                ]
              }
              popoverClassName={styles.projectCardActionsMenu}
            >
              <Dropdown.Item>
                <Button
                  variant={Button.variants.Cancel}
                  onClick={() => setMode("duplicate")}
                >
                  {t("projects.card.actions.duplicate")}
                </Button>
              </Dropdown.Item>
              {status === ProjectStatuses.queued && queueOrder !== 1 && (
                <Dropdown.Item>
                  <Button
                    variant={Button.variants.Cancel}
                    onClick={() => setMode("prioritise")}
                  >
                    {t("projects.card.actions.prioritise")}
                  </Button>
                </Dropdown.Item>
              )}
              {!project?.deletedAt && isProjectEditable(status) && (
                <Dropdown.Item>
                  <Button
                    variant={Button.variants.Cancel}
                    onClick={() => setMode("delete")}
                  >
                    {t("projects.card.actions.delete")}
                  </Button>
                </Dropdown.Item>
              )}
            </Dropdown>
          </section>
        );
      };

      const onCloseMode = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        setMode(null);
      };

      return (
        <Card
          className={clsx(
            styles.projectCard,
            styles[cn("projectCard", variant)],
            styles[cn("projectCard", status as CamelCase<ProjectStatuses>)],
            { [styles.projectCardInteractable]: projectId },
            className
          )}
          variant={variant}
          {...rest}
        >
          {title && (
            <Card.Title className="flex justify-between items-center">
              <p className={styles.projectCardTitle}>{title}</p>
              {variant === Card.variants.Card && <Actions />}
            </Card.Title>
          )}
          {createdAt && (
            <Card.Description className={styles.projectCardDescription}>
              <div>
                <Protect access={[Roles.admin, Roles.account_manager]}>
                  {variant === Card.variants.Card && (
                    <div className={clsx(["words-break"])}>
                      {organization?.name}
                    </div>
                  )}
                  <section className="flex items-center">
                    {categoryIcon && (
                      <img className="mr-3" src={categoryIcon} />
                    )}
                    <DividedList
                      separator={variant === Card.variants.List ? " • " : " | "}
                      className={clsx(
                        {
                          truncate: variant === Card.variants.List,
                          "words-break": variant === Card.variants.Card,
                        },
                        styles.projectCardOptions
                      )}
                    >
                      {variant === Card.variants.Card &&
                        !!deliveredAssetsCount &&
                        t("projects.card.assets.count", {
                          count: deliveredAssetsCount,
                        })}
                      {date && format(date, "PPP")}
                      {variant === Card.variants.List && organization?.name}
                    </DividedList>
                  </section>
                </Protect>

                <Protect access={[Roles.organization_owner]}>
                  <section className="flex items-center">
                    {categoryIcon && (
                      <img className="mr-3" src={categoryIcon} />
                    )}
                    {date && format(date, "PPP")}
                  </section>
                </Protect>
              </div>
              {status === ProjectStatuses.queued && queueOrder === 1 && (
                <section
                  className={clsx(
                    "absolute",
                    variant === Card.variants.Card
                      ? "top-7 left-7"
                      : "top-6 left-6"
                  )}
                >
                  <Tooltip
                    placement={Tooltip.placements.Right}
                    variant={Tooltip.variants.Secondary}
                    title={t("projects.card.prioritised")}
                    className="!-top-7 z-10 w-[220px]"
                    pointer
                  >
                    <PinIcon />
                  </Tooltip>
                </section>
              )}
            </Card.Description>
          )}
          <Card.Image className={styles.image}>
            {image && <Media url={image} />}
          </Card.Image>
          <Card.Details className="flex items-center w-max">
            {variant === Card.variants.List && (
              <>
                <Protect access={[Roles.admin, Roles.account_manager]}>
                  <Protect access={accesses.gallery.bind(null, project)}>
                    {link && (
                      <Tooltip
                        placement={Tooltip.placements.Left}
                        className="text-center"
                        title={t("share.link.actions.copy", {
                          count: +copied,
                        })}
                      >
                        <CopyToClipboard
                          onCopy={(_, result) => {
                            setCopied(result);
                            setTimeout(() => setCopied(false), 3000);
                          }}
                          text={link}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            className={styles.projectCardShare}
                            type="button"
                          >
                            <ShareIcon />
                          </button>
                        </CopyToClipboard>
                      </Tooltip>
                    )}
                  </Protect>
                </Protect>

                {!deletedAt && (
                  <>
                    {!!delivered?.image && (
                      <AssetLabel
                        className="mr-5"
                        type={AssetLabel.types.Image}
                        count={delivered?.image}
                      />
                    )}
                    {!!delivered?.video && (
                      <AssetLabel
                        className="mr-5"
                        type={AssetLabel.types.Video}
                        count={delivered?.video}
                      />
                    )}
                    {!!delivered?.motion && (
                      <AssetLabel
                        className="mr-5"
                        type={AssetLabel.types.Motion}
                        count={delivered.motion}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {status && (
              <ProjectLabels
                project={project}
                className={clsx({
                  "flex-col items-end": variant === Card.variants.Card,
                })}
                labelClassName={styles.status}
                size={
                  variant === Card.variants.Card
                    ? Label.sizes.Tiny
                    : Label.sizes.Regular
                }
              />
            )}
            {variant === Card.variants.List && <Actions />}
            <Modal visible={mode === "duplicate"} onClose={onCloseMode}>
              <DuplicateProject
                projectId={projectId ?? ""}
                onClose={onCloseMode}
              />
            </Modal>
            <Modal visible={mode === "prioritise"} onClose={onCloseMode}>
              <PrioritiseProject
                projectId={projectId ?? ""}
                title={title ?? ""}
                onClose={onCloseMode}
              />
            </Modal>
            <Modal visible={mode === "delete"} onClose={onCloseMode}>
              <DeleteProject
                projectId={projectId ?? ""}
                title={title ?? ""}
                onClose={onCloseMode}
                type={isAdmin ? "project" : "brief"}
                onSubmit={() =>
                  setNotification({
                    message: t("projects:delete.notifications.success", {
                      context: "project",
                    }),
                  })
                }
              />
            </Modal>
          </Card.Details>
        </Card>
      );
    }
  ),
  {
    variants: Card.variants,
  }
);

ProjectCard.displayName = "ProjectCard";
