import { Loader } from "@CreativelySquared/uikit";
import { FC, ReactNode } from "react";
import { ReactComponent as MoreIcon } from "images/more.svg";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface Option {
  id: string;
  name: string;
  [key: string]: any;
}

interface Props {
  loading?: boolean;
  // Option nodes
  renderOption: (option: Option) => ReactNode;
  moreResults?: number;
  options: Option[];
  className?: string;
}

export const OptionsList: FC<Props> = ({
  loading,
  options,
  renderOption,
  moreResults,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.options, className)}>
      {loading && <Loader radius={30} className="mx-auto my-6" />}
      {!loading && options.map(renderOption)}
      {!loading && !!moreResults && (
        <div className="flex items-center m-auto">
          <MoreIcon className="mr-4" />
          <p className="text-blue-steel">
            {t("filter.search.more", { count: moreResults })}
          </p>
        </div>
      )}
      {!options.length && !loading && (
        <p className="text-sm text-blackberry text-center py-8">
          {t("filter.search.empty")}
        </p>
      )}
    </div>
  );
};
