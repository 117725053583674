import { get, isEqual } from "lodash";
import { MessageParams } from "yup/lib/types";

export type AdvancedErrorMessage<T = any> = {
  key: string;
  value?: T;
};

export const advancedErrorMessage =
  (key: string) =>
  <T extends { [x: string]: any }>({
    /* eslint-disable */
    path,
    value,
    originalValue,
    label,
    type,
    /* eslint-enable */
    ...rest
  }: T & MessageParams) =>
    ({
      key,
      value: rest,
    } as unknown as AdvancedErrorMessage<T>);

export const parseError = (
  error?: string | string[] | AdvancedErrorMessage
): [string, any?] => {
  if (Array.isArray(error)) {
    return error
      .filter(Boolean)
      .map((error) =>
        typeof error === "object"
          ? [(error as any).key, (error as any).value]
          : error
      )
      ?.filter(Boolean)?.[0] as [string, any?];
  }
  if (typeof error === "object") {
    return [error.key, error.value];
  }
  return [error as string];
};

export const getNestedError = <
  T extends Array<{
    [key in string]: string;
  }>
>(
  errors: T
) => {
  if (Array.isArray(errors))
    return Object.values(errors?.find((error) => error) ?? {})?.[0];
  return errors;
};

enum CommonErrorCodes {
  StoredDataUnprocessableError = "StoredDataUnprocessableError",
  StoredDataNotSupportedError = "StoredDataNotSupportedError",
  StoredDataSizeExceededError = "StoredDataSizeExceededError",
  InvalidStoredDataMimeTypeError = "InvalidStoredDataMimeTypeError",
  AuthenticationError = "AuthenticationError",
}

export const handleErrorMessage = (error: any) =>
  Object.values(CommonErrorCodes).includes(
    error?.graphQLErrors?.[0]?.extensions?.code
  )
    ? `common:errors.${error.graphQLErrors?.[0]?.extensions?.code}`
    : error?.message ?? "common:validation.general";

export const isInputUpdated = (data: any, field: string, value: any) =>
  !isEqual(value, get(data, field));

export const formaNumberValue = (
  value?: string | number,
  options?: { allowZero?: boolean }
) => {
  const numericValue = String(value)?.replace(/\D/g, "") || "";

  if (options?.allowZero && numericValue === "0") {
    return "0";
  }

  return numericValue.replace(/^0+/, "");
};
