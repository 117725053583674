import React, { FC } from "react";
import { CollectionItemNodeFragment } from "api/types";
import { Card, Label } from "@CreativelySquared/uikit";
import { ReactComponent as CollectionsIcon } from "images/collections.svg";
import clsx from "clsx";
import { GetProps } from "react-router-hoc/lib/types";
import { CollectionCaption } from "components/CollectionCaption";
import { ReactComponent as PrivateIcon } from "images/hidden.svg";
import { ReactComponent as PublicIcon } from "images/visible.svg";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type Props = {
  data: CollectionItemNodeFragment;
  variant?: CollectionCardVariants;
} & Omit<GetProps<typeof Card>, "variant">;

export enum CollectionCardVariants {
  Grid = "grid",
  List = "list",
}

export const CollectionCard: FC<Props> = ({
  data,
  className,
  variant = CollectionCardVariants.Grid,
  ...props
}) => {
  const { t } = useTranslation("dam");

  const coverURL =
    data.coverAsset?.latestVersion?.file?.thumbnail?.downloadUrl?.url;

  return (
    <Card
      className={clsx(
        styles.container,
        { [styles.list]: variant === CollectionCardVariants.List },
        className
      )}
      variant={
        variant === CollectionCardVariants.List
          ? Card.variants.List
          : Card.variants.Card
      }
      {...props}
    >
      <Card.Image className="bg-grey flex justify-center items-center">
        {coverURL ? (
          <img src={coverURL} />
        ) : (
          <CollectionsIcon
            width={46}
            height={45}
            className={styles.placeholderImage}
          />
        )}
      </Card.Image>
      <Card.Details>
        <Label
          variant={Label.variants.Primary}
          size={
            variant === CollectionCardVariants.List
              ? Label.sizes.Small
              : Label.sizes.Tiny
          }
          className={clsx(
            "flex items-center gap-2 cursor-pointer",
            data.isPrivate ? "bg-purple" : "bg-teal",
            "rounded-full"
          )}
        >
          {data.isPrivate ? (
            <PrivateIcon className="w-6" />
          ) : (
            <PublicIcon className="w-6" />
          )}
          {data.isPrivate
            ? t("collections.type.private")
            : t("collections.type.public")}
        </Label>
      </Card.Details>
      <Card.Title className="font-bold truncate">{data.name}</Card.Title>
      {data.createdAt && (
        <Card.Description className={styles.description}>
          <CollectionCaption
            createdAt={Number(data.createdAt)}
            count={data.assetsCount || 0}
          />
        </Card.Description>
      )}
    </Card>
  );
};
