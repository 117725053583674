import { Features } from "api/features";
import { useAssetLibraryDetailsQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { usePagination } from "utils/hooks/pagination";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Asset } from "Assets/components";
import React from "react";
import { Services } from "utils/services";
import { idRegEx } from "utils/regex";
import { BriefCategory } from "api/enums";
import { SortFields, SortOrder } from "utils/order";
import { Link } from "react-router-dom";
import { useServices } from "DAM/hooks/useServices";
import { AssetActions } from "components/AssetActions";
import { useAssetsRouteSettings } from "DAM/hooks/useAssetsRouteSettings";
import { getFilters } from "DAM/utils/filters";
import { AssetRatingType } from "utils/types";

import { AssetsView, useAssetsSelect } from "../components/AssetsView";
import { AssetListItem, AssetViewType } from "../components/AssetListItem";

const AssetLibraryRoute = compose(
  ProtectedRoute({
    access: [Roles.admin, Roles.account_manager, Roles.organization_owner],
    feature: Features.dam_asset_library,
  }),
  Route(
    {
      page: Route.query.number,
      view: Route.query.oneOf(...Object.values(AssetViewType)),
      search: Route.query.string,
      assetTypes: Route.query.array(
        Route.query.oneOf(...Object.values(Services))
      ),
      categories: Route.query.array(
        Route.query.oneOf(...Object.values(BriefCategory))
      ),
      details: Route.params.oneOf("asset").optional,
      id: Route.params.regex(idRegEx).optional,
      versionId: Route.query.regex(idRegEx),
      brands: Route.query.array(Route.query.string),
      projects: Route.query.array(Route.query.string),
      organizations: Route.query.array(Route.query.string),
      rating: Route.query.array(
        Route.query.oneOf(...Object.values(AssetRatingType))
      ),
      createdAt: Route.query.array(Route.query.string),
      tags: Route.query.array(Route.query.string),
      sort: Route.query.oneOf(...Object.values(SortFields)),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
    },
    ({ details, id }) => `/dam/library/${details}/${id}`
  )
);

export const AssetLibrary = AssetLibraryRoute(
  ({
    match: {
      params: { id },
      query: {
        page = 1,
        search,
        assetTypes,
        brands,
        projects,
        organizations,
        createdAt,
        categories,
        tags,
        rating,
        view = AssetViewType.Gallery,
        versionId,
        sort = SortFields.createdAt,
        sortOrder = SortOrder.DESC,
      },
      params,
      query,
    },
    history: { replace },
    link,
  }) => {
    const { t } = useTranslation("dam");
    const pagination = usePagination({
      page,
      sort,
      sortOrder,
    });
    const {
      selectedAssetIds,
      selectedAssets,
      selectedAssetVersionIds,
      selectedAssetFiles,
      assetsViewRef,
      onSelectAllAssets,
      onSelectAsset,
      onClearSelection,
    } = useAssetsSelect();

    const { onFilter, onSearch, onSort, onViewChange, getLink } =
      useAssetsRouteSettings({
        link,
        query,
        params,
      });

    const { activeServiceIds, serviceValues, servicesLoading } = useServices(
      assetTypes || []
    );

    const filters = getFilters({
      activeServiceIds,
      brands: brands as string[],
      projects: projects as string[],
      categories: categories as BriefCategory[],
      tags: tags as string[],
      rating: rating as AssetRatingType[],
      createdAt: createdAt as string[],
      organizations: organizations as string[],
    });

    const { data: { getAssetLibraryNeighbours } = {}, loading: assetLoading } =
      useAssetLibraryDetailsQuery({
        variables: {
          assetId: id ?? "",
          pagination,
          search: search || undefined,
          filters,
        },
        skip: !id,
      });

    const asset = getAssetLibraryNeighbours?.current;

    return (
      <>
        <Helmet>
          <title>{t("library.title")}</title>
        </Helmet>
        <header className="mb-10">
          <h1>{t("library.title")}</h1>
        </header>
        <AssetsView
          view={view}
          filters={filters}
          search={search}
          sortOrder={sortOrder}
          serviceValues={serviceValues}
          assetTypes={assetTypes as Services[]}
          createdAt={createdAt as string[]}
          dataLoading={servicesLoading}
          pagination={pagination}
          page={page}
          onSearch={onSearch}
          onSort={onSort}
          onFilter={onFilter}
          onViewChange={onViewChange}
          renderAsset={({ asset, selected, onSelect, view }) => (
            <Link
              to={getLink({
                id: asset.assetId || "",
                details: "asset",
                versionId: asset.latestVersion?.assetVersionId || "",
              })}
              key={asset?.assetId}
              className="w-full h-full mb-4"
            >
              <AssetListItem
                asset={asset}
                selected={selected}
                onSelect={onSelect}
                view={view}
              />
            </Link>
          )}
          ref={assetsViewRef}
          selectedAssets={selectedAssetIds}
          onSelectAsset={onSelectAsset}
        />
        {!!selectedAssets.length && (
          <AssetActions
            assetsIds={selectedAssetIds}
            assetVersionIds={selectedAssetVersionIds}
            assetFiles={selectedAssetFiles}
            onClear={onClearSelection}
            onSelectAll={onSelectAllAssets}
          />
        )}
        {id && (
          <Asset
            className="fixed w-screen h-screen z-10  top-0 left-0 bg-white"
            next={
              getAssetLibraryNeighbours?.nextId &&
              link({
                ...params,
                ...query,
                versionId: undefined,
                id: getAssetLibraryNeighbours?.nextId,
              })
            }
            prev={
              getAssetLibraryNeighbours?.previousId &&
              link({
                ...params,
                ...query,
                versionId: undefined,
                id: getAssetLibraryNeighbours?.previousId,
              })
            }
            versionId={versionId}
            setVersion={(versionId) =>
              replace(link({ ...params, ...query, versionId }))
            }
            backURL={link({
              ...params,
              ...query,
              id: undefined,
              details: undefined,
              versionId: undefined,
            })}
            loading={assetLoading}
            value={asset}
          />
        )}
      </>
    );
  }
);
