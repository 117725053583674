import { Button, Modal, Textarea } from "@CreativelySquared/uikit";
import { CommentTargetType } from "api/enums";
import { useCreateBulkCommentsMutation, useProfileQuery } from "api/graphql";
import { Project } from "api/types";
import clsx from "clsx";
import { ModalDescription } from "components/ModalDescription";
import { ModalTitle } from "components/ModalTitle";
import { MentionsTextarea } from "components/Note/MentionsTextarea";
import { useNotification } from "components/Notifications";
import { useFormik } from "formik";
import { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MentionsInputProps } from "react-mentions";
import { parseError } from "utils/form";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { commentValidationSchema } from "utils/validation";
import { object } from "yup";

import styles from "./styles.module.scss";

interface Props {
  visible: boolean;
  onClose: () => void;
  assetIds: string[];
  onSubmit: () => void;
  projectId?: Project["projectId"];
}

type FormData = {
  comment: {
    message: string;
    mentions?: string[];
  };
};

export const AddCommentModal: FC<Props> = ({
  visible,
  onClose,
  assetIds,
  onSubmit,
  projectId,
}) => {
  const { t } = useTranslation("common");
  const { setNotification, notificationTypes } = useNotification();
  const isProtected = useProtect();
  const isCustomer = isProtected([Roles.organization_owner]);

  const { data: { me } = {} } = useProfileQuery({
    skip: !isCustomer,
  });

  const organizationId =
    me?.__typename === "OrganizationUser"
      ? me?.organization?.organizationId
      : "";

  const [createComments, { loading }] = useCreateBulkCommentsMutation({
    onError: (error) => {
      setNotification({
        message: t(...parseError(error.message)),
        type: notificationTypes.Error,
      });
    },
    onCompleted: () => {
      resetForm();
      onSubmit();
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    resetForm,
    isValid,
    values: { comment },
    errors: { comment: commentError },
    touched: { comment: commentTouched },
  } = useFormik<FormData>({
    async onSubmit(values) {
      await createComments({
        variables: {
          comments: assetIds.map((id) => ({
            targetId: id,
            content: values.comment.message,
            targetType: CommentTargetType.AssetVersion,
            ...(values.comment.mentions
              ? { extra: { mentions: values.comment.mentions } }
              : {}),
          })),
        },
      });
    },
    validationSchema: object({
      comment: commentValidationSchema,
    }),
    initialValues: {
      comment: {
        message: "",
        mentions: undefined,
      },
    },
  });

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      className="w-full pb-9 max-w-[545px] overflow-visible"
    >
      <form noValidate onSubmit={handleSubmit}>
        <ModalTitle>{t("comments.form.title")}</ModalTitle>
        <ModalDescription>
          <Trans
            i18nKey="comments.form.description"
            t={t}
            components={{
              strong: <strong />,
            }}
          />
        </ModalDescription>
        <Textarea
          className="w-full resize-none overflow-visible"
          value={comment.message}
          onBlur={handleBlur}
          onChange={(e) =>
            setFieldValue("comment", {
              message: e.target.value,
              mentions: undefined,
            })
          }
          name="comment"
          placeholder={t("comments.form.placeholder.create")}
          error={
            commentTouched && commentError
              ? t(...parseError(commentError.message)).toString()
              : undefined
          }
          renderComponent={
            isCustomer
              ? ({ className, ...inputProps }) => (
                  <MentionsTextarea
                    {...(inputProps as MentionsInputProps)}
                    containerClassName={clsx(
                      className,
                      styles.textareaContainer
                    )}
                    inputClassName={clsx(
                      className,
                      "shadow-none !overflow-y-auto"
                    )}
                    onChange={(message, mentions) =>
                      setFieldValue("comment", { message, mentions })
                    }
                    organizationId={organizationId}
                    projectId={projectId}
                  />
                )
              : undefined
          }
        />
        <section className="flex justify-end mt-7">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={handleClose}
            outlined
          >
            {t("actions.cancel")}
          </Button>
          <Button
            loading={loading}
            type="submit"
            disabled={!isValid || loading}
          >
            {t("actions.submit")}
          </Button>
        </section>
      </form>
    </Modal>
  );
};
