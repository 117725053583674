import { useCustomerEventsQuery } from "api/graphql";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { EventType } from "utils/event";
import { usePagination } from "utils/hooks/pagination";
import { ActivityView } from "Activity/components/ActivityView";
import { HeaderInjector } from "Customers/components/HeaderInjection";
import { ActivityFilter } from "components";

export const CustomerActivityRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      type: Route.query.array(Route.query.oneOf(...Object.values(EventType))),
      page: Route.query.number,
      eventId: Route.query.string,
    },
    ({ id }) => `/customers/${id}/activity`
  )
);

export const CustomerActivity = CustomerActivityRoute(
  ({
    match: {
      params: { id },
      query: { type, page = 1 },
      query,
      params,
    },
    link,
  }) => {
    const { t } = useTranslation();
    const pagination = usePagination({ page });
    const eventNames = (type || Object.values(EventType)) as EventType[];

    const { data: { getOrganizationEvents } = {}, loading: getEventsLoading } =
      useCustomerEventsQuery({
        variables: {
          organizationId: id,
          eventNames,
          pagination,
        },
        fetchPolicy: "no-cache",
      });
    const loading = getEventsLoading && !getOrganizationEvents?.nodes?.length;

    return (
      <main className="mt-10">
        <Helmet title={t("activity:title")} />
        <HeaderInjector>
          <ActivityFilter
            className="min-w-min"
            events={Object.values(EventType)}
            value={{ ...query, ...params, page: 1 }}
            link={link}
          />
        </HeaderInjector>
        <ActivityView
          events={getOrganizationEvents?.nodes}
          loading={loading}
          pagination={pagination}
          totalPages={getOrganizationEvents?.pageInfo?.totalPages}
          organizationId={id}
          query={query}
          params={params}
          link={link}
        />
      </main>
    );
  }
);
