import { Button } from "@CreativelySquared/uikit";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import { ReactComponent as ArrowIcon } from "images/arrow.svg";

import styles from "./styles.module.scss";

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
  buttonLabel: string;
  disabled?: boolean;
  borderless?: boolean;
  onClick?: () => void;
  className?: string;
  buttonClassName?: string;
}

export const ToolItem: FC<Props> = ({
  icon,
  title,
  description,
  buttonLabel,
  disabled,
  borderless,
  className,
  buttonClassName,
  onClick,
}) => {
  return (
    <div
      className={clsx(
        styles.tool,
        disabled && styles.disabled,
        borderless && styles.borderless,
        className
      )}
    >
      <div>
        {icon}
        <h2 className="text-l mt-5">{title}</h2>
        <p className="text-base text-mid-blue-steel mt-3">{description}</p>
      </div>
      <Button
        outlined
        borderless
        disabled={disabled}
        className={clsx(
          styles.link,
          disabled && styles.disabled,
          buttonClassName
        )}
        onClick={onClick}
      >
        {buttonLabel}
        <ArrowIcon className="ml-3 rotate-180" />
      </Button>
    </div>
  );
};
