import { Loader } from "@CreativelySquared/uikit";
import { EventsFragment, OffsetPaginationParameters } from "api/types";
import clsx from "clsx";
import { ActivityEvent, EventVariants, Pagination } from "components";
import { isThisWeek, isToday } from "date-fns";
import { groupBy } from "lodash";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EventDetails, EventType } from "utils/event";

import { BulkComment } from "../BulkComment";

enum Periods {
  today = "today",
  thisWeek = "thisWeek",
  previous = "previous",
}

type Props = {
  events?: Array<EventsFragment | null> | null;
  loading?: boolean;
  pagination?: OffsetPaginationParameters;
  totalPages?: number | null;
  projectId?: string;
  organizationId?: string;
  variant?: EventVariants;
  query: {
    page?: number;
    manager?: string;
    type?: Array<EventType | undefined>;
    eventId?: string;
  };
  params: Record<string, string>;
  link: (params: any) => string;
};

export const ActivityView = memo<Props>(
  ({
    events,
    loading,
    pagination,
    totalPages,
    projectId,
    organizationId,
    variant,
    query: { page = 1, eventId },
    query,
    params,
    link,
  }) => {
    const { t } = useTranslation("activity");
    const { push } = useHistory();
    const eventsGroup = groupBy(events, (event) =>
      isToday(Number(event?.happenedAt))
        ? Periods.today
        : isThisWeek(Number(event?.happenedAt))
        ? Periods.thisWeek
        : Periods.previous
    );

    return (
      <section>
        {loading && (
          <div className="flex items-center justify-center">
            <Loader radius={50} className="mt-11" />
          </div>
        )}

        {events &&
          !loading &&
          Object.values(Periods).map(
            (period) =>
              (!!eventsGroup[period]?.length ||
                (page === 1 && events.length !== pagination?.limit)) && (
                <section key={period} className="mt-10">
                  <h3 className="mb-8">{t(`period.${period}`)}</h3>
                  {!!eventsGroup[period]?.length ? (
                    eventsGroup[period].map((event) => (
                      <ActivityEvent
                        className="pb-8 mb-8 border-solid border-b-[1px] border-light-blue-steel"
                        event={event as EventDetails}
                        variant={variant}
                        projectId={projectId}
                        organizationId={organizationId}
                        key={event?.eventId}
                      />
                    ))
                  ) : (
                    <p className={clsx({ "mb-11": period !== "previous" })}>
                      {t("empty")}
                    </p>
                  )}
                </section>
              )
          )}

        {!loading && (
          <Pagination className="mt-11" page={page} total={totalPages} />
        )}

        {!!eventId && (
          <BulkComment
            eventId={eventId}
            onClose={() =>
              push(
                link({
                  ...query,
                  ...params,
                  eventId: undefined,
                })
              )
            }
          />
        )}
      </section>
    );
  }
);
