import React, { memo, NamedExoticComponent, SVGProps } from "react";

const MotionIcon: NamedExoticComponent<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.22621"
        y="11.3782"
        width="38.9231"
        height="44.3077"
        rx="5"
        transform="rotate(-15.1188 1.22621 11.3782)"
        fill="white"
        stroke="#3A2548"
        strokeWidth="2"
      />
      <rect
        x="15.0769"
        y="1.07692"
        width="40.9231"
        height="46.3077"
        rx="6"
        fill="white"
      />
      <path
        d="M54.9231 38.7692C55.4615 44.6923 52.2308 46.3077 48.4616 46.3077H26.9231C25.3077 31.7692 37.6923 18.8462 54.9231 19.3846V38.7692Z"
        fill="#7B6D85"
        stroke="#3A2548"
        strokeWidth="2"
      />
      <rect
        x="16.0769"
        y="2.07692"
        width="38.9231"
        height="44.3077"
        rx="5"
        stroke="#3A2548"
        strokeWidth="2"
      />
      <circle cx="25.3077" cy="11.3077" r="4.84615" fill="#3A2548" />
    </svg>
  )
);

MotionIcon.displayName = "MotionIcon";

export default MotionIcon;
