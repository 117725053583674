import "./locales";
import { lazy } from "react";
import { Switch } from "react-router";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { SortFields, SortOrder } from "utils/order";
import { ProtectedRoute } from "utils/route";

export const UsersRoute = compose(
  ProtectedRoute({
    access: [Roles.admin, Roles.account_manager],
  }),
  Route(
    {
      view: Route.query.oneOf("grid", "list"),
      search: Route.query.string,
      page: Route.query.number,
      sort: Route.query.oneOf(...Object.values(SortFields)),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      manager: Route.query.string,
    },
    "/users"
  )
);

export const Users = UsersRoute(
  lazy(() => import(/* webpackChunkName: "Users" */ "./Users"))
);

export const links = {
  Users,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Users />
    </Switch>
  );
});
