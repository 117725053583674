import {
  Alert,
  Button,
  Dropdown,
  Loader,
  Modal,
} from "@CreativelySquared/uikit";
import { useProjectQuery } from "api/graphql";
import { format } from "date-fns";
import { ProjectStatuses } from "Projects/utils/types";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { getFullName } from "utils/users";

import { CompleteProject } from "../components/CompleteProject";

import styles from "./styles.module.scss";

const actions = [
  ProjectStatuses.under_revision,
  ProjectStatuses.partial_delivery,
  ProjectStatuses.delivered,
  ProjectStatuses.finalized,
];

export const ProjectGalleryCompleteRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
      mode: Route.query.oneOf(...actions),
    },
    ({ id }) => `/projects/${id}/manage/complete`
  )
);

export const ProjectGalleryComplete = ProjectGalleryCompleteRoute(
  ({
    match: {
      query: { mode },
      params: { id },
    },
    history: { push, replace },
    link,
  }) => {
    const { t } = useTranslation("projects");

    const { data: { getProject: project } = {}, loading } = useProjectQuery({
      variables: {
        projectId: id,
        includeCompletion: true,
        includeUnderRevision: true,
        includeFinalised: true,
      },
    });
    const onClose = useCallback(() => {
      push(link({ id, mode: undefined }));
    }, [id]);

    const onUpdate = useCallback(() => {
      replace(link({ id, mode: undefined }));
    }, [id]);

    const info = {
      [ProjectStatuses.partial_delivery]: {
        user: project?.partiallyCompletedBy,
        date: project?.partiallyCompletedAt,
      },

      [ProjectStatuses.delivered]: {
        user: project?.completedBy,
        date: project?.completedAt,
      },
      [ProjectStatuses.finalized]: {
        user: project?.finalizedBy,
        date: project?.finalizedAt,
      },
      [ProjectStatuses.under_revision]: {
        user: project?.underRevisionBy,
        date: project?.underRevisionAt,
      },
    }[project?.status as string];

    const statuses = actions.filter((item) => {
      return (
        item !== ProjectStatuses.under_revision ||
        [ProjectStatuses.delivered, ProjectStatuses.partial_delivery].includes(
          project?.status as ProjectStatuses
        )
      );
    });

    if (loading && !project) {
      return (
        <section className="flex items-center justify-center">
          <Loader className="my-11" radius={50} />
        </section>
      );
    }

    return (
      <main>
        {project && mode && (
          <Modal visible={mode} onClose={onClose}>
            <CompleteProject
              className="max-w-md"
              onSubmit={onUpdate}
              mode={mode}
              projectId={project.projectId}
              onClose={onClose}
            />
          </Modal>
        )}
        {project && actions.includes(project.status as ProjectStatuses) && (
          <Alert
            className="mt-10"
            icon
            variant={Alert.variants.Info}
            title={t(`gallery.manage.info.${project.status}`, {
              count: +!!info?.user,
              date: info?.date && format(new Date(+info.date), "PPP"),
              user: info?.user && getFullName(info.user),
            })}
          />
        )}

        <section className="mt-11 mb-8 flex justify-between items-start">
          <section className={styles.gallerySection}>
            <h3 className="mb-8">{t("gallery.manage.complete.title")}</h3>
            <Trans
              i18nKey="gallery.manage.complete.description"
              t={t}
              components={{
                ul: <ul className="list-disc ml-8" />,
                li: <li />,
                strong: <strong />,
              }}
            />
          </section>

          <section className="flex flex-col ml-11 relative">
            <Dropdown
              popoverClassName="w-full"
              value={project?.status}
              label={
                actions.includes(project?.status as ProjectStatuses)
                  ? t(`gallery.manage.complete.actions.${project?.status}`)
                  : t(`gallery.manage.complete.actions.placeholder`)
              }
              render={({ children }) => (
                <Button outlined variant={Button.variants.Secondary}>
                  {children}
                </Button>
              )}
            >
              {statuses
                .filter((item) => item !== project?.status)
                .map((action) => (
                  <Dropdown.Item
                    key={action}
                    onClick={() => push(link({ id, mode: action }))}
                    className="cursor-pointer hover:font-medium"
                  >
                    {t(`gallery.manage.complete.actions.${action}`)}
                  </Dropdown.Item>
                ))}
            </Dropdown>
          </section>
        </section>
        {project?.forcedPartialCompletionReason && (
          <section className={styles.galleryInfo}>
            <p> {t(`gallery.manage.complete.full.overried.heading`)}</p>
            <span className="words-break whitespace-pre-line">
              {project.forcedPartialCompletionReason}
            </span>
          </section>
        )}
      </main>
    );
  }
);
