import { BaseHTMLAttributes, DetailedHTMLProps, memo, useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Button, Loader } from "@CreativelySquared/uikit";
import { ReactComponent as CollapseIcon } from "images/doubleRight.svg";
import { Note, NoteForm, NoteType } from "components/Note";
import {
  useBriefCommentDetailsQuery,
  useBriefCommentsQuery,
  useProjectCommentsQuery,
} from "api/graphql";
import { ActiveRegionType } from "components/Media";
import { AssetComment, BriefComment } from "api/types";
import { Object } from "ts-toolbelt";
import { commentsWithRegions } from "utils/filter";

import styles from "./styles.module.scss";

type Props = (
  | {
      assetVersion: {
        assetId: string;
        assetVersionId: string;
      };
      briefId?: never;
      organizationId?: string;
      projectId?: string;
    }
  | {
      briefId: string;
      assetVersion?: never;
      organizationId?: never;
      projectId?: never;
    }
) & {
  onSelect?: (region: ActiveRegionType) => void;
  onClose?: () => void;
  active?: number;
  header?: boolean;
  readOnly?: boolean;
} & Omit<
    DetailedHTMLProps<BaseHTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "onSelect"
  >;

export const CommentsBar = memo<Props>(
  ({
    className,
    assetVersion,
    briefId,
    active,
    readOnly,
    onSelect,
    onClose,
    header = true,
    organizationId,
    projectId,
    ...props
  }) => {
    const { t } = useTranslation("assets");

    const {
      data: { getAssetComments: assetComments } = {},
      loading: assetCommentsLoading,
    } = useProjectCommentsQuery({
      variables: {
        filters: {
          isDeleted: false,
          ...assetVersion,
        },
      },
      fetchPolicy: "cache-and-network",
      skip: !assetVersion?.assetId,
    });

    const {
      data: { getBriefCommentsByBriefId: briefComments } = {},
      loading: briefCommentsLoading,
    } = useBriefCommentsQuery({
      variables: { briefId: briefId ?? "" },
      skip: !briefId,
      fetchPolicy: "cache-and-network",
    });

    const { data: { getBrief: brief } = {} } = useBriefCommentDetailsQuery({
      variables: {
        briefId,
      },
      skip: !briefId,
    });

    const concepts = useMemo(
      () => brief?.concepts?.map((concept) => concept?.conceptId),
      [brief?.concepts]
    );
    const filteredbriefComments =
      briefComments?.nodes?.filter((comment) => {
        const conceptId = comment?.extra?.conceptId;
        return !conceptId || concepts?.includes(conceptId);
      }) ?? [];

    const comments = useMemo(
      () =>
        [...(assetComments?.nodes ?? []), ...filteredbriefComments].sort(
          (current, next) =>
            Number(next?.createdAt) - Number(current?.createdAt)
        ),
      [assetComments, filteredbriefComments]
    ) as Object.Partial<AssetComment & BriefComment, "deep">[];

    let regionNumber = commentsWithRegions(comments).length;

    const noteData = {
      ...(assetVersion?.assetVersionId
        ? {
            targetId: assetVersion.assetVersionId,
            noteType: NoteType.AssetComment,
          }
        : {
            targetId: briefId ?? "",
            noteType: NoteType.BriefComment,
          }),
    };

    const loading =
      (assetCommentsLoading && !assetComments?.nodes?.length) ||
      (briefCommentsLoading && !briefComments?.nodes?.length);

    const _organizationId = brief?.organizationId || organizationId || "";
    const _projectId = brief?.project?.projectId || projectId;

    return (
      <div className={clsx(styles.commentsBar, className)} {...props}>
        {header && (
          <header className="flex justify-between items-center pb-7 mx-9 mb-10">
            <h3>{t("comments.header")}</h3>

            {onClose && (
              <Button onClick={onClose} variant={Button.variants.Icon}>
                <CollapseIcon />
              </Button>
            )}
          </header>
        )}

        <section
          className={clsx(styles.commentsBarList, {
            ["justify-center"]: loading || !comments.length,
          })}
        >
          {loading && <Loader radius={50} className="m-auto" />}
          {!loading &&
            (comments?.length ? (
              comments?.map((comment) => {
                if (!comment) return;
                const currentNumber = !!commentsWithRegions([comment]).length
                  ? regionNumber--
                  : undefined;

                const briefComment = comment.extra?.sectionId
                  ? comment.extra
                  : undefined;

                return (
                  comment && (
                    <Note
                      className={styles.commentsBarListItem}
                      data={comment}
                      readOnly={readOnly}
                      regionNumber={currentNumber}
                      briefComment={briefComment}
                      onSelect={onSelect}
                      active={Boolean(active && active === currentNumber)}
                      key={comment.assetCommentId}
                      allowReplies
                      organizationId={_organizationId}
                      projectId={_projectId}
                    />
                  )
                );
              })
            ) : (
              <div className={styles.commentsBarEmpty}>
                <h3>{t("comments.empty.title")}</h3>
                <p>{t("comments.empty.description")}</p>
              </div>
            ))}
        </section>

        {!readOnly && (
          <NoteForm
            className="mt-auto relative px-9"
            compact
            organizationId={_organizationId}
            projectId={_projectId}
            {...noteData}
          />
        )}
      </div>
    );
  }
);
