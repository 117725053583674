import { memo, useRef, useState } from "react";
import { GetProps } from "react-router-hoc/lib/types";
import { Input } from "@CreativelySquared/uikit";
import { useFormik } from "formik";
import { object, string, TestFunction } from "yup";
import { client } from "api/apollo.client";
import { EmailExistsDocument, PhoneValidDocument } from "api/graphql";
import { isEmailExist } from "Authorization/utils/isEmailExist";
import { ApolloError } from "@apollo/client";
import { AuthErrorCodes } from "utils/auth";
import { isPhoneValid } from "Authorization/utils/isPhoneValid";
import { useTranslation } from "react-i18next";

type Props = GetProps<typeof Input> & {
  validationMessage: string;
  name: string;
} & (
    | {
        type: "email";
        existAllowed: boolean;
      }
    | {
        type: "tel";
        existAllowed?: undefined;
      }
  );

export const ValidationInput = memo<Props>(
  ({
    name,
    value: initialValue,
    error,
    existAllowed,
    validationMessage,
    onChange,
    type,
    ...props
  }) => {
    const { t } = useTranslation("common");
    const abortController = useRef<AbortController>();
    const [loading, setLoading] = useState(false);

    const emailValidation: TestFunction = async (email, { createError }) => {
      if (!email) return true;

      abortController?.current?.abort();

      const cachedResult = client.readQuery({
        query: EmailExistsDocument,
        variables: {
          email,
        },
      });

      if (cachedResult) {
        setLoading(false);
        if (cachedResult?.isEmailRegistrable?.error) {
          return createError({ message: t("validation.email") });
        }

        return Boolean(
          cachedResult.isEmailRegistrable ? !existAllowed : existAllowed
        );
      }

      setLoading(true);

      const controller = new window.AbortController();
      abortController.current = controller;
      const signal = controller.signal;

      const emailExist = await isEmailExist({
        variables: { email: email as string },
        errorPolicy: "all",
        context: { fetchOptions: { signal } },
      })
        ?.then(({ data, errors }) => {
          const errorList = errors?.filter(
            (error) =>
              error?.extensions?.code !==
              AuthErrorCodes.EmailAlreadyRegisteredError
          );

          if (errorList?.length) {
            client.writeQuery({
              query: EmailExistsDocument,
              variables: {
                email,
              },
              data: {
                isEmailRegistrable: {
                  error: true,
                },
              },
            });

            return createError({ message: t("validation.email") });
          }

          return (existAllowed && !data?.isEmailRegistrable) ||
            (!existAllowed && data?.isEmailRegistrable)
            ? true
            : false;
        })
        .catch((error) => {
          if (
            signal.aborted ||
            (error as ApolloError).graphQLErrors?.[0]?.extensions?.code ===
              AuthErrorCodes.EmailAlreadyRegisteredError
          )
            return true;

          if (
            (error as ApolloError).graphQLErrors?.[0]?.extensions?.code ===
            AuthErrorCodes.InputValidationError
          )
            return createError({ message: t("validation.email") });

          return createError(error);
        });

      setLoading(false);

      return emailExist ?? false;
    };

    const phoneValidation: TestFunction = async (phone, { createError }) => {
      if (!phone) return true;

      abortController?.current?.abort();

      const cachedResult = client.readQuery({
        query: PhoneValidDocument,
        variables: {
          phone,
        },
      });

      if (cachedResult) {
        setLoading(false);
        return !!cachedResult.isPhoneRegistrable;
      }

      setLoading(true);

      const controller = new window.AbortController();
      abortController.current = controller;
      const signal = controller.signal;

      const phoneValid = await isPhoneValid({
        variables: { phone: phone as string },
        errorPolicy: "all",
        context: { fetchOptions: { signal } },
      })
        ?.then(({ data }) => !!data?.isPhoneRegistrable)
        .catch((error) => {
          if (signal.aborted) return true;

          if ((error as ApolloError).graphQLErrors?.[0]?.extensions?.code) {
            return createError(error);
          }
        });

      setLoading(false);

      return phoneValid ?? false;
    };

    const {
      values: { [name]: value },
      handleChange,
      handleBlur,
      errors,
    } = useFormik({
      validationSchema: object({
        [name]: string().test(
          "notValid",
          validationMessage,
          type === "email" ? emailValidation : phoneValidation
        ),
      }),
      onSubmit: () => {},
      initialValues: { [name]: initialValue },
    });

    return (
      <Input
        name={name}
        value={value}
        loader={loading}
        onChange={(event) => {
          handleChange(event);
          if (onChange) onChange(event);
        }}
        type={type}
        error={!loading && (error || errors?.[name])}
        onBlur={handleBlur}
        {...props}
      />
    );
  }
);
