import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@CreativelySquared/uikit";
import { ModalTitle } from "components/ModalTitle";
import { ModalDescription } from "components/ModalDescription";
import { CollectionItemNodeFragment } from "api/types";
import { Link } from "react-router-dom";
import { links } from "App";
import { DividedList } from "components/DividedList";
import { ReactComponent as SuccessIcon } from "images/success.svg";

type Props = {
  collections: Array<CollectionItemNodeFragment>;
  onClose: () => void;
};

export const SuccessMessage = memo<Props>(({ collections, onClose }) => {
  const { t } = useTranslation("dam");

  return (
    <Modal
      visible={!!collections?.length}
      onClose={onClose}
      className="flex items-center text-center flex-col w-full max-w-[460px] px-[95px]"
    >
      <SuccessIcon />
      <ModalTitle className="text-green mt-4">
        {t("collections.success.heading")}
      </ModalTitle>
      <ModalDescription className="mb-7">
        {t("collections.success.description")}
      </ModalDescription>
      <DividedList className="text-blackberry" separator={", "}>
        {collections.map(
          (collection) =>
            !!collection.collectionId && (
              <Link
                to={links.CollectionDetails({ id: collection.collectionId })}
                key={collection.collectionId}
                className="text-teal font-medium inline no-underline"
              >
                {collection.name}
              </Link>
            )
        )}
      </DividedList>
      <Button
        variant={Button.variants.Cancel}
        type="button"
        className="mt-9"
        onClick={onClose}
        outlined
      >
        {t("common:actions.close")}
      </Button>
    </Modal>
  );
});
