import { FC, useMemo } from "react";
import { Button, Input } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import clsx from "clsx";
import { urlToFile } from "utils/urlToFile";
import { MediaFormats, MediaType } from "utils/types";

type Props = {
  onUpload: (file: File) => void;
  formats: Array<MediaFormats>;
};

export const MediaLink: FC<Props> = ({ formats, onUpload }) => {
  const { t } = useTranslation("common");
  const formatTypes = useMemo(
    () => formats.map((format) => MediaType[format]),
    [formats]
  );

  const {
    values: { link },
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldError,
  } = useFormik<{ link: string }>({
    async onSubmit({ link }) {
      if (!link) return;

      try {
        const file = await urlToFile({
          url: link,
          availableFormats: formatTypes,
        });

        onUpload(file);
      } catch {
        setFieldError("link", t("addMedia.link.errors.invalid"));
      }
    },
    initialValues: { link: "" },
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="flex">
        <Input
          placeholder={t("addMedia.link.placeholder")}
          className="flex-1 mr-5"
          name="link"
          value={link}
          error={errors.link}
          onChange={handleChange}
          onBlur={handleBlur}
          variant={Input.variants.Secondary}
        />
        <Button type="submit" loading={isSubmitting}>
          {t("actions.submit")}
        </Button>
      </div>
      <p
        className={clsx("text-xs text-red mt-3 mb-8", {
          "mb-3": errors.link,
        })}
      >
        {errors.link}
      </p>
    </form>
  );
};
