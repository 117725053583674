import { useUserNamesQuery } from "api/graphql";
import { Filter } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";

export enum CollectionTypes {
  private = "private",
  public = "public",
}

enum CollectionsFilter {
  createdAt = "createdAt",
  types = "types",
  users = "users",
}

type Props = {
  query: {
    createdAt?: Array<string | undefined>;
    types?: Array<CollectionTypes | undefined>;
    users?: Array<string | undefined>;
  };
  params: Record<string, string>;
  link: (params: any) => string;
  organizationId?: string;
};

export const Filters: FC<Props> = ({
  query: { createdAt, types, users },
  query,
  params,
  link,
  organizationId,
}) => {
  const { push } = useHistory();
  const { t } = useTranslation("common");
  const getAccess = useProtect();
  const isCustomer = getAccess(Roles.organization_owner);
  const isAdmin = getAccess([Roles.admin, Roles.account_manager]);

  const { data: { getUsers: userList } = {}, loading: usersLoading } =
    useUserNamesQuery({
      variables: {
        organizationId,
        ...(isAdmin &&
          !organizationId && { role: [Roles.admin, Roles.account_manager] }),
      },
    });

  const filterData = [
    {
      key: CollectionsFilter.createdAt,
      value: createdAt,
    },
    {
      key: CollectionsFilter.types,
      value: types,
    },
    {
      key: CollectionsFilter.users,
      value: users,
    },
  ];

  const filterOptions = [
    {
      key: CollectionsFilter.createdAt,
      label: t("filter.value.createdAt"),
      type: Filter.types.Datepicker,
    },
    ...(isCustomer || (isAdmin && !organizationId)
      ? [
          {
            key: CollectionsFilter.types,
            label: t("filter.value.type"),
            value: Object.values(CollectionTypes).map((type) => ({
              id: type,
              name: t(`dam:collections.type.${type}`),
            })),
            type: Filter.types.Checkbox,
          },
        ]
      : []),
    {
      key: CollectionsFilter.users,
      label: t("filter.value.user"),
      value: userList?.nodes?.map((user) => ({
        id: user?.userId ?? "",
        name: `${user?.name} ${user?.surname}`,
      })),
      type: Filter.types.Checkbox,
    },
  ];

  return (
    <Filter
      value={filterData?.flatMap(({ key, value }) =>
        value?.length ? [{ key, value: value as string[] }] : []
      )}
      options={filterOptions}
      onChange={(data) =>
        push(
          link({
            ...params,
            ...query,
            ...data,
            page: 1,
          })
        )
      }
      searchLoading={usersLoading}
    />
  );
};
