import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { ProtectedRoute } from "utils/route";

export const ProfileGoalsRoute = compose(
  ProtectedRoute({
    access: ({ draftStatus: { profileGoalsModificationPending } }) =>
      profileGoalsModificationPending,
  }),
  Route("/registration/profile-goals")
);

export const ProfileGoals = ProfileGoalsRoute(
  lazy(() => import(/* webpackChunkName: "ProfileGoals" */ "./ProfileGoals"))
);
