import { memo } from "react";
import type { Object } from "ts-toolbelt";
import { OrganizationUser } from "api/types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Button, Card } from "@CreativelySquared/uikit";
import { GetProps } from "react-router-hoc/lib/types";
import { links } from "App";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useQueryParams } from "utils/hooks/query";
import editIcon from "images/edit.svg";
import BinIcon from "images/BinIcon";
import { getFullName } from "utils/users";
import { useSendActivationEmailMutation } from "api/graphql";
import { useNotification } from "components";

import eyesIcon from "./images/eyes.svg";

import styles from "./styles.module.scss";

type Props = {
  user: Object.Partial<OrganizationUser, "deep">;
} & GetProps<typeof Card>;

export const UserCard = memo<Props>(
  ({
    user: {
      userId,
      email,
      lastLoginAt,
      name,
      surname,
      isEmailConfirmed,
      organizationId,
      organization,
    },
    className,
    ...props
  }) => {
    const { t } = useTranslation("customers");
    const query = useQueryParams();
    const { setNotification, notificationTypes } = useNotification();

    const [sendActivationEmai, { loading: sendActivationLoading }] =
      useSendActivationEmailMutation({
        onCompleted: () => {
          setNotification({
            message: t("users.card.invitation.success"),
            type: notificationTypes.Success,
          });
        },
        onError: (error) => {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
      });

    return (
      <Card
        {...props}
        className={clsx(styles.userCard, className)}
        variant={Card.variants.Card}
      >
        <Card.Title className={styles.title}>
          <p className={clsx(["pr-6", { ["pr-[100px]"]: !lastLoginAt }])}>
            {getFullName({ name, surname })}
          </p>
          {lastLoginAt && (
            <div className={styles.login}>
              <img alt="" className="mr-4" src={eyesIcon} />
              <span>{format(new Date(+lastLoginAt), "d MMMM yyyy HH:mm")}</span>
            </div>
          )}
        </Card.Title>

        <Card.Description>
          {organization && (
            <div className={styles.label}>
              <span>{t("users.card.labels.customer")}</span>
              <span className={styles.value}>{organization.name}</span>
            </div>
          )}

          {email && (
            <div className={styles.label}>
              <span>{t("users.card.labels.email")}</span>
              <span className={styles.value}>{email}</span>
            </div>
          )}

          <div className={clsx(styles.label, "flex items-center")}>
            <span>{t("users.card.labels.confirmed")}</span>
            <span className={styles.value}>
              {t(`common:actions.${isEmailConfirmed ? "yes" : "no"}`)}
            </span>
            {!isEmailConfirmed && userId && (
              <Button
                outlined
                size={Button.sizes.Small}
                loading={sendActivationLoading}
                className="ml-6 py-3 px-5 font-medium"
                onClick={() => sendActivationEmai({ variables: { userId } })}
              >
                {t("users.card.invitation.resend")}
              </Button>
            )}
          </div>

          {userId && (
            <div className={styles.label}>
              <span>{t("users.card.labels.databaseId")}</span>
              <span className={styles.value}>{userId}</span>
            </div>
          )}
        </Card.Description>
        <Card.Details
          className={styles.details}
          position="top"
          visibility="hover"
        >
          {organizationId && userId && (
            <>
              <Link
                className="shrink-0"
                to={links.CustomerUserManagement({
                  id: organizationId,
                  mode: "edit",
                  userId: userId,
                })}
              >
                <Button variant={Button.variants.Icon}>
                  <img src={editIcon} alt="edit" />
                </Button>
              </Link>
              <Link
                to={links.CustomerUsers({
                  ...query,
                  id: organizationId,
                  userId,
                  mode: "delete",
                })}
                className={styles.deleteBtn}
              >
                <Button variant={Button.variants.Icon}>
                  <BinIcon />
                </Button>
              </Link>
            </>
          )}
        </Card.Details>
      </Card>
    );
  }
);
