import { Progress } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { memo, useMemo } from "react";
import { GetProps } from "react-router-hoc/lib/types";
import { FileStatuses } from "utils/types";

import styles from "./styles.module.scss";

type Props = GetProps<typeof Progress> & {
  status?: FileStatuses | null;
  error?: boolean;
};

const progressPercentage = (status?: FileStatuses | null) => {
  switch (status) {
    case undefined:
      return 20;
    case FileStatuses.waiting_data:
      return 40;
    case FileStatuses.processing_metadata:
      return 60;
    case FileStatuses.ready_to_attach:
      return 80;
    case FileStatuses.processing_formats:
      return 90;
    case FileStatuses.failed:
    case FileStatuses.completed:
      return 100;
    default:
      break;
  }
};

export const UploadProgress = memo<Props>(
  ({ status, error, className, ...props }) => {
    const progress = useMemo(() => progressPercentage(status), [status]);
    if (!status) return null;
    return (
      <Progress
        value={progress}
        className={clsx([className, styles.uploadProgress])}
        variant={
          status === FileStatuses.failed || error
            ? Progress.variants.Error
            : status === FileStatuses.completed
            ? Progress.variants.Success
            : Progress.variants.Loading
        }
        {...props}
      />
    );
  }
);
