import { BriefCategory } from "api/enums";
import {
  BrandProfile,
  Concept,
  Maybe,
  Project,
  SetBriefInput,
} from "api/types";
import { useFormik } from "formik";
import { Object } from "ts-toolbelt";

export enum BriefShareableField {
  yes = "yes",
  yes_custom = "yes_custom",
  no = "no",
}

export type BriefForm = {
  concepts?: Maybe<Object.Partial<Concept, "deep">>[] | null;
  brandProfile?: Maybe<Object.Partial<BrandProfile, "deep">>;
  project?: Maybe<Object.Partial<Project, "deep">> | null;
  organizationId?: string | null;
  briefId?: string | null;
} & SetBriefInput;

const briefFormType = () => useFormik<BriefForm>({} as any);

export type BriefFormProps<HasConcept extends boolean = false> =
  HasConcept extends true
    ? ReturnType<typeof briefFormType> & {
        concept: Maybe<Object.Partial<Concept, "deep">> | null;
      }
    : ReturnType<typeof briefFormType>;

export enum BriefSection {
  title = "title",
  brand = "brand",
  audienceNotes = "audienceNotes",
  purposeObjective = "purposeObjective",
  conceptName = "conceptName",
  conceptDescription = "conceptDescription",
  conceptDetails = "conceptDetails",
  conceptVisualGuide = "conceptVisualGuide",
  conceptInspiration = "conceptInspiration",
  conceptDeliverable = "conceptDeliverable",
  conceptGuidelines = "conceptGuidelines",
  productAcquisitionMethod = "productAcquisitionMethod",
  shareable = "shareable",
  credits = "credits",
  category = "category",
}

export type BriefCommentMetadata = {
  sectionId?: BriefSection;
  conceptId?: string | null;
};

export const briefGuides = {
  [BriefCategory.OriginalCreative]: {
    interface: "https://player.vimeo.com/video/1019995591?pip=0",
    overview: "https://player.vimeo.com/video/1019997952?pip=0",
    concepts: "https://player.vimeo.com/video/1006824884?pip=0",
    components: "https://player.vimeo.com/video/1006834137?pip=0",
    product: "https://player.vimeo.com/video/1006827529?pip=0",
    visual: "https://player.vimeo.com/video/1006828117?pip=0",
    inspiration: "https://player.vimeo.com/video/1006828588?pip=0",
    deliverables: "https://player.vimeo.com/video/1006828869?pip=0",
    logistics: "https://player.vimeo.com/video/1006829180?pip=0",
    complete: "https://player.vimeo.com/video/1020781478?pip=0",
  },
  [BriefCategory.ContentCreationCreative]: {
    interface: "https://player.vimeo.com/video/1022304892?pip=0",
    overview: "https://player.vimeo.com/video/1019999780?pip=0",
    concepts: "https://player.vimeo.com/video/1006824884?pip=0",
    components: "https://player.vimeo.com/video/1006836872?pip=0",
    product: "https://player.vimeo.com/video/1006827529?pip=0",
    inspiration: "https://player.vimeo.com/video/1006828588?pip=0",
    deliverables: "https://player.vimeo.com/video/1006828869?pip=0",
    logistics: "https://player.vimeo.com/video/1006829180?pip=0",
    complete: "https://player.vimeo.com/video/1020767486?pip=0",
  },
  [BriefCategory.AdaptAndOptimise]: {
    interface: "https://player.vimeo.com/video/1010452852?pip=0",
    overview: "https://player.vimeo.com/video/1010452852?pip=0",
    components: "https://player.vimeo.com/video/1006843195?pip=0",
    visual: "https://player.vimeo.com/video/1006828117?pip=0",
    inspiration: "https://player.vimeo.com/video/1006828588?pip=0",
    deliverables: "https://player.vimeo.com/video/1006828869?pip=0",
    complete: "https://player.vimeo.com/video/1020784082?pip=0",
  },
};
