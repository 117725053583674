import { Button, Modal } from "@CreativelySquared/uikit";
import { useProjectQuery } from "api/graphql";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { SummaryOverview } from "Brief/Summary/SummaryOverview";
import { SummaryConcept } from "Brief/Summary/SummaryConcept";
import { SummaryLogistics } from "Brief/Summary/SummaryLogistics";
import { SidebarInjector } from "Projects/components/SidebarInjector";
import { CreditsSummary, Protect, SectionPopup } from "components";
import { accesses } from "utils/accesses";
import { BriefProvider } from "Brief/brief.context";
import { BriefComments } from "Brief/components/BriefComments";
import { BriefCredits } from "Brief/Credits";
import { ProjectHeaderInjector } from "Projects/components/ProjectHeaderInjector";
import { links } from "App";
import { BriefSection } from "Brief/utils/types";

import { UpdateStatus } from "../components/UpdateStatus";
import { DeclineProject } from "../components/DeclineProject";
import { RevertStatus } from "../components/RevertStatus";

export const ProjectBriefRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
      mode: Route.query.oneOf("update", "decline", "revert"),
    },
    ({ id }) => `/projects/${id}/brief`
  )
);

export const ProjectBrief = ProjectBriefRoute(
  ({
    match: {
      params: { id },
      query: { mode },
    },
    history: { replace },
    link,
  }) => {
    const { t } = useTranslation("projects");
    const { data: { getProject: project } = {} } = useProjectQuery({
      variables: {
        projectId: id,
        includeBrief: true,
      },
    });

    const onClose = useCallback(() => {
      replace(link({ id, mode: undefined }));
    }, [id, replace]);

    const brief = project?.brief;

    return (
      <BriefProvider brief={brief ?? {}}>
        <Helmet title={t("brief.title")} />
        <Protect access={accesses.edit.bind(null, project)}>
          <ProjectHeaderInjector>
            <Link to={links.Brief({ id })}>
              <Button>{t("brief.actions.edit")}</Button>
            </Link>
          </ProjectHeaderInjector>
        </Protect>
        <SidebarInjector>
          {project?.estimatedCost && (
            <CreditsSummary className="mb-7" data={project?.estimatedCost} />
          )}
        </SidebarInjector>

        <section className="flex justify-between items-center">
          <div className="flex-1 mr-10">
            {brief && (
              <section className="grid gap-10">
                <SummaryOverview brief={brief} />

                <section>
                  <h2>{t("brief.concept.title")}</h2>
                  {brief.concepts?.map(
                    (concept) =>
                      concept && (
                        <SummaryConcept
                          briefId={brief.briefId}
                          concept={concept}
                          key={concept.conceptId}
                          className="mt-8"
                        />
                      )
                  )}
                </section>

                <SummaryLogistics brief={brief} />

                <section>
                  <SectionPopup
                    comment={{
                      briefId: brief.briefId,
                      sectionId: BriefSection.credits,
                    }}
                  >
                    <h2>{t("brief:credits.title")}</h2>
                    <BriefCredits brief={brief} />
                  </SectionPopup>
                </section>
              </section>
            )}

            <footer className="flex items-center justify-between mt-10">
              <Protect access={accesses.decline.bind(null, project)}>
                <Link to={link({ id, mode: "decline" })}>
                  <Button variant={Button.variants.Alert}>
                    {t("brief.actions.decline")}
                  </Button>
                </Link>
              </Protect>
              <section className="flex">
                <Protect access={accesses.revertance.bind(null, project)}>
                  <Link to={link({ id, mode: "revert" })} className="mr-6">
                    <Button variant={Button.variants.Secondary}>
                      {t("brief.actions.revert")}
                    </Button>
                  </Link>
                </Protect>
                <Protect access={accesses.acceptance.bind(null, project)}>
                  <Link className="ml-6" to={link({ id, mode: "update" })}>
                    <Button variant={Button.variants.Primary}>
                      {t("brief.actions.update")}
                    </Button>
                  </Link>
                </Protect>
                <Protect access={accesses.start.bind(null, project)}>
                  <Link className="ml-6" to={link({ id, mode: "update" })}>
                    <Button variant={Button.variants.Primary}>
                      {t("brief.actions.start")}
                    </Button>
                  </Link>
                </Protect>
              </section>
            </footer>
          </div>
          {brief?.id && <BriefComments brief={brief} className="-ml-10" />}
        </section>

        {mode === "update" && <UpdateStatus onClose={onClose} projectId={id} />}
        <Modal onClose={onClose} visible={mode === "decline"}>
          <DeclineProject onClose={onClose} projectId={id} />
        </Modal>
        <Modal onClose={onClose} visible={mode === "revert"}>
          <RevertStatus onClose={onClose} projectId={id} />
        </Modal>
      </BriefProvider>
    );
  }
);
