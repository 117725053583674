import "./locales";
import { Switch } from "react-router";
import { Route } from "react-router-hoc";

import { SharedGallery } from "./SharedGallery";
import { SharedBrief } from "./SharedBrief";
import { SharedBrand } from "./SharedBrand";
import { SharedCollection } from "./SharedCollection";

export const SharedRoute = Route("/shared");

export const links = {
  SharedGallery,
  SharedBrief,
  SharedBrand,
  SharedCollection,
};

export const route = SharedRoute(() => {
  return (
    <Switch>
      <SharedGallery />
      <SharedBrief />
      <SharedBrand />
      <SharedCollection />
    </Switch>
  );
});
