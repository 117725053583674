import { useAssetLibraryDetailsQuery } from "api/graphql";
import {
  AssetLibraryFiltersInput,
  OffsetPaginationParameters,
} from "api/types";
import { Asset } from "Assets/components";
import React, { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router";

export interface AssetDetailsProps {
  pagination?: OffsetPaginationParameters;
  filters: AssetLibraryFiltersInput;
  backURL?: string;
  getLink: (params: { assetId: string; [x: string]: any }) => string;
  search?: string;
}

export const AssetDetails: FC<AssetDetailsProps> = ({
  pagination,
  filters,
  backURL,
  getLink,
  search,
}) => {
  const { replace } = useHistory();
  const { search: searchString } = useLocation();
  const searchParams = new URLSearchParams(searchString);

  const { assetId } = useParams<{ assetId: string; versionId?: string }>();

  const versionId = searchParams.get("versionId");

  const { data: { getAssetLibraryNeighbours } = {}, loading: assetLoading } =
    useAssetLibraryDetailsQuery({
      variables: {
        assetId,
        pagination,
        filters,
        search: search || undefined,
      },
      skip: !assetId,
    });

  const asset = getAssetLibraryNeighbours?.current;

  return (
    <Asset
      className="fixed w-screen h-screen z-10  top-0 left-0 bg-white"
      next={
        getAssetLibraryNeighbours?.nextId &&
        getLink({
          versionId: undefined,
          assetId: getAssetLibraryNeighbours?.nextId,
        })
      }
      prev={
        getAssetLibraryNeighbours?.previousId &&
        getLink({
          versionId: undefined,
          assetId: getAssetLibraryNeighbours?.previousId,
        })
      }
      versionId={versionId || undefined}
      setVersion={(versionId) => replace(getLink({ assetId, versionId }))}
      backURL={backURL}
      loading={assetLoading}
      value={asset}
    />
  );
};
