import { useState } from "react";
export const ACCESS_TOKEN_KEY = "accessToken";

let isListenerActive = false;

export const useAuthData = () => {
  const [accessToken, setAccessToken] = useState<string | undefined | null>(
    sessionStorage.getItem(ACCESS_TOKEN_KEY) ??
      localStorage.getItem(ACCESS_TOKEN_KEY)
  );

  const setToken = (token?: string | null) => {
    setAccessToken(token);
    localStorage.setItem(ACCESS_TOKEN_KEY, token ?? "");
  };

  const setSessionToken = (token?: string | null) => {
    setAccessToken(token);
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token ?? "");

    if (!isListenerActive) {
      window.addEventListener("beforeunload", () => {
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        isListenerActive = false;
      });

      isListenerActive = true;
    }
  };

  return {
    accessToken,
    setAccessToken: setToken,
    setSessionToken,
  };
};
