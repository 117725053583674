export const VIEW_ONLY_ACCESS = {
  canDownloadAssets: false,
  canViewAssetComments: false,
  canViewAssets: true,
  canViewBrief: true,
  isInternal: false,
};

export const DOWNLOAD_ACCESS = {
  canDownloadAssets: true,
  canViewAssetComments: false,
  canViewAssets: true,
  canViewBrief: true,
  isInternal: false,
};

export const INTERNAL_ACCESS = {
  canDownloadAssets: false,
  canViewAssetComments: false,
  canViewAssets: true,
  canViewBrief: true,
  isInternal: true,
};
