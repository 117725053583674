import React, { FC, ReactNode } from "react";

import styles from "./styles.module.scss";

interface Props {
  title: string;
  description?: string | ReactNode;
}

export const Empty: FC<Props> = ({ title, description }) => {
  return (
    <section className={styles.container}>
      <h2>{title}</h2>
      {description && <span>{description}</span>}
    </section>
  );
};
