import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { Project } from "api/types";
import { useDeleteProjectMutation } from "api/graphql";
import clsx from "clsx";
import { Reference } from "@apollo/client";
import { useNotification } from "components";

import styles from "./styles.module.scss";

type FormData = { confirm: string };

type Props = {
  projectId: Project["projectId"];
  onSubmit: () => void;
  onClose: () => void;
  type?: "project" | "brief";
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const DeleteProject = memo<Props>(
  ({ projectId, onSubmit, onClose, type = "project", className, ...props }) => {
    const { t } = useTranslation("projects");
    const { setNotification, notificationTypes } = useNotification();

    const [deleteProject, { loading, error }] = useDeleteProjectMutation({
      variables: {
        projectId,
      },
      onError(error) {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      onCompleted() {
        onSubmit();
      },
      optimisticResponse({ projectId }) {
        return {
          destroyProject: {
            projectId,
            __typename: "Project",
          },
        };
      },
      update(cache, { data }) {
        if (!data?.destroyProject) return;

        cache.modify<{
          getProjects: {
            __typename: "GetProjectsResponsePage";
            nodes: Reference[];
          };
        }>({
          fields: {
            getProjects(existingProjects, { readField, INVALIDATE }) {
              if ("nodes" in existingProjects) {
                return {
                  ...existingProjects,
                  nodes: existingProjects.nodes.filter(
                    (project) =>
                      data.destroyProject?.projectId !==
                      readField("projectId", project)
                  ),
                };
              } else {
                return INVALIDATE;
              }
            },
          },
        });
      },
    });

    const validationSchema = object({
      confirm: string()
        .required("common:validation.required")
        .lowercase()
        .oneOf([t("delete.validationWord")]),
    });

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
    } = useFormik<FormData>({
      async onSubmit() {
        try {
          await deleteProject({ variables: { projectId } });
        } catch (error) {
          console.log(error);
        }
      },
      validationSchema,
      initialErrors: {
        confirm: "",
      },
      initialValues: {
        confirm: "",
      },
    });

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(styles.deleteProject, className)}
        {...props}
      >
        <h3>{t("delete.title", { context: type })}</h3>
        <p className="text-base text-blackberry">
          {t("delete.explanation", { context: type })}
        </p>
        {error && <p className={styles.error}>{t("delete.errors.common")}</p>}
        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full mt-7 mb-9"
          placeholder={t("delete.placeholder")}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
            borderless
          >
            {t("delete.actions.cancel")}
          </Button>
          <Button
            loading={loading}
            type="submit"
            disabled={!isValid || loading}
          >
            {t("delete.actions.delete", { context: type })}
          </Button>
        </section>
      </form>
    );
  }
);
