import { Route } from "react-router-hoc";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Loader, Modal } from "@CreativelySquared/uikit";
import { SubscriptionPlanStatus } from "api/enums";
import { useProfileQuery } from "api/graphql";
import { useMemo } from "react";
import { contactSalesURL, pricingURL } from "utils/links";

export const Restricted = Route(
  {
    type: Route.params.oneOf(
      SubscriptionPlanStatus.Expired,
      SubscriptionPlanStatus.Pending,
      SubscriptionPlanStatus.Suspended
    ),
  },
  ({ type }) => `/restricted/${type}`
)(
  ({
    match: {
      params: { type },
    },
  }) => {
    const { t } = useTranslation("authorization");
    const { data: { me } = {}, loading } = useProfileQuery();
    const plan = useMemo(() => {
      if (me?.__typename === "OrganizationUser") {
        return me.organization?.subscriptionPlan?.type;
      }
    }, [me]);
    const image = useMemo(() => {
      if (!plan) return;
      try {
        return require(`./images/${type}_${plan}.svg`);
      } catch (e) {
        return require(`./images/${type}.svg`);
      }
    }, [plan, type]);

    return (
      <section>
        <Helmet>
          <title>{t(`restricted.${type}.title`)}</title>
        </Helmet>
        <Modal className="max-w-[550px]" onClose={() => {}} visible>
          {plan && (
            <section className="flex flex-col items-center">
              {Image && <img src={image} className="mb-8" />}
              <h3>
                {t(`restricted.${type}.content.title`, { context: plan })}
              </h3>
              <p className="text-blackberry text-center whitespace-pre-line mt-5">
                {t(`restricted.${type}.content.description`, { context: plan })}
              </p>
              <section className="mt-8 flex">
                {type === SubscriptionPlanStatus.Expired.toLowerCase() && (
                  <a href={pricingURL} className="mr-6">
                    <Button variant={Button.variants.Secondary}>
                      {t("restricted.actions.pricing")}
                    </Button>
                  </a>
                )}
                <a href={contactSalesURL}>
                  <Button variant={Button.variants.Primary}>
                    {t("restricted.actions.sales")}
                  </Button>
                </a>
              </section>
            </section>
          )}
          {!plan && loading && <Loader radius={50} />}
        </Modal>
      </section>
    );
  }
);
