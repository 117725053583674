import clsx from "clsx";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { cn } from "utils/styles";
import { Button, Loader } from "@CreativelySquared/uikit";
import { File as UploadedFile } from "api/types";
import { ReactComponent as ImageIcon } from "images/image.svg";
import { MediaFormats, MediaType } from "utils/types";
import { useDragAndDrop } from "utils/hooks/dragAndDrop";
import { filterFilesByFormats } from "utils/file";

import { ReactComponent as FilesIcon } from "./images/files.svg";
import { ReactComponent as CloseIcon } from "./images/close.svg";

import styles from "./styles.module.scss";

enum Sizes {
  Regular = "regular",
  Small = "small",
  Tiny = "tiny",
}

enum Types {
  Assets = "assets",
  Files = "files",
  Cover = "cover image",
}

type Props = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
  "ref" | "placeholder"
> & {
  formats: Array<MediaFormats>;
  placeholder?: UploadedFile | null;
  size?: Sizes;
  disabled?: boolean;
  type?: Types;
  name?: string;
  onReset?: () => void;
} & (
    | {
        multiple: true;
        onUpload: (media: File[]) => void;
      }
    | {
        multiple?: false | undefined;
        onUpload: (media: File) => void;
      }
  );

export const UploadMedia = Object.assign(
  memo<Props>(
    ({
      formats,
      onUpload,
      onReset,
      size = Sizes.Regular,
      disabled,
      className,
      type = Types.Assets,
      name,
      multiple,
      placeholder,
      ...props
    }) => {
      const { t } = useTranslation("common");
      const [coverImage, setCoverImage] = useState<File | null>();
      const isVideo = coverImage?.type.startsWith("video")
        ? "video"
        : undefined;

      const handleUpload = (data: File[]) => {
        multiple ? onUpload(data) : onUpload(data[0]);

        if (type === Types.Cover) setCoverImage(data[0]);
      };

      const { dragOver, onDrop, onDragLeave, onDragOver } = useDragAndDrop({
        formats,
        onUpload: handleUpload,
      });

      const mediaUrl = useMemo(
        () =>
          (coverImage && URL.createObjectURL(coverImage)) ??
          placeholder?.thumbnail?.downloadUrl?.url,
        [coverImage, placeholder?.thumbnail?.downloadUrl?.url]
      );

      if (coverImage || placeholder)
        return (
          <section
            className={clsx(
              styles.uploadMedia,
              styles.cover,
              styles[cn("uploadMedia", size)],
              className
            )}
            {...props}
          >
            {coverImage || placeholder?.thumbnail?.downloadUrl?.url ? (
              isVideo ? (
                <video src={mediaUrl} autoPlay loop controls={false} muted />
              ) : (
                <img src={mediaUrl} />
              )
            ) : (
              <Loader radius={50} />
            )}
            {onReset && (
              <Button
                type="button"
                variant={Button.variants.Icon}
                className={styles.closeButton}
                onClick={() => {
                  setCoverImage(null);
                  onReset && onReset();
                }}
              >
                <CloseIcon />
              </Button>
            )}
          </section>
        );

      return (
        <label
          className={clsx(
            styles.uploadMedia,
            styles[cn("uploadMedia", size)],
            {
              [styles.dragOver]: dragOver,
              [styles.disabled]: disabled,
            },
            className
          )}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          {...props}
        >
          {(type === Types.Assets || type === Types.Cover) && <ImageIcon />}
          {type === Types.Files && <FilesIcon />}

          <p className={styles.title}>
            <Trans
              t={t}
              i18nKey="uploadMedia.title"
              values={{ type: name ?? type }}
              components={{ span: <span /> }}
            />
          </p>

          <p className={styles.types}>
            {formats.map((format) => (
              <span key={format}>{t(`uploadMedia.types.${format}`)}</span>
            ))}
            <> {t("uploadMedia.allowed")}</>
          </p>

          <input
            type="file"
            accept={formats.map((format) => MediaType[format]).join()}
            multiple={multiple}
            onChange={(event) => {
              const files = filterFilesByFormats(
                [...(event.target.files ?? [])],
                formats
              );
              if (files.length) handleUpload(files);
              event.target.value = "";
            }}
            disabled={disabled}
          />
        </label>
      );
    }
  ),
  {
    sizes: Sizes,
    types: Types,
  }
);
