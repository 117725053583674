import { memo } from "react";
import { BriefCategory } from "api/enums";
import { SelectedCard } from "components/SelectedCard";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const preview = {
  ORIGINAL_CREATIVE:
    "https://creativeysquared-app-static-files.s3.ap-southeast-1.amazonaws.com/-635b-4507-920a-72271390396f.mp4",
  CONTENT_CREATION_CREATIVE:
    "https://creativeysquared-app-static-files.s3.ap-southeast-1.amazonaws.com/-a810-4f56-a58e-a6505e894549.mp4",
  ADAPT_AND_OPTIMISE:
    "https://creativeysquared-app-static-files.s3.ap-southeast-1.amazonaws.com/-a8e2-4da7-bd66-9a0e85e13011.mp4",
};

interface Props {
  value?: BriefCategory | null;
  options?: BriefCategory[];
  onSelect: (category: BriefCategory) => void;
  className?: string;
}

export const SelectBriefCategory = memo<Props>(
  ({
    value,
    options = [
      BriefCategory.OriginalCreative,
      BriefCategory.ContentCreationCreative,
      BriefCategory.AdaptAndOptimise,
    ],
    onSelect,
    className,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={clsx("flex gap-8", className)}>
        {options.map((category) => (
          <SelectedCard
            key={category}
            selected={value === category}
            onSelect={() => onSelect(category)}
            preview={preview[category]}
          >
            <SelectedCard.Title className="text-l">
              {t(`briefCategory.${category}.title`)}
            </SelectedCard.Title>
            <SelectedCard.Description className="text-base text-mid-blue-steel font-regular">
              {t(`briefCategory.${category}.description`)}
            </SelectedCard.Description>
            <SelectedCard.Image className="mb-6">
              <img
                alt={t(`briefCategory.${category}.title`)}
                src={require(`./images/${category}.jpg`)}
              />
            </SelectedCard.Image>
          </SelectedCard>
        ))}
      </div>
    );
  }
);
