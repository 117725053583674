import {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  memo,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";
import { Alert, Button, Input } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { ReactComponent as BinIcon } from "images/bin.svg";
import { uniqueId } from "lodash";

import { ReactComponent as DoIcon } from "./images/do.svg";
import { ReactComponent as DontIcon } from "./images/dont.svg";
import { ReactComponent as PlusIcon } from "./images/plus.svg";

import styles from "./styles.module.scss";

const MAX_OPTIONS = 10;

type Props = {
  type: "do" | "dont";
  value: Array<string | null | undefined>;
  onUpdate?: (value: Array<string | null | undefined>) => void;
} & PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>;

export const BrandGuidelines = memo<Props>(
  ({ type, value: initialValue, onUpdate, className, ...props }) => {
    const { t } = useTranslation("brands");
    const [value, setValue] = useState<
      Array<{ id: string; item?: string | null }>
    >([]);

    useEffect(
      () => setValue(initialValue.map((item) => ({ id: uniqueId(), item }))),
      [initialValue]
    );

    return (
      <section className={className} {...props}>
        {value.length === MAX_OPTIONS && onUpdate && (
          <Alert
            variant={Alert.variants.Info}
            icon
            className="mt-8 mb-8"
            title={t("details.form.guidelines.validation.max", {
              max: MAX_OPTIONS,
            })}
          />
        )}
        {value.map(({ id, item }) => (
          <Input
            placeholder={t(`details.form.guidelines.${type}.placeholder`)}
            className={clsx([styles.brandGuidelinesInput], {
              [styles.brandGuidelinesInputDo]: type === "do",
              [styles.brandGuidelinesInputDont]: type === "dont",
              [styles.brandGuidelinesInputFilled]: item,
            })}
            readOnly={!onUpdate}
            tabIndex={onUpdate ? 0 : -1}
            prefix={
              item
                ? ((type === "do" ? (
                    <DoIcon />
                  ) : (
                    <DontIcon />
                  )) as unknown as string)
                : undefined
            }
            postfix={
              !!id &&
              onUpdate && (
                <Button
                  active
                  type="button"
                  onClick={() =>
                    onUpdate(
                      value.flatMap((el) => (el.id !== id ? [el.item] : []))
                    )
                  }
                  className="mr-5 ml-5"
                  variant={Button.variants.Icon}
                >
                  <BinIcon />
                </Button>
              )
            }
            key={id}
            onBlur={
              onUpdate &&
              ((event) => {
                const data = value.map((el) =>
                  el.id === id ? { id, item: event.target.value } : el
                );
                setValue(data);
                onUpdate(data.flatMap((el) => el.item));
              })
            }
            defaultValue={item ?? ""}
          />
        ))}
        {onUpdate && (
          <footer className="flex items-center justify-center mt-6">
            <Button
              type="button"
              variant={
                type === "do" ? Button.variants.Success : Button.variants.Alert
              }
              borderless
              outlined
              disabled={value.length >= MAX_OPTIONS}
              onClick={() => setValue([...value, { id: uniqueId(), item: "" }])}
            >
              <PlusIcon className="mr-4" />
              {t("details.form.guidelines.actions.add")}
            </Button>
          </footer>
        )}
      </section>
    );
  }
);
