import { memo, useEffect, useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import clsx from "clsx";
import { GetProps } from "react-router-hoc/lib/types";
import { Button, Card, Loader, Progress } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { formatBytes } from "utils/formatBytes";
import { FileStatuses } from "utils/types";
import { Media } from "components/Media/Media";
import { useFileThumbnailQuery, usePreviewFileLazyQuery } from "api/graphql";
import { isString } from "lodash";
import Textarea from "react-textarea-autosize";
import { useNotification } from "components/Notifications";
import { ReactComponent as DocumentIcon } from "images/document.svg";
import { useDownload } from "utils/hooks/download";
import { ReactComponent as CheckedIcon } from "images/checked.svg";
import { ReactComponent as UncheckedIcon } from "images/unchecked.svg";
import { usePreview } from "components/Preview";

import { ReactComponent as CloseIcon } from "./images/close.svg";
import { ReactComponent as CaptionIcon } from "./images/caption.svg";

import styles from "./styles.module.scss";

type Props = {
  fileId?: string;
  fileName?: string | null;
  status?: FileStatuses;
  src?: string;
  size?: number | null;
  type?: string | null;
  onRemove?: (() => void) | false;
  onSelect?: (selected?: boolean) => void;
  selected?: boolean | null;
  onAdd?: () => void;
  caption?: string;
  captionLength?: number;
  onCaptionUpdate?: (caption: string) => void;
  versionNumber?: number;
  onTitleClick?: () => void;
  onAddAssetVersion?: () => void;
  autoPreview?: boolean;
  previewDownload?: boolean;
} & Omit<GetProps<typeof Card>, "onSelect" | "type">;

const progressPercentage = (status?: FileStatuses) => {
  switch (status) {
    case undefined:
      return 15;
    case FileStatuses.waiting_data:
      return 30;
    case FileStatuses.processing_metadata:
      return 45;
    case FileStatuses.ready_to_attach:
      return 60;
    case FileStatuses.processing_formats:
      return 75;
    case FileStatuses.failed:
    case FileStatuses.completed:
      return 100;
    default:
      break;
  }
};
const pollInterval = 2000;
const defaultCaptionLength = 50;

export const MediaCard = memo<Props>(
  ({
    fileId,
    fileName,
    status: externalStatus,
    src,
    size,
    type,
    onRemove,
    onSelect,
    selected,
    onAdd,
    caption: initialCaption,
    captionLength,
    onCaptionUpdate,
    versionNumber,
    onAddAssetVersion,
    onTitleClick,
    autoPreview,
    previewDownload = true,
    className,
    ...props
  }) => {
    const { setNotification, notificationTypes } = useNotification();
    const [download] = useDownload();
    const { setPreview, setPreviewLoading } = usePreview();

    const [caption, setNewCaption] = useState(initialCaption ?? null);
    const [brokenImage, setBrokenImage] = useState(!src);
    const { t } = useTranslation("brief");
    const isMedia = ["image", "video"].includes(type ?? "");
    // We use it on BriefInspirations after uploading files, but we don't use it for uploading visual guides.
    // Do we really need it there? After reloading the page we don't use this query, so we should be able to avoid that and reduce a code complexity
    const { data: { getFile } = {}, stopPolling } = useFileThumbnailQuery({
      variables: { fileId: fileId! },
      fetchPolicy: "network-only",
      pollInterval,
      skip: !fileId || !isMedia || !!src,
    });

    const [loadFileView] = usePreviewFileLazyQuery({
      onError: () => {
        setPreview(null);
        setNotification({
          message: t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      onCompleted({ file }) {
        const url = file?.view?.downloadUrl?.url;
        if (url) {
          handlePreview(url);
        }
      },
    });

    const status = (getFile?.status as FileStatuses) ?? externalStatus;

    const progress = useMemo(() => progressPercentage(status), [status]);
    const fileCompleted = [
      FileStatuses.failed,
      FileStatuses.completed,
    ].includes((getFile?.status ?? status) as FileStatuses);
    const thumbnailUrl = getFile?.thumbnail?.downloadUrl?.url;
    const completed = progress === 100;

    const mediaSrc = thumbnailUrl ?? src;
    const mediaType = getFile?.originalMetadata?.common?.type ?? type;

    const isPreviewAvailable =
      (autoPreview || (status === FileStatuses.completed && !!fileId)) &&
      !!mediaType;

    const onChange = () => {
      onSelect?.(!selected);
    };

    useEffect(() => {
      if (fileCompleted || thumbnailUrl) {
        if (thumbnailUrl) setBrokenImage(false);
        stopPolling();
      }
    }, [getFile?.status, thumbnailUrl]);

    const handlePreview = (previewURL?: string) => {
      if (mediaType && previewURL) {
        setPreview({
          previewURL,
          mediaType,
          onDownload:
            fileId && previewDownload
              ? () => download([{ fileId }])
              : undefined,
        });
      }
    };

    return (
      <>
        <Card
          className={clsx(styles.mediaCard, className)}
          variant={Card.variants.List}
          {...props}
        >
          <Card.Image className={styles.image}>
            {onSelect && (
              <button
                onClick={onChange}
                type="button"
                className={clsx(styles.selection, selected && styles.selected)}
              >
                {selected ? <CheckedIcon /> : <UncheckedIcon />}
              </button>
            )}

            {onAdd && <PlusIcon className={styles.plus} />}

            {((brokenImage && !thumbnailUrl) || !isMedia) && (
              <div className={styles.broken}>
                <DocumentIcon />
              </div>
            )}

            {!src && !thumbnailUrl && !fileCompleted && isMedia && (
              <section className={styles.loading}>
                <Loader radius={50} />
              </section>
            )}

            {((src && !brokenImage && isMedia) || thumbnailUrl) && (
              <div
                onClick={
                  isPreviewAvailable
                    ? () => {
                        if (fileId && !autoPreview) {
                          setPreviewLoading(true);
                          loadFileView({
                            variables: {
                              fileId,
                              thumbnail:
                                mediaType === "video"
                                  ? "dwn_video_medium"
                                  : "hq_image_large",
                            },
                          });
                        } else {
                          handlePreview(src);
                        }
                      }
                    : undefined
                }
                className={clsx(isPreviewAvailable && "cursor-pointer")}
              >
                <Media
                  fileId={fileId}
                  url={mediaSrc}
                  type={mediaType}
                  onError={() => setBrokenImage(true)}
                  sectionClassName="w-full h-full"
                />
              </div>
            )}
          </Card.Image>

          {(onAddAssetVersion || onRemove) && (
            <Card.Details visibility="hover">
              <div className={styles.actions}>
                {onAddAssetVersion && (
                  <Button
                    type="button"
                    variant={Button.variants.Icon}
                    className={clsx(styles.actionsItem, styles.addVersion)}
                    onClick={onAddAssetVersion}
                  >
                    <PlusIcon />
                  </Button>
                )}
                {onRemove && (
                  <Button
                    type="button"
                    variant={Button.variants.Icon}
                    className={styles.actionsItem}
                    onClick={onRemove}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </div>
            </Card.Details>
          )}

          {isString(caption) && (
            <Card.Description className={styles.caption}>
              {status !== FileStatuses.completed ? (
                <Progress value={progress} className={styles.progress} />
              ) : (
                (caption || onCaptionUpdate) && (
                  <section className="relative">
                    <CaptionIcon className="absolute left-3 top-3" />
                    <Textarea
                      defaultValue={caption}
                      maxRows={4}
                      placeholder={t("mediaCard.caption.placeholder")}
                      maxLength={captionLength ?? defaultCaptionLength}
                      onBlur={(event) => {
                        if (caption !== event.target.value) {
                          setNewCaption(event.target.value);
                          onCaptionUpdate?.(event.target.value);
                        }
                      }}
                      readOnly={!onCaptionUpdate}
                    />
                  </section>
                )
              )}
            </Card.Description>
          )}

          {(fileName || size) && !isString(caption) && (
            <Card.Description className={styles.metadata}>
              <p
                className={clsx(styles.name, {
                  [styles.clickable]: !!onTitleClick,
                })}
                onClick={onTitleClick}
              >
                {fileName}
              </p>
              {!completed && status !== FileStatuses.failed && (
                <Progress
                  value={progress}
                  className={styles.progress}
                  variant={
                    status === FileStatuses.completed
                      ? Progress.variants.Success
                      : Progress.variants.Loading
                  }
                />
              )}
              {completed && (
                <section className={styles.details}>
                  <p className={styles.size}>
                    {size && formatBytes(size)}
                    {status === "failed" && <span>{status}</span>}
                  </p>
                  <div className={styles.info}>
                    {(versionNumber ?? 0) > 1 && (
                      <span className={styles.version}>
                        {t("mediaCard.version", { versionNumber })}
                      </span>
                    )}
                    {fileName && <span>{fileName?.split(".").pop()}</span>}
                  </div>
                </section>
              )}
            </Card.Description>
          )}
        </Card>
      </>
    );
  }
);
