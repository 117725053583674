import { ColorPicker as Picker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  memo,
  useRef,
  useState,
} from "react";
import { Button } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { useClickOutside } from "utils/hooks/events";
import { ReactComponent as BinIcon } from "images/bin.svg";

import styles from "./styles.module.scss";

type Props = {
  onUpdate?: (color?: string) => void;
  value?: string;
  onRemove?: () => void;
} & PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>;

export const ColorPicker = memo<Props>(
  ({ value, onUpdate, onRemove, className, ...props }) => {
    const { t } = useTranslation("common");
    const [defaultColor] = useColor("hex", value ?? "#00C5C8");
    const [color, setColor] = useColor("hex", value ?? defaultColor.hex);
    const [active, setActive] = useState(false);
    const pickerRef = useRef<HTMLElement>(null);

    const onReset = () => {
      setColor(defaultColor);
      setActive(false);
    };

    useClickOutside(pickerRef, () => active && onReset());

    return (
      <section
        className={clsx(styles.colorPicker, className)}
        ref={pickerRef}
        {...props}
      >
        {active && (
          <div className={styles.colorPickerArea}>
            <Picker
              width={240}
              height={150}
              color={color}
              onChange={setColor}
              hideHSV
              alpha
            />
            <div className={styles.colorPickerActions}>
              <Button variant={Button.variants.Cancel} onClick={onReset}>
                {t("actions.cancel")}
              </Button>
              <Button
                variant={Button.variants.Primary}
                onClick={() => {
                  onUpdate?.(color.hex);
                  setActive(false);
                  if (!value) setColor(defaultColor);
                }}
              >
                {t("actions.save")}
              </Button>
            </div>
          </div>
        )}
        <div className={styles.colorPickerView}>
          <button
            style={{ backgroundColor: color.hex }}
            className={clsx(styles.colorPickerViewButton, {
              ["cursor-default"]: !onUpdate,
            })}
            type="button"
            onClick={
              onUpdate &&
              (() => {
                if (active) {
                  onReset();
                } else {
                  setActive(true);
                }
              })
            }
          >
            {!value && <PlusIcon />}
          </button>
          {value && (
            <>
              <p className={styles.colorPickerViewTitle}>{color.hex}</p>
              {onRemove && (
                <Button
                  className={styles.removeButton}
                  onClick={onRemove}
                  type="button"
                  variant={Button.variants.Icon}
                >
                  <BinIcon />
                </Button>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
);
