import { CreditsHistoryEntry } from "api/types";
import clsx from "clsx";
import { format } from "date-fns";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { Trans, useTranslation } from "react-i18next";
import type { Object } from "ts-toolbelt";
import { getFullName } from "utils/users";

import styles from "./styles.module.scss";

type Props = {
  credit: Object.Partial<CreditsHistoryEntry, "deep">;
} & DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>;

export const CreditDetails = memo<Props>(({ credit, className, ...props }) => {
  const { t } = useTranslation("customers");
  const { currentValue, difference, timestamp, type, user } = credit;

  return (
    <li {...props} className={clsx(styles.creditDetails, className)}>
      <div className={styles.creditDetailsDifference}>
        {(difference ?? 0) > 0 && "+"}
        {difference}
      </div>

      <section className={styles.creditDetailsDetails}>
        <Trans t={t} i18nKey="credits.history.item.title">
          {{ name: getFullName(user) }}
          {{ type: t(`credits.history.item.reason.${type}`) }}
          {{ value: currentValue }}
          {t(`credits.history.item.reason.${credit.type}`)}
        </Trans>

        {timestamp && <span>{format(new Date(timestamp), "PPPp")}</span>}
      </section>
    </li>
  );
});

CreditDetails.displayName = "CreditDetails";
