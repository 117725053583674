import {
  ProfileDocument,
  useBrandsQuery,
  useEditEmailPreferencesMutation,
  useProfileQuery,
} from "api/graphql";
import {
  ActivityNotificationPreferences,
  OrganizationUser,
  ProjectEmailPreferences,
  ProjectStatusesNotificationPreferences,
} from "api/types";
import { ProjectNotificationsModes } from "api/enums";
import { CreateCard, MultiInput, useNotification } from "components";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-hoc";
import { useProtect } from "utils/hooks/protection";
import { Roles } from "utils/roles";
import { array, object, string } from "yup";
import {
  Button,
  Checkbox,
  Dropdown,
  Loader,
  Radio,
} from "@CreativelySquared/uikit";
import clsx from "clsx";
import { parseError } from "utils/form";
import { BrandCard } from "Brands/components/BrandCard";
import { xor } from "lodash";
import { useCreateNewBrand } from "Brands/hooks/useCreateNewBrand";

import styles from "./styles.module.scss";

export const SettingsNotificationsRoute = Route("/settings/notifications");

const settings = {
  modes: [
    ProjectNotificationsModes.All,
    ProjectNotificationsModes.Submitted,
    ProjectNotificationsModes.Brands,
  ],
  rules: [
    "projectSubmitted",
    "projectClarification",
    "projectAccepted",
    "projectDelivery",
    "projectFinalized",
    "projectCanceled",
  ] as const,
  preferences: [
    "briefCommentCreated",
    "assetCommentCreated",
    "assetVersionUpdated",
  ] as const,
};

export const SettingsNotifications = SettingsNotificationsRoute(() => {
  const { t } = useTranslation("settings");
  const { setNotification, notificationTypes } = useNotification();
  const isProtected = useProtect();
  const isOrganizationUser = isProtected([Roles.organization_owner]);
  const { createBrand } = useCreateNewBrand();

  const { data: profileData, loading: profileLoading } = useProfileQuery({
    variables: { includeEmailPreferences: true },
    skip: !isOrganizationUser,
  });
  const profile = profileData?.me as OrganizationUser;

  const { data: { getBrandProfiles } = {}, loading: brandsLoading } =
    useBrandsQuery({
      variables: {
        organizationId: profile?.organization?.organizationId,
      },
      skip: !isOrganizationUser,
      fetchPolicy: "cache-and-network",
    });
  const [editEmailPreferences, { loading: editEmailLoading }] =
    useEditEmailPreferencesMutation({
      onError: (error) =>
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        }),
      onCompleted: () =>
        setNotification({
          message: t("notifications.saved"),
        }),
      refetchQueries: [
        {
          query: ProfileDocument,
          variables: { includeEmailPreferences: true },
        },
      ],
      awaitRefetchQueries: true,
    });

  const validationSchema = object({
    mode: string().required(),
    brands: array()
      .of(string())
      .when("mode", {
        is: (mode: ProjectNotificationsModes) =>
          mode === ProjectNotificationsModes.Brands,
        then: (schema) =>
          schema.min(1, t("notifications.form.modes.BRANDS.validation.min")),
      }),
  });

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleSubmit,
    submitCount,
  } = useFormik<
    Omit<
      ActivityNotificationPreferences &
        ProjectStatusesNotificationPreferences &
        ProjectEmailPreferences,
      "__typename"
    >
  >({
    validationSchema,
    enableReinitialize: true,
    initialValues: isOrganizationUser
      ? {
          ...profile?.emailPreferences?.activityPreferences,
          ...profile?.emailPreferences?.projectStatuses,
          ...profile?.emailPreferences?.projectsPreferences,
        }
      : {},
    onSubmit: (data) => {
      const {
        mode,
        brands,
        assetCommentCreated,
        assetVersionUpdated,
        briefCommentCreated,
        projectAccepted,
        projectCanceled,
        projectDelivery,
        projectFinalized,
        projectClarification,
        projectSubmitted,
      } = data;

      editEmailPreferences({
        variables: {
          preferences: {
            projectsPreferences: {
              mode: mode ?? ProjectNotificationsModes.All,
              brands,
            },
            activityPreferences: {
              assetCommentCreated,
              assetVersionUpdated,
              briefCommentCreated,
            },
            projectStatuses: {
              projectAccepted,
              projectCanceled,
              projectDelivery,
              projectFinalized,
              projectClarification,
              projectSubmitted,
            },
          },
        },
      });
    },
  });

  const loading = profileLoading || brandsLoading;

  if (!isOrganizationUser) {
    return null;
  }

  if (loading) {
    return <Loader radius={50} className="mx-auto mt-10" />;
  }

  return (
    <section>
      <Helmet>
        <title>{t("notifications.title")}</title>
      </Helmet>
      <form
        noValidate
        onSubmit={handleSubmit}
        className={styles.settingsNotifications}
      >
        <h3 className="text-l">{t("notifications.heading")}</h3>
        <p className={styles.description}>{t("notifications.description")}</p>

        <section className={styles.section}>
          <p className={styles.sectionTitle}>{t("notifications.project")}</p>
          <div>
            {settings.modes.map((setting) => (
              <Radio
                className={styles.sectionField}
                key={setting}
                name={setting}
                checked={values.mode === setting}
                onChange={() => setFieldValue("mode", setting)}
                onBlur={handleBlur}
              >
                <div className={styles.sectionFieldInfo}>
                  <p className={styles.sectionFieldLabel}>
                    {t(`notifications.form.modes.${setting}.label`)}
                  </p>
                  <p className={styles.sectionFieldDescription}>
                    {t(`notifications.form.modes.${setting}.description`)}
                  </p>
                </div>
              </Radio>
            ))}
            <Dropdown
              render={({ visible }) => (
                <div>
                  <MultiInput
                    active={visible}
                    arrow
                    placeholder={t(
                      "notifications.form.modes.BRANDS.placeholder"
                    )}
                    onChange={(data) => setFieldValue("brands", data)}
                    value={values.brands ?? []}
                    format={(brand) =>
                      getBrandProfiles?.nodes?.find(
                        (node) => node?.brandProfileId === brand
                      )?.name ?? ""
                    }
                    error={
                      !!submitCount &&
                      t(...parseError(errors.brands)).toString()
                    }
                    className={styles.sectionFieldBrands}
                  />
                </div>
              )}
              placement={Dropdown.placements.TopStart}
            >
              <div className={styles.sectionFieldBrandsGrid}>
                {getBrandProfiles?.nodes?.map((brand) => (
                  <BrandCard
                    key={brand?.brandProfileId}
                    brand={brand}
                    onClick={() =>
                      setFieldValue(
                        "brands",
                        xor(values.brands, [brand?.brandProfileId])
                      )
                    }
                  />
                ))}
                <CreateCard
                  title={t("notifications.form.modes.BRANDS.add")}
                  size={CreateCard.sizes.Small}
                  onClick={createBrand}
                />
              </div>
            </Dropdown>
          </div>
        </section>

        <section
          className={clsx(
            styles.section,
            "pt-10 pb-7 border-y-[1px] border-light-blue-steel"
          )}
        >
          <p className={styles.sectionTitle}>{t("notifications.status")}</p>
          <div>
            {settings.rules.map((setting) => (
              <Checkbox
                className={styles.sectionField}
                key={setting}
                name={setting}
                checked={!!values?.[setting]}
                onChange={() => setFieldValue(setting, !values?.[setting])}
                onBlur={handleBlur}
              >
                <div className={styles.sectionFieldInfo}>
                  <p className={styles.sectionFieldLabel}>
                    {t(`notifications.form.rules.${setting}.label`)}
                  </p>
                  <p className={styles.sectionFieldDescription}>
                    {t(`notifications.form.rules.${setting}.description`)}
                  </p>
                </div>
              </Checkbox>
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <p className={styles.sectionTitle}>{t("notifications.activity")}</p>
          <div>
            {settings.preferences.map((setting) => (
              <Checkbox
                className={styles.sectionField}
                key={setting}
                name={setting}
                checked={!!values?.[setting]}
                onChange={() => setFieldValue(setting, !values?.[setting])}
                onBlur={handleBlur}
              >
                <div className={styles.sectionFieldInfo}>
                  <p className={styles.sectionFieldLabel}>
                    {t(`notifications.form.preferences.${setting}.label`)}
                  </p>
                  <p className={styles.sectionFieldDescription}>
                    {t(`notifications.form.preferences.${setting}.description`)}
                  </p>
                </div>
              </Checkbox>
            ))}
          </div>
        </section>

        <Button
          className="ml-auto mt-10"
          loading={editEmailLoading}
          type="submit"
        >
          {t("notifications.save")}
        </Button>
      </form>
    </section>
  );
});
