import {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { assetFormats, FileStatuses } from "utils/types";
import {
  MediaCard,
  MediaCardSkeleton,
  UploadMedia,
  useNotification,
} from "components";
import { availableStatuses, UploadType, useUpload } from "utils/hooks/upload";
import { File as FileType } from "api/types";
import { Object } from "ts-toolbelt";
import {
  useDeleteBrandProfileReferenceMutation,
  useEditBrandProfileReferenceMutation,
} from "api/graphql";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export type ReferenceType = {
  file?: Object.Partial<FileType, "deep"> | null;
  caption?: string | null;
};

type Props = {
  value: Array<ReferenceType | null | undefined>;
  onAdd?: (references?: Array<ReferenceType | null | undefined>) => void;
  organizationId: string;
} & PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>;

export const BrandReferences = memo<Props>(
  ({ value: defaultValue, onAdd, organizationId, className, ...props }) => {
    const { t } = useTranslation("brands");
    const { setNotification, notificationTypes } = useNotification();
    const [upload, { uploadStatus, uploadingCount }] = useUpload({
      completeStatus: FileStatuses.completed,
    });
    const [value, setValue] = useState(defaultValue);

    const [deleteReference] = useDeleteBrandProfileReferenceMutation({
      onError(error) {
        setNotification({
          message: error?.message,
          type: notificationTypes.Error,
        });
      },
      onCompleted() {
        setNotification({
          message: t("details.notifications.saved"),
        });
      },
    });

    const [editReference] = useEditBrandProfileReferenceMutation({
      onError(error) {
        setNotification({
          message: error?.message,
          type: notificationTypes.Error,
        });
      },
      onCompleted() {
        setNotification({
          message: t("details.notifications.saved"),
        });
      },
    });

    const onUpload = async (files: File[]) => {
      const uploaded = await upload({
        files,
        type: UploadType.brand_reference,
        organizationId,
        wait: true,
      });
      setValue((prev) => [
        ...prev,
        ...uploaded.map((file) => ({
          ...file,
          file: {
            ...file,
            originalMetadata: {
              common: {
                type: file.type,
                size: file.size,
              },
            },
            thumbnail: {
              fileId: file?.fileId,
              formatId: "",
              downloadUrl: {
                url: file.src,
              },
            },
          },
          caption: "",
          fileId: file.fileId ?? "",
        })),
      ]);
    };

    const allFilesReady = useMemo(
      () =>
        !!value?.length &&
        value.every((item) =>
          [...availableStatuses, FileStatuses.ready_to_attach].includes(
            item?.file?.status as FileStatuses
          )
        ),
      [value]
    );

    const onDelete = (fileId: string) => {
      setValue((prevValue) =>
        prevValue.filter((file) => file?.file?.fileId !== fileId)
      );
      deleteReference({ variables: { fileId } });
    };

    useEffect(() => {
      if (
        allFilesReady &&
        value.some(
          (item) => item?.file?.status === FileStatuses.ready_to_attach
        )
      ) {
        onAdd?.(value);
      }
    }, [allFilesReady]);

    useEffect(() => setValue(defaultValue), [defaultValue]);

    useEffect(() => {
      setValue((prevValue) =>
        prevValue.map(
          (item) =>
            item && {
              ...item,
              file: {
                ...item.file,
                ...uploadStatus.find(
                  (uploadFile) => uploadFile?.fileId === item?.file?.fileId
                ),
              },
            }
        )
      );
    }, [uploadStatus]);

    return (
      <section className={clsx(styles.brandReferences, className)} {...props}>
        {onAdd && (
          <UploadMedia
            formats={assetFormats}
            onUpload={onUpload}
            className={styles.brandReferencesUpload}
            multiple
            size={UploadMedia.sizes.Tiny}
          />
        )}
        {value.map(
          (item) =>
            !!item?.file?.fileId && (
              <MediaCard
                fileId={item.file?.fileId}
                type={item.file?.originalMetadata?.common?.type}
                size={item.file?.originalMetadata?.common?.size}
                status={item.file?.status as FileStatuses}
                src={item.file?.thumbnail?.downloadUrl?.url}
                caption={item.caption ?? ""}
                captionLength={15}
                onCaptionUpdate={
                  onAdd &&
                  ((caption) =>
                    editReference({
                      variables: { fileId: item.file!.fileId!, caption },
                    }))
                }
                fileName={item.file?.fileName}
                onRemove={
                  onAdd &&
                  allFilesReady &&
                  !!item.file?.fileId &&
                  (() => onDelete(item.file!.fileId!))
                }
                key={item.file?.fileId}
              />
            )
        )}
        {Array.from({ length: uploadingCount }).map((_item, index) => (
          <MediaCardSkeleton key={index} />
        ))}
      </section>
    );
  }
);
