import { QueryResult } from "@apollo/client";
import { useMemo } from "react";
import { Function } from "ts-toolbelt";

export const withSuspense =
  <Q extends (...args: any[]) => any>(query: Q) =>
  (
    options?: Function.Parameters<Q>[0] & { suspense?: boolean }
  ): ReturnType<Q> => {
    const result = query(options);
    return useSuspenseQuery(result, options);
  };

const useSuspenseQuery = <Q extends (...args: any[]) => any>(
  query: ReturnType<Q>,
  options?: Function.Parameters<Q>[0] & { suspense?: boolean }
): ReturnType<Q> => {
  const { data, loading, error, observable, ...res } = query as QueryResult;
  const suspense = options?.suspense;
  const errorPolicy = options?.errorPolicy || "none";
  const promise = useMemo(
    () =>
      suspense &&
      new Promise((resolve) => {
        const resolver = () => {
          resolve(true);
          subscription.unsubscribe();
        };
        const subscription = observable.subscribe(({ data, loading }) => {
          if (data && !loading) resolver();
        });
      }),
    [observable, suspense]
  );
  const proxy = useMemo(
    () =>
      suspense &&
      new Proxy((data || {}) as any, {
        get: (target, prop) => {
          if (!Object.keys(target).length && loading) {
            throw promise;
          } else if (errorPolicy === "none" && error) {
            throw error;
          }
          return target[prop as keyof typeof target];
        },
      }),
    [data, error, errorPolicy, loading, promise, suspense]
  );

  return {
    data: suspense ? proxy : data,
    loading,
    error,
    observable,
    ...res,
  } as ReturnType<Q>;
};
