import { FC } from "react";
import { ModalDescription, ModalTitle } from "components";
import { Button, Modal } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import {
  AssetLibraryDocument,
  GetCollectionsDocument,
  useRemoveAssetsFromCollectionMutation,
} from "api/graphql";
import { getOperationName } from "@apollo/client/utilities";
import { useNotification } from "components/Notifications";
import { Maybe } from "api/types";

interface Props {
  visible: boolean;
  onClose: () => void;
  collectionId: string;
  assetsIds: Array<Maybe<string>>;
  onSubmit?: () => void;
}

export const RemoveFromCollectionModal: FC<Props> = ({
  visible,
  onClose,
  collectionId,
  assetsIds,
  onSubmit,
}) => {
  // TODO: move to common
  const { t } = useTranslation("common");
  const { setNotification, notificationTypes } = useNotification();

  const [
    removeAssetsFromCollection,
    { loading: removeAssetsFromCollectionLoading },
  ] = useRemoveAssetsFromCollectionMutation({
    refetchQueries: [
      // TODO: it refetches multiple queries, but only one is needed
      // TODO: we need to refetch only specific queries for the same page and filters
      getOperationName(AssetLibraryDocument)!,
      getOperationName(GetCollectionsDocument)!,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setNotification({
        message: t("collections.removeAsset.success"),
        type: notificationTypes.Success,
      });
    },
    onError(error) {
      setNotification({
        message: error.message ?? t("common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      className="w-full pb-9 max-w-[535px]"
    >
      <ModalTitle>{t("collections.removeAsset.heading")}</ModalTitle>
      <ModalDescription>
        {t("collections.removeAsset.description")}
      </ModalDescription>
      <section className="flex justify-end mt-9">
        <Button
          variant={Button.variants.Cancel}
          type="button"
          className="mr-5"
          onClick={onClose}
          outlined
        >
          {t("actions.cancel")}
        </Button>
        <Button
          onClick={async () => {
            await removeAssetsFromCollection({
              variables: {
                collectionId,
                assetsIds,
              },
            });
            onSubmit?.();
          }}
          loading={removeAssetsFromCollectionLoading}
          type="submit"
        >
          {t("actions.confirm")}
        </Button>
      </section>
    </Modal>
  );
};
