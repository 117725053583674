import { BriefCategory } from "api/enums";

const global = window as any;

export const chameleonToursIds = {
  customerDashboard: {
    createAds: "6784ce2e75b77d0013f85938",
    overview: "6784d3ae75b77d002283c649",
    ideation: "6784db1f75b77d00170c76d2",
    briefing: "6784e50175b77d002283ce16",
  },
  brief: {
    [BriefCategory.OriginalCreative]: "670d11e695c09c002c70b9a4",
    [BriefCategory.ContentCreationCreative]: "671641d9e0120e00174e9809",
    [BriefCategory.AdaptAndOptimise]: "671645b4e0120e00174ea057",
  },
  aiTools: {
    mindMapBuilder: {
      tourId: "67570fd575d073003048a618",
      demoId: "673f194bafcfb2002f4abf1e",
    },
    conceptGenerator: {
      tourId: "675c17e6ea117700185eb42e",
      demoId: "673f19caafcfb20017821e20",
    },
    scriptWriter: {
      tourId: "675c47287c41d0002d674eb1",
      demoId: "673f1adb4e5e87002b784def",
    },
    ugcIdeaGenerator: {
      tourId: "675c51a27f403900279f2a23",
      demoId: "673f1b6de39400001be07780",
    },
    ugcStoryboardCreator: {
      tourId: "675c54fe7f403900233598cb",
      demoId: "673f1be9e3940000236f8c68",
    },
    briefBuilder: {
      tourId: "675c585b7c41d0001b06cfba",
      demoId: "673f1c4ee394000013c83a35",
    },
  },
};

export const clearChameleon = () => global?.chmln?.clear?.();
export const openChameleonTour = (tourId?: string) => {
  if (tourId) global?.chmln?.show(tourId);
};
