import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const CustomerManagementRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx).optional,
      mode: Route.params.oneOf("create", "edit"),
    },
    ({ id, mode }) => `/customers/${mode}/${id}`
  )
);

export const CustomerManagement = CustomerManagementRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "CustomerManagement" */ "./CustomerManagement"
      )
  )
);
