import { useProfileQuery } from "api/graphql";
import chameleon from "@chamaeleonidae/chmln";
import { useEffect } from "react";
import { getFullName } from "utils/users";

export const useChameleon = () => {
  const { data: { me } = {} } = useProfileQuery({
    fetchPolicy: "cache-only",
    nextFetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (me?.userId) {
      chameleon.init(
        "ShGHHxdRAETxPCMB31P8kdOhZEjekuoBF91n1snbZrPmAX-1R5UCu-ENEotzwXns1txR5z",
        { fastUrl: "https://fast.chameleon.io/" }
      );

      chameleon.identify(me?.userId, {
        email: me?.email,
        name: getFullName(me),
      });
    }
  }, [me?.userId]);
};
