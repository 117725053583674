import { useHistory } from "react-router";

export const useQueryParams = <T = { [key: string]: string }>(): T => {
  const { location } = useHistory();
  const params = parseQueryParams(location.search);
  return params as T;
};

export const parseQueryParams = (search: string) => {
  const urlParams = new URLSearchParams(decodeURI(search));
  const params: { [x: string]: any } = {};
  for (const key of urlParams.keys()) {
    const param = urlParams.getAll(key);
    params[key] = param.length > 1 ? param : param[0];
  }
  return params;
};
