import { Dropdown } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetProps } from "react-router-hoc/lib/types";
import type { Function } from "ts-toolbelt";
import { EventType, filterOptions } from "utils/event";

import { ReactComponent as FilterIcon } from "./images/filter.svg";

import styles from "./styles.module.scss";

type Props<T extends (...args: any[]) => any> = Omit<
  GetProps<typeof Dropdown>,
  "value"
> & {
  link: T;
  value: Function.Parameters<T>[0];
  events: EventType[];
};

export function ActivityFilter<T extends (...args: any[]) => any>({
  value,
  link,
  events,
  className,
}: Props<T>) {
  const { t } = useTranslation();

  const optionsByEvents = Object.fromEntries(
    Object.entries(filterOptions)
      .map(([key, value]) => [
        key,
        value.filter((event) => events.includes(event)),
      ])
      .filter(([_key, value]) => !!value.length)
  );

  return (
    <Dropdown
      className={clsx(styles.activityFilter, className)}
      placement={Dropdown.placements.BottomEnd}
      label="Filter"
      outlined
      icon={FilterIcon}
      value={link(value)}
    >
      <Dropdown.Item component={Link} to={link({ ...value, type: undefined })}>
        {t("activity.filter.type.all")}
      </Dropdown.Item>
      {Object.entries(optionsByEvents).map(([key, type]) => (
        <Dropdown.Item
          component={Link}
          to={link({
            ...value,
            type,
          })}
          key={key}
        >
          {t(`activity.filter.type.${key}`)}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
}
