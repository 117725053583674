import { memo, NamedExoticComponent, SVGProps } from "react";

const ArrowIcon: NamedExoticComponent<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      width="17"
      height="11"
      viewBox="0 0 17 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.55 10.4C1.80625 10.4 1.16875 10.2 0.690625 9.7C0.2125 9.25 0 8.7 0 8.05C0 7.4 0.2125 6.8 0.74375 6.3L6.69375 0.7C7.17187 0.25 7.75625 0 8.5 0C9.19063 0 9.775 0.25 10.3062 0.7L16.2563 6.3C16.7344 6.8 16.9469 7.4 16.9469 8.05C16.9469 8.7 16.6813 9.25 16.2031 9.7C15.725 10.2 15.1406 10.4 14.45 10.4H2.55ZM8.5 2.4L2.55 8H14.45L8.5 2.4Z"
        fill="#3a2548"
      />
      <path
        d="M0.74375 9.70039C1.22187 10.1504 1.80625 10.4004 2.55 10.4004L14.45 10.4004C15.1938 10.4004 15.7781 10.1504 16.2563 9.70039C16.7344 9.20039 17 8.65039 17 8.00039C17 7.35039 16.7875 6.80039 16.2563 6.30039L10.3063 0.700391C9.82813 0.200391 9.24375 0.000390328 8.5 0.000390263C7.80938 0.000390203 7.225 0.200391 6.69375 0.700391L0.74375 6.30039C0.265625 6.80039 0 7.35039 0 8.00039C0 8.65039 0.265625 9.20039 0.74375 9.70039Z"
        fill="#3a2548"
      />
    </svg>
  )
);

ArrowIcon.displayName = "ArrowIcon";

export default ArrowIcon;
