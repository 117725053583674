import { EstimatedConceptCost } from "api/types";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { Object as ObjectType } from "ts-toolbelt";
import { conceptNumberGenerator } from "utils/generators";
import { Services } from "utils/services";

import styles from "./styles.module.scss";

type Props = {
  costs: Array<
    ObjectType.Partial<EstimatedConceptCost, "deep"> | null | undefined
  >;
};

export const ConceptDeliverables = memo<Props>(({ costs }) => {
  const { t } = useTranslation("");
  const generateNumber = useMemo(() => conceptNumberGenerator(), []);
  const outcomes = useMemo(() => {
    return costs.map(
      (cost) => ({
        ...cost,
        number: cost?.conceptId && generateNumber(cost?.conceptId),
        outcomes: cost?.outcomesCosts
          ?.filter((cost) => Services[cost?.outcome?.serviceId as Services])
          ?.reduce(
            (acc, item) => ({
              total: acc.total + (item?.outcome?.amount ?? 0),
              uploaded: acc.uploaded + (item?.outcome?.assetsCount ?? 0),
            }),
            {
              total: 0,
              uploaded: 0,
            }
          ),
      }),
      [costs]
    );
  }, [costs]);

  return (
    <ul>
      {outcomes.map((outcome) => (
        <li
          className={styles.conceptDeliverablesListItem}
          key={outcome?.conceptId}
        >
          <p className={styles.conceptDeliverablesListItemTitle}>
            {t(
              `credits.summary.concept.titles.${
                outcome.concept?.name ? "named" : outcome.number
              }`,
              { name: outcome.concept?.name }
            )}
          </p>
          <p className={styles.conceptDeliverablesListItemAmount}>
            {outcome?.outcomes?.uploaded} / {outcome?.outcomes?.total}
          </p>
        </li>
      ))}
    </ul>
  );
});
