import camelCase from "lodash/camelCase";

type CamelizeString<T extends PropertyKey> = T extends string
  ? string extends T
    ? string
    : T extends `${infer F}-${infer R}`
    ? `${Capitalize<F>}${Capitalize<CamelizeString<R>>}`
    : Capitalize<T>
  : T;
/**
 * Combine string to provide a class name with type string
 * @param className The class name you want to adjust
 * @param suffix the ending of the class name you want to add
 */
export function cn<C extends string, T extends string>(
  className: C,
  suffix: T
) {
  return camelCase(
    `${className}-${suffix}`
  ) as `${typeof className}${CamelizeString<typeof suffix>}`;
}
