import { useCreateBrandMutation, useProfileQuery } from "api/graphql";
import { links } from "App";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const useCreateNewBrand = () => {
  const { t } = useTranslation("brands");
  const { data: { me: profile } = {} } = useProfileQuery();
  const { setNotification, notificationTypes } = useNotification();
  const { push } = useHistory();
  const organizationId =
    profile?.__typename === "OrganizationUser"
      ? profile?.organization?.organizationId ?? ""
      : "";

  const [createBrandMutation, { loading }] = useCreateBrandMutation({
    onCompleted: ({ createBrandProfile }) =>
      createBrandProfile?.brandProfileId &&
      push(links.BrandDetails({ id: createBrandProfile?.brandProfileId })),
    onError(error) {
      setNotification({
        message: error.message ?? t("common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  const createBrand = () => {
    createBrandMutation({
      variables: {
        brandProfileFields: { organizationId, name: t("create.name") },
      },
    });
  };

  return {
    createBrand,
    loading,
  };
};
