import { Loader } from "@CreativelySquared/uikit";
import { FC, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

import { usePreview } from "./PreviewContext";

import styles from "./styles.module.scss";

export const Preview: FC = () => {
  const { preview, setPreview } = usePreview();
  const [downloading, setDownloading] = useState(false);

  const { previewURL, mediaType, onDownload, loading } = preview ?? {};

  return (
    <Lightbox
      open={!!previewURL || loading}
      plugins={[Video, ...(onDownload ? [Download] : [])]}
      close={() => setPreview(null)}
      slides={[
        mediaType === "video"
          ? {
              type: "video",
              sources: [
                {
                  src: previewURL || "",
                  type: "video/mp4",
                },
              ],
            }
          : {
              type: "image",
              src: previewURL || "",
            },
      ]}
      controller={{
        closeOnPullDown: true,
        closeOnBackdropClick: true,
      }}
      carousel={{
        preload: 1,
        finite: true,
      }}
      render={{
        buttonPrev: () => null,
        buttonNext: () => null,
        slide: ({ slide }) => {
          if (
            ((slide.type === "image" && !slide.src) ||
              (slide.type === "video" && !slide.sources[0].src)) &&
            loading
          ) {
            return <Loader radius={50} />;
          }

          return undefined;
        },
        iconLoading: () => <Loader radius={50} />,
        ...(downloading && {
          iconDownload: () => (
            <button disabled>
              <Loader radius={20} />
            </button>
          ),
        }),
      }}
      download={
        onDownload
          ? {
              download: async () => {
                setDownloading(true);
                await onDownload();
                setDownloading(false);
              },
            }
          : undefined
      }
      className={styles.lightbox}
    />
  );
};
