import React, { FC } from "react";
import { ItemSelector, Media } from "components";
import { formatBytes } from "utils/formatBytes";
import { useTranslation } from "react-i18next";
import { getFileMetadata } from "utils/file";

import { AssetListItemProps } from "./types";
import { ListViewItem } from "./ListViewItem";

import styles from "./styles.module.scss";

export enum AssetViewType {
  Gallery = "gallery",
  Details = "details",
  List = "list",
}

export const AssetListItem: FC<AssetListItemProps> = (props) => {
  const { t } = useTranslation("common");
  const {
    asset,
    selected = false,
    starred = false,
    onSelect,
    onStar,
    view = AssetViewType.Gallery,
  } = props;

  const file = asset.latestVersion?.file;
  const metadata = getFileMetadata(file);

  if (view === AssetViewType.List) {
    return <ListViewItem {...props} />;
  }

  return (
    <div className="flex flex-col h-full">
      <ItemSelector
        selected={selected}
        starred={starred}
        onSelect={onSelect ? () => onSelect(asset.assetId) : undefined}
        onStar={onStar ? () => onStar(asset) : undefined}
        className={styles.selector}
      >
        <Media
          type={metadata?.common?.type}
          url={file?.thumbnail?.downloadUrl?.url}
          fileId={file?.fileId}
          className="rounded-lg object-cover"
          sectionClassName="h-full w-full"
        />
        {view === AssetViewType.Details && (
          <div className={styles.details}>
            <p className={styles.detailsName}>{file?.fileName}</p>
            <div className={styles.detailsInfo}>
              <div className={styles.detailsInfoMeta}>
                {`${formatBytes(metadata?.common?.size ?? 0)}, ${
                  metadata?.canvas?.width ?? 0
                }x${metadata?.canvas?.height ?? 0}`}
              </div>
              <div className={styles.detailsInfoLabels}>
                {(asset?.versions?.length ?? 0) > 1 && (
                  <span className="bg-light-teal">
                    {t("gallery.version", {
                      version: asset?.versions?.length,
                    })}
                  </span>
                )}
                {file?.fileName && (
                  <span>{file.fileName.split(".").pop()}</span>
                )}
              </div>
            </div>
          </div>
        )}
      </ItemSelector>
    </div>
  );
};
