import { Route } from "react-router-hoc";
export * from "./Authorization";
import { Switch } from "react-router";

import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { CreateAccount } from "./Registration/CreateAccount";
import { CreateProfile } from "./Registration/CreateProfile";
import { ProfileGoals } from "./Registration/ProfileGoals";
import { Maintenance } from "./Maintenance";
import { Restricted } from "./Restricted";
import { Logout } from "./Logout";
import { Authorization } from "./Authorization";
import { ActivateAccount } from "./ActivateAccount";

export const links = {
  Login,
  ForgotPassword,
  ResetPassword,
  CreateAccount,
  CreateProfile,
  ProfileGoals,
  Logout,
  ActivateAccount,
  Maintenance,
  Restricted,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Restricted />
      <CreateAccount />
      <CreateProfile />
      <ProfileGoals />
      <Authorization />
    </Switch>
  );
});
