import { useDeleteTagMutation } from "api/graphql";
import React, { FC } from "react";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import { Tag as ITag } from "api/types";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface Props {
  label: string;
  assetId: string;
  tagId: string;
  tags: Array<ITag | null>;
  className?: string;
  deleteClassName?: string;
}

export const Tag: FC<Props> = ({
  label,
  assetId,
  tagId,
  tags,
  className,
  deleteClassName,
}) => {
  const { t } = useTranslation("common");

  const { setNotification, notificationTypes } = useNotification();

  const [deleteTag] = useDeleteTagMutation({
    optimisticResponse({ assetId, tagId }) {
      return {
        destroyAssetTag: {
          __typename: "Asset",
          assetId,
          tags: tags.filter((tag) => tag?.tagId !== tagId),
        },
      };
    },
    onError(error) {
      setNotification({
        message: error.message ?? t("common.error"),
        type: notificationTypes.Error,
      });
    },
  });

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={clsx(
        "group mr-3 mb-4 overflow-hidden rounded-md px-7 py-3 inline-flex items-center text-xs font-medium text-blackberry bg-grey relative",
        className
      )}
    >
      <span className="text-ellipsis overflow-hidden">{label}</span>

      <button
        className="hidden group-hover:block absolute right-1"
        type="button"
        onClick={() => {
          deleteTag({
            variables: {
              assetId,
              tagId,
            },
          });
        }}
      >
        <CrossIcon className={clsx(styles.delete, deleteClassName)} />
      </button>
    </div>
  );
};
