import { memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Button, Dropdown } from "@CreativelySquared/uikit";
import { GetProps } from "@CreativelySquared/uikit/dist/types/props";
import { AssetVersion } from "api/types";
import { formatDistance } from "date-fns";
import { DividedList } from "components/DividedList";
import { Object } from "ts-toolbelt";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import { getFullName } from "utils/users";

import styles from "./styles.module.scss";

type Props = {
  currentVersion?: string;
  setVersion: (version: string) => void;
  versions: Array<
    Object.Partial<AssetVersion, "deep"> | undefined | null
  > | null;
  allOption?: boolean;
  onRemove?: (versionId: string) => void;
} & GetProps<typeof Dropdown>;

export const SelectAssetVersion = memo<Props>(
  ({
    currentVersion = "",
    versions,
    setVersion,
    allOption,
    onRemove,
    className,
    ...props
  }) => {
    const { t } = useTranslation();

    return (
      <div className="relative">
        <Dropdown
          value={currentVersion}
          placement={Dropdown.placements.TopStart}
          popoverClassName="!-left-0 max-h-[320px] overflow-y-auto"
          className={clsx(styles.selectAssetVersionDropdown, className)}
          outlined
          onSelect={(version) => setVersion((version as string) ?? "")}
          {...props}
        >
          {allOption && (
            <Dropdown.Item value={""}>
              {t("common:asset.actions.versions.all")}
            </Dropdown.Item>
          )}
          {[...(versions ?? [])]
            ?.sort(
              (current, next) =>
                Number(next?.createdAt) - Number(current?.createdAt)
            )
            ?.map((version, id) => {
              const date = formatDistance(
                new Date(+(version?.createdAt ?? 0)),
                new Date(),
                { addSuffix: true }
              );
              const author = getFullName(version?.file?.createdBy);

              return (
                <Dropdown.Item
                  key={version?.assetVersionId}
                  value={version?.assetVersionId}
                  className={clsx(styles.selectAssetVersionItem, {
                    [styles.selectAssetVersionItemSelected]:
                      version?.assetVersionId === currentVersion,
                  })}
                >
                  {t("common:asset.actions.versions.value", {
                    version: (versions?.length ?? 0) - id,
                  })}
                  <DividedList
                    className={styles.selectAssetVersionInfo}
                    separator=" | "
                  >
                    {date}
                    {author}
                  </DividedList>
                  {onRemove && (
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemove(version?.assetVersionId ?? "");
                      }}
                      variant={Button.variants.Icon}
                      className={styles.selectAssetVersionRemove}
                    >
                      <CrossIcon />
                    </Button>
                  )}
                </Dropdown.Item>
              );
            })}
        </Dropdown>
      </div>
    );
  }
);
