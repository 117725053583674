import { Button, Input, Tooltip } from "@CreativelySquared/uikit";
import { memo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "images/copy.svg";
import { useTranslation } from "react-i18next";
import { GetProps } from "react-router-hoc/lib/types";
import clsx from "clsx";

import styles from "./styles.module.scss";

export type ShareLinkProps = GetProps<typeof Input> & {
  placeholder?: string;
  link?: string | null;
  loading?: boolean;
  onCreate: () => void;
};

export const ShareLink = memo<ShareLinkProps>(
  ({ placeholder, className, link, loading, onCreate, ...props }) => {
    const { t } = useTranslation("common");
    const [copied, setCopied] = useState(false);

    return (
      <section className="relative">
        <Input
          {...props}
          className={clsx("w-full", className)}
          visibility="instant"
          readOnly
          value={link ?? ""}
          variant={Input.variants.Secondary}
          placeholder={placeholder}
          postfix={
            link ? (
              <Tooltip
                placement={Tooltip.placements.Top}
                title={t("share.link.actions.copy", { count: +copied })}
                className={clsx(styles.tooltip, {
                  [styles.inActive]: !copied,
                })}
                variant={Tooltip.variants.Secondary}
              >
                <div className="bg-white px-3 h-9 flex items-center">
                  <CopyToClipboard
                    onCopy={(_, result) => setCopied(result)}
                    text={link}
                  >
                    <CopyIcon className="cursor-pointer" />
                  </CopyToClipboard>
                </div>
              </Tooltip>
            ) : (
              <Button
                onClick={onCreate}
                className="-mr-4"
                loading={loading}
                size={Button.sizes.Small}
              >
                {t("share.link.actions.create")}
              </Button>
            )
          }
        />
      </section>
    );
  }
);
