import { useTranslation } from "react-i18next";
import { Label } from "@CreativelySquared/uikit";
import { Brief } from "api/types";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import type { Object } from "ts-toolbelt";
import { groupCountries } from "components/SelectCountry/countries";
import { isBoolean } from "lodash";
import { BriefProductAcquisitionMethod, BriefCategory } from "api/enums";
import { SectionPopup } from "components";
import { BriefSection, BriefShareableField } from "Brief/utils/types";
import { format } from "date-fns";

import { BriefAccess } from "../components/Access";

import styles from "./styles.module.scss";

type Props = {
  brief: Object.Partial<Brief, "deep">;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const SummaryLogistics = memo<Props>(
  ({ brief, className, ...props }) => {
    const { t } = useTranslation("brief");
    const {
      productAcquisitionMethod,
      productAvailableCountries,
      shouldProductBeReturned,
      productAvailableLocations,
      shareable,
      shareableDate,
      shareableReason,
      briefId,
    } = brief;

    return (
      <BriefAccess
        categories={[
          BriefCategory.OriginalCreative,
          BriefCategory.ContentCreationCreative,
        ]}
      >
        <section className={className} {...props}>
          <h2>{t("logistics.title")}</h2>

          {productAcquisitionMethod && (
            <SectionPopup
              comment={{
                briefId,
                sectionId: BriefSection.productAcquisitionMethod,
              }}
            >
              <div className={styles.summaryFormField}>
                <label className={styles.summaryFormFieldTitle}>
                  {t("summary.form.productAcquisitionMethod")}
                </label>
                <div className="flex flex-wrap gap-4">
                  <Label
                    variant={Label.variants.Neutral}
                    size={Label.sizes.Small}
                  >
                    {t(
                      `logistics.form.productAcquisitionMethod.options.${productAcquisitionMethod}`
                    )}
                  </Label>

                  {[
                    BriefProductAcquisitionMethod.Shipped,
                    BriefProductAcquisitionMethod.Store,
                    BriefProductAcquisitionMethod.Online,
                  ].includes(
                    productAcquisitionMethod as BriefProductAcquisitionMethod
                  ) &&
                    !!productAvailableCountries?.length &&
                    groupCountries(productAvailableCountries as string[])?.map(
                      (country) => (
                        <Label
                          variant={Label.variants.Neutral}
                          size={Label.sizes.Small}
                          key={country}
                        >
                          {country}
                        </Label>
                      )
                    )}

                  {BriefProductAcquisitionMethod.Shipped ===
                    productAcquisitionMethod &&
                    isBoolean(shouldProductBeReturned) && (
                      <Label
                        variant={Label.variants.Neutral}
                        size={Label.sizes.Small}
                      >
                        {t(
                          `logistics.form.shouldProductBeReturned.options.${
                            shouldProductBeReturned ? "yes" : "no"
                          }`
                        )}
                      </Label>
                    )}
                </div>

                {[
                  BriefProductAcquisitionMethod.Store,
                  BriefProductAcquisitionMethod.Online,
                ].includes(
                  productAcquisitionMethod as BriefProductAcquisitionMethod
                ) &&
                  !!productAvailableLocations?.length && (
                    <div className="mt-4">
                      {productAvailableLocations?.map(
                        (location, idx) =>
                          location && (
                            <p key={idx} className="text-sm mt-3 words-break">
                              <span className="mr-6">{idx + 1}.</span>
                              {location}
                            </p>
                          )
                      )}
                    </div>
                  )}
              </div>
            </SectionPopup>
          )}

          {/* <div className="grid grid-cols-2 w-full"> */}
          {shareable && (
            <SectionPopup
              comment={{
                briefId,
                sectionId: BriefSection.shareable,
              }}
            >
              <div className={styles.summaryFormField}>
                <label className={styles.summaryFormFieldTitle}>
                  {t("summary.form.shareable")}
                </label>
                <Label
                  variant={Label.variants.Neutral}
                  size={Label.sizes.Small}
                >
                  {t(
                    `logistics.form.shareable.summaryOptions.${shareable}`,
                    shareable === BriefShareableField.yes_custom
                      ? {
                          customDate: shareableDate
                            ? format(
                                new Date(Number(shareableDate)),
                                "dd MMM yyyy"
                              )
                            : "",
                        }
                      : shareable === BriefShareableField.no
                      ? {
                          reason: shareableReason
                            ? ` - ${shareableReason}`
                            : "",
                        }
                      : {}
                  )}
                </Label>
              </div>
            </SectionPopup>
          )}
          {/* <div className={styles.summaryFormField}>
                <label className={styles.summaryFormFieldTitle}>
                  {t("summary.form.hasRushOutcome")}
                </label>
                <p className={styles.summaryFormFieldDescription}>
                  <Label
                    variant={Label.variants.Neutral}
                    size={Label.sizes.Small}
                  >
                    {t(`common:actions.${hasRushOutcome ? "yes" : "no"}`)}
                  </Label>
                </p>
              </div>
              </div> */}
        </section>
      </BriefAccess>
    );
  }
);
