import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Project } from "api/types";
import {
  useCompleteProjectMutation,
  useFinalizeProjectMutation,
  useInitialDeliveryProjectMutation,
  useUnderRevisionProjectMutation,
} from "api/graphql";
import { ProjectStatuses } from "Projects/utils/types";
import { object, string } from "yup";
import { useFormik } from "formik";
import { ProjectErrorCodes } from "Projects/utils/errors";

type FormData = { confirm?: string; force?: boolean };

type Props = {
  projectId: Project["projectId"];
  mode: ProjectStatuses;
  onClose: () => void;
  onSubmit?: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const CompleteProject = memo<Props>(
  ({ onClose, className, projectId, mode, onSubmit, ...props }) => {
    const { t, i18n } = useTranslation("projects");
    const validationSchema = object({
      confirm: string().when("force", {
        is: true,
        then: (schema) =>
          schema
            .required("common:validation.required")
            .lowercase()
            .oneOf([t(`brief.${mode}.validationWord`)]),
      }),
    });
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      setFieldValue,
      values: { confirm, force },
    } = useFormik<FormData>({
      async onSubmit({ force }) {
        switch (mode) {
          case ProjectStatuses.delivered:
            return deliverProject();
          case ProjectStatuses.partial_delivery:
            return initialDelivery();
          case ProjectStatuses.finalized:
            return finalize({
              variables: {
                projectId,
                force,
              },
            });
          case ProjectStatuses.under_revision:
            return underRevision();
          default:
            return;
        }
      },
      validationSchema,
      initialValues: {
        confirm: "",
        force: false,
      },
    });
    const [deliverProject, { error: deliverError, loading: deliverLoading }] =
      useCompleteProjectMutation({
        variables: {
          projectId,
          override: { isForced: true },
        },
        onCompleted() {
          onSubmit?.();
        },
      });

    const [
      initialDelivery,
      { error: initialDeliveryError, loading: initialDeliveryLoading },
    ] = useInitialDeliveryProjectMutation({
      variables: {
        projectId,
      },
      onCompleted() {
        onSubmit?.();
      },
    });

    const [
      underRevision,
      { error: underRevisionError, loading: underRevisionLoading },
    ] = useUnderRevisionProjectMutation({
      variables: {
        projectId,
      },
      onCompleted() {
        onSubmit?.();
      },
    });

    const [finalize, { error: finalizeError, loading: finalizeLoading }] =
      useFinalizeProjectMutation({
        variables: {
          projectId,
        },
        onError: (error) => {
          if (
            error.graphQLErrors?.[0].extensions.code ===
            ProjectErrorCodes.ProjectHasAssetsNeedsChangesError
          ) {
            return setFieldValue("force", true);
          }
        },
        onCompleted() {
          onSubmit?.();
        },
      });

    const error =
      deliverError ??
      initialDeliveryError ??
      finalizeError ??
      underRevisionError;
    const loading =
      deliverLoading ||
      initialDeliveryLoading ||
      finalizeLoading ||
      underRevisionLoading;

    return (
      <form onSubmit={handleSubmit} noValidate className={className} {...props}>
        <h3 className="mt-4">{t(`brief.${mode}.title`)}</h3>
        <p className="text-base text-blackberry">
          {t(`brief.${mode}.explanation`)}
        </p>
        {error && (
          <p className="my-5 text-red">
            {i18n.exists(
              `projects:brief.${mode}.errors.${error.graphQLErrors?.[0].extensions.code}`
            )
              ? t(
                  `brief.${mode}.errors.${error.graphQLErrors?.[0].extensions.code}`
                )
              : error.message ?? t(`brief.${mode}.errors.common`)}
          </p>
        )}
        {force && (
          <Input
            name="confirm"
            variant={Input.variants.Secondary}
            value={confirm?.toUpperCase()}
            className="w-full mt-7"
            placeholder={t(`brief.${mode}.placeholder`)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}

        <section className="flex justify-end mt-9">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t(`brief.${mode}.actions.cancel`)}
          </Button>
          <Button
            loading={loading}
            disabled={!isValid}
            variant={Button.variants.Primary}
            type="submit"
          >
            {t(`brief.${mode}.actions.confirm`, { context: force && "force" })}
          </Button>
        </section>
      </form>
    );
  }
);
