import { AssetViewType } from "DAM/components/AssetListItem";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { RouteLink } from "react-router-hoc/lib/types";
import { SortFields, SortOrder } from "utils/order";

type Filter = Record<string, string | number | string[] | number[] | undefined>;

export const useAssetsRouteSettings = <Q, P>({
  link,
  query,
  params,
}: {
  link: RouteLink<string, P, Q>;
  query: Q;
  params: P;
}) => {
  const { push } = useHistory();

  const getLink = useCallback(
    (data: Filter) =>
      link({
        ...params,
        ...query,
        ...data,
      }),
    [query, params]
  );

  const onSearch = useCallback(
    (value: string) => push(getLink({ page: 1, search: value })),
    [push, getLink]
  );

  const onSort = useCallback(
    (sortOrder: SortOrder) =>
      push(
        getLink({
          page: 1,
          sortOrder,
          sort: SortFields.createdAt,
        })
      ),
    [push, getLink]
  );

  const onFilter = useCallback(
    (data: Record<string, string[]>) =>
      push(
        getLink({
          ...data,
          page: 1,
        })
      ),
    [push, getLink]
  );

  const onViewChange = useCallback(
    (view: AssetViewType) => push(getLink({ view })),
    [push, getLink]
  );

  return {
    onSearch,
    onSort,
    onFilter,
    onViewChange,
    getLink,
  };
};
