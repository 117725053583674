import { useCreateProjectShareLinkMutation } from "api/graphql";
import { CreateProjectSharingLinkInput, Project } from "api/types";
import { links } from "App";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import { useMemo } from "react";
import { Object as ObjectType } from "ts-toolbelt";
import { InternalRefetchQueriesInclude } from "@apollo/client";

export const useShareLink = ({
  access,
  project,
  onUpdate,
  type = "gallery",
}: {
  access: CreateProjectSharingLinkInput;
  project?: ObjectType.Partial<Project, "deep"> | null;
  onUpdate?: InternalRefetchQueriesInclude;
  type?: "gallery" | "brief";
}) => {
  const [createLink, { loading: linkLoading, data }] =
    useCreateProjectShareLinkMutation({
      variables: {
        projectId: project?.projectId ?? "",
        input: access,
      },
      awaitRefetchQueries: true,
      refetchQueries: onUpdate,
    });

  const linkData = useMemo(() => {
    const sharedLink = project?.sharingLinks?.find((link) =>
      isEqual(pick(link, Object.keys(access)), access)
    );

    const link = type === "gallery" ? links.SharedGallery : links.SharedBrief;

    return Object.assign(
      {},
      {
        link:
          sharedLink?.linkCode &&
          `${location.origin}${link({
            sharedCode: sharedLink?.linkCode,
          })}`,
        sharedLink,
      }
    );
  }, [data, project?.sharingLinks, access]);

  return {
    createLink,
    linkLoading,
    linkData,
  };
};
