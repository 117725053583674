import { Card } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { FC } from "react";

import styles from "./styles.module.scss";

interface Props {
  onClick: () => void;
  isSelected: boolean;
  error?: boolean;
  title?: string;
  className?: string;
  description?: string;
  descriptionClassName?: string;
  disabled?: boolean;
}

export const OptionCard: FC<Props> = ({
  onClick,
  isSelected,
  error,
  title,
  className,
  description,
  descriptionClassName,
  disabled,
}) => {
  return (
    <Card
      onClick={disabled ? undefined : onClick}
      className={clsx(
        styles.card,
        {
          [styles.selected]: isSelected,
          [styles.error]: error,
          [styles.disabled]: disabled,
        },
        className
      )}
      infoClassName={styles.info}
    >
      {title && <Card.Title className={styles.title}>{title}</Card.Title>}
      {description && (
        <Card.Description
          className={clsx(styles.description, descriptionClassName)}
        >
          {description}
        </Card.Description>
      )}
    </Card>
  );
};
