import { GetProps } from "react-router-hoc/lib/types";

export type GetRouteProps<T> = T extends (component: infer C) => any
  ? GetProps<C>
  : any;

export enum BooleanFields {
  no = "no",
  yes = "yes",
}

export enum FileStatuses {
  waiting_data = "waiting_data",
  processing_metadata = "processing_metadata",
  ready_to_attach = "ready_to_attach",
  failed = "failed",
  processing_formats = "processing_formats",
  completed = "completed",
}

export const fileFinishedUploadStatuses = [
  FileStatuses.completed,
  FileStatuses.failed,
  FileStatuses.processing_formats,
  FileStatuses.processing_metadata,
  FileStatuses.ready_to_attach,
];

export type CamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<CamelCase<U>>}`
  : S;

export enum MediaFormats {
  jpg = "jpg",
  png = "png",
  jpeg = "jpeg",
  gif = "gif",
  webp = "webp",
  mp4 = "mp4",
  mov = "mov",
  avi = "avi",
  avk = "avk",
  pdf = "pdf",
  doc = "doc",
  docx = "docx",
  ppt = "ppt",
  pptx = "pptx",
  xls = "xls",
  xlsx = "xlsx",
  csv = "csv",
  plain = "plain",
  tiff = "tiff",
  webm = "webm",
}

export const MediaType = {
  [MediaFormats.jpg]: "image/jpg",
  [MediaFormats.png]: "image/png",
  [MediaFormats.jpeg]: "image/jpeg",
  [MediaFormats.gif]: "image/gif",
  [MediaFormats.webp]: "image/webp",
  [MediaFormats.mp4]: "video/mp4",
  [MediaFormats.mov]: "video/quicktime",
  [MediaFormats.avi]: "video/avi",
  [MediaFormats.avk]: "video/avi",
  [MediaFormats.pdf]: "application/pdf",
  [MediaFormats.doc]: "application/msword",
  [MediaFormats.docx]:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  [MediaFormats.ppt]: "application/vnd.ms-powerpoint",
  [MediaFormats.pptx]:
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  [MediaFormats.xls]: "application/vnd.ms-excel",
  [MediaFormats.xlsx]:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  [MediaFormats.csv]: "text/csv",
  [MediaFormats.plain]: "text/plain",
  [MediaFormats.tiff]: "image/tiff",
  [MediaFormats.webm]: "video/webm",
};

export const assetFormats = [
  MediaFormats.jpg,
  MediaFormats.png,
  MediaFormats.jpeg,
  MediaFormats.gif,
  MediaFormats.webp,
  MediaFormats.mp4,
  MediaFormats.mov,
  MediaFormats.avi,
  MediaFormats.avk,
  MediaFormats.tiff,
  MediaFormats.webm,
];

export enum AssetRatingType {
  liked = "liked",
  disliked = "disliked",
}
