import "./locales";
import { lazy } from "react";
import { Switch } from "react-router-dom";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const AssetDetailsRoute = compose(
  ProtectedRoute({
    access: [Roles.admin, Roles.organization_owner, Roles.account_manager],
  }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      versionId: Route.query.regex(idRegEx),
      comments: Route.params.oneOf("comments").optional,
    },
    ({ id, comments }) => `/assets/${id}/${comments}`
  )
);

const AssetDetails = AssetDetailsRoute(
  lazy(() => import(/* webpackChunkName: "Asset" */ "./AssetDetails"))
);

export const links = {
  AssetDetails,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <AssetDetails />
    </Switch>
  );
});
