import { Object } from "ts-toolbelt";
import { Brief } from "api/types";
import { BriefCategory } from "api/enums";

export const getBriefCredits = (brief: Object.Partial<Brief, "deep">) => {
  const concepts = brief?.concepts?.map((concept) => ({
    concept: {
      id: concept?.conceptId,
      name: concept?.name,
    },
    outcomes: concept?.outcomes?.reduce(
      (
        acc: Array<{
          serviceId: string;
          amount: number;
          price: number;
        }>,
        outcome
      ) => {
        const serviceId = outcome?.service?.serviceId ?? "";
        const existingOutcome = acc.find(
          (item) => item.serviceId === serviceId
        );

        return existingOutcome
          ? acc.map((item) =>
              item.serviceId === serviceId
                ? { ...item, amount: item.amount + (outcome?.amount || 0) }
                : item
            )
          : [
              ...acc,
              {
                serviceId,
                amount: outcome?.amount ?? 0,
                price: outcome?.service?.price ?? 0,
              },
            ];
      },
      []
    ),
    total: concept?.outcomes?.reduce(
      (acc, credit) =>
        acc + (credit?.amount ?? 0) * (credit?.service?.price ?? 0),
      0
    ),
  }));

  const totalCredits =
    (concepts?.reduce((acc, concept) => acc + (concept?.total ?? 0), 0) ?? 0) *
    (brief.hasRushOutcome ? 2 : 1); // Doubling the cost if hasRushOutcome is true

  return { concepts, total: totalCredits };
};

export const minConceptCredits = (category?: BriefCategory) =>
  category === BriefCategory.AdaptAndOptimise ? 3 : 10;
