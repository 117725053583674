import { CreatorUser, OrganizationUser, InternalUser } from "api/types";

export type User = CreatorUser | OrganizationUser | InternalUser;

export const getFullName = (
  user?: {
    name?: string | null;
    surname?: string | null;
    __typename?: string;
  } | null
) => `${user?.name ?? ""} ${user?.surname ?? ""}`.trim();
