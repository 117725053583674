import { memo } from "react";
import { GetProps } from "react-router-hoc/lib/types";
import { StatusLabel } from "components/StatusLabel";
import { ProjectStatuses } from "Projects/utils/types";
import { ProjectNodeFragment } from "api/types";
import { Roles } from "utils/roles";
import clsx from "clsx";

type Props = {
  project: Pick<ProjectNodeFragment, "status" | "deletedAt" | "createdBy">;
  labelClassName?: string;
} & Omit<GetProps<typeof StatusLabel>, "type" | "status">;

export const ProjectLabels = memo<Props>(
  ({
    project: { status, deletedAt, createdBy },
    className,
    labelClassName,
    ...props
  }) => {
    const isDeleted = !!deletedAt;
    const proposal =
      status === ProjectStatuses.draft &&
      [Roles.admin, Roles.account_manager].includes(createdBy?.role as Roles);

    return (
      <div className={clsx("flex gap-4", className)}>
        <StatusLabel
          type="project"
          status={
            (isDeleted
              ? ProjectStatuses.deleted
              : status ?? ProjectStatuses.draft) as ProjectStatuses
          }
          className={labelClassName}
          {...props}
        />

        {proposal && (
          <StatusLabel
            type="project"
            status="proposal"
            className={labelClassName}
            {...props}
          />
        )}
      </div>
    );
  }
);
