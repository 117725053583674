import { MutableRefObject, useEffect } from "react";

export function useClickOutside<T extends MutableRefObject<any>>(
  ref: T,
  handler?: (event: MouseEvent | TouchEvent) => any
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (handler) {
        handler(event);
      }
    };
    if (handler) {
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
    }

    return () => {
      if (handler) {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      }
    };
  }, [ref, handler]);
}
