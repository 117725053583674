import { QueryOptions } from "@apollo/client";
import { EmailExistsQueryVariables } from "api/types";
import { EmailExistsDocument, EmailExistsQueryResult } from "api/graphql";
import { client } from "api/apollo.client";

import { asyncDebounce } from "./asyncDebounce";

export const isEmailExist = asyncDebounce(
  (
    options: Omit<
      QueryOptions<EmailExistsQueryVariables, EmailExistsQueryResult>,
      "query"
    >
  ) =>
    client.query<EmailExistsQueryResult["data"], EmailExistsQueryVariables>({
      ...options,
      query: EmailExistsDocument,
    }),
  1000
);
