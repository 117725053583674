import React, {
  DetailedHTMLProps,
  Fragment,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  ReactElement,
} from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
> & {
  separator?: string | ReactElement;
};

export const DividedList = memo<Props>(
  ({ separator = " / ", className, children }) => {
    const items = React.Children.toArray(children);

    if (!items.length) {
      return null;
    }

    return (
      <div className={clsx(styles.dividedList, className)}>
        {items.map((child, index) => (
          <Fragment key={index}>
            {!!index && separator}
            {child}
          </Fragment>
        ))}
      </div>
    );
  }
);
