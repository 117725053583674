import { useProjectQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { withSuspense } from "utils/hooks/suspense";
import { useMemo } from "react";
import type { Object as ObjectType } from "ts-toolbelt";
import { Asset, User } from "api/types";
import { getFullName } from "utils/users";

import { AssetPreview } from "../components/AssetPreview";

import styles from "./styles.module.scss";

export const ProjectAssetsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
      rate: Route.params.oneOf("liked", "disliked"),
    },
    ({ id, rate }) => `/projects/${id}/${rate}`
  )
);

type RatedAssets = {
  liked?: Map<
    ObjectType.Partial<User, "deep">,
    ObjectType.Partial<Asset, "deep">[]
  >;
  disliked?: Map<
    ObjectType.Partial<User, "deep">,
    ObjectType.Partial<Asset, "deep">[]
  >;
};

const useProjectSuspenseQuery = withSuspense(useProjectQuery);

export const ProjectAssets = ProjectAssetsRoute(
  ({
    match: {
      params: { rate, id },
    },
  }) => {
    const { t } = useTranslation("projects");
    const { data: { getProject } = {} } = useProjectSuspenseQuery({
      variables: { projectId: id, includeAssets: true },
      suspense: true,
    });

    const { [rate]: users } = useMemo(() => {
      return (
        getProject?.assets2?.reduce(
          (acc, asset) => {
            return (
              asset?.ratings?.reduce((ratings, vote) => {
                if (!vote) return ratings;

                const rating: typeof rate = ~(vote?.vote ?? 0)
                  ? "liked"
                  : "disliked";

                const user = vote.user;

                return {
                  ...ratings,
                  [rating]: user
                    ? ratings[rating]?.set(
                        user,
                        ratings[rating]?.get(user)?.concat(asset) ?? [asset]
                      )
                    : ratings[rating],
                };
              }, acc) ?? acc
            );
          },
          {
            disliked: new Map(),
            liked: new Map(),
          } as RatedAssets
        ) ?? {}
      );
    }, [getProject]);

    const data = useMemo(() => users && Array.from(users), [users]);

    return (
      <main className={styles.projectAssets}>
        <Helmet title={t(`assets.title.${rate}`)} />
        {data?.map(([user, assets]) => (
          <section className="mb-10" key={user.userId}>
            <h2 className="words-break">{getFullName(user)}</h2>
            <section className={styles.projectAssetsList}>
              {assets.map((asset) => (
                <AssetPreview asset={asset} key={asset.assetId} />
              ))}
            </section>
          </section>
        ))}
        {data?.length === 0 && (
          <section className={styles.projectAssetsEmpty}>
            <h2>{t(`assets.empty.${rate}.title`)}</h2>
            <span>{t(`assets.empty.${rate}.description`)}</span>
          </section>
        )}
      </main>
    );
  }
);
