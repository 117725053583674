import clsx from "clsx";
import { groupBy } from "lodash";
import {
  Children,
  cloneElement,
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useMemo,
} from "react";
import isEqual from "react-fast-compare";

import styles from "./styles.module.scss";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  sidebar?: boolean;
};

const InnerLayoutSidebar = memo<PropsWithChildren<Props>>(
  ({ className, children, ...props }) => (
    <section className={clsx(styles.sidebarContent, className)} {...props}>
      {children}
    </section>
  )
);

InnerLayoutSidebar.displayName = "InnerLayout.Sidebar";

const InnerLayoutMain = memo<PropsWithChildren<Props>>(
  ({ className, ...props }) => (
    <section className={clsx(styles.main, className)} {...props} />
  )
);

InnerLayoutMain.displayName = "InnerLayout.Main";

export const InnerLayout = Object.assign(
  memo<PropsWithChildren<Props>>(
    ({ children, className, sidebar: aside = true, ...props }) => {
      const components = useMemo(() => Children.toArray(children), [children]);

      const {
        [`${InnerLayoutMain.displayName}`]: [main] = [],
        [`${InnerLayoutSidebar.displayName}`]: [sidebar] = [],
      } = useMemo(() => groupBy(components, "type.displayName"), [components]);
      const mainLayout = useMemo(
        () =>
          cloneElement(main as any, { className: aside && styles.mainSidebar }),
        [main]
      );
      return (
        <main className={clsx("flex", "w-full", className)} {...props}>
          <aside
            className={clsx(styles.sidebar, { hidden: !aside })}
            {...props}
          >
            {sidebar}
          </aside>

          {mainLayout}
        </main>
      );
    },
    isEqual
  ),
  {
    displayName: "InnerLayout",
    Main: InnerLayoutMain,
    Sidebar: InnerLayoutSidebar,
  }
);
