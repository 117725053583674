import {
  useCustomerQuery,
  useUsersQuery,
  useSetOrganizationManagerMutation,
  CustomerDocument,
  useCustomerEventsQuery,
  useOrganizationManagedProjectsQuery,
} from "api/graphql";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Button, Card, Dropdown } from "@CreativelySquared/uikit";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { links } from "App";
import editIcon from "images/edit.svg";
import clsx from "clsx";
import { useFormik } from "formik";
import { object, string } from "yup";
import { CSSTransition } from "react-transition-group";
import { SaveButton } from "components/SaveButton";
import { isNumber } from "lodash";
import { getFullName } from "utils/users";
import { ProjectActiveStatus } from "api/enums";

import styles from "./styles.module.scss";

type FormData = { manager: string | null };

const validationSchema = object({
  manager: string().required(),
});

export const CustomerSettingsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
    },
    ({ id }) => `/customers/${id}/settings`
  )
);

const accountManagerAssignedEvent = "organization_account_manager_assigned";

export const CustomerSettings = CustomerSettingsRoute(
  ({
    match: {
      params: { id },
    },
  }) => {
    const managerAssignedEventVariables = {
      organizationId: id,
      eventNames: accountManagerAssignedEvent,
      pagination: { limit: 1 },
    };

    const { data: { getOrganization: organization } = {} } = useCustomerQuery({
      variables: { id },
    });

    const { data: { getProjects: projects } = {} } =
      useOrganizationManagedProjectsQuery({
        variables: {
          organizationId: id,
          filters: {
            isDeleted: false,
            status: Object.values(ProjectActiveStatus),
          },
        },
      });

    const activeManager = organization?.managedBy?.userId || undefined;

    const { data: { getUsers } = {} } = useUsersQuery({
      variables: { role: Roles.account_manager },
    });

    const users = getUsers?.nodes;

    const projectManagers = useMemo(
      () =>
        projects?.nodes?.reduce((acc, project) => {
          const user = users?.find(
            (user) => user?.userId === project?.managedByUserId
          );

          if (
            !user ||
            !activeManager ||
            project?.managedByUserId === activeManager
          ) {
            return acc;
          }

          return {
            ...acc,
            [project?.title as string]: getFullName(user),
          };
        }, {} as { [x: string]: string }),
      [projects, users, organization?.managedBy?.userId]
    );

    const { data: { getOrganizationEvents } = {} } = useCustomerEventsQuery({
      variables: managerAssignedEventVariables,
    });

    const [setManager, { loading: setManagerLoading }] =
      useSetOrganizationManagerMutation({
        update(cache, { data }) {
          if (!data?.setOrganizationAccountManager) return;

          cache.updateQuery(
            {
              query: CustomerDocument,
              variables: { id },
            },
            (customer) => ({
              getOrganization: {
                ...customer?.getOrganization,
                managedBy: data?.setOrganizationAccountManager,
              },
            })
          );
        },
      });

    const { t } = useTranslation("customers");

    const {
      handleChange,
      handleSubmit,
      resetForm,
      setFieldValue,
      isValid,
      values: { manager },
    } = useFormik<FormData>({
      async onSubmit() {
        try {
          await setManager({
            variables: {
              organizationId: id,
              userId: manager!,
            },
          });

          setFieldValue("manager", null);
        } catch (error) {
          console.error(error);
        }
      },
      validationSchema,
      initialErrors: {
        manager: "",
      },
      initialValues: {
        manager: null,
      },
    });

    const onCancel = () => {
      resetForm({
        values: {
          manager: null,
        },
      });
    };

    const usage = organization?.subscriptionPlan?.usage;

    const showControls = manager && manager !== activeManager;

    return (
      <main className="mt-10">
        <Helmet title={t("settings.title")} />
        <Card
          variant={Card.variants.List}
          className={styles.customerSettingsCard}
        >
          <Card.Title>{t("settings.details.title")}</Card.Title>
          <Card.Description>
            {organization?.name && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings.details.fields.customer")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {organization?.name}
                </span>
              </section>
            )}
            {organization?.cname && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings.details.fields.workspace")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {organization?.cname}
                </span>
              </section>
            )}
            {organization?.createdAt && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings.details.fields.created")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {format(new Date(+organization.createdAt), "PP")}
                </span>
              </section>
            )}
            {organization?.createdBy && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings.details.fields.created_by")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {getFullName(organization.createdBy)}
                </span>
              </section>
            )}
            {organization?.industry && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings.details.fields.industry")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {t(
                    `authorization:registration.profileGoals.industry.${organization.industry}`
                  )}
                </span>
              </section>
            )}
            {organization?.organizationId && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings.details.fields.id")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {organization?.organizationId}
                </span>
              </section>
            )}
          </Card.Description>
          <Card.Details className="shrink-0" position="top" visibility="hover">
            <Link
              to={links.CustomerManagement({
                mode: "edit",
                id: organization?.organizationId,
              })}
            >
              <Button variant={Button.variants.Icon}>
                <img src={editIcon} />
              </Button>
            </Link>
          </Card.Details>
        </Card>
        <Card
          variant={Card.variants.List}
          className={clsx(styles.customerSettingsCard, "mt-10")}
        >
          <Card.Title>
            {t("settings:account.usage.title")}
            <p className="text-sm font-regular text-mid-blue-steel">
              {t("settings:account.usage.description")}
            </p>
          </Card.Title>
          <Card.Description>
            {isNumber(usage?.credits) && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings:account.usage.credits")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {usage?.credits}
                </span>
              </section>
            )}
            {isNumber(usage?.assets) && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings:account.usage.assets")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {usage?.assets}
                </span>
              </section>
            )}
            {isNumber(usage?.projects) && (
              <section className={styles.customerSettingsCardLabel}>
                <span>{t("settings:account.usage.projects")}</span>
                <span className={styles.customerSettingsCardLabelValue}>
                  {usage?.projects}
                </span>
              </section>
            )}
          </Card.Description>
        </Card>
        <Card
          variant={Card.variants.List}
          className={clsx(styles.customerSettingsCard, "mt-10")}
        >
          <Card.Title>{t("settings.manager.title")}</Card.Title>
          <Card.Description>
            {activeManager ? (
              <>
                <section className={styles.customerSettingsCardLabel}>
                  <span>{t("settings.manager.fields.manager")}</span>
                  <span className={styles.customerSettingsCardLabelValue}>
                    {getFullName(organization?.managedBy)}
                  </span>
                </section>
                {getOrganizationEvents?.nodes?.[0]?.happenedAt && (
                  <section className={styles.customerSettingsCardLabel}>
                    <span>{t("settings.manager.fields.date")}</span>
                    <span className={styles.customerSettingsCardLabelValue}>
                      {format(
                        new Date(+getOrganizationEvents.nodes[0].happenedAt),
                        "d MMMM yyyy"
                      )}
                    </span>
                  </section>
                )}
                <section className={styles.customerSettingsCardExplanation}>
                  {t("settings.manager.explanation")}
                </section>
              </>
            ) : (
              <section className={styles.customerSettingsCardExplanation}>
                <p>{t("settings.manager.hint")}</p>
                <p>{t("settings.manager.description")}</p>
              </section>
            )}

            {projectManagers && !!Object.keys(projectManagers).length && (
              <>
                <hr className="mt-8" />
                <h3 className="mb-6 mt-8">{t("settings.details.overrides")}</h3>

                {Object.entries(projectManagers).map(([title, manager]) => (
                  <section
                    className={styles.customerSettingsCardLabel}
                    key={title}
                  >
                    <span>{title}</span>
                    <span className={styles.customerSettingsCardLabelValue}>
                      {manager as string}
                    </span>
                  </section>
                ))}
              </>
            )}
          </Card.Description>

          <Card.Details position="top">
            <form
              onSubmit={handleSubmit}
              noValidate
              className={clsx("relative", {
                [styles.showControls]: showControls,
                [styles.loading]: setManagerLoading,
              })}
            >
              <Dropdown
                className={clsx({
                  "pointer-events-none": setManagerLoading,
                })}
                popoverClassName="w-full"
                onSelect={handleChange("manager")}
                value={manager!}
                label={t(
                  `settings.manager.actions.${
                    activeManager ? "change" : "create"
                  }`
                )}
              >
                {users
                  ?.filter((user) => user?.userId !== activeManager)
                  ?.map((user) => (
                    <Dropdown.Item value={user?.userId} key={user?.userId}>
                      {getFullName(user)}
                    </Dropdown.Item>
                  ))}
              </Dropdown>

              <div className={styles.actions}>
                <Button
                  className={styles.cancel}
                  onClick={onCancel}
                  type="button"
                  variant={Button.variants.Action}
                >
                  {t("common:actions.discard")}
                </Button>

                <CSSTransition
                  classNames={{
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                  }}
                  in={!!showControls}
                  timeout={{
                    exit: manager ? 2000 : 0,
                  }}
                  unmountOnExit
                >
                  <SaveButton
                    loading={setManagerLoading && manager !== activeManager}
                    readonly={!isValid}
                    reset={!!manager}
                    className="ml-auto"
                    type="submit"
                  />
                </CSSTransition>
              </div>
            </form>
          </Card.Details>
        </Card>
      </main>
    );
  }
);
