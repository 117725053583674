import { lazy } from "react";
import { Route } from "react-router-hoc";

export const ScriptWriterRoute = Route(
  {
    conceptId: Route.query.string,
    organizationId: Route.query.string,
  },
  "/ai/tools/script-writer"
);

export const ScriptWriter = ScriptWriterRoute(
  lazy(() => import(/* webpackChunkName: "ScriptWriter" */ "./ScriptWriter"))
);
