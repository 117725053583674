import { addDays } from "date-fns";
import i18next from "i18next";

export const getRange = (dates?: Array<string | undefined>) => {
  const [startDate, until] = dates?.filter((date) => date) ?? [];

  // to get next Day 00:00 AM
  const endDate = startDate
    ? addDays(+(until ?? startDate), 1)
        .valueOf()
        .toString()
    : undefined;

  return { startDate, endDate };
};

export const formatDate = (date: Date | number | string) =>
  i18next.t("common:intlDateTime", {
    val: new Date(date),
    formatParams: {
      val: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    },
  });
