import { Card, Loader } from "@CreativelySquared/uikit";
import { groupBy } from "lodash";
import {
  Children,
  cloneElement,
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useMemo,
  useRef,
} from "react";
import isEqual from "react-fast-compare";
import clsx from "clsx";

import styles from "./styles.module.scss";

type Props = {
  clearable?: boolean;
  onSelect?: (() => void) | false;
  selected?: boolean;
  preview?: string;
} & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onSelect"
>;

export const SelectedCard = Object.assign(
  memo<PropsWithChildren<Props>>(
    ({
      children,
      className,
      onSelect,
      selected = false,
      preview,
      ...props
    }) => {
      const videoRef = useRef<HTMLVideoElement>(null);
      const components = useMemo(() => Children.toArray(children), [children]);

      const {
        [`${Card.Description.displayName}`]: [description] = [],
        [`${Card.Details.displayName}`]: [details] = [],
        [`${Card.Image.displayName}`]: [image] = [],
        [`${Card.Title.displayName}`]: [title] = [],
      } = useMemo(() => groupBy(components, "type.displayName"), [components]);

      const cardImage =
        image &&
        cloneElement(
          image as any,
          {
            className: clsx(styles.image, (image as any).props.className, {
              [styles.imageSelectable]: onSelect,
            }),
            onClick: onSelect,
          },
          <section
            className="flex justify-center items-center"
            onMouseMove={() => videoRef.current?.play()}
            onMouseLeave={() => {
              if (videoRef.current) {
                videoRef.current.currentTime = 0;
                videoRef.current.pause();
              }
            }}
          >
            <Loader radius={50} className="absolute" />
            <div className="absolute top-0 left-0 w-full h-full">
              {(image as any).props.children}
            </div>
            {preview && (
              <video
                src={preview}
                ref={videoRef}
                loop
                muted
                className={styles.preview}
                controls={false}
              />
            )}
          </section>
        );

      const cardDescription =
        description &&
        cloneElement(description as any, {
          className: clsx(
            styles.description,
            (description as any).props.className
          ),
        });

      const cardDetails = cloneElement((details ?? <Card.Details />) as any, {
        visibillity: selected ? "instant" : "hover",
      });

      const cardTitle =
        title &&
        cloneElement(
          title as any,
          {
            className: clsx(styles.title, (title as any).props.className),
          },
          (title as any).props.children
        );

      return (
        <Card
          className={clsx(
            styles.imageCard,
            {
              [styles.selected]: selected,
            },
            className
          )}
          variant={Card.variants.Card}
          {...props}
        >
          {cardDetails}
          {cardDescription}
          {cardImage}
          {cardTitle}
        </Card>
      );
    },
    isEqual
  ),
  {
    displayName: "SelectedCard",
    Description: Card.Description,
    Details: Card.Details,
    Image: Card.Image,
    Title: Card.Title,
  }
);
