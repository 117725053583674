import {
  PaginationSortingDirectionValue,
  ProjectSortingField,
} from "api/enums";

export const filterSearchPagination = {
  limit: 10,
  sortingField: ProjectSortingField.CreatedAt,
  sorting: PaginationSortingDirectionValue.Desc,
};

export const moreResultsCount = (total?: number | null) =>
  total && total > filterSearchPagination.limit
    ? total - filterSearchPagination.limit
    : undefined;

export const formatFilterOption = (
  array: { nodes?: Array<any> | null } | null = {},
  id = "id",
  name = "name"
) =>
  array?.nodes?.map((item) => ({
    id: item?.[id] ?? "",
    name: item?.[name] ?? "",
  }));
