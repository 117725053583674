import {
  DetailedHTMLProps,
  FormEvent,
  forwardRef,
  InputHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { debounce } from "lodash";
import { Input } from "@CreativelySquared/uikit";
import { ReactComponent as SearchIcon } from "images/search.svg";
import clsx from "clsx";

import styles from "./styles.module.scss";

type Props = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "type" | "ref"
> & { onSearch: (value: string) => any };

/** Remove onSelect in order to prevent dropdown from closing when click on search input */
export const Search = memo(
  forwardRef<{ input: HTMLInputElement | null } | undefined, Props>(
    ({ onSearch, value, className, onSelect: _onSelect, ...props }, ref) => {
      const searchRef = useRef<HTMLInputElement>(null);
      const debounceSearch = useMemo(() => {
        return debounce(onSearch, 200);
      }, [onSearch]);
      const search = useCallback(
        (event: FormEvent<HTMLInputElement>) =>
          debounceSearch(event.currentTarget.value),
        [onSearch]
      );

      useImperativeHandle(ref, () => ({
        input: searchRef.current,
      }));

      useEffect(() => {
        if (searchRef.current)
          searchRef.current.value = value?.toString() ?? "";
      }, [value]);

      useEffect(() => {
        searchRef.current?.addEventListener("search" as any, search);
        return () => {
          searchRef.current?.removeEventListener("search" as any, search);
        };
      }, [search]);

      return (
        <Input
          icon={<SearchIcon />}
          ref={searchRef}
          name="search"
          type="search"
          variant={Input.variants.Secondary}
          onChange={search}
          className={clsx(styles.search, className)}
          {...props}
        />
      );
    }
  )
);

Search.displayName = "Search";
