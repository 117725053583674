export enum OrderType {
  outcomeImageAmount = "images",
  outcomePremiumImageAmount = "premiumImages",
  outcomeVideoClipAmount = "videoClips",
  outcomeVideoStoryAmount = "videoStory",
  outcomePremiumVideoAmount = "premiumVideo",
  outcomeLongVideoAmount = "longFormVideo",
  outcomeStopMotionAmount = "stopMotion",
  outcomePremiumStopMotionAmount = "premiumStopMotion",
  outcomeIntensiveStopMotionAmount = "intensiveStopMotion",
  outcomeUgcVideoAmount = "ugcVideo",
}

export enum Services {
  original_static_creative = "original_static_creative",
  original_video = "original_video",
  original_text_variation = "original_text_variation",
  original_format_variation = "original_format_variation",
  content_creation_photo = "content_creation_photo",
  content_creation_video = "content_creation_video",
  content_creation_stop_motion = "content_creation_stop_motion",
  content_creation_mini_stop_motion = "content_creation_mini_stop_motion",
  creative_static = "creative_static",
  creative_video = "creative_video",
  creative_text_variation = "creative_text_variation",
  creative_format_variation = "creative_format_variation",
  /* Legacy services v2 */
  studio_photo = "studio_photo",
  studio_premium_photo = "studio_premium_photo",
  studio_clip = "studio_clip",
  studio_video_story = "studio_video_story",
  studio_long_video = "studio_long_video",
  studio_stop_motion = "studio_stop_motion",
  studio_premium_stop_motion = "studio_premium_stop_motion",
  studio_premium_video = "studio_premium_video",
  lifestyle_photo = "lifestyle_photo",
  lifestyle_clip = "lifestyle_clip",
  lifestyle_video_story = "lifestyle_video_story",
  lifestyle_premium_video = "lifestyle_premium_video",
  lifestyle_long_video = "lifestyle_long_video",
  lifestyle_stop_motion = "lifestyle_stop_motion",
  lifestyle_premium_stop_motion = "lifestyle_premium_stop_motion",
  lifestyle_intensive_stop_motion = "lifestyle_intensive_stop_motion",
  ugc_video = "ugc_video",
  /* Legacy services v1 */
  legacy_image_asset = "legacy_image_asset",
  legacy_image_ad_asset = "legacy_image_ad_asset",
  legacy_video_asset = "legacy_video_asset",
  legacy_video_ad_asset = "legacy_video_ad_asset",
  legacy_motion_asset = "legacy_motion_asset",
}

export enum DeliverableMedias {
  image = "image",
  motion = "motion",
  video = "video",
}

export const Deliverables: { [x: string]: OrderType } = {
  [Services.studio_photo]: OrderType.outcomeImageAmount,
  [Services.lifestyle_photo]: OrderType.outcomeImageAmount,
  [Services.studio_premium_photo]: OrderType.outcomePremiumImageAmount,
  [Services.studio_clip]: OrderType.outcomeVideoClipAmount,
  [Services.lifestyle_clip]: OrderType.outcomeVideoClipAmount,
  [Services.studio_video_story]: OrderType.outcomeVideoStoryAmount,
  [Services.lifestyle_video_story]: OrderType.outcomeVideoStoryAmount,
  [Services.studio_long_video]: OrderType.outcomeLongVideoAmount,
  [Services.lifestyle_long_video]: OrderType.outcomeLongVideoAmount,
  [Services.studio_stop_motion]: OrderType.outcomeStopMotionAmount,
  [Services.lifestyle_stop_motion]: OrderType.outcomeStopMotionAmount,
  [Services.studio_premium_stop_motion]:
    OrderType.outcomePremiumStopMotionAmount,
  [Services.lifestyle_premium_stop_motion]:
    OrderType.outcomePremiumStopMotionAmount,
  [Services.lifestyle_intensive_stop_motion]:
    OrderType.outcomeIntensiveStopMotionAmount,
  [Services.studio_long_video]: OrderType.outcomeLongVideoAmount,
  [Services.lifestyle_long_video]: OrderType.outcomeLongVideoAmount,
  [Services.lifestyle_premium_video]: OrderType.outcomePremiumVideoAmount,
  [Services.studio_premium_video]: OrderType.outcomePremiumVideoAmount,
  [Services.legacy_image_asset]: OrderType.outcomeImageAmount,
  [Services.legacy_image_ad_asset]: OrderType.outcomeImageAmount,
  [Services.legacy_video_asset]: OrderType.outcomeVideoClipAmount,
  [Services.legacy_video_ad_asset]: OrderType.outcomeVideoClipAmount,
  [Services.legacy_motion_asset]: OrderType.outcomeStopMotionAmount,
  [Services.ugc_video]: OrderType.outcomeUgcVideoAmount,
};

export const deliverableMedia = (serviceId: Services) => {
  if (
    [
      Services.studio_photo,
      Services.lifestyle_photo,
      Services.studio_premium_photo,
      Services.legacy_image_asset,
      Services.legacy_image_ad_asset,
    ].includes(serviceId)
  )
    return DeliverableMedias.image;

  if (
    [
      Services.studio_clip,
      Services.lifestyle_clip,
      Services.studio_video_story,
      Services.lifestyle_video_story,
      Services.studio_long_video,
      Services.lifestyle_long_video,
      Services.lifestyle_premium_video,
      Services.ugc_video,
      Services.legacy_video_asset,
      Services.legacy_video_ad_asset,
    ].includes(serviceId)
  )
    return DeliverableMedias.video;

  if (
    [
      Services.studio_stop_motion,
      Services.lifestyle_stop_motion,
      Services.studio_premium_stop_motion,
      Services.lifestyle_premium_stop_motion,
      Services.lifestyle_intensive_stop_motion,
      Services.legacy_motion_asset,
    ].includes(serviceId)
  )
    return DeliverableMedias.motion;
};
