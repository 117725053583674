import { BriefCategory, Maybe } from "api/types";
import { useContext } from "react";

import { BriefContext } from "../brief.context";

export const useBriefAccess = (categories: BriefCategory | BriefCategory[]) => {
  const { brief } = useContext(BriefContext);

  return new Array<Maybe<BriefCategory> | undefined>()
    .concat(categories)
    .includes(brief?.category);
};
