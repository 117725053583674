import React, { memo, NamedExoticComponent, SVGProps } from "react";

const ImageIcon: NamedExoticComponent<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      width="56"
      height="44"
      viewBox="0 0 56 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        y="0.251713"
        width="55.8084"
        height="43"
        rx="6"
        transform="rotate(-0.257212 0 0.251713)"
        fill="white"
      />
      <path
        d="M7.45419 21.18L1.9906 26.4V38C1.9906 40.2091 3.78146 42 5.9906 42H51.7426C53.3995 42 54.7426 40.6569 54.7426 39V26.4L39.3168 10.966C38.2082 9.85678 36.4323 9.78858 35.2419 10.8095L19.1242 24.6319C17.954 25.6356 16.2136 25.5888 15.0989 24.5238L11.599 21.18C10.4395 20.0722 8.61372 20.0722 7.45419 21.18Z"
        fill="#7B6D85"
      />
      <rect
        x="1.00448"
        y="1.24721"
        width="53.8084"
        height="41"
        rx="5"
        transform="rotate(-0.257212 1.00448 1.24721)"
        stroke="#3A2548"
        strokeWidth="2"
      />
    </svg>
  )
);

ImageIcon.displayName = "ImageIcon";

export default ImageIcon;
