import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";

import { advancedErrorMessage } from "./form";

declare module "yup" {
  export interface StringSchema {
    sequence(
      rules: Array<
        () => StringSchema<string | undefined, AnyObject, string | undefined>
      >
    ): StringSchema;
  }
}

Yup.addMethod(Yup.string, "sequence", function (funcList) {
  return this.test(async (value, context) => {
    try {
      for (const func of funcList) {
        await func().validate(value);
      }
    } catch ({ message }) {
      return context.createError({ message: message as any });
    }
    return true;
  });
});

export const passwordValidationSchema = (schema: Yup.StringSchema) =>
  schema
    .required("common:validation.required")
    .min(8, advancedErrorMessage("common:validation.min"))
    .test("password", "common:validation.password", (value) => {
      if (!value) return false;
      const checks = [
        /[a-z]/g, // Lower case
        /[A-Z]/g, // Upper case
        /[0-9]/g, // Digits
        /[^a-zA-Z0-9]/g, // Symbols
      ].reduce((acc, check) => acc + Number(check.test(value)), 0);

      return checks >= 2;
    });

export const commentValidationSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .required("common:validation.required")
    .min(3, advancedErrorMessage("common:validation.min"))
    .max(1000, advancedErrorMessage("common:validation.max")),
  mentions: Yup.array().of(Yup.string()),
});
