import clsx from "clsx";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replace } from "lodash";
import { GetProps } from "react-router-hoc/lib/types";
import { Label } from "@CreativelySquared/uikit";
import { Services } from "utils/services";

import styles from "./styles.module.scss";

enum AssetLabelType {
  Image = "image",
  ImageAd = "imageAd",
  Motion = "motion",
  Video = "video",
  VideoAd = "videoAd",
}

type Props = {
  selected?: number;
  count: number;
  showLabel?: boolean;
  type: AssetLabelType | Services;
} & GetProps<typeof Label>;

const getDeliverablesIconName = (type: Services) => {
  if (type.includes("video")) return "video";
  if (type.includes("motion")) return "motion";
  return "image";
};

export const AssetLabel = Object.assign(
  memo<Props>(({ className, showLabel, count, type, selected, ...props }) => {
    const fileName =
      type in Services
        ? getDeliverablesIconName(type as Services)
        : replace(type, "Ad", "Da");

    const icon = useMemo(() => {
      try {
        return require(`./images/${fileName}.svg`);
      } catch {
        return undefined;
      }
    }, [fileName]);

    const { t } = useTranslation();

    return (
      <Label
        {...props}
        className={clsx(styles.label, className)}
        variant={Label.variants.Neutral}
        size={Label.sizes.Small}
      >
        {icon && (
          <img alt={type} className={clsx(styles.icon, "mr-4")} src={icon} />
        )}
        <span className={styles.info}>
          {showLabel ? (
            t(`common:asset.label.${type}.title`, {
              count,
            })
          ) : (
            <>
              {selected !== undefined && `${selected} / `}
              {t(`asset.label.${type}.count`, {
                count,
              })}
            </>
          )}
        </span>
      </Label>
    );
  }),
  {
    types: AssetLabelType,
  }
);
