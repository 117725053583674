import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { Project } from "api/types";
import { useCancelProjectMutation } from "api/graphql";
import clsx from "clsx";

import styles from "./styles.module.scss";

type FormData = { confirm: string };

type Props = {
  projectId: Project["projectId"];
  onClose: () => void;
  onSubmit?: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const DeclineProject = memo<Props>(
  ({ projectId, onSubmit, onClose, className, ...props }) => {
    const { t } = useTranslation("projects");

    const [declineProject, { loading, error }] = useCancelProjectMutation({
      variables: {
        projectId,
      },
    });

    const validationSchema = object({
      confirm: string()
        .required("common:validation.required")
        .lowercase()
        .oneOf([t("brief.decline.validationWord")]),
    });

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
    } = useFormik<FormData>({
      async onSubmit() {
        try {
          await declineProject({ variables: { projectId } });

          onClose();
          onSubmit?.();
        } catch (error) {
          console.log(error);
        }
      },
      validationSchema,
      initialErrors: {
        confirm: "",
      },
      initialValues: {
        confirm: "",
      },
    });

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(styles.declineProject, className)}
        {...props}
      >
        <h3 className={styles.title}>{t("brief.decline.title")}</h3>
        <h4 className={styles.explanation}>{t("brief.decline.explanation")}</h4>
        {error && (
          <p className={styles.error}>{t("brief.decline.errors.common")}</p>
        )}
        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full mt-7 mb-6"
          placeholder={t("brief.decline.placeholder")}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t("brief.decline.actions.cancel")}
          </Button>
          <Button
            variant={Button.variants.Alert}
            loading={loading}
            type="submit"
            disabled={!isValid || loading}
          >
            {t("brief.decline.actions.delete")}
          </Button>
        </section>
      </form>
    );
  }
);
