import { Label } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { ItemSelector, Media } from "components";
import { ListItemWrapper } from "DAM/components/ListItemWrapper";
import { format } from "date-fns";
import { FC, useMemo } from "react";
import { formatBytes } from "utils/formatBytes";
import { getFileMetadata } from "utils/file";

import { AssetListItemProps } from "../types";

import styles from "./styles.module.scss";

export const ListViewItem: FC<AssetListItemProps> = ({
  asset,
  selected = false,
  starred = false,
  onSelect,
  onStar,
}) => {
  const file = asset.latestVersion?.file;
  const metadata = getFileMetadata(file);

  const customTags = useMemo(
    () => asset.tags?.filter((tag) => tag && !tag?.isGenerated),
    [asset.tags]
  );

  return (
    <ListItemWrapper
      className={clsx(styles.container, selected && styles.selected)}
    >
      <div className="h-full flex items-center">
        <ItemSelector
          selected={selected}
          starred={starred}
          onSelect={onSelect ? () => onSelect(asset.assetId) : undefined}
          onStar={onStar ? () => onStar(asset) : undefined}
          className={styles.selector}
          selectClassName={styles.select}
          starClassName={styles.star}
        >
          <Media
            type={metadata?.common?.type}
            url={file?.thumbnail?.downloadUrl?.url}
            fileId={file?.fileId}
            className={styles.image}
            sectionClassName="h-full w-full"
          />
        </ItemSelector>
        <p className={styles.name}>{file?.fileName}</p>
      </div>
      <div>{asset.project?.brief?.brandProfile?.name}</div>
      <div>
        {asset.createdAt
          ? format(new Date(Number(asset.createdAt)), "dd MMM yyyy")
          : ""}
      </div>
      <div>{formatBytes(metadata?.common?.size ?? 0)}</div>
      <div className="uppercase">{file?.fileName?.split(".").pop() || ""}</div>
      <div className={styles.tags}>
        {customTags?.map(
          (tag) =>
            tag && (
              <Label
                key={tag?.tagId}
                size={Label.sizes.Tiny}
                className={"h-fit rounded bg-mint text-teal cursor-pointer"}
              >
                {tag?.name}
              </Label>
            )
        )}
      </div>
    </ListItemWrapper>
  );
};
