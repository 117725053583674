import { Button, Dropdown, Loader } from "@CreativelySquared/uikit";
import { AssetByIdFragment } from "api/types";
import { MultiInput, Search } from "components";
import { memo, useMemo, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { useNotification } from "components";
import { useTranslation } from "react-i18next";
import { useCreateTagMutation, useTagsQuery } from "api/graphql";
import { Tag as TagItem } from "Assets/components/Tag";

import styles from "./styles.module.scss";

type Props = {
  asset: AssetByIdFragment;
};

export const AssetTags = memo<Props>(({ asset }) => {
  const { t } = useTranslation("assets");
  const [tag, setTag] = useState("");
  const { setNotification, notificationTypes } = useNotification();
  const tags = asset?.tags?.filter((tag) => !tag?.isGenerated) ?? [];
  const search = useRef<{ input: HTMLInputElement | null }>();

  const { data: { getTags } = {}, loading: searchLoading } = useTagsQuery({
    variables: {
      filters: {
        search: tag,
      },
      pagination: {
        limit: 6,
      },
    },
    skip: !tag,
  });
  const [createTag, { loading }] = useCreateTagMutation({
    onCompleted() {
      if (search.current?.input?.value) {
        search.current.input.value = "";
      }
      setTag("");
    },
    onError(error) {
      setNotification({
        message: error.message ?? t("common:common.error"),
        type: notificationTypes.Error,
      });
    },
  });
  const isAlreadyExist = useMemo(
    () => getTags?.nodes?.map((tag) => tag?.name).includes(tag),
    [getTags?.nodes, tag]
  );

  return (
    <Dropdown
      onClose={() => setTag("")}
      placement={Dropdown.placements.LeftStart}
      render={
        <MultiInput
          value={tags}
          className="mt-4 shadow-none px-0"
          suffix={
            !!tags.length && (
              <button className="ml-4 mb-4">
                <PlusIcon />
              </button>
            )
          }
          render={({ item }) =>
            asset && (
              <TagItem
                key={item.tagId}
                label={item.name}
                assetId={asset.assetId || ""}
                tagId={item.tagId}
                tags={asset.tags || []}
                className="!text-teal !bg-mint"
                deleteClassName={styles.deleteTag}
              />
            )
          }
          prefix={
            !tags.length && (
              <Button outlined borderless className="p-0">
                <PlusIcon className="mr-4" /> {t("info.tags.custom.add")}
              </Button>
            )
          }
        />
      }
    >
      <Search
        onSearch={setTag}
        autoFocus
        ref={search}
        className="m-6 w-auto"
        autoComplete="off"
        placeholder={t("info.tags.custom.placeholder")}
      />
      {tag && !isAlreadyExist && (
        <Button
          loading={loading}
          onClick={() =>
            asset?.assetId &&
            createTag({
              variables: {
                assetId: asset.assetId,
                name: search.current?.input?.value,
              },
            })
          }
          outlined
          borderless
          className="px-0 pb-0 mb-6 mx-6 overflow-hidden max-w-[250px]"
        >
          {!loading && <PlusIcon className="mr-4" />}{" "}
          <span className="text-ellipsis overflow-hidden">
            {t("info.tags.custom.create", { tag, count: +loading })}
          </span>
        </Button>
      )}
      {searchLoading && <Loader className="m-auto my-6" />}
      {!searchLoading && !!getTags?.nodes?.length && (
        <section className="max-w-[250px] overflow-auto">
          {getTags?.nodes?.map((tag) => (
            <Dropdown.Item
              onSelect={() =>
                asset?.assetId &&
                createTag({
                  variables: {
                    assetId: asset?.assetId,
                    name: tag?.name,
                  },
                })
              }
              className="text-ellipsis overflow-hidden cursor-pointer hover:font-medium"
              key={tag?.tagId}
            >
              {tag?.name}
            </Dropdown.Item>
          ))}
        </section>
      )}
    </Dropdown>
  );
});
