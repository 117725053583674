import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const ResetPasswordRoute = compose(
  ProtectedRoute({ access: Roles.unauthorized }),
  Route(
    {
      userId: Route.query.string,
      code: Route.query.string,
    },
    "/reset-password"
  )
);

export const ResetPassword = ResetPasswordRoute(
  lazy(() => import(/* webpackChunkName: "ResetPassword" */ "./ResetPassword"))
);
