import { debounce } from "lodash";

export const asyncDebounce = <T extends (...args: any) => Promise<unknown>>(
  func: T,
  delay: number
): T => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, delay);

  return ((...args: []) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    })) as T;
};
