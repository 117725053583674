import { Button } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { bookDemo } from "utils/links";

import styles from "./styles.module.scss";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Footer = memo<Props>(({ className, ...props }) => {
  const { t } = useTranslation("shared");

  return (
    <footer className={clsx(styles.footer, className)} {...props}>
      <div className={styles.main}>
        <span>{t("footer.label")}</span>
        <a href={bookDemo} target="_blank" rel="noopener noreferrer">
          <Button>{t("footer.start")}</Button>
        </a>
      </div>
      <div className={styles.margin} />
    </footer>
  );
});
