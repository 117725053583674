import { ProjectsViewType } from "Projects/components/ProjectsView";
import { ProjectSort } from "Projects/utils/types";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { RouteLink } from "react-router-hoc/lib/types";

type Filter = Record<string, string | number | string[] | number[] | undefined>;

export const useProjectsRouteSettings = <Q, P>({
  link,
  query,
  params,
}: {
  link: RouteLink<string, P, Q>;
  query: Q;
  params: P;
}) => {
  const { push } = useHistory();

  const getLink = useCallback(
    (data: Filter) =>
      link({
        ...params,
        ...query,
        ...data,
      }),
    [query, params]
  );

  const onSearch = useCallback(
    (value?: string, page?: number) =>
      push(getLink({ page: page ?? 1, search: value })),
    [push, getLink]
  );

  const onSort = useCallback(
    (sort: ProjectSort) =>
      push(
        getLink({
          ...params,
          ...query,
          page: 1,
          sort,
        })
      ),
    [push, getLink]
  );

  const onFilter = useCallback(
    (data: Record<string, string[]>) =>
      push(
        getLink({
          ...data,
          page: 1,
        })
      ),
    [push, getLink]
  );

  const onViewChange = useCallback(
    (view: ProjectsViewType) => push(getLink({ view })),
    [push, getLink]
  );

  return {
    onSearch,
    onSort,
    onFilter,
    onViewChange,
    getLink,
  };
};
