import { QueryOptions } from "@apollo/client";
import { PhoneValidQueryVariables } from "api/types";
import { PhoneValidDocument, PhoneValidQueryResult } from "api/graphql";
import { client } from "api/apollo.client";

import { asyncDebounce } from "./asyncDebounce";

export const isPhoneValid = asyncDebounce(
  (
    options: Omit<
      QueryOptions<PhoneValidQueryVariables, PhoneValidQueryResult>,
      "query"
    >
  ) =>
    client.query<PhoneValidQueryResult["data"], PhoneValidQueryVariables>({
      ...options,
      query: PhoneValidDocument,
    }),
  1000
);
