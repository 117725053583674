import { Project } from "api/types";
import { ProjectStatuses } from "Projects/utils/types";
import type { Object } from "ts-toolbelt";

const manageStatuses = [
  ProjectStatuses["in_progress"],
  ProjectStatuses.delivered,
  ProjectStatuses.finalized,
  ProjectStatuses.under_revision,
  ProjectStatuses["partial_delivery"],
];

const completeStatuses = [
  ProjectStatuses["in_progress"],
  ProjectStatuses["partial_delivery"],
];

export const galleryStatuses = [
  ProjectStatuses.delivered,
  ProjectStatuses["partial_delivery"],
  ProjectStatuses.under_revision,
  ProjectStatuses.finalized,
];

const acceptanceStatuses = [
  ProjectStatuses["pending_review"],
  ProjectStatuses.clarification_requested,
];

const declineStatuses = [
  ProjectStatuses["pending_review"],
  ProjectStatuses.queued,
  ProjectStatuses.in_progress,
  ProjectStatuses.clarification_requested,
];

const startStatuses = [ProjectStatuses.queued];

const editStatuses = [
  ProjectStatuses.draft,
  ProjectStatuses.clarification_requested,
];

const revertanceStatuses = [ProjectStatuses.queued];

export const accesses = {
  manage: (project?: Object.Partial<Project, "deep"> | null) =>
    manageStatuses.includes((project?.status ?? "") as ProjectStatuses),
  complete: (project?: Object.Partial<Project, "deep"> | null) =>
    completeStatuses.includes((project?.status ?? "") as ProjectStatuses),
  start: (project?: Object.Partial<Project, "deep"> | null) =>
    startStatuses.includes((project?.status ?? "") as ProjectStatuses),
  decline: (project?: Object.Partial<Project, "deep"> | null) =>
    declineStatuses.includes((project?.status ?? "") as ProjectStatuses),
  acceptance: (project?: Object.Partial<Project, "deep"> | null) => {
    return acceptanceStatuses.includes(
      (project?.status ?? "") as ProjectStatuses
    );
  },
  edit: (project?: Object.Partial<Project, "deep"> | null) =>
    editStatuses.includes((project?.status ?? "") as ProjectStatuses) &&
    !project?.deletedAt,
  revertance: (project?: Object.Partial<Project, "deep"> | null) => {
    return revertanceStatuses.includes(
      (project?.status ?? "") as ProjectStatuses
    );
  },
  gallery: (project?: Object.Partial<Project, "deep"> | null) => {
    return galleryStatuses.includes((project?.status ?? "") as ProjectStatuses);
  },
};
