type SectionProps =
  | {
      sectionId?: string;
      conceptId?: string | null;
    }
  | undefined
  | null;

export const isActiveSection = (obj1: SectionProps, obj2: SectionProps) =>
  obj1?.sectionId &&
  obj1?.sectionId === obj2?.sectionId &&
  obj1?.conceptId === obj2?.conceptId;
