import { PropsWithChildren, createContext, memo, useState } from "react";

import { BriefCommentMetadata, BriefForm } from "./utils/types";

type BriefContextType = {
  brief?: BriefForm | null;
  activeSection?: BriefCommentMetadata | null;
  setActiveSection?: (section?: BriefCommentMetadata | null) => void;
};

export const BriefContext = createContext<BriefContextType>({
  brief: null,
  setActiveSection: () => {},
});

export const BriefProvider = memo<
  PropsWithChildren<{ brief: BriefContextType["brief"] }>
>(({ brief, children }) => {
  const [activeSection, setActiveSection] = useState<
    BriefCommentMetadata | undefined | null
  >(null);

  return (
    <BriefContext.Provider value={{ brief, activeSection, setActiveSection }}>
      {children}
    </BriefContext.Provider>
  );
});
