export enum Features {
  user_notifications_settings = "user-notifications-settings",
  upvoty = "upvoty",
  brand_share_link = "brand-share-link",
  dam_page = "dam-page",
  dam_asset_library = "dam-asset-library",
  dam_filters = "dam-filters",
  dam_asset_details_info = "dam-asset-details-info",
  dam_asset_details_comments = "dam-asset-details-comments",
  dam_asset_details_tags = "dam-asset-details-tags",
  dam_asset_details_crop = "dam-asset-details-crop",
  whimsical_page = "whimsical_page",
}
