import { SavedItemsTabs } from "AITools/SavedItems";

export const tabToUrl = (tab?: SavedItemsTabs) => {
  // convert to lowercase and replace camelCase with dash-case. For example, IdeaTab -> idea-tab, UGCTab -> ugc-tab
  return (
    tab
      ?.replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1-$2")
      .toLowerCase() || ""
  );
};
