import { NetworkError } from "@apollo/client/errors";

type ErrorResult = {
  code: string;
  details: any;
  message: string;
};

export enum AuthErrorCodes {
  MalformedTokenError = "MalformedTokenError",
  TokenExpiredError = "TokenExpiredError",
  EmailAlreadyRegisteredError = "EmailAlreadyRegisteredError",
  AuthenticationError = "AuthenticationError",
  InputValidationError = "InputValidationError",
}

export const isGQLError = (
  error: any
): error is NetworkError & { result?: ErrorResult; statusCode: number } =>
  !!error?.result;
