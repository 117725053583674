import React, { useMemo } from "react";
import { matchPath, Redirect, RouteComponentProps } from "react-router-dom";
import * as H from "history";
import { links } from "App";
import { RouteLink } from "react-router-hoc/lib/types";
import memoize from "lodash/memoize";
import pick from "lodash/pickBy";
import { SharedGallery } from "Shared/SharedGallery";
import { Logout } from "Authorization/Logout";
import { Features } from "api/features";

import { Access, Roles } from "./roles";
import { useProtect } from "./hooks/protection";

type GetProps<T> = T extends React.ElementType<infer P> ? P : any;

type ProtectedParams = {
  access: Access;
  redirect?: H.LocationDescriptor;
  feature?: Features;
};

const skipRedirection = [SharedGallery, Logout];

// Add to the state current url with query params for father redirection after login
export const getPreviousURL = (location: H.Location<any>) => {
  const skip = skipRedirection.some(({ defaultProps }) => {
    return matchPath(location.pathname, {
      path: defaultProps?.path,
      exact: true,
      strict: true,
    });
  });
  if (skip) return;

  return `${location.pathname.concat(location.search)}`;
};

/**
 * The HOC that protec the route
 * @param params - Protected page params
 */
export function ProtectedRoute<T extends React.ComponentType>(
  params: ProtectedParams
): (WrappedComponent: T) => T;
/**
 * @param WrappedComponent - The component route that should be protected
 */
export function ProtectedRoute<T extends React.ComponentType>(
  WrappedComponent: T
): React.FC<GetProps<T> & Partial<ProtectedParams>>;
export function ProtectedRoute<T extends React.ComponentType>(
  ComponentOrParams: T | ProtectedParams
) {
  if ("access" in ComponentOrParams) {
    return (WrappedComponent: T) => {
      const RenderedComponent: any = WrappedComponent;
      const ProtectedPage: React.FC<
        GetProps<T> & RouteComponentProps<{ sharedCode?: string }>
      > = (props) => {
        const suspense =
          ComponentOrParams.access !== "authorized" &&
          ComponentOrParams.access !== "unauthorized";

        const sharedCode =
          ComponentOrParams.access === Roles.shared &&
          props.match.params.sharedCode;

        const isAccessible = useProtect({
          suspense,
          sharedCode,
          feature: ComponentOrParams?.feature,
        });

        // Add redirection URL based on protection params or default one that contains current URL for after login redirection
        const redirect = useMemo(() => {
          if (ComponentOrParams.access === Roles.unauthorized) {
            return ComponentOrParams.redirect ?? "/";
          }

          const loginURL = {
            pathname: links.Login(),
            state: Object.assign({}, props.location.state, {
              redirectURL:
                (props.location.state as any)?.redirectURL ||
                getPreviousURL(props.location),
            }),
          } as H.LocationDescriptor;

          if (!isAccessible(Roles.authorized)) {
            return loginURL;
          }
          return ComponentOrParams.redirect ?? links.NotFound();
        }, [props.location]);

        return isAccessible(ComponentOrParams.access) ? (
          <RenderedComponent {...props} />
        ) : (
          <Redirect to={redirect} />
        );
      };
      return ProtectedPage;
    };
  }
  const RenderedComponent: any = ComponentOrParams;
  const ProtectedPage: React.FC<
    GetProps<T> & Partial<ProtectedParams> & RouteComponentProps
  > = ({ access = Roles.authorized, redirect, feature, ...props }) => {
    const suspense = access !== "authorized" && access !== "unauthorized";
    const isAccessible = useProtect({ suspense, feature });
    // Add redirection URL based on protection params or default one that contains current URL for after login redirection
    const redirection = useMemo(() => {
      if (access === Roles.unauthorized) {
        return redirect ?? "/";
      }
      const loginURL = {
        pathname: links.Login(),
        state: Object.assign({}, props.location.state, {
          redirectURL:
            (props.location.state as any)?.redirectURL ||
            getPreviousURL(props.location),
        }),
      } as H.LocationDescriptor;
      if (!isAccessible(Roles.authorized)) {
        return loginURL;
      }
      return redirect ?? links.NotFound();
    }, [redirect, props.location]);

    return isAccessible(access) ? (
      <RenderedComponent {...props} />
    ) : (
      <Redirect to={redirection} />
    );
  };
  return ProtectedPage;
}

export function setLinkParams<T extends RouteLink<any, any, any>>(
  link: T,
  data: Partial<Parameters<T>["0"]>
): T {
  return ((params: any) => {
    const value = pick(params, Boolean);

    return link(Object.assign({}, data, value));
  }) as T;
}

function setRouteLinkPartialParams<
  R extends ((...args: any) => any) & {
    link: RouteLink<any, any, any>;
    defaultProps?: any;
  }
>(route: R, params: Partial<Parameters<R["link"]>["0"]>) {
  const adjustedRoute: any = Object.assign(
    (...args: any[]) => route(...args),
    route,
    {
      link: setLinkParams(route.link, params),
    }
  );
  return adjustedRoute;
}

export const setRouteLinkParams = memoize(
  setRouteLinkPartialParams,
  (route, params) => {
    return `${route?.defaultProps?.path}:${JSON.stringify(params)}`;
  }
);
