import { useServicesQuery } from "api/graphql";
import { useTranslation } from "react-i18next";
import { Services } from "utils/services";

export const useServices = (assetTypes: Array<Services | undefined>) => {
  const { t } = useTranslation("common");

  const { data: { getServices } = {}, loading: servicesLoading } =
    useServicesQuery({
      variables: {
        filter: {
          isLegacy: false,
        },
      },
    });

  // Merge services with the same names
  const serviceValues = getServices?.map((service) => ({
    id: service?.serviceId ?? "",
    name: t(`asset.label.${service?.serviceId}.title`, {
      count: 1,
    }),
  }));
  const activeServiceNames = assetTypes?.map(
    (type) => serviceValues?.find((service) => service.id === type)?.name
  );
  const activeServiceIds = serviceValues?.flatMap((service) =>
    activeServiceNames?.includes(service.name) ? [service.id] : []
  );

  return {
    activeServiceIds,
    servicesLoading,
    serviceValues,
  };
};
