import "./locales";
import { lazy } from "react";
import { Switch } from "react-router";
import { Route } from "react-router-hoc";

import { SettingsAccount } from "./Account";
import { SettingsProfile } from "./Profile";
import { SettingsNotifications } from "./Notifications";

export const SettingsRoute = Route("/settings");

export const Settings = SettingsRoute(
  lazy(() => import(/* webpackChunkName: "Settings" */ "./Settings"))
);

export const links = {
  Settings,
  SettingsAccount,
  SettingsProfile,
  SettingsNotifications,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Settings />
    </Switch>
  );
});
