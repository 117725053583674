import { useContext } from "react";
import { AuthContext } from "Authorization/authorization.context";
import {
  ProfileDocument,
  useGetSharingLinkTokenQuery,
  useProfileQuery,
} from "api/graphql";
import { User } from "utils/users";
import type { Object as ObjectType } from "ts-toolbelt";
import { client } from "api/apollo.client";
import { ProfileQuery } from "api/types";
import { Features } from "api/features";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useAuthData } from "Authorization/authorization.hooks";

import { Access, AccessFactory, hasAccess, Roles } from "../roles";

import { withSuspense } from "./suspense";

type useRolesArguments = {
  suspense?: boolean;
  user?: ObjectType.Partial<User, "deep"> | null;
  sharedCode?: string | boolean;
  feature?: Features;
};

const useProfileSuspenseQuery = withSuspense(useProfileQuery);
const useSharedLinkTokenSuspenseQuery = withSuspense(
  useGetSharingLinkTokenQuery
);

export const useProtect = (options?: useRolesArguments) => {
  const { accessToken } = useContext(AuthContext);
  const { setSessionToken } = useAuthData();

  const { data: { getAuthenticationTokensForSharingLink } = {} } =
    useSharedLinkTokenSuspenseQuery({
      skip: !options?.sharedCode,
      suspense: options?.suspense,
      errorPolicy: "all",
      variables: {
        linkCode: String(options?.sharedCode),
      },
      context: {
        headers: {
          authorization: "",
        },
      },
      onCompleted: ({ getAuthenticationTokensForSharingLink }) => {
        const token = getAuthenticationTokensForSharingLink?.accessToken;
        if (token) setSessionToken(token);
      },
    });

  const sharedAccessToken = getAuthenticationTokensForSharingLink?.accessToken;

  const { data } = useProfileSuspenseQuery({
    fetchPolicy: "cache-first",
    skip: !accessToken || !!options?.sharedCode,
    suspense: options?.suspense,
  });

  const cachedUser = client.readQuery({
    query: ProfileDocument,
  }) as ProfileQuery;

  const user = options?.user ?? cachedUser?.me ?? data?.me;

  const draftStatus = {
    profileModificationPending: !!(
      user?.__typename === "OrganizationUser" &&
      user?.organization?.draftStatus?.hasCnameModificationPending
    ),
    profileGoalsModificationPending: !!(
      user?.__typename === "OrganizationUser" &&
      !user?.organization?.draftStatus?.hasCnameModificationPending &&
      (user?.organization?.draftStatus?.hasRequiredFieldsPending ||
        user?.organization?.draftStatus?.hasOwnerRequiredFieldsPending)
    ),
  };

  const enabled = useFeatureIsOn(options?.feature as string);

  return (access?: Access) => {
    if (options?.feature && !enabled) return false;

    const checkAccess = (access: Roles | AccessFactory) =>
      (typeof access === "function" ? access : hasAccess[access])({
        role: user?.role as Roles,
        accessToken,
        sharedAccessToken,
        id: user?.userId,
        draftStatus,
      });

    if (Array.isArray(access)) {
      return access?.some((role) => checkAccess(role));
    }

    return access ? checkAccess(access) : options?.feature && enabled;
  };
};
