import { ProductionBrief } from "api/types";
import { lazy } from "react";
import { Route } from "react-router-hoc";

export const BriefBuilderRoute = Route(
  {
    scriptId: Route.query.string,
    organizationId: Route.query.string,
  },
  "/ai/tools/brief-builder"
);

export const BriefBuilder = BriefBuilderRoute(
  lazy(() => import(/* webpackChunkName: "BriefBuilder" */ "./BriefBuilder"))
);

export type ProductionBriefData = PartialBy<
  Omit<ProductionBrief, "__typename">,
  "id"
>;
