import clsx from "clsx";
import React, { ReactNode, FC, DetailedHTMLProps, HTMLAttributes } from "react";

type Props = {
  children: ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export const ModalDescription: FC<Props> = ({
  children,
  className,
  ...props
}) => (
  <p className={clsx("mt-4 mb-8 text-blackberry", className)} {...props}>
    {children}
  </p>
);
