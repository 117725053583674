import clsx from "clsx";
import React, { ComponentType, FC, SVGProps } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import styles from "./styles.module.scss";

enum Variant {
  Default = "default",
  SubMenu = "subMenu",
}

interface Props {
  to?: string;
  onClick?: () => void;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  variant?: Variant;
}

export const SidebarNavItem: FC<Props & Partial<NavLinkProps>> & {
  Variant: typeof Variant;
} = ({ to, Icon, label, onClick, variant = Variant.Default, ...props }) => {
  const Item = (
    <>
      <div className={styles.icon}>{Icon && <Icon />}</div>
      {label}
    </>
  );

  return to ? (
    <NavLink
      to={to}
      className={clsx(styles.container, variant && styles[variant])}
      activeClassName={styles.active}
      {...props}
    >
      {Item}
    </NavLink>
  ) : (
    <div className={clsx(styles.container)} onClick={onClick}>
      {Item}
    </div>
  );
};

SidebarNavItem.displayName = "SidebarNavItem";
SidebarNavItem.Variant = Variant;
