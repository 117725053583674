import { memo } from "react";
import { Card, Loader } from "@CreativelySquared/uikit";
import clsx from "clsx";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { GetProps } from "react-router-hoc/lib/types";

import styles from "./styles.module.scss";

enum Sizes {
  Regular = "regular",
  Small = "small",
}

type Props = { title?: string; size?: Sizes; loading?: boolean } & GetProps<
  typeof Card
>;

export const CreateCard = Object.assign(
  memo<Props>(
    ({
      title,
      size = Sizes.Regular,
      className,
      loading,
      onClick,
      ...props
    }) => {
      return (
        <Card
          className={clsx(
            styles.create,
            styles[size],
            loading && "pointer-events-none",
            className
          )}
          {...props}
          onClick={!loading ? onClick : undefined}
        >
          <Card.Title className={styles.title}>
            {loading ? <Loader radius={30} /> : <PlusIcon />}
          </Card.Title>
          <Card.Description className={styles.description}>
            {title}
          </Card.Description>
        </Card>
      );
    }
  ),
  {
    sizes: Sizes,
  }
);
