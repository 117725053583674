import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const DashboardRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      page: Route.query.number,
      manager: Route.query.string,
    },
    "/am-dashboard"
  )
);

export const Dashboard = DashboardRoute(
  lazy(() => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"))
);
