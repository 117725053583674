import { useState, useEffect, useCallback } from "react";

export const useMousePosition = () => {
  const [position, setPosition] = useState({
    clientX: 0,
    clientY: 0,
  });

  const updatePosition = useCallback((event: MouseEventInit) => {
    const { clientX = 0, clientY = 0 } = event;

    setPosition({
      clientX,
      clientY,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", updatePosition, false);
    document.addEventListener("mouseenter", updatePosition, false);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("mouseenter", updatePosition);
    };
  }, [updatePosition]);

  return position;
};
