import { BriefCategory } from "api/types";
import { useBriefAccess } from "Brief/utils/access";
import { memo, PropsWithChildren } from "react";

type Props = {
  categories: BriefCategory | BriefCategory[];
};

export const BriefAccess = memo<PropsWithChildren<Props>>(
  ({ categories, children }) => (
    <>{useBriefAccess(categories) ? children : null}</>
  )
);
