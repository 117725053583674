/* eslint-disable */
import type * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const MindMapNodeFragmentDoc = gql`
    fragment MindMapNode on ConceptMindMap {
  id
  file {
    fileId
    view {
      fileId
      downloadUrl {
        url
      }
    }
  }
  fileId
  list {
    frameworks {
      framework_title
      items {
        message
        title
      }
    }
    title
  }
  whimsicalUrl
  productName
}
    `;
export const ProductionBriefNodeFragmentDoc = gql`
    fragment ProductionBriefNode on ProductionBrief {
  description
  id
  coreVisualsNotes
  contentDescription
  originalAudioNotes
  productDescription
  productName
  title
  visualGuide {
    description
    message
    title
  }
}
    `;
export const UgcIdeaNodeFragmentDoc = gql`
    fragment UGCIdeaNode on UgcIdea {
  approach
  id
  productDescription
  keyMessage
  productName
  title
  type
}
    `;
export const UgcStoryboardNodeFragmentDoc = gql`
    fragment UGCStoryboardNode on UgcStoryboard {
  id
  productName
  title
  scenes {
    title
    visualPrompt
    scriptPrompt
  }
}
    `;
export const AdStoryboardNodeFragmentDoc = gql`
    fragment AdStoryboardNode on AdStoryboardData {
  title
  type
  frames {
    message
    title
  }
}
    `;
export const UgcIdeaDetailsFragmentDoc = gql`
    fragment UGCIdeaDetails on GeneratedUgcIdea {
  approach
  keyMessage
  title
  type
}
    `;
export const EventUserDetailsFragmentDoc = gql`
    fragment EventUserDetails on User {
  ... on OrganizationUser {
    userId
    name
    surname
    organization {
      organizationId
      name
    }
  }
  ... on CreatorUser {
    userId
    name
    surname
  }
  ... on InternalUser {
    userId
    name
    surname
  }
}
    `;
export const OrganizationNameFragmentDoc = gql`
    fragment OrganizationName on Organization {
  cname
  name
  organizationId
}
    `;
export const EventProjectDetailsFragmentDoc = gql`
    fragment EventProjectDetails on Project {
  title
  projectId
  organizationId
  status
  deletedAt
}
    `;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on Organization {
  ...OrganizationName
  createdAt
  credits
  industry
}
    ${OrganizationNameFragmentDoc}`;
export const FileThumbnailFragmentDoc = gql`
    fragment FileThumbnail on File {
  fileId
  thumbnail(name: "thumb_image_large") {
    formatId
    fileId
    downloadUrl {
      url
    }
  }
}
    `;
export const OrganizationUserDetailsFragmentDoc = gql`
    fragment OrganizationUserDetails on OrganizationUser {
  createdAt
  email
  lastLoginAt
  isEmailConfirmed
  lastLoginAt
  name
  organizationId
  organization {
    ...OrganizationDetails
  }
  role
  roleNotes
  surname
  userId
  avatar {
    fileId
    ...FileThumbnail
  }
}
    ${OrganizationDetailsFragmentDoc}
${FileThumbnailFragmentDoc}`;
export const CreatorUserDetailsFragmentDoc = gql`
    fragment CreatorUserDetails on CreatorUser {
  createdAt
  email
  isEmailConfirmed
  lastLoginAt
  name
  role
  roleNotes
  surname
  userId
  avatar {
    fileId
    ...FileThumbnail
  }
}
    ${FileThumbnailFragmentDoc}`;
export const InternalUserDetailsFragmentDoc = gql`
    fragment InternalUserDetails on InternalUser {
  createdAt
  email
  isEmailConfirmed
  lastLoginAt
  name
  role
  roleNotes
  surname
  userId
  avatar {
    fileId
    ...FileThumbnail
  }
}
    ${FileThumbnailFragmentDoc}`;
export const UserPreviewFragmentDoc = gql`
    fragment UserPreview on User {
  ... on OrganizationUser {
    ...OrganizationUserDetails
  }
  ... on CreatorUser {
    ...CreatorUserDetails
  }
  ... on InternalUser {
    ...InternalUserDetails
  }
}
    ${OrganizationUserDetailsFragmentDoc}
${CreatorUserDetailsFragmentDoc}
${InternalUserDetailsFragmentDoc}`;
export const EventAssetDetailsFragmentDoc = gql`
    fragment EventAssetDetails on Asset {
  assetId
  latestVersion {
    assetVersionId
    file {
      fileId
      fileName
    }
  }
}
    `;
export const EventsFragmentDoc = gql`
    fragment Events on Event {
  eventId
  name
  happenedAt
  ... on GenericProjectUserEvent {
    doneBy {
      ...EventUserDetails
    }
    doneTo {
      ...EventUserDetails
    }
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
  }
  ... on GenericUserEvent {
    doneBy {
      ...EventUserDetails
    }
  }
  ... on GenericOrganizationEvent {
    organization {
      ...OrganizationName
    }
    doneBy {
      ...EventUserDetails
    }
  }
  ... on GenericOrganizationUserEvent {
    organization {
      ...OrganizationName
    }
    doneBy {
      ...EventUserDetails
    }
    doneTo {
      ...EventUserDetails
    }
  }
  ... on GenericProjectEvent {
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...EventUserDetails
    }
  }
  ... on GenericProjectFeedbackEvent {
    details {
      current {
        isFeedbackCallRequested
        note
        vote
      }
      previous {
        vote
      }
    }
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...UserPreview
    }
  }
  ... on GenericAssetEvent {
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...EventUserDetails
    }
    asset {
      ...EventAssetDetails
    }
  }
  ... on GenericAssetCommentEvent {
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...EventUserDetails
    }
    comment {
      assetCommentId
      asset {
        ...EventAssetDetails
      }
    }
  }
  ... on GenericCommentEvent {
    doneBy {
      ...EventUserDetails
    }
  }
  ... on GenericBrandProfileEvent {
    doneBy {
      ...EventUserDetails
    }
    brandProfile {
      brandProfileId
      name
    }
  }
  ... on ProjectGalleryDownloadedEvent {
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...EventUserDetails
    }
  }
  ... on GenericBriefCommentEvent {
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...EventUserDetails
    }
  }
  ... on AssetVotedEvent {
    current
    previous
    organization {
      ...OrganizationName
    }
    project {
      ...EventProjectDetails
    }
    doneBy {
      ...EventUserDetails
    }
    asset {
      ...EventAssetDetails
    }
  }
  ... on GenericSubscriptionPlanEvent {
    subscriptionPlan {
      subscriptionPlanId
      name
      type
      status
      expirationDate
    }
    organization {
      organizationId
      name
    }
  }
  ... on GenericBriefEvent {
    project {
      ...EventProjectDetails
    }
  }
}
    ${EventUserDetailsFragmentDoc}
${OrganizationNameFragmentDoc}
${EventProjectDetailsFragmentDoc}
${UserPreviewFragmentDoc}
${EventAssetDetailsFragmentDoc}`;
export const AssetTagsFragmentDoc = gql`
    fragment AssetTags on Asset {
  tags {
    tagId
    name
    isGenerated
  }
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  ... on OrganizationUser {
    ...OrganizationUserDetails
  }
  ... on CreatorUser {
    ...CreatorUserDetails
  }
  ... on InternalUser {
    ...InternalUserDetails
  }
}
    ${OrganizationUserDetailsFragmentDoc}
${CreatorUserDetailsFragmentDoc}
${InternalUserDetailsFragmentDoc}`;
export const CommonMetadataFragmentDoc = gql`
    fragment CommonMetadata on Format {
  storedData {
    metadata {
      common {
        type
        size
      }
      canvas {
        width
        height
      }
    }
  }
}
    `;
export const FileMetadataFragmentDoc = gql`
    fragment FileMetadata on File {
  originalMetadata {
    common {
      type
    }
  }
  imageMetadata: format(name: "dwn_image_large") {
    ...CommonMetadata
  }
  videoMetadata: format(name: "dwn_video_large") {
    ...CommonMetadata
  }
  gifMetadata: format(name: "dwn_gif_large") {
    ...CommonMetadata
  }
}
    ${CommonMetadataFragmentDoc}`;
export const AssetDetailsFragmentDoc = gql`
    fragment AssetDetails on Asset {
  assetId
  status
  description
  ...AssetTags
  project {
    projectId
    title
    status
    submittedBy {
      ... on User {
        userId
        fullName
      }
    }
    submittedAt
    brief {
      briefId
      brandProfile {
        name
        brandProfileId
      }
    }
  }
  organization {
    organizationId
    name
  }
  latestVersion {
    assetId
    assetVersionId
  }
  versions {
    assetVersionId
    createdAt
    file {
      fileId
      fileName
      tags {
        tagId
        name
      }
      view(name: "hq_image_large") {
        fileId
        formatId
        downloadUrl {
          url
        }
      }
      createdBy {
        ...UserDetails
      }
      ...FileMetadata
    }
  }
  myRating {
    vote
  }
  concept {
    conceptId
    name
  }
  mainOutcome {
    outcomeId
    serviceId
    ratio
  }
}
    ${AssetTagsFragmentDoc}
${UserDetailsFragmentDoc}
${FileMetadataFragmentDoc}`;
export const AttachmentsFragmentDoc = gql`
    fragment Attachments on File {
  ...FileThumbnail
  status
  fileName
  originalMetadata {
    common {
      type
    }
  }
}
    ${FileThumbnailFragmentDoc}`;
export const AssetCommentDetailsFragmentDoc = gql`
    fragment AssetCommentDetails on AssetComment {
  assetCommentId
  assetVersionId
  content
  createdAt
  deletedAt
  extra
  replies {
    commentId
    createdAt
    deletedAt
    content
    user {
      ...UserPreview
      role
    }
    organization {
      ...OrganizationDetails
    }
    attachments {
      ...Attachments
    }
  }
  organization {
    ...OrganizationDetails
  }
  user {
    ...UserPreview
    role
  }
  attachments {
    ...Attachments
  }
}
    ${UserPreviewFragmentDoc}
${OrganizationDetailsFragmentDoc}
${AttachmentsFragmentDoc}`;
export const AssetByIdFragmentDoc = gql`
    fragment AssetById on Asset {
  ...AssetDetails
  comments {
    assetCommentId
    ...AssetCommentDetails @include(if: $includeComments)
  }
}
    ${AssetDetailsFragmentDoc}
${AssetCommentDetailsFragmentDoc}`;
export const BrandFileDetailsFragmentDoc = gql`
    fragment BrandFileDetails on File {
  fileId
  status
  fileName
  originalMetadata {
    common {
      size
    }
  }
  download(name: "original") {
    downloadUrl {
      url
    }
  }
}
    `;
export const BrandDetailsFragmentDoc = gql`
    fragment BrandDetails on BrandProfile {
  brandProfileId
  name
  description
  organizationId
  guidelines {
    ...BrandFileDetails
  }
  assets {
    ...BrandFileDetails
  }
  colors
  guidelinesRequirements
  wantedCharacteristics
  unwantedCharacteristics
  avatar {
    fileId
    type
    originalMetadata {
      common {
        type
        size
      }
    }
    ...FileThumbnail
  }
  references {
    caption
    file {
      fileId
      status
      originalMetadata {
        common {
          type
          size
        }
      }
      ...FileThumbnail
    }
  }
}
    ${BrandFileDetailsFragmentDoc}
${FileThumbnailFragmentDoc}`;
export const ProjectUpdateFragmentDoc = gql`
    fragment ProjectUpdate on Project {
  projectId
  title
}
    `;
export const BriefDetailsFragmentDoc = gql`
    fragment BriefDetails on Brief {
  id
  briefId
  organizationId
  category
  audienceGenders
  audienceAges
  audienceCountries
  audienceNotes
  purposeContents
  purposeContexts
  purposeRequirement
  productAcquisitionMethod
  productAvailableCountries
  shouldProductBeReturned
  productAvailableLocations
  purposeObjective
  projectId
  project {
    id
    title
    projectId
    status
    updatedAt
    submittedAt
    submittedBy {
      userId
      name
      surname
    }
  }
  shareable
  shareableDate
  shareableReason
  hasRushOutcome
  wantedCharacteristics
  unwantedCharacteristics
  updatedAt
  brandProfile {
    brandProfileId
    name
    avatar {
      fileId
      ...FileThumbnail
    }
  }
  briefUpdatedAt: updatedAt
}
    ${FileThumbnailFragmentDoc}`;
export const SharedLinksAccessFragmentDoc = gql`
    fragment SharedLinksAccess on ProjectSharingLink {
  linkCode
  projectId
  projectSharingLinkId
  canDownloadAssets
  canViewAssetComments
  canViewAssets
  canViewBrief
  isInternal
}
    `;
export const ProductDetailsFragmentDoc = gql`
    fragment ProductDetails on ConceptProduct {
  id
  description
  name
  conceptProductId
}
    `;
export const VisualGuideFragmentDoc = gql`
    fragment VisualGuide on ConceptVisualGuide {
  id
  message
  title
  description
  file {
    fileId
    status
    originalMetadata {
      common {
        type
        size
      }
    }
    ...FileThumbnail
  }
  fileId
}
    ${FileThumbnailFragmentDoc}`;
export const ConceptDeliverableFragmentDoc = gql`
    fragment ConceptDeliverable on Outcome {
  outcomeId
  id
  amount
  platform
  placement
  ratio
  orientation
  detailsNote
  service {
    name
    price
    serviceId
  }
  serviceId
}
    `;
export const ConceptMoodboardFragmentDoc = gql`
    fragment ConceptMoodboard on ConceptMoodboardItem {
  id
  fileId
  caption
  file {
    fileId
    fileName
    status
    originalMetadata {
      common {
        type
        size
      }
    }
    ...FileThumbnail
  }
}
    ${FileThumbnailFragmentDoc}`;
export const ConceptDetailsFragmentDoc = gql`
    fragment ConceptDetails on Concept {
  id
  conceptId
  bodiesAges
  bodiesGender
  bodiesNote
  bodiesPeopleAmount
  bodiesRaces
  bodiesRequests
  hasProducts
  hasProps
  hasTalents
  talents
  limbsNote
  limbsRaces
  limbsGender
  limbsPeopleAmount
  locationsNote
  locations
  name
  featuredProducts {
    ...ProductDetails
  }
  visualGuides {
    ...VisualGuide
  }
  outcomeImageEditing
  outcomeRetouching
  outcomeVideoEditing
  productAdquisitionMethod
  productAdquisitionOptions
  productAvailableCountries
  productSamplesMustBeReturned
  outcomeImageAmount
  outcomeLongVideoAmount
  outcomeIntensiveStopMotionAmount
  outcomePremiumStopMotionAmount
  outcomePremiumVideoAmount
  outcomePremiumImageAmount
  outcomeStopMotionAmount
  outcomeVideoClipAmount
  outcomeVideoStoryAmount
  outcomeUgcVideoAmount
  outcomes {
    ...ConceptDeliverable
  }
  moodboard {
    ...ConceptMoodboard
  }
  products
  projectId
  props
  propTypes
  updatedAt
  propsNote
  petsAmount
  petsSpecies
  petsSizes
  petsAges
  petsNote
  description
  contentDescription
  presentationNotes
  participantsNotes
  coreVisualsNotes
  suppliedVisualsNotes
  originalAudioNotes
  optimiseAudioNotes
  actionNotes
  areOutcomesDigitalAds
  orientations
  type
}
    ${ProductDetailsFragmentDoc}
${VisualGuideFragmentDoc}
${ConceptDeliverableFragmentDoc}
${ConceptMoodboardFragmentDoc}`;
export const BriefDataFragmentDoc = gql`
    fragment BriefData on Brief {
  ...BriefDetails
  project {
    id
    title
    projectId
    status
    updatedAt
    sharingLinks @include(if: $includeSharedLinks) {
      ...SharedLinksAccess
    }
    submittedBy {
      userId
      name
      surname
    }
    submittedAt
    deletedAt
    createdBy {
      userId
      role
    }
  }
  concepts {
    ...ConceptDetails
  }
}
    ${BriefDetailsFragmentDoc}
${SharedLinksAccessFragmentDoc}
${ConceptDetailsFragmentDoc}`;
export const ConceptUpdateFragmentDoc = gql`
    fragment ConceptUpdate on Concept {
  id
  conceptId
  name
  type
  description
  contentDescription
  locationsNote
  presentationNotes
  participantsNotes
  actionNotes
  coreVisualsNotes
  suppliedVisualsNotes
  originalAudioNotes
  optimiseAudioNotes
}
    `;
export const ActiveProjectUsageDetailsFragmentDoc = gql`
    fragment ActiveProjectUsageDetails on Project {
  projectId
  title
  status
  estimatedCost {
    total
  }
}
    `;
export const OrganizationNoteDetailsFragmentDoc = gql`
    fragment OrganizationNoteDetails on OrganizationInternalNote {
  content
  createdAt
  deletedAt
  organizationId
  organizationInternalNoteId
  user {
    ...UserDetails
  }
  userId
  updatedAt
}
    ${UserDetailsFragmentDoc}`;
export const OrganizationManagerFragmentDoc = gql`
    fragment OrganizationManager on User {
  userId
  name
  surname
  role
}
    `;
export const OrganizationSubscriptionPlanFragmentDoc = gql`
    fragment OrganizationSubscriptionPlan on SubscriptionPlan {
  subscriptionPlanId
  name
  creditsLimit
  expirationDate
  type
  usage {
    assets
    credits
    projects
  }
  organization {
    credits
    organizationId
  }
  status
}
    `;
export const AssetThumbnailFragmentDoc = gql`
    fragment AssetThumbnail on AssetVersion {
  assetVersionId
  file {
    fileId
    fileName
    status
    ...FileMetadata
    ...FileThumbnail
  }
}
    ${FileMetadataFragmentDoc}
${FileThumbnailFragmentDoc}`;
export const AssetItemFragmentDoc = gql`
    fragment AssetItem on Asset {
  assetId
  status
  latestVersion {
    ...AssetThumbnail
  }
  versions {
    assetVersionId
  }
  project @include(if: $includeDetails) {
    projectId
    brief {
      briefId
      brandProfile {
        brandProfileId
        name
      }
    }
  }
  createdAt @include(if: $includeDetails)
  tags @include(if: $includeDetails) {
    tagId
    name
    isGenerated
  }
}
    ${AssetThumbnailFragmentDoc}`;
export const CollectionItemNodeFragmentDoc = gql`
    fragment CollectionItemNode on Collection {
  organizationId
  name
  createdAt
  collectionId
  assetsCount
  isPrivate
  coverAsset {
    assetId
    latestVersion {
      file {
        ...FileThumbnail
      }
      assetVersionId
    }
  }
}
    ${FileThumbnailFragmentDoc}`;
export const ProjectNoteDetailsFragmentDoc = gql`
    fragment ProjectNoteDetails on ProjectInternalNote {
  content
  createdAt
  deletedAt
  organizationId
  organization {
    ...OrganizationDetails
  }
  projectId
  projectInternalNoteId
  user {
    ...UserDetails
  }
  userId
  updatedAt
}
    ${OrganizationDetailsFragmentDoc}
${UserDetailsFragmentDoc}`;
export const ProjectSharingLinkAccessFragmentDoc = gql`
    fragment ProjectSharingLinkAccess on SharingLink {
  linkCode
  projectId
  sharingLinkId
  canDownloadAssets
  canViewAssetComments
  canViewAssets
  canViewBrief
  isInternal
}
    `;
export const CoverImageFragmentDoc = gql`
    fragment CoverImage on ProjectCoverImage {
  projectCoverImageId
  fileId
  file {
    fileId
    status
    thumbnail(name: "thumb_image_xlarge") {
      fileId
      downloadUrl {
        url
      }
    }
  }
}
    `;
export const ProjectDetailsFragmentDoc = gql`
    fragment ProjectDetails on Project {
  approvedAt
  createdAt
  canceledAt
  deletedAt
  submittedAt
  managedByUserId
  projectId
  status
  title
}
    `;
export const ProjectNodeFragmentDoc = gql`
    fragment ProjectNode on Project {
  ...ProjectDetails
  organization {
    managedByUserId
    ...OrganizationName
  }
  coverImage {
    projectCoverImageId
    fileId
    file {
      fileId
      ...FileThumbnail
    }
  }
  sharingLinks {
    ...SharedLinksAccess
  }
  assetsCount
  deliveredAssetsCount
  outcomes {
    outcomeId
    serviceId
    assetsCount
  }
  brief {
    briefId
    category
  }
  queueOrder
  createdBy {
    userId
    role
  }
}
    ${ProjectDetailsFragmentDoc}
${OrganizationNameFragmentDoc}
${FileThumbnailFragmentDoc}
${SharedLinksAccessFragmentDoc}`;
export const CreditsSummaryFragmentDoc = gql`
    fragment CreditsSummary on EstimatedCreditsCost {
  briefCost {
    outcomesCosts {
      price
      outcome {
        assetsCount
        target
        service {
          name
          scopes
        }
        serviceId
        customPrice
        conceptId
        amount
        outcomeId
      }
    }
    total
  }
  conceptsCosts {
    conceptId
    concept {
      conceptId
      name
    }
    total
    outcomesCosts {
      price
      outcome {
        assetsCount
        conceptId
        target
        service {
          name
          scopes
        }
        serviceId
        customPrice
        conceptId
        amount
        outcomeId
      }
    }
  }
  projectLevelCosts {
    price
    outcome {
      outcomeId
      serviceId
      service {
        name
        scopes
      }
    }
  }
  total
}
    `;
export const AssetSharedFragmentDoc = gql`
    fragment AssetShared on Asset {
  assetId
  conceptId
  latestVersion {
    assetVersionId
    file {
      fileId
      fileName
      originalMetadata {
        common {
          type
        }
      }
      thumbnail(name: "thumb_image_mlarge") {
        fileId
        downloadUrl {
          url
        }
      }
    }
  }
}
    `;
export const GenerateProductionBriefDocument = gql`
    query GenerateProductionBrief($script: AdStoryboardInput, $ugcScript: UgcStoryboardInput) {
  generateProductionBrief(script: $script, ugcScript: $ugcScript) {
    title
    contentDescription
    coreVisualsNotes
    description
    originalAudioNotes
    productDescription
    productName
    visualGuide {
      description
      message
      title
    }
  }
}
    `;

/**
 * __useGenerateProductionBriefQuery__
 *
 * To run a query within a React component, call `useGenerateProductionBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateProductionBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateProductionBriefQuery({
 *   variables: {
 *      script: // value for 'script'
 *      ugcScript: // value for 'ugcScript'
 *   },
 * });
 */
export function useGenerateProductionBriefQuery(baseOptions?: Apollo.QueryHookOptions<Types.GenerateProductionBriefQuery, Types.GenerateProductionBriefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateProductionBriefQuery, Types.GenerateProductionBriefQueryVariables>(GenerateProductionBriefDocument, options);
      }
export function useGenerateProductionBriefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateProductionBriefQuery, Types.GenerateProductionBriefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateProductionBriefQuery, Types.GenerateProductionBriefQueryVariables>(GenerateProductionBriefDocument, options);
        }
export type GenerateProductionBriefQueryHookResult = ReturnType<typeof useGenerateProductionBriefQuery>;
export type GenerateProductionBriefLazyQueryHookResult = ReturnType<typeof useGenerateProductionBriefLazyQuery>;
export type GenerateProductionBriefQueryResult = Apollo.QueryResult<Types.GenerateProductionBriefQuery, Types.GenerateProductionBriefQueryVariables>;
export const CreateProductionBriefDocument = gql`
    mutation CreateProductionBrief($organizationId: ID, $data: ProductionBriefInput!) {
  createProductionBrief(organizationId: $organizationId, data: $data) {
    id
  }
}
    `;
export type CreateProductionBriefMutationFn = Apollo.MutationFunction<Types.CreateProductionBriefMutation, Types.CreateProductionBriefMutationVariables>;

/**
 * __useCreateProductionBriefMutation__
 *
 * To run a mutation, you first call `useCreateProductionBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductionBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductionBriefMutation, { data, loading, error }] = useCreateProductionBriefMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductionBriefMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProductionBriefMutation, Types.CreateProductionBriefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProductionBriefMutation, Types.CreateProductionBriefMutationVariables>(CreateProductionBriefDocument, options);
      }
export type CreateProductionBriefMutationHookResult = ReturnType<typeof useCreateProductionBriefMutation>;
export type CreateProductionBriefMutationResult = Apollo.MutationResult<Types.CreateProductionBriefMutation>;
export type CreateProductionBriefMutationOptions = Apollo.BaseMutationOptions<Types.CreateProductionBriefMutation, Types.CreateProductionBriefMutationVariables>;
export const DestroyProductionBriefDocument = gql`
    mutation DestroyProductionBrief($productionBriefId: ID!) {
  destroyProductionBrief(productionBriefId: $productionBriefId) {
    id
  }
}
    `;
export type DestroyProductionBriefMutationFn = Apollo.MutationFunction<Types.DestroyProductionBriefMutation, Types.DestroyProductionBriefMutationVariables>;

/**
 * __useDestroyProductionBriefMutation__
 *
 * To run a mutation, you first call `useDestroyProductionBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyProductionBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyProductionBriefMutation, { data, loading, error }] = useDestroyProductionBriefMutation({
 *   variables: {
 *      productionBriefId: // value for 'productionBriefId'
 *   },
 * });
 */
export function useDestroyProductionBriefMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyProductionBriefMutation, Types.DestroyProductionBriefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyProductionBriefMutation, Types.DestroyProductionBriefMutationVariables>(DestroyProductionBriefDocument, options);
      }
export type DestroyProductionBriefMutationHookResult = ReturnType<typeof useDestroyProductionBriefMutation>;
export type DestroyProductionBriefMutationResult = Apollo.MutationResult<Types.DestroyProductionBriefMutation>;
export type DestroyProductionBriefMutationOptions = Apollo.BaseMutationOptions<Types.DestroyProductionBriefMutation, Types.DestroyProductionBriefMutationVariables>;
export const EditProductionBriefDocument = gql`
    mutation EditProductionBrief($productionBriefId: ID!, $data: ProductionBriefInput!) {
  editProductionBrief(productionBriefId: $productionBriefId, data: $data) {
    id
    title
    contentDescription
    coreVisualsNotes
    description
    originalAudioNotes
    productDescription
    productName
    visualGuide {
      description
      message
      title
    }
  }
}
    `;
export type EditProductionBriefMutationFn = Apollo.MutationFunction<Types.EditProductionBriefMutation, Types.EditProductionBriefMutationVariables>;

/**
 * __useEditProductionBriefMutation__
 *
 * To run a mutation, you first call `useEditProductionBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductionBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductionBriefMutation, { data, loading, error }] = useEditProductionBriefMutation({
 *   variables: {
 *      productionBriefId: // value for 'productionBriefId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditProductionBriefMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProductionBriefMutation, Types.EditProductionBriefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProductionBriefMutation, Types.EditProductionBriefMutationVariables>(EditProductionBriefDocument, options);
      }
export type EditProductionBriefMutationHookResult = ReturnType<typeof useEditProductionBriefMutation>;
export type EditProductionBriefMutationResult = Apollo.MutationResult<Types.EditProductionBriefMutation>;
export type EditProductionBriefMutationOptions = Apollo.BaseMutationOptions<Types.EditProductionBriefMutation, Types.EditProductionBriefMutationVariables>;
export const GetAiBriefItemsDocument = gql`
    query GetAiBriefItems($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetAiBriefItemsFilter) {
  scripts: getAiBriefItems(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    pageInfo {
      totalItems
    }
    nodes {
      id
      productName
      ... on AdStoryboard {
        title
        frames {
          title
          message
        }
      }
      ... on UgcStoryboard {
        title
        scenes {
          title
          visualPrompt
          scriptPrompt
        }
      }
    }
  }
}
    `;

/**
 * __useGetAiBriefItemsQuery__
 *
 * To run a query within a React component, call `useGetAiBriefItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiBriefItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiBriefItemsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAiBriefItemsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAiBriefItemsQuery, Types.GetAiBriefItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAiBriefItemsQuery, Types.GetAiBriefItemsQueryVariables>(GetAiBriefItemsDocument, options);
      }
export function useGetAiBriefItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAiBriefItemsQuery, Types.GetAiBriefItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAiBriefItemsQuery, Types.GetAiBriefItemsQueryVariables>(GetAiBriefItemsDocument, options);
        }
export type GetAiBriefItemsQueryHookResult = ReturnType<typeof useGetAiBriefItemsQuery>;
export type GetAiBriefItemsLazyQueryHookResult = ReturnType<typeof useGetAiBriefItemsLazyQuery>;
export type GetAiBriefItemsQueryResult = Apollo.QueryResult<Types.GetAiBriefItemsQuery, Types.GetAiBriefItemsQueryVariables>;
export const GenerateAdConceptDocument = gql`
    query GenerateAdConcept($framework: AiConceptFramework!, $product: String!, $productName: String) {
  generateAdConcept(
    framework: $framework
    product: $product
    productName: $productName
  ) {
    message
    title
    type
  }
}
    `;

/**
 * __useGenerateAdConceptQuery__
 *
 * To run a query within a React component, call `useGenerateAdConceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAdConceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAdConceptQuery({
 *   variables: {
 *      framework: // value for 'framework'
 *      product: // value for 'product'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useGenerateAdConceptQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateAdConceptQuery, Types.GenerateAdConceptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateAdConceptQuery, Types.GenerateAdConceptQueryVariables>(GenerateAdConceptDocument, options);
      }
export function useGenerateAdConceptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateAdConceptQuery, Types.GenerateAdConceptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateAdConceptQuery, Types.GenerateAdConceptQueryVariables>(GenerateAdConceptDocument, options);
        }
export type GenerateAdConceptQueryHookResult = ReturnType<typeof useGenerateAdConceptQuery>;
export type GenerateAdConceptLazyQueryHookResult = ReturnType<typeof useGenerateAdConceptLazyQuery>;
export type GenerateAdConceptQueryResult = Apollo.QueryResult<Types.GenerateAdConceptQuery, Types.GenerateAdConceptQueryVariables>;
export const RegenerateAdConceptDocument = gql`
    query RegenerateAdConcept($framework: AiConceptFramework!, $product: String!, $type: String!, $productName: String) {
  regenerateAdConcept(
    framework: $framework
    product: $product
    type: $type
    productName: $productName
  ) {
    message
    title
    type
  }
}
    `;

/**
 * __useRegenerateAdConceptQuery__
 *
 * To run a query within a React component, call `useRegenerateAdConceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegenerateAdConceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegenerateAdConceptQuery({
 *   variables: {
 *      framework: // value for 'framework'
 *      product: // value for 'product'
 *      type: // value for 'type'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useRegenerateAdConceptQuery(baseOptions: Apollo.QueryHookOptions<Types.RegenerateAdConceptQuery, Types.RegenerateAdConceptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RegenerateAdConceptQuery, Types.RegenerateAdConceptQueryVariables>(RegenerateAdConceptDocument, options);
      }
export function useRegenerateAdConceptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RegenerateAdConceptQuery, Types.RegenerateAdConceptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RegenerateAdConceptQuery, Types.RegenerateAdConceptQueryVariables>(RegenerateAdConceptDocument, options);
        }
export type RegenerateAdConceptQueryHookResult = ReturnType<typeof useRegenerateAdConceptQuery>;
export type RegenerateAdConceptLazyQueryHookResult = ReturnType<typeof useRegenerateAdConceptLazyQuery>;
export type RegenerateAdConceptQueryResult = Apollo.QueryResult<Types.RegenerateAdConceptQuery, Types.RegenerateAdConceptQueryVariables>;
export const CreateAdConceptDocument = gql`
    mutation CreateAdConcept($data: AdConceptInput!, $organizationId: String) {
  createAdConcept(data: $data, organizationId: $organizationId) {
    id
    title
  }
}
    `;
export type CreateAdConceptMutationFn = Apollo.MutationFunction<Types.CreateAdConceptMutation, Types.CreateAdConceptMutationVariables>;

/**
 * __useCreateAdConceptMutation__
 *
 * To run a mutation, you first call `useCreateAdConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdConceptMutation, { data, loading, error }] = useCreateAdConceptMutation({
 *   variables: {
 *      data: // value for 'data'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateAdConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAdConceptMutation, Types.CreateAdConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAdConceptMutation, Types.CreateAdConceptMutationVariables>(CreateAdConceptDocument, options);
      }
export type CreateAdConceptMutationHookResult = ReturnType<typeof useCreateAdConceptMutation>;
export type CreateAdConceptMutationResult = Apollo.MutationResult<Types.CreateAdConceptMutation>;
export type CreateAdConceptMutationOptions = Apollo.BaseMutationOptions<Types.CreateAdConceptMutation, Types.CreateAdConceptMutationVariables>;
export const DestroyAdConceptDocument = gql`
    mutation DestroyAdConcept($adConceptId: ID!) {
  destroyAdConcept(adConceptId: $adConceptId) {
    id
  }
}
    `;
export type DestroyAdConceptMutationFn = Apollo.MutationFunction<Types.DestroyAdConceptMutation, Types.DestroyAdConceptMutationVariables>;

/**
 * __useDestroyAdConceptMutation__
 *
 * To run a mutation, you first call `useDestroyAdConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAdConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAdConceptMutation, { data, loading, error }] = useDestroyAdConceptMutation({
 *   variables: {
 *      adConceptId: // value for 'adConceptId'
 *   },
 * });
 */
export function useDestroyAdConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyAdConceptMutation, Types.DestroyAdConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyAdConceptMutation, Types.DestroyAdConceptMutationVariables>(DestroyAdConceptDocument, options);
      }
export type DestroyAdConceptMutationHookResult = ReturnType<typeof useDestroyAdConceptMutation>;
export type DestroyAdConceptMutationResult = Apollo.MutationResult<Types.DestroyAdConceptMutation>;
export type DestroyAdConceptMutationOptions = Apollo.BaseMutationOptions<Types.DestroyAdConceptMutation, Types.DestroyAdConceptMutationVariables>;
export const GenerateConceptMindMapDocument = gql`
    query GenerateConceptMindMap($product: String!, $productName: String!) {
  generateConceptMindMap(product: $product, productName: $productName) {
    whimsicalUrl
    imageUrl
    list {
      title
      frameworks {
        framework_title
        items {
          message
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGenerateConceptMindMapQuery__
 *
 * To run a query within a React component, call `useGenerateConceptMindMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateConceptMindMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateConceptMindMapQuery({
 *   variables: {
 *      product: // value for 'product'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useGenerateConceptMindMapQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateConceptMindMapQuery, Types.GenerateConceptMindMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateConceptMindMapQuery, Types.GenerateConceptMindMapQueryVariables>(GenerateConceptMindMapDocument, options);
      }
export function useGenerateConceptMindMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateConceptMindMapQuery, Types.GenerateConceptMindMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateConceptMindMapQuery, Types.GenerateConceptMindMapQueryVariables>(GenerateConceptMindMapDocument, options);
        }
export type GenerateConceptMindMapQueryHookResult = ReturnType<typeof useGenerateConceptMindMapQuery>;
export type GenerateConceptMindMapLazyQueryHookResult = ReturnType<typeof useGenerateConceptMindMapLazyQuery>;
export type GenerateConceptMindMapQueryResult = Apollo.QueryResult<Types.GenerateConceptMindMapQuery, Types.GenerateConceptMindMapQueryVariables>;
export const CreateConceptMindMapDocument = gql`
    mutation CreateConceptMindMap($data: ConceptMindMapInput!, $organizationId: String) {
  mindMap: createConceptMindMap(data: $data, organizationId: $organizationId) {
    id
    file {
      fileId
      status
    }
    fileId
  }
}
    `;
export type CreateConceptMindMapMutationFn = Apollo.MutationFunction<Types.CreateConceptMindMapMutation, Types.CreateConceptMindMapMutationVariables>;

/**
 * __useCreateConceptMindMapMutation__
 *
 * To run a mutation, you first call `useCreateConceptMindMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConceptMindMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConceptMindMapMutation, { data, loading, error }] = useCreateConceptMindMapMutation({
 *   variables: {
 *      data: // value for 'data'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateConceptMindMapMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateConceptMindMapMutation, Types.CreateConceptMindMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateConceptMindMapMutation, Types.CreateConceptMindMapMutationVariables>(CreateConceptMindMapDocument, options);
      }
export type CreateConceptMindMapMutationHookResult = ReturnType<typeof useCreateConceptMindMapMutation>;
export type CreateConceptMindMapMutationResult = Apollo.MutationResult<Types.CreateConceptMindMapMutation>;
export type CreateConceptMindMapMutationOptions = Apollo.BaseMutationOptions<Types.CreateConceptMindMapMutation, Types.CreateConceptMindMapMutationVariables>;
export const DestroyConceptMindMapDocument = gql`
    mutation DestroyConceptMindMap($conceptMindMapId: ID!) {
  mindMap: destroyConceptMindMap(conceptMindMapId: $conceptMindMapId) {
    id
  }
}
    `;
export type DestroyConceptMindMapMutationFn = Apollo.MutationFunction<Types.DestroyConceptMindMapMutation, Types.DestroyConceptMindMapMutationVariables>;

/**
 * __useDestroyConceptMindMapMutation__
 *
 * To run a mutation, you first call `useDestroyConceptMindMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyConceptMindMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyConceptMindMapMutation, { data, loading, error }] = useDestroyConceptMindMapMutation({
 *   variables: {
 *      conceptMindMapId: // value for 'conceptMindMapId'
 *   },
 * });
 */
export function useDestroyConceptMindMapMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyConceptMindMapMutation, Types.DestroyConceptMindMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyConceptMindMapMutation, Types.DestroyConceptMindMapMutationVariables>(DestroyConceptMindMapDocument, options);
      }
export type DestroyConceptMindMapMutationHookResult = ReturnType<typeof useDestroyConceptMindMapMutation>;
export type DestroyConceptMindMapMutationResult = Apollo.MutationResult<Types.DestroyConceptMindMapMutation>;
export type DestroyConceptMindMapMutationOptions = Apollo.BaseMutationOptions<Types.DestroyConceptMindMapMutation, Types.DestroyConceptMindMapMutationVariables>;
export const GetAdConceptsDocument = gql`
    query GetAdConcepts($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetAdConceptsFilter) {
  concepts: getAdConcepts(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      id
      message
      title
      productName
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    `;

/**
 * __useGetAdConceptsQuery__
 *
 * To run a query within a React component, call `useGetAdConceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdConceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdConceptsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAdConceptsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAdConceptsQuery, Types.GetAdConceptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdConceptsQuery, Types.GetAdConceptsQueryVariables>(GetAdConceptsDocument, options);
      }
export function useGetAdConceptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdConceptsQuery, Types.GetAdConceptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdConceptsQuery, Types.GetAdConceptsQueryVariables>(GetAdConceptsDocument, options);
        }
export type GetAdConceptsQueryHookResult = ReturnType<typeof useGetAdConceptsQuery>;
export type GetAdConceptsLazyQueryHookResult = ReturnType<typeof useGetAdConceptsLazyQuery>;
export type GetAdConceptsQueryResult = Apollo.QueryResult<Types.GetAdConceptsQuery, Types.GetAdConceptsQueryVariables>;
export const EditAdConceptDocument = gql`
    mutation EditAdConcept($adConceptId: ID!, $data: EditAdConceptInput!) {
  editAdConcept(adConceptId: $adConceptId, data: $data) {
    id
    message
    title
    productName
  }
}
    `;
export type EditAdConceptMutationFn = Apollo.MutationFunction<Types.EditAdConceptMutation, Types.EditAdConceptMutationVariables>;

/**
 * __useEditAdConceptMutation__
 *
 * To run a mutation, you first call `useEditAdConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdConceptMutation, { data, loading, error }] = useEditAdConceptMutation({
 *   variables: {
 *      adConceptId: // value for 'adConceptId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAdConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAdConceptMutation, Types.EditAdConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAdConceptMutation, Types.EditAdConceptMutationVariables>(EditAdConceptDocument, options);
      }
export type EditAdConceptMutationHookResult = ReturnType<typeof useEditAdConceptMutation>;
export type EditAdConceptMutationResult = Apollo.MutationResult<Types.EditAdConceptMutation>;
export type EditAdConceptMutationOptions = Apollo.BaseMutationOptions<Types.EditAdConceptMutation, Types.EditAdConceptMutationVariables>;
export const GetConceptMindMapsDocument = gql`
    query GetConceptMindMaps($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetConceptMindMapsFilter) {
  mindMaps: getConceptMindMaps(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      ...MindMapNode
    }
    pageInfo {
      totalItems
    }
  }
}
    ${MindMapNodeFragmentDoc}`;

/**
 * __useGetConceptMindMapsQuery__
 *
 * To run a query within a React component, call `useGetConceptMindMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConceptMindMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConceptMindMapsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetConceptMindMapsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetConceptMindMapsQuery, Types.GetConceptMindMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetConceptMindMapsQuery, Types.GetConceptMindMapsQueryVariables>(GetConceptMindMapsDocument, options);
      }
export function useGetConceptMindMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetConceptMindMapsQuery, Types.GetConceptMindMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetConceptMindMapsQuery, Types.GetConceptMindMapsQueryVariables>(GetConceptMindMapsDocument, options);
        }
export type GetConceptMindMapsQueryHookResult = ReturnType<typeof useGetConceptMindMapsQuery>;
export type GetConceptMindMapsLazyQueryHookResult = ReturnType<typeof useGetConceptMindMapsLazyQuery>;
export type GetConceptMindMapsQueryResult = Apollo.QueryResult<Types.GetConceptMindMapsQuery, Types.GetConceptMindMapsQueryVariables>;
export const GetProductionBriefsDocument = gql`
    query getProductionBriefs($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetProductionBriefsFilter) {
  productionBriefs: getProductionBriefs(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      ...ProductionBriefNode
    }
    pageInfo {
      totalItems
    }
  }
}
    ${ProductionBriefNodeFragmentDoc}`;

/**
 * __useGetProductionBriefsQuery__
 *
 * To run a query within a React component, call `useGetProductionBriefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductionBriefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductionBriefsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProductionBriefsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetProductionBriefsQuery, Types.GetProductionBriefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProductionBriefsQuery, Types.GetProductionBriefsQueryVariables>(GetProductionBriefsDocument, options);
      }
export function useGetProductionBriefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProductionBriefsQuery, Types.GetProductionBriefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProductionBriefsQuery, Types.GetProductionBriefsQueryVariables>(GetProductionBriefsDocument, options);
        }
export type GetProductionBriefsQueryHookResult = ReturnType<typeof useGetProductionBriefsQuery>;
export type GetProductionBriefsLazyQueryHookResult = ReturnType<typeof useGetProductionBriefsLazyQuery>;
export type GetProductionBriefsQueryResult = Apollo.QueryResult<Types.GetProductionBriefsQuery, Types.GetProductionBriefsQueryVariables>;
export const GetAdStoryboardsDocument = gql`
    query GetAdStoryboards($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetAdStoryboardsFilter) {
  storyboards: getAdStoryboards(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      id
      frames {
        message
        title
      }
      title
      productName
      type
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    `;

/**
 * __useGetAdStoryboardsQuery__
 *
 * To run a query within a React component, call `useGetAdStoryboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdStoryboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdStoryboardsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAdStoryboardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAdStoryboardsQuery, Types.GetAdStoryboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdStoryboardsQuery, Types.GetAdStoryboardsQueryVariables>(GetAdStoryboardsDocument, options);
      }
export function useGetAdStoryboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdStoryboardsQuery, Types.GetAdStoryboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdStoryboardsQuery, Types.GetAdStoryboardsQueryVariables>(GetAdStoryboardsDocument, options);
        }
export type GetAdStoryboardsQueryHookResult = ReturnType<typeof useGetAdStoryboardsQuery>;
export type GetAdStoryboardsLazyQueryHookResult = ReturnType<typeof useGetAdStoryboardsLazyQuery>;
export type GetAdStoryboardsQueryResult = Apollo.QueryResult<Types.GetAdStoryboardsQuery, Types.GetAdStoryboardsQueryVariables>;
export const EditAdStoryboardDocument = gql`
    mutation EditAdStoryboard($adStoryboardId: ID!, $data: AdStoryboardInput!) {
  editAdStoryboard(adStoryboardId: $adStoryboardId, data: $data) {
    id
    title
    type
    productName
    frames {
      message
      title
    }
  }
}
    `;
export type EditAdStoryboardMutationFn = Apollo.MutationFunction<Types.EditAdStoryboardMutation, Types.EditAdStoryboardMutationVariables>;

/**
 * __useEditAdStoryboardMutation__
 *
 * To run a mutation, you first call `useEditAdStoryboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdStoryboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdStoryboardMutation, { data, loading, error }] = useEditAdStoryboardMutation({
 *   variables: {
 *      adStoryboardId: // value for 'adStoryboardId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAdStoryboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAdStoryboardMutation, Types.EditAdStoryboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAdStoryboardMutation, Types.EditAdStoryboardMutationVariables>(EditAdStoryboardDocument, options);
      }
export type EditAdStoryboardMutationHookResult = ReturnType<typeof useEditAdStoryboardMutation>;
export type EditAdStoryboardMutationResult = Apollo.MutationResult<Types.EditAdStoryboardMutation>;
export type EditAdStoryboardMutationOptions = Apollo.BaseMutationOptions<Types.EditAdStoryboardMutation, Types.EditAdStoryboardMutationVariables>;
export const GetUgcIdeasDocument = gql`
    query GetUgcIdeas($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetUgcIdeasFilter) {
  UGCIdeas: getUgcIdeas(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      ...UGCIdeaNode
    }
    pageInfo {
      totalItems
    }
  }
}
    ${UgcIdeaNodeFragmentDoc}`;

/**
 * __useGetUgcIdeasQuery__
 *
 * To run a query within a React component, call `useGetUgcIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUgcIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUgcIdeasQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUgcIdeasQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUgcIdeasQuery, Types.GetUgcIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUgcIdeasQuery, Types.GetUgcIdeasQueryVariables>(GetUgcIdeasDocument, options);
      }
export function useGetUgcIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUgcIdeasQuery, Types.GetUgcIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUgcIdeasQuery, Types.GetUgcIdeasQueryVariables>(GetUgcIdeasDocument, options);
        }
export type GetUgcIdeasQueryHookResult = ReturnType<typeof useGetUgcIdeasQuery>;
export type GetUgcIdeasLazyQueryHookResult = ReturnType<typeof useGetUgcIdeasLazyQuery>;
export type GetUgcIdeasQueryResult = Apollo.QueryResult<Types.GetUgcIdeasQuery, Types.GetUgcIdeasQueryVariables>;
export const GetUgcStoryboardsDocument = gql`
    query GetUGCStoryboards($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetUgcStoryboardsFilter) {
  UGCStoryboards: getUgcStoryboards(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    pageInfo {
      totalItems
    }
    nodes {
      ...UGCStoryboardNode
    }
  }
}
    ${UgcStoryboardNodeFragmentDoc}`;

/**
 * __useGetUgcStoryboardsQuery__
 *
 * To run a query within a React component, call `useGetUgcStoryboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUgcStoryboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUgcStoryboardsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUgcStoryboardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUgcStoryboardsQuery, Types.GetUgcStoryboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUgcStoryboardsQuery, Types.GetUgcStoryboardsQueryVariables>(GetUgcStoryboardsDocument, options);
      }
export function useGetUgcStoryboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUgcStoryboardsQuery, Types.GetUgcStoryboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUgcStoryboardsQuery, Types.GetUgcStoryboardsQueryVariables>(GetUgcStoryboardsDocument, options);
        }
export type GetUgcStoryboardsQueryHookResult = ReturnType<typeof useGetUgcStoryboardsQuery>;
export type GetUgcStoryboardsLazyQueryHookResult = ReturnType<typeof useGetUgcStoryboardsLazyQuery>;
export type GetUgcStoryboardsQueryResult = Apollo.QueryResult<Types.GetUgcStoryboardsQuery, Types.GetUgcStoryboardsQueryVariables>;
export const GetSavingsNumberDocument = gql`
    query GetSavingsNumber($organizationId: ID, $createdByUserId: ID) {
  concept: getAdConcepts(
    organizationId: $organizationId
    filters: {createdByUserId: $createdByUserId}
  ) {
    pageInfo {
      totalItems
    }
  }
  script: getAdStoryboards(
    organizationId: $organizationId
    filters: {createdByUserId: $createdByUserId}
  ) {
    pageInfo {
      totalItems
    }
  }
  idea: getConceptMindMaps(
    organizationId: $organizationId
    filters: {createdByUserId: $createdByUserId}
  ) {
    pageInfo {
      totalItems
    }
  }
  productionBrief: getProductionBriefs(
    organizationId: $organizationId
    filters: {createdByUserId: $createdByUserId}
  ) {
    pageInfo {
      totalItems
    }
  }
  UGCStoryboard: getUgcStoryboards(
    organizationId: $organizationId
    filters: {createdByUserId: $createdByUserId}
  ) {
    pageInfo {
      totalItems
    }
  }
  UGCIdea: getUgcIdeas(
    organizationId: $organizationId
    filters: {createdByUserId: $createdByUserId}
  ) {
    pageInfo {
      totalItems
    }
  }
}
    `;

/**
 * __useGetSavingsNumberQuery__
 *
 * To run a query within a React component, call `useGetSavingsNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavingsNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavingsNumberQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      createdByUserId: // value for 'createdByUserId'
 *   },
 * });
 */
export function useGetSavingsNumberQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSavingsNumberQuery, Types.GetSavingsNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSavingsNumberQuery, Types.GetSavingsNumberQueryVariables>(GetSavingsNumberDocument, options);
      }
export function useGetSavingsNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSavingsNumberQuery, Types.GetSavingsNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSavingsNumberQuery, Types.GetSavingsNumberQueryVariables>(GetSavingsNumberDocument, options);
        }
export type GetSavingsNumberQueryHookResult = ReturnType<typeof useGetSavingsNumberQuery>;
export type GetSavingsNumberLazyQueryHookResult = ReturnType<typeof useGetSavingsNumberLazyQuery>;
export type GetSavingsNumberQueryResult = Apollo.QueryResult<Types.GetSavingsNumberQuery, Types.GetSavingsNumberQueryVariables>;
export const SearchAdConceptsDocument = gql`
    query SearchAdConcepts($organizationId: ID, $filters: GetAdConceptsFilter, $pagination: OffsetPaginationParameters) {
  concepts: getAdConcepts(
    organizationId: $organizationId
    filters: $filters
    pagination: $pagination
  ) {
    pageInfo {
      totalItems
    }
    nodes {
      id
      message
      title
      productName
      productDescription
    }
  }
}
    `;

/**
 * __useSearchAdConceptsQuery__
 *
 * To run a query within a React component, call `useSearchAdConceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAdConceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAdConceptsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchAdConceptsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchAdConceptsQuery, Types.SearchAdConceptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchAdConceptsQuery, Types.SearchAdConceptsQueryVariables>(SearchAdConceptsDocument, options);
      }
export function useSearchAdConceptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchAdConceptsQuery, Types.SearchAdConceptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchAdConceptsQuery, Types.SearchAdConceptsQueryVariables>(SearchAdConceptsDocument, options);
        }
export type SearchAdConceptsQueryHookResult = ReturnType<typeof useSearchAdConceptsQuery>;
export type SearchAdConceptsLazyQueryHookResult = ReturnType<typeof useSearchAdConceptsLazyQuery>;
export type SearchAdConceptsQueryResult = Apollo.QueryResult<Types.SearchAdConceptsQuery, Types.SearchAdConceptsQueryVariables>;
export const GenerateAdStoryboardDocument = gql`
    query GenerateAdStoryboard($duration: Int!, $concept: String, $framework: AiStoryboardFramework!, $product: String, $productName: String) {
  storyboards: generateAdStoryboard(
    duration: $duration
    concept: $concept
    framework: $framework
    product: $product
    productName: $productName
  ) {
    ...AdStoryboardNode
  }
}
    ${AdStoryboardNodeFragmentDoc}`;

/**
 * __useGenerateAdStoryboardQuery__
 *
 * To run a query within a React component, call `useGenerateAdStoryboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAdStoryboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAdStoryboardQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      concept: // value for 'concept'
 *      framework: // value for 'framework'
 *      product: // value for 'product'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useGenerateAdStoryboardQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateAdStoryboardQuery, Types.GenerateAdStoryboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateAdStoryboardQuery, Types.GenerateAdStoryboardQueryVariables>(GenerateAdStoryboardDocument, options);
      }
export function useGenerateAdStoryboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateAdStoryboardQuery, Types.GenerateAdStoryboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateAdStoryboardQuery, Types.GenerateAdStoryboardQueryVariables>(GenerateAdStoryboardDocument, options);
        }
export type GenerateAdStoryboardQueryHookResult = ReturnType<typeof useGenerateAdStoryboardQuery>;
export type GenerateAdStoryboardLazyQueryHookResult = ReturnType<typeof useGenerateAdStoryboardLazyQuery>;
export type GenerateAdStoryboardQueryResult = Apollo.QueryResult<Types.GenerateAdStoryboardQuery, Types.GenerateAdStoryboardQueryVariables>;
export const RegenerateAdStoryboardDocument = gql`
    query RegenerateAdStoryboard($duration: Int!, $concept: String, $framework: AiStoryboardFramework!, $type: String!, $product: String, $productName: String) {
  regenerateAdStoryboard(
    duration: $duration
    concept: $concept
    framework: $framework
    type: $type
    product: $product
    productName: $productName
  ) {
    ...AdStoryboardNode
  }
}
    ${AdStoryboardNodeFragmentDoc}`;

/**
 * __useRegenerateAdStoryboardQuery__
 *
 * To run a query within a React component, call `useRegenerateAdStoryboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegenerateAdStoryboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegenerateAdStoryboardQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      concept: // value for 'concept'
 *      framework: // value for 'framework'
 *      type: // value for 'type'
 *      product: // value for 'product'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useRegenerateAdStoryboardQuery(baseOptions: Apollo.QueryHookOptions<Types.RegenerateAdStoryboardQuery, Types.RegenerateAdStoryboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RegenerateAdStoryboardQuery, Types.RegenerateAdStoryboardQueryVariables>(RegenerateAdStoryboardDocument, options);
      }
export function useRegenerateAdStoryboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RegenerateAdStoryboardQuery, Types.RegenerateAdStoryboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RegenerateAdStoryboardQuery, Types.RegenerateAdStoryboardQueryVariables>(RegenerateAdStoryboardDocument, options);
        }
export type RegenerateAdStoryboardQueryHookResult = ReturnType<typeof useRegenerateAdStoryboardQuery>;
export type RegenerateAdStoryboardLazyQueryHookResult = ReturnType<typeof useRegenerateAdStoryboardLazyQuery>;
export type RegenerateAdStoryboardQueryResult = Apollo.QueryResult<Types.RegenerateAdStoryboardQuery, Types.RegenerateAdStoryboardQueryVariables>;
export const CreateAdStoryboardDocument = gql`
    mutation CreateAdStoryboard($data: AdStoryboardInput!, $organizationId: String) {
  createAdStoryboard(data: $data, organizationId: $organizationId) {
    id
  }
}
    `;
export type CreateAdStoryboardMutationFn = Apollo.MutationFunction<Types.CreateAdStoryboardMutation, Types.CreateAdStoryboardMutationVariables>;

/**
 * __useCreateAdStoryboardMutation__
 *
 * To run a mutation, you first call `useCreateAdStoryboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdStoryboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdStoryboardMutation, { data, loading, error }] = useCreateAdStoryboardMutation({
 *   variables: {
 *      data: // value for 'data'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateAdStoryboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAdStoryboardMutation, Types.CreateAdStoryboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAdStoryboardMutation, Types.CreateAdStoryboardMutationVariables>(CreateAdStoryboardDocument, options);
      }
export type CreateAdStoryboardMutationHookResult = ReturnType<typeof useCreateAdStoryboardMutation>;
export type CreateAdStoryboardMutationResult = Apollo.MutationResult<Types.CreateAdStoryboardMutation>;
export type CreateAdStoryboardMutationOptions = Apollo.BaseMutationOptions<Types.CreateAdStoryboardMutation, Types.CreateAdStoryboardMutationVariables>;
export const DestroyAdStoryboardDocument = gql`
    mutation DestroyAdStoryboard($adStoryboardId: ID!) {
  destroyAdStoryboard(adStoryboardId: $adStoryboardId) {
    id
  }
}
    `;
export type DestroyAdStoryboardMutationFn = Apollo.MutationFunction<Types.DestroyAdStoryboardMutation, Types.DestroyAdStoryboardMutationVariables>;

/**
 * __useDestroyAdStoryboardMutation__
 *
 * To run a mutation, you first call `useDestroyAdStoryboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAdStoryboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAdStoryboardMutation, { data, loading, error }] = useDestroyAdStoryboardMutation({
 *   variables: {
 *      adStoryboardId: // value for 'adStoryboardId'
 *   },
 * });
 */
export function useDestroyAdStoryboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyAdStoryboardMutation, Types.DestroyAdStoryboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyAdStoryboardMutation, Types.DestroyAdStoryboardMutationVariables>(DestroyAdStoryboardDocument, options);
      }
export type DestroyAdStoryboardMutationHookResult = ReturnType<typeof useDestroyAdStoryboardMutation>;
export type DestroyAdStoryboardMutationResult = Apollo.MutationResult<Types.DestroyAdStoryboardMutation>;
export type DestroyAdStoryboardMutationOptions = Apollo.BaseMutationOptions<Types.DestroyAdStoryboardMutation, Types.DestroyAdStoryboardMutationVariables>;
export const GenerateUgcIdeaDocument = gql`
    query GenerateUgcIdea($framework: AiConceptFramework!, $product: String!, $productName: String) {
  generateUgcIdea(
    framework: $framework
    product: $product
    productName: $productName
  ) {
    ...UGCIdeaDetails
  }
}
    ${UgcIdeaDetailsFragmentDoc}`;

/**
 * __useGenerateUgcIdeaQuery__
 *
 * To run a query within a React component, call `useGenerateUgcIdeaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateUgcIdeaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateUgcIdeaQuery({
 *   variables: {
 *      framework: // value for 'framework'
 *      product: // value for 'product'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useGenerateUgcIdeaQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateUgcIdeaQuery, Types.GenerateUgcIdeaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateUgcIdeaQuery, Types.GenerateUgcIdeaQueryVariables>(GenerateUgcIdeaDocument, options);
      }
export function useGenerateUgcIdeaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateUgcIdeaQuery, Types.GenerateUgcIdeaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateUgcIdeaQuery, Types.GenerateUgcIdeaQueryVariables>(GenerateUgcIdeaDocument, options);
        }
export type GenerateUgcIdeaQueryHookResult = ReturnType<typeof useGenerateUgcIdeaQuery>;
export type GenerateUgcIdeaLazyQueryHookResult = ReturnType<typeof useGenerateUgcIdeaLazyQuery>;
export type GenerateUgcIdeaQueryResult = Apollo.QueryResult<Types.GenerateUgcIdeaQuery, Types.GenerateUgcIdeaQueryVariables>;
export const CreateUgcIdeaDocument = gql`
    mutation CreateUgcIdea($organizationId: ID, $data: UgcIdeaInput!) {
  createUgcIdea(organizationId: $organizationId, data: $data) {
    id
  }
}
    `;
export type CreateUgcIdeaMutationFn = Apollo.MutationFunction<Types.CreateUgcIdeaMutation, Types.CreateUgcIdeaMutationVariables>;

/**
 * __useCreateUgcIdeaMutation__
 *
 * To run a mutation, you first call `useCreateUgcIdeaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUgcIdeaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUgcIdeaMutation, { data, loading, error }] = useCreateUgcIdeaMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUgcIdeaMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUgcIdeaMutation, Types.CreateUgcIdeaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUgcIdeaMutation, Types.CreateUgcIdeaMutationVariables>(CreateUgcIdeaDocument, options);
      }
export type CreateUgcIdeaMutationHookResult = ReturnType<typeof useCreateUgcIdeaMutation>;
export type CreateUgcIdeaMutationResult = Apollo.MutationResult<Types.CreateUgcIdeaMutation>;
export type CreateUgcIdeaMutationOptions = Apollo.BaseMutationOptions<Types.CreateUgcIdeaMutation, Types.CreateUgcIdeaMutationVariables>;
export const DestroyUgcIdeaDocument = gql`
    mutation DestroyUgcIdea($ugcIdeaId: ID!) {
  destroyUgcIdea(ugcIdeaId: $ugcIdeaId) {
    id
  }
}
    `;
export type DestroyUgcIdeaMutationFn = Apollo.MutationFunction<Types.DestroyUgcIdeaMutation, Types.DestroyUgcIdeaMutationVariables>;

/**
 * __useDestroyUgcIdeaMutation__
 *
 * To run a mutation, you first call `useDestroyUgcIdeaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyUgcIdeaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyUgcIdeaMutation, { data, loading, error }] = useDestroyUgcIdeaMutation({
 *   variables: {
 *      ugcIdeaId: // value for 'ugcIdeaId'
 *   },
 * });
 */
export function useDestroyUgcIdeaMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyUgcIdeaMutation, Types.DestroyUgcIdeaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyUgcIdeaMutation, Types.DestroyUgcIdeaMutationVariables>(DestroyUgcIdeaDocument, options);
      }
export type DestroyUgcIdeaMutationHookResult = ReturnType<typeof useDestroyUgcIdeaMutation>;
export type DestroyUgcIdeaMutationResult = Apollo.MutationResult<Types.DestroyUgcIdeaMutation>;
export type DestroyUgcIdeaMutationOptions = Apollo.BaseMutationOptions<Types.DestroyUgcIdeaMutation, Types.DestroyUgcIdeaMutationVariables>;
export const EditUgcIdeaDocument = gql`
    mutation EditUgcIdea($ugcIdeaId: ID!, $data: UgcIdeaInput!) {
  editUgcIdea(ugcIdeaId: $ugcIdeaId, data: $data) {
    id
    approach
    keyMessage
    productDescription
    productName
    title
    type
  }
}
    `;
export type EditUgcIdeaMutationFn = Apollo.MutationFunction<Types.EditUgcIdeaMutation, Types.EditUgcIdeaMutationVariables>;

/**
 * __useEditUgcIdeaMutation__
 *
 * To run a mutation, you first call `useEditUgcIdeaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUgcIdeaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUgcIdeaMutation, { data, loading, error }] = useEditUgcIdeaMutation({
 *   variables: {
 *      ugcIdeaId: // value for 'ugcIdeaId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditUgcIdeaMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditUgcIdeaMutation, Types.EditUgcIdeaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditUgcIdeaMutation, Types.EditUgcIdeaMutationVariables>(EditUgcIdeaDocument, options);
      }
export type EditUgcIdeaMutationHookResult = ReturnType<typeof useEditUgcIdeaMutation>;
export type EditUgcIdeaMutationResult = Apollo.MutationResult<Types.EditUgcIdeaMutation>;
export type EditUgcIdeaMutationOptions = Apollo.BaseMutationOptions<Types.EditUgcIdeaMutation, Types.EditUgcIdeaMutationVariables>;
export const RegenerateUgcIdeaDocument = gql`
    query RegenerateUgcIdea($framework: AiConceptFramework!, $product: String!, $type: String!, $productName: String) {
  regenerateUgcIdea(
    framework: $framework
    product: $product
    type: $type
    productName: $productName
  ) {
    ...UGCIdeaDetails
  }
}
    ${UgcIdeaDetailsFragmentDoc}`;

/**
 * __useRegenerateUgcIdeaQuery__
 *
 * To run a query within a React component, call `useRegenerateUgcIdeaQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegenerateUgcIdeaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegenerateUgcIdeaQuery({
 *   variables: {
 *      framework: // value for 'framework'
 *      product: // value for 'product'
 *      type: // value for 'type'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useRegenerateUgcIdeaQuery(baseOptions: Apollo.QueryHookOptions<Types.RegenerateUgcIdeaQuery, Types.RegenerateUgcIdeaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RegenerateUgcIdeaQuery, Types.RegenerateUgcIdeaQueryVariables>(RegenerateUgcIdeaDocument, options);
      }
export function useRegenerateUgcIdeaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RegenerateUgcIdeaQuery, Types.RegenerateUgcIdeaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RegenerateUgcIdeaQuery, Types.RegenerateUgcIdeaQueryVariables>(RegenerateUgcIdeaDocument, options);
        }
export type RegenerateUgcIdeaQueryHookResult = ReturnType<typeof useRegenerateUgcIdeaQuery>;
export type RegenerateUgcIdeaLazyQueryHookResult = ReturnType<typeof useRegenerateUgcIdeaLazyQuery>;
export type RegenerateUgcIdeaQueryResult = Apollo.QueryResult<Types.RegenerateUgcIdeaQuery, Types.RegenerateUgcIdeaQueryVariables>;
export const GenerateUgcStoryboardDocument = gql`
    query GenerateUgcStoryboard($framework: UgcStoryboardFramework!, $concept: String, $productDescription: String!, $productName: String) {
  storyboard: generateUgcStoryboard(
    framework: $framework
    concept: $concept
    productDescription: $productDescription
    productName: $productName
  ) {
    title
    scenes {
      title
      visualPrompt
      scriptPrompt
    }
  }
}
    `;

/**
 * __useGenerateUgcStoryboardQuery__
 *
 * To run a query within a React component, call `useGenerateUgcStoryboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateUgcStoryboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateUgcStoryboardQuery({
 *   variables: {
 *      framework: // value for 'framework'
 *      concept: // value for 'concept'
 *      productDescription: // value for 'productDescription'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useGenerateUgcStoryboardQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateUgcStoryboardQuery, Types.GenerateUgcStoryboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateUgcStoryboardQuery, Types.GenerateUgcStoryboardQueryVariables>(GenerateUgcStoryboardDocument, options);
      }
export function useGenerateUgcStoryboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateUgcStoryboardQuery, Types.GenerateUgcStoryboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateUgcStoryboardQuery, Types.GenerateUgcStoryboardQueryVariables>(GenerateUgcStoryboardDocument, options);
        }
export type GenerateUgcStoryboardQueryHookResult = ReturnType<typeof useGenerateUgcStoryboardQuery>;
export type GenerateUgcStoryboardLazyQueryHookResult = ReturnType<typeof useGenerateUgcStoryboardLazyQuery>;
export type GenerateUgcStoryboardQueryResult = Apollo.QueryResult<Types.GenerateUgcStoryboardQuery, Types.GenerateUgcStoryboardQueryVariables>;
export const CreateUgcStoryboardDocument = gql`
    mutation CreateUgcStoryboard($organizationId: ID, $data: UgcStoryboardInput!) {
  createUgcStoryboard(organizationId: $organizationId, data: $data) {
    id
  }
}
    `;
export type CreateUgcStoryboardMutationFn = Apollo.MutationFunction<Types.CreateUgcStoryboardMutation, Types.CreateUgcStoryboardMutationVariables>;

/**
 * __useCreateUgcStoryboardMutation__
 *
 * To run a mutation, you first call `useCreateUgcStoryboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUgcStoryboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUgcStoryboardMutation, { data, loading, error }] = useCreateUgcStoryboardMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUgcStoryboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUgcStoryboardMutation, Types.CreateUgcStoryboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUgcStoryboardMutation, Types.CreateUgcStoryboardMutationVariables>(CreateUgcStoryboardDocument, options);
      }
export type CreateUgcStoryboardMutationHookResult = ReturnType<typeof useCreateUgcStoryboardMutation>;
export type CreateUgcStoryboardMutationResult = Apollo.MutationResult<Types.CreateUgcStoryboardMutation>;
export type CreateUgcStoryboardMutationOptions = Apollo.BaseMutationOptions<Types.CreateUgcStoryboardMutation, Types.CreateUgcStoryboardMutationVariables>;
export const DestroyUgcStoryboardDocument = gql`
    mutation DestroyUgcStoryboard($ugcStoryboardId: ID!) {
  destroyUgcStoryboard(ugcStoryboardId: $ugcStoryboardId) {
    id
  }
}
    `;
export type DestroyUgcStoryboardMutationFn = Apollo.MutationFunction<Types.DestroyUgcStoryboardMutation, Types.DestroyUgcStoryboardMutationVariables>;

/**
 * __useDestroyUgcStoryboardMutation__
 *
 * To run a mutation, you first call `useDestroyUgcStoryboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyUgcStoryboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyUgcStoryboardMutation, { data, loading, error }] = useDestroyUgcStoryboardMutation({
 *   variables: {
 *      ugcStoryboardId: // value for 'ugcStoryboardId'
 *   },
 * });
 */
export function useDestroyUgcStoryboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyUgcStoryboardMutation, Types.DestroyUgcStoryboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyUgcStoryboardMutation, Types.DestroyUgcStoryboardMutationVariables>(DestroyUgcStoryboardDocument, options);
      }
export type DestroyUgcStoryboardMutationHookResult = ReturnType<typeof useDestroyUgcStoryboardMutation>;
export type DestroyUgcStoryboardMutationResult = Apollo.MutationResult<Types.DestroyUgcStoryboardMutation>;
export type DestroyUgcStoryboardMutationOptions = Apollo.BaseMutationOptions<Types.DestroyUgcStoryboardMutation, Types.DestroyUgcStoryboardMutationVariables>;
export const EditUgcStoryboardDocument = gql`
    mutation EditUgcStoryboard($ugcStoryboardId: ID!, $data: UgcStoryboardInput!) {
  editUgcStoryboard(ugcStoryboardId: $ugcStoryboardId, data: $data) {
    id
    title
    productName
    scenes {
      title
      scriptPrompt
      visualPrompt
    }
  }
}
    `;
export type EditUgcStoryboardMutationFn = Apollo.MutationFunction<Types.EditUgcStoryboardMutation, Types.EditUgcStoryboardMutationVariables>;

/**
 * __useEditUgcStoryboardMutation__
 *
 * To run a mutation, you first call `useEditUgcStoryboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUgcStoryboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUgcStoryboardMutation, { data, loading, error }] = useEditUgcStoryboardMutation({
 *   variables: {
 *      ugcStoryboardId: // value for 'ugcStoryboardId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditUgcStoryboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditUgcStoryboardMutation, Types.EditUgcStoryboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditUgcStoryboardMutation, Types.EditUgcStoryboardMutationVariables>(EditUgcStoryboardDocument, options);
      }
export type EditUgcStoryboardMutationHookResult = ReturnType<typeof useEditUgcStoryboardMutation>;
export type EditUgcStoryboardMutationResult = Apollo.MutationResult<Types.EditUgcStoryboardMutation>;
export type EditUgcStoryboardMutationOptions = Apollo.BaseMutationOptions<Types.EditUgcStoryboardMutation, Types.EditUgcStoryboardMutationVariables>;
export const EventsDocument = gql`
    query events($events: [String], $pagination: OffsetPaginationParameters) {
  getAllEvents(eventNames: $events, pagination: $pagination) {
    nodes {
      ...Events
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${EventsFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      events: // value for 'events'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<Types.EventsQuery, Types.EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventsQuery, Types.EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventsQuery, Types.EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventsQuery, Types.EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<Types.EventsQuery, Types.EventsQueryVariables>;
export const EventsByManagerDocument = gql`
    query eventsByManager($userId: ID!, $events: [String], $pagination: OffsetPaginationParameters) {
  getAccountManagerOrganizationsEvents(
    userId: $userId
    eventNames: $events
    pagination: $pagination
  ) {
    nodes {
      ...Events
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${EventsFragmentDoc}`;

/**
 * __useEventsByManagerQuery__
 *
 * To run a query within a React component, call `useEventsByManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByManagerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      events: // value for 'events'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useEventsByManagerQuery(baseOptions: Apollo.QueryHookOptions<Types.EventsByManagerQuery, Types.EventsByManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventsByManagerQuery, Types.EventsByManagerQueryVariables>(EventsByManagerDocument, options);
      }
export function useEventsByManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventsByManagerQuery, Types.EventsByManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventsByManagerQuery, Types.EventsByManagerQueryVariables>(EventsByManagerDocument, options);
        }
export type EventsByManagerQueryHookResult = ReturnType<typeof useEventsByManagerQuery>;
export type EventsByManagerLazyQueryHookResult = ReturnType<typeof useEventsByManagerLazyQuery>;
export type EventsByManagerQueryResult = Apollo.QueryResult<Types.EventsByManagerQuery, Types.EventsByManagerQueryVariables>;
export const OrganizationEventsDocument = gql`
    query organizationEvents($organizationId: ID!, $events: [String], $pagination: OffsetPaginationParameters) {
  getOrganizationEvents(
    organizationId: $organizationId
    eventNames: $events
    pagination: $pagination
  ) {
    nodes {
      ...Events
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${EventsFragmentDoc}`;

/**
 * __useOrganizationEventsQuery__
 *
 * To run a query within a React component, call `useOrganizationEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationEventsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      events: // value for 'events'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrganizationEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationEventsQuery, Types.OrganizationEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationEventsQuery, Types.OrganizationEventsQueryVariables>(OrganizationEventsDocument, options);
      }
export function useOrganizationEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationEventsQuery, Types.OrganizationEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationEventsQuery, Types.OrganizationEventsQueryVariables>(OrganizationEventsDocument, options);
        }
export type OrganizationEventsQueryHookResult = ReturnType<typeof useOrganizationEventsQuery>;
export type OrganizationEventsLazyQueryHookResult = ReturnType<typeof useOrganizationEventsLazyQuery>;
export type OrganizationEventsQueryResult = Apollo.QueryResult<Types.OrganizationEventsQuery, Types.OrganizationEventsQueryVariables>;
export const BulkCommentsEventDocument = gql`
    query bulkCommentsEvent($eventId: ID!) {
  getBulkCommentsEvent(eventId: $eventId) {
    eventId
    comments {
      commentId
      content
      user {
        userId
        name
        surname
        avatar {
          ...FileThumbnail
        }
      }
      createdAt
      assetVersion {
        assetId
        assetVersionId
        file {
          fileId
          originalMetadata {
            common {
              type
            }
          }
          ...FileThumbnail
        }
      }
    }
  }
}
    ${FileThumbnailFragmentDoc}`;

/**
 * __useBulkCommentsEventQuery__
 *
 * To run a query within a React component, call `useBulkCommentsEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkCommentsEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkCommentsEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useBulkCommentsEventQuery(baseOptions: Apollo.QueryHookOptions<Types.BulkCommentsEventQuery, Types.BulkCommentsEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BulkCommentsEventQuery, Types.BulkCommentsEventQueryVariables>(BulkCommentsEventDocument, options);
      }
export function useBulkCommentsEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BulkCommentsEventQuery, Types.BulkCommentsEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BulkCommentsEventQuery, Types.BulkCommentsEventQueryVariables>(BulkCommentsEventDocument, options);
        }
export type BulkCommentsEventQueryHookResult = ReturnType<typeof useBulkCommentsEventQuery>;
export type BulkCommentsEventLazyQueryHookResult = ReturnType<typeof useBulkCommentsEventLazyQuery>;
export type BulkCommentsEventQueryResult = Apollo.QueryResult<Types.BulkCommentsEventQuery, Types.BulkCommentsEventQueryVariables>;
export const AssetDocument = gql`
    query asset($assetId: ID!, $includeComments: Boolean = false) {
  getAsset(assetId: $assetId) {
    ...AssetById
  }
}
    ${AssetByIdFragmentDoc}`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      includeComments: // value for 'includeComments'
 *   },
 * });
 */
export function useAssetQuery(baseOptions: Apollo.QueryHookOptions<Types.AssetQuery, Types.AssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssetQuery, Types.AssetQueryVariables>(AssetDocument, options);
      }
export function useAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssetQuery, Types.AssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssetQuery, Types.AssetQueryVariables>(AssetDocument, options);
        }
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetQueryResult = Apollo.QueryResult<Types.AssetQuery, Types.AssetQueryVariables>;
export const SetAssetRatingDocument = gql`
    mutation setAssetRating($vote: Int!, $assetId: ID!) {
  setAssetRating(vote: $vote, assetId: $assetId) {
    asset {
      assetId
      myRating {
        vote
      }
    }
  }
}
    `;
export type SetAssetRatingMutationFn = Apollo.MutationFunction<Types.SetAssetRatingMutation, Types.SetAssetRatingMutationVariables>;

/**
 * __useSetAssetRatingMutation__
 *
 * To run a mutation, you first call `useSetAssetRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAssetRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAssetRatingMutation, { data, loading, error }] = useSetAssetRatingMutation({
 *   variables: {
 *      vote: // value for 'vote'
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useSetAssetRatingMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetAssetRatingMutation, Types.SetAssetRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetAssetRatingMutation, Types.SetAssetRatingMutationVariables>(SetAssetRatingDocument, options);
      }
export type SetAssetRatingMutationHookResult = ReturnType<typeof useSetAssetRatingMutation>;
export type SetAssetRatingMutationResult = Apollo.MutationResult<Types.SetAssetRatingMutation>;
export type SetAssetRatingMutationOptions = Apollo.BaseMutationOptions<Types.SetAssetRatingMutation, Types.SetAssetRatingMutationVariables>;
export const EditAssetDescriptionDocument = gql`
    mutation editAssetDescription($assetId: ID!, $description: String!) {
  editAssetDescription(assetId: $assetId, description: $description) {
    assetId
    description
  }
}
    `;
export type EditAssetDescriptionMutationFn = Apollo.MutationFunction<Types.EditAssetDescriptionMutation, Types.EditAssetDescriptionMutationVariables>;

/**
 * __useEditAssetDescriptionMutation__
 *
 * To run a mutation, you first call `useEditAssetDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssetDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssetDescriptionMutation, { data, loading, error }] = useEditAssetDescriptionMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useEditAssetDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAssetDescriptionMutation, Types.EditAssetDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAssetDescriptionMutation, Types.EditAssetDescriptionMutationVariables>(EditAssetDescriptionDocument, options);
      }
export type EditAssetDescriptionMutationHookResult = ReturnType<typeof useEditAssetDescriptionMutation>;
export type EditAssetDescriptionMutationResult = Apollo.MutationResult<Types.EditAssetDescriptionMutation>;
export type EditAssetDescriptionMutationOptions = Apollo.BaseMutationOptions<Types.EditAssetDescriptionMutation, Types.EditAssetDescriptionMutationVariables>;
export const UpdateAssetStatusDocument = gql`
    mutation updateAssetStatus($ids: [ID!]!, $status: AssetStatus!) {
  editAssetsStatus(assetsIds: $ids, status: $status) {
    assetId
    status
  }
}
    `;
export type UpdateAssetStatusMutationFn = Apollo.MutationFunction<Types.UpdateAssetStatusMutation, Types.UpdateAssetStatusMutationVariables>;

/**
 * __useUpdateAssetStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAssetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetStatusMutation, { data, loading, error }] = useUpdateAssetStatusMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAssetStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssetStatusMutation, Types.UpdateAssetStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssetStatusMutation, Types.UpdateAssetStatusMutationVariables>(UpdateAssetStatusDocument, options);
      }
export type UpdateAssetStatusMutationHookResult = ReturnType<typeof useUpdateAssetStatusMutation>;
export type UpdateAssetStatusMutationResult = Apollo.MutationResult<Types.UpdateAssetStatusMutation>;
export type UpdateAssetStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssetStatusMutation, Types.UpdateAssetStatusMutationVariables>;
export const ViewFileDocument = gql`
    query viewFile($fileId: ID!, $thumbnail: String) {
  getFile(fileId: $fileId) {
    fileId
    view(name: $thumbnail) {
      fileId
      downloadUrl {
        url
      }
    }
  }
}
    `;

/**
 * __useViewFileQuery__
 *
 * To run a query within a React component, call `useViewFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      thumbnail: // value for 'thumbnail'
 *   },
 * });
 */
export function useViewFileQuery(baseOptions: Apollo.QueryHookOptions<Types.ViewFileQuery, Types.ViewFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ViewFileQuery, Types.ViewFileQueryVariables>(ViewFileDocument, options);
      }
export function useViewFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ViewFileQuery, Types.ViewFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ViewFileQuery, Types.ViewFileQueryVariables>(ViewFileDocument, options);
        }
export type ViewFileQueryHookResult = ReturnType<typeof useViewFileQuery>;
export type ViewFileLazyQueryHookResult = ReturnType<typeof useViewFileLazyQuery>;
export type ViewFileQueryResult = Apollo.QueryResult<Types.ViewFileQuery, Types.ViewFileQueryVariables>;
export const PreviewFileDocument = gql`
    query previewFile($fileId: ID!, $thumbnail: String) {
  file: getFile(fileId: $fileId) {
    fileId
    view(name: $thumbnail) {
      fileId
      downloadUrl {
        url
      }
    }
    ...FileThumbnail
  }
}
    ${FileThumbnailFragmentDoc}`;

/**
 * __usePreviewFileQuery__
 *
 * To run a query within a React component, call `usePreviewFileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      thumbnail: // value for 'thumbnail'
 *   },
 * });
 */
export function usePreviewFileQuery(baseOptions: Apollo.QueryHookOptions<Types.PreviewFileQuery, Types.PreviewFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreviewFileQuery, Types.PreviewFileQueryVariables>(PreviewFileDocument, options);
      }
export function usePreviewFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewFileQuery, Types.PreviewFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreviewFileQuery, Types.PreviewFileQueryVariables>(PreviewFileDocument, options);
        }
export type PreviewFileQueryHookResult = ReturnType<typeof usePreviewFileQuery>;
export type PreviewFileLazyQueryHookResult = ReturnType<typeof usePreviewFileLazyQuery>;
export type PreviewFileQueryResult = Apollo.QueryResult<Types.PreviewFileQuery, Types.PreviewFileQueryVariables>;
export const FileThumbnailDocument = gql`
    query fileThumbnail($fileId: ID!) {
  getFile(fileId: $fileId) {
    status
    originalMetadata {
      common {
        type
      }
    }
    ...FileThumbnail
  }
}
    ${FileThumbnailFragmentDoc}`;

/**
 * __useFileThumbnailQuery__
 *
 * To run a query within a React component, call `useFileThumbnailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileThumbnailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileThumbnailQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileThumbnailQuery(baseOptions: Apollo.QueryHookOptions<Types.FileThumbnailQuery, Types.FileThumbnailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FileThumbnailQuery, Types.FileThumbnailQueryVariables>(FileThumbnailDocument, options);
      }
export function useFileThumbnailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FileThumbnailQuery, Types.FileThumbnailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FileThumbnailQuery, Types.FileThumbnailQueryVariables>(FileThumbnailDocument, options);
        }
export type FileThumbnailQueryHookResult = ReturnType<typeof useFileThumbnailQuery>;
export type FileThumbnailLazyQueryHookResult = ReturnType<typeof useFileThumbnailLazyQuery>;
export type FileThumbnailQueryResult = Apollo.QueryResult<Types.FileThumbnailQuery, Types.FileThumbnailQueryVariables>;
export const AssetNavigationDocument = gql`
    query assetNavigation($assetId: ID!) {
  getAssetNeighbours(assetId: $assetId) {
    next {
      assetId
    }
    previous {
      assetId
    }
  }
}
    `;

/**
 * __useAssetNavigationQuery__
 *
 * To run a query within a React component, call `useAssetNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetNavigationQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useAssetNavigationQuery(baseOptions: Apollo.QueryHookOptions<Types.AssetNavigationQuery, Types.AssetNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssetNavigationQuery, Types.AssetNavigationQueryVariables>(AssetNavigationDocument, options);
      }
export function useAssetNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssetNavigationQuery, Types.AssetNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssetNavigationQuery, Types.AssetNavigationQueryVariables>(AssetNavigationDocument, options);
        }
export type AssetNavigationQueryHookResult = ReturnType<typeof useAssetNavigationQuery>;
export type AssetNavigationLazyQueryHookResult = ReturnType<typeof useAssetNavigationLazyQuery>;
export type AssetNavigationQueryResult = Apollo.QueryResult<Types.AssetNavigationQuery, Types.AssetNavigationQueryVariables>;
export const CreateAssetVersionDocument = gql`
    mutation createAssetVersion($assetId: String, $fileId: String) {
  createAssetVersion(assetId: $assetId, fileId: $fileId) {
    assetId
    fileId
    assetVersionId
  }
}
    `;
export type CreateAssetVersionMutationFn = Apollo.MutationFunction<Types.CreateAssetVersionMutation, Types.CreateAssetVersionMutationVariables>;

/**
 * __useCreateAssetVersionMutation__
 *
 * To run a mutation, you first call `useCreateAssetVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetVersionMutation, { data, loading, error }] = useCreateAssetVersionMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCreateAssetVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssetVersionMutation, Types.CreateAssetVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssetVersionMutation, Types.CreateAssetVersionMutationVariables>(CreateAssetVersionDocument, options);
      }
export type CreateAssetVersionMutationHookResult = ReturnType<typeof useCreateAssetVersionMutation>;
export type CreateAssetVersionMutationResult = Apollo.MutationResult<Types.CreateAssetVersionMutation>;
export type CreateAssetVersionMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssetVersionMutation, Types.CreateAssetVersionMutationVariables>;
export const DeleteAssetVersionDocument = gql`
    mutation deleteAssetVersion($assetVersionId: ID) {
  deleteAssetVersion(assetVersionId: $assetVersionId) {
    assetId
    assetVersionId
  }
}
    `;
export type DeleteAssetVersionMutationFn = Apollo.MutationFunction<Types.DeleteAssetVersionMutation, Types.DeleteAssetVersionMutationVariables>;

/**
 * __useDeleteAssetVersionMutation__
 *
 * To run a mutation, you first call `useDeleteAssetVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetVersionMutation, { data, loading, error }] = useDeleteAssetVersionMutation({
 *   variables: {
 *      assetVersionId: // value for 'assetVersionId'
 *   },
 * });
 */
export function useDeleteAssetVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAssetVersionMutation, Types.DeleteAssetVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAssetVersionMutation, Types.DeleteAssetVersionMutationVariables>(DeleteAssetVersionDocument, options);
      }
export type DeleteAssetVersionMutationHookResult = ReturnType<typeof useDeleteAssetVersionMutation>;
export type DeleteAssetVersionMutationResult = Apollo.MutationResult<Types.DeleteAssetVersionMutation>;
export type DeleteAssetVersionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAssetVersionMutation, Types.DeleteAssetVersionMutationVariables>;
export const ProjectAssetsDocument = gql`
    query projectAssets($pagination: OffsetPaginationParameters, $filters: AssetLibraryFiltersInput) {
  getAssetLibrary(pagination: $pagination, filters: $filters) {
    nodes {
      assetId
      status
      concept {
        conceptId
        name
      }
      project {
        projectId
        status
      }
      mainOutcome {
        outcomeId
        serviceId
      }
      conceptId
      latestVersion {
        ...AssetThumbnail
      }
      versions {
        assetVersionId
      }
      comments {
        assetCommentId
        assetVersionId
        deletedAt
        replies {
          commentId
          deletedAt
        }
      }
    }
  }
}
    ${AssetThumbnailFragmentDoc}`;

/**
 * __useProjectAssetsQuery__
 *
 * To run a query within a React component, call `useProjectAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssetsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectAssetsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectAssetsQuery, Types.ProjectAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectAssetsQuery, Types.ProjectAssetsQueryVariables>(ProjectAssetsDocument, options);
      }
export function useProjectAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectAssetsQuery, Types.ProjectAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectAssetsQuery, Types.ProjectAssetsQueryVariables>(ProjectAssetsDocument, options);
        }
export type ProjectAssetsQueryHookResult = ReturnType<typeof useProjectAssetsQuery>;
export type ProjectAssetsLazyQueryHookResult = ReturnType<typeof useProjectAssetsLazyQuery>;
export type ProjectAssetsQueryResult = Apollo.QueryResult<Types.ProjectAssetsQuery, Types.ProjectAssetsQueryVariables>;
export const LoginDocument = gql`
    query login($email: String!, $password: String!) {
  getAuthenticationTokens(email: $email, password: $password) {
    accessToken
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<Types.LoginQuery, Types.LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoginQuery, Types.LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoginQuery, Types.LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoginQuery, Types.LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<Types.LoginQuery, Types.LoginQueryVariables>;
export const EmailExistsDocument = gql`
    query emailExists($email: String!) {
  isEmailRegistrable(email: $email)
}
    `;

/**
 * __useEmailExistsQuery__
 *
 * To run a query within a React component, call `useEmailExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailExistsQuery(baseOptions: Apollo.QueryHookOptions<Types.EmailExistsQuery, Types.EmailExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmailExistsQuery, Types.EmailExistsQueryVariables>(EmailExistsDocument, options);
      }
export function useEmailExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmailExistsQuery, Types.EmailExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmailExistsQuery, Types.EmailExistsQueryVariables>(EmailExistsDocument, options);
        }
export type EmailExistsQueryHookResult = ReturnType<typeof useEmailExistsQuery>;
export type EmailExistsLazyQueryHookResult = ReturnType<typeof useEmailExistsLazyQuery>;
export type EmailExistsQueryResult = Apollo.QueryResult<Types.EmailExistsQuery, Types.EmailExistsQueryVariables>;
export const PhoneValidDocument = gql`
    query phoneValid($phone: String!) {
  isPhoneRegistrable(phone: $phone)
}
    `;

/**
 * __usePhoneValidQuery__
 *
 * To run a query within a React component, call `usePhoneValidQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneValidQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function usePhoneValidQuery(baseOptions: Apollo.QueryHookOptions<Types.PhoneValidQuery, Types.PhoneValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneValidQuery, Types.PhoneValidQueryVariables>(PhoneValidDocument, options);
      }
export function usePhoneValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneValidQuery, Types.PhoneValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneValidQuery, Types.PhoneValidQueryVariables>(PhoneValidDocument, options);
        }
export type PhoneValidQueryHookResult = ReturnType<typeof usePhoneValidQuery>;
export type PhoneValidLazyQueryHookResult = ReturnType<typeof usePhoneValidLazyQuery>;
export type PhoneValidQueryResult = Apollo.QueryResult<Types.PhoneValidQuery, Types.PhoneValidQueryVariables>;
export const AvailableCnamesDocument = gql`
    query availableCnames($organizationName: String!, $maxAmount: Int) {
  getAvailableCnames(organizationName: $organizationName, maxAmount: $maxAmount)
}
    `;

/**
 * __useAvailableCnamesQuery__
 *
 * To run a query within a React component, call `useAvailableCnamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCnamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCnamesQuery({
 *   variables: {
 *      organizationName: // value for 'organizationName'
 *      maxAmount: // value for 'maxAmount'
 *   },
 * });
 */
export function useAvailableCnamesQuery(baseOptions: Apollo.QueryHookOptions<Types.AvailableCnamesQuery, Types.AvailableCnamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableCnamesQuery, Types.AvailableCnamesQueryVariables>(AvailableCnamesDocument, options);
      }
export function useAvailableCnamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableCnamesQuery, Types.AvailableCnamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableCnamesQuery, Types.AvailableCnamesQueryVariables>(AvailableCnamesDocument, options);
        }
export type AvailableCnamesQueryHookResult = ReturnType<typeof useAvailableCnamesQuery>;
export type AvailableCnamesLazyQueryHookResult = ReturnType<typeof useAvailableCnamesLazyQuery>;
export type AvailableCnamesQueryResult = Apollo.QueryResult<Types.AvailableCnamesQuery, Types.AvailableCnamesQueryVariables>;
export const GetSharingLinkTokenDocument = gql`
    query getSharingLinkToken($linkCode: String!) {
  getAuthenticationTokensForSharingLink(linkCode: $linkCode) {
    accessToken
  }
}
    `;

/**
 * __useGetSharingLinkTokenQuery__
 *
 * To run a query within a React component, call `useGetSharingLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharingLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharingLinkTokenQuery({
 *   variables: {
 *      linkCode: // value for 'linkCode'
 *   },
 * });
 */
export function useGetSharingLinkTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSharingLinkTokenQuery, Types.GetSharingLinkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSharingLinkTokenQuery, Types.GetSharingLinkTokenQueryVariables>(GetSharingLinkTokenDocument, options);
      }
export function useGetSharingLinkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSharingLinkTokenQuery, Types.GetSharingLinkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSharingLinkTokenQuery, Types.GetSharingLinkTokenQueryVariables>(GetSharingLinkTokenDocument, options);
        }
export type GetSharingLinkTokenQueryHookResult = ReturnType<typeof useGetSharingLinkTokenQuery>;
export type GetSharingLinkTokenLazyQueryHookResult = ReturnType<typeof useGetSharingLinkTokenLazyQuery>;
export type GetSharingLinkTokenQueryResult = Apollo.QueryResult<Types.GetSharingLinkTokenQuery, Types.GetSharingLinkTokenQueryVariables>;
export const UpvotyAuthTokenDocument = gql`
    query upvotyAuthToken {
  getUpvotyAuthenticationToken
}
    `;

/**
 * __useUpvotyAuthTokenQuery__
 *
 * To run a query within a React component, call `useUpvotyAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpvotyAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpvotyAuthTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpvotyAuthTokenQuery(baseOptions?: Apollo.QueryHookOptions<Types.UpvotyAuthTokenQuery, Types.UpvotyAuthTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UpvotyAuthTokenQuery, Types.UpvotyAuthTokenQueryVariables>(UpvotyAuthTokenDocument, options);
      }
export function useUpvotyAuthTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UpvotyAuthTokenQuery, Types.UpvotyAuthTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UpvotyAuthTokenQuery, Types.UpvotyAuthTokenQueryVariables>(UpvotyAuthTokenDocument, options);
        }
export type UpvotyAuthTokenQueryHookResult = ReturnType<typeof useUpvotyAuthTokenQuery>;
export type UpvotyAuthTokenLazyQueryHookResult = ReturnType<typeof useUpvotyAuthTokenLazyQuery>;
export type UpvotyAuthTokenQueryResult = Apollo.QueryResult<Types.UpvotyAuthTokenQuery, Types.UpvotyAuthTokenQueryVariables>;
export const UpdateOrganizationUserProfileDocument = gql`
    mutation updateOrganizationUserProfile($userId: ID!, $organizationUserProfileFields: OrganizationUserProfileInput!) {
  editOrganizationUserProfile(
    userId: $userId
    organizationUserProfileFields: $organizationUserProfileFields
  ) {
    userId
  }
}
    `;
export type UpdateOrganizationUserProfileMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationUserProfileMutation, Types.UpdateOrganizationUserProfileMutationVariables>;

/**
 * __useUpdateOrganizationUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUserProfileMutation, { data, loading, error }] = useUpdateOrganizationUserProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationUserProfileFields: // value for 'organizationUserProfileFields'
 *   },
 * });
 */
export function useUpdateOrganizationUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationUserProfileMutation, Types.UpdateOrganizationUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationUserProfileMutation, Types.UpdateOrganizationUserProfileMutationVariables>(UpdateOrganizationUserProfileDocument, options);
      }
export type UpdateOrganizationUserProfileMutationHookResult = ReturnType<typeof useUpdateOrganizationUserProfileMutation>;
export type UpdateOrganizationUserProfileMutationResult = Apollo.MutationResult<Types.UpdateOrganizationUserProfileMutation>;
export type UpdateOrganizationUserProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationUserProfileMutation, Types.UpdateOrganizationUserProfileMutationVariables>;
export const CreatePasswordRecoveryCodeDocument = gql`
    mutation createPasswordRecoveryCode($email: String!) {
  createPasswordRecoveryCode(email: $email)
}
    `;
export type CreatePasswordRecoveryCodeMutationFn = Apollo.MutationFunction<Types.CreatePasswordRecoveryCodeMutation, Types.CreatePasswordRecoveryCodeMutationVariables>;

/**
 * __useCreatePasswordRecoveryCodeMutation__
 *
 * To run a mutation, you first call `useCreatePasswordRecoveryCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordRecoveryCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordRecoveryCodeMutation, { data, loading, error }] = useCreatePasswordRecoveryCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreatePasswordRecoveryCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePasswordRecoveryCodeMutation, Types.CreatePasswordRecoveryCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePasswordRecoveryCodeMutation, Types.CreatePasswordRecoveryCodeMutationVariables>(CreatePasswordRecoveryCodeDocument, options);
      }
export type CreatePasswordRecoveryCodeMutationHookResult = ReturnType<typeof useCreatePasswordRecoveryCodeMutation>;
export type CreatePasswordRecoveryCodeMutationResult = Apollo.MutationResult<Types.CreatePasswordRecoveryCodeMutation>;
export type CreatePasswordRecoveryCodeMutationOptions = Apollo.BaseMutationOptions<Types.CreatePasswordRecoveryCodeMutation, Types.CreatePasswordRecoveryCodeMutationVariables>;
export const ChangePasswordUsingCodeDocument = gql`
    mutation changePasswordUsingCode($userId: ID!, $newPassword: String!, $code: String!) {
  changePasswordUsingCode(userId: $userId, newPassword: $newPassword, code: $code) {
    userId
    email
  }
}
    `;
export type ChangePasswordUsingCodeMutationFn = Apollo.MutationFunction<Types.ChangePasswordUsingCodeMutation, Types.ChangePasswordUsingCodeMutationVariables>;

/**
 * __useChangePasswordUsingCodeMutation__
 *
 * To run a mutation, you first call `useChangePasswordUsingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordUsingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordUsingCodeMutation, { data, loading, error }] = useChangePasswordUsingCodeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newPassword: // value for 'newPassword'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useChangePasswordUsingCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangePasswordUsingCodeMutation, Types.ChangePasswordUsingCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangePasswordUsingCodeMutation, Types.ChangePasswordUsingCodeMutationVariables>(ChangePasswordUsingCodeDocument, options);
      }
export type ChangePasswordUsingCodeMutationHookResult = ReturnType<typeof useChangePasswordUsingCodeMutation>;
export type ChangePasswordUsingCodeMutationResult = Apollo.MutationResult<Types.ChangePasswordUsingCodeMutation>;
export type ChangePasswordUsingCodeMutationOptions = Apollo.BaseMutationOptions<Types.ChangePasswordUsingCodeMutation, Types.ChangePasswordUsingCodeMutationVariables>;
export const ConfirmUserEmailDocument = gql`
    mutation confirmUserEmail($userId: ID!, $confirmationCode: String!) {
  confirmUserEmail(userId: $userId, confirmationCode: $confirmationCode) {
    userId
    email
  }
}
    `;
export type ConfirmUserEmailMutationFn = Apollo.MutationFunction<Types.ConfirmUserEmailMutation, Types.ConfirmUserEmailMutationVariables>;

/**
 * __useConfirmUserEmailMutation__
 *
 * To run a mutation, you first call `useConfirmUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserEmailMutation, { data, loading, error }] = useConfirmUserEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      confirmationCode: // value for 'confirmationCode'
 *   },
 * });
 */
export function useConfirmUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmUserEmailMutation, Types.ConfirmUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmUserEmailMutation, Types.ConfirmUserEmailMutationVariables>(ConfirmUserEmailDocument, options);
      }
export type ConfirmUserEmailMutationHookResult = ReturnType<typeof useConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationResult = Apollo.MutationResult<Types.ConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmUserEmailMutation, Types.ConfirmUserEmailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $userId: ID, $oldPassword: String) {
  changePassword(
    newPassword: $newPassword
    userId: $userId
    oldPassword: $oldPassword
  ) {
    userId
    role
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      userId: // value for 'userId'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<Types.ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;
export const ValidatePasswordRecoveryCodeDocument = gql`
    query validatePasswordRecoveryCode($code: String!) {
  validatePasswordRecoveryCode(code: $code)
}
    `;

/**
 * __useValidatePasswordRecoveryCodeQuery__
 *
 * To run a query within a React component, call `useValidatePasswordRecoveryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePasswordRecoveryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePasswordRecoveryCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidatePasswordRecoveryCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.ValidatePasswordRecoveryCodeQuery, Types.ValidatePasswordRecoveryCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ValidatePasswordRecoveryCodeQuery, Types.ValidatePasswordRecoveryCodeQueryVariables>(ValidatePasswordRecoveryCodeDocument, options);
      }
export function useValidatePasswordRecoveryCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidatePasswordRecoveryCodeQuery, Types.ValidatePasswordRecoveryCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ValidatePasswordRecoveryCodeQuery, Types.ValidatePasswordRecoveryCodeQueryVariables>(ValidatePasswordRecoveryCodeDocument, options);
        }
export type ValidatePasswordRecoveryCodeQueryHookResult = ReturnType<typeof useValidatePasswordRecoveryCodeQuery>;
export type ValidatePasswordRecoveryCodeLazyQueryHookResult = ReturnType<typeof useValidatePasswordRecoveryCodeLazyQuery>;
export type ValidatePasswordRecoveryCodeQueryResult = Apollo.QueryResult<Types.ValidatePasswordRecoveryCodeQuery, Types.ValidatePasswordRecoveryCodeQueryVariables>;
export const SendActivationEmailDocument = gql`
    mutation sendActivationEmail($userId: ID!) {
  sendActivationEmail(userId: $userId)
}
    `;
export type SendActivationEmailMutationFn = Apollo.MutationFunction<Types.SendActivationEmailMutation, Types.SendActivationEmailMutationVariables>;

/**
 * __useSendActivationEmailMutation__
 *
 * To run a mutation, you first call `useSendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendActivationEmailMutation, { data, loading, error }] = useSendActivationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendActivationEmailMutation, Types.SendActivationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendActivationEmailMutation, Types.SendActivationEmailMutationVariables>(SendActivationEmailDocument, options);
      }
export type SendActivationEmailMutationHookResult = ReturnType<typeof useSendActivationEmailMutation>;
export type SendActivationEmailMutationResult = Apollo.MutationResult<Types.SendActivationEmailMutation>;
export type SendActivationEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendActivationEmailMutation, Types.SendActivationEmailMutationVariables>;
export const ShareBrandLinkDocument = gql`
    query shareBrandLink($id: ID!) {
  getBrandProfileSharingLinks(brandProfileId: $id) {
    linkCode
    sharingLinkId
  }
}
    `;

/**
 * __useShareBrandLinkQuery__
 *
 * To run a query within a React component, call `useShareBrandLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareBrandLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareBrandLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareBrandLinkQuery(baseOptions: Apollo.QueryHookOptions<Types.ShareBrandLinkQuery, Types.ShareBrandLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ShareBrandLinkQuery, Types.ShareBrandLinkQueryVariables>(ShareBrandLinkDocument, options);
      }
export function useShareBrandLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ShareBrandLinkQuery, Types.ShareBrandLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ShareBrandLinkQuery, Types.ShareBrandLinkQueryVariables>(ShareBrandLinkDocument, options);
        }
export type ShareBrandLinkQueryHookResult = ReturnType<typeof useShareBrandLinkQuery>;
export type ShareBrandLinkLazyQueryHookResult = ReturnType<typeof useShareBrandLinkLazyQuery>;
export type ShareBrandLinkQueryResult = Apollo.QueryResult<Types.ShareBrandLinkQuery, Types.ShareBrandLinkQueryVariables>;
export const CreateShareBrandLinkDocument = gql`
    mutation createShareBrandLink($id: ID!) {
  createBrandProfileSharingLink(brandProfileId: $id, canDownloadFiles: true) {
    linkCode
    sharingLinkId
  }
}
    `;
export type CreateShareBrandLinkMutationFn = Apollo.MutationFunction<Types.CreateShareBrandLinkMutation, Types.CreateShareBrandLinkMutationVariables>;

/**
 * __useCreateShareBrandLinkMutation__
 *
 * To run a mutation, you first call `useCreateShareBrandLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareBrandLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareBrandLinkMutation, { data, loading, error }] = useCreateShareBrandLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateShareBrandLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateShareBrandLinkMutation, Types.CreateShareBrandLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateShareBrandLinkMutation, Types.CreateShareBrandLinkMutationVariables>(CreateShareBrandLinkDocument, options);
      }
export type CreateShareBrandLinkMutationHookResult = ReturnType<typeof useCreateShareBrandLinkMutation>;
export type CreateShareBrandLinkMutationResult = Apollo.MutationResult<Types.CreateShareBrandLinkMutation>;
export type CreateShareBrandLinkMutationOptions = Apollo.BaseMutationOptions<Types.CreateShareBrandLinkMutation, Types.CreateShareBrandLinkMutationVariables>;
export const BrandsDocument = gql`
    query brands($organizationId: ID, $pagination: OffsetPaginationParameters, $filters: GetBrandProfilesFilter) {
  getBrandProfiles(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      brandProfileId
      name
      organization {
        organizationId
        name
      }
      avatar {
        fileId
        ...FileThumbnail
      }
    }
    pageInfo {
      totalPages
      totalItems
    }
  }
}
    ${FileThumbnailFragmentDoc}`;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: Apollo.QueryHookOptions<Types.BrandsQuery, Types.BrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandsQuery, Types.BrandsQueryVariables>(BrandsDocument, options);
      }
export function useBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandsQuery, Types.BrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandsQuery, Types.BrandsQueryVariables>(BrandsDocument, options);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<Types.BrandsQuery, Types.BrandsQueryVariables>;
export const BrandNamesDocument = gql`
    query brandNames($organizationId: ID, $filters: GetBrandProfilesFilter, $pagination: OffsetPaginationParameters) {
  getBrandProfiles(
    organizationId: $organizationId
    filters: $filters
    pagination: $pagination
  ) {
    nodes {
      brandProfileId
      name
    }
    pageInfo {
      totalItems
    }
  }
}
    `;

/**
 * __useBrandNamesQuery__
 *
 * To run a query within a React component, call `useBrandNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandNamesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBrandNamesQuery(baseOptions?: Apollo.QueryHookOptions<Types.BrandNamesQuery, Types.BrandNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandNamesQuery, Types.BrandNamesQueryVariables>(BrandNamesDocument, options);
      }
export function useBrandNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandNamesQuery, Types.BrandNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandNamesQuery, Types.BrandNamesQueryVariables>(BrandNamesDocument, options);
        }
export type BrandNamesQueryHookResult = ReturnType<typeof useBrandNamesQuery>;
export type BrandNamesLazyQueryHookResult = ReturnType<typeof useBrandNamesLazyQuery>;
export type BrandNamesQueryResult = Apollo.QueryResult<Types.BrandNamesQuery, Types.BrandNamesQueryVariables>;
export const BrandDocument = gql`
    query brand($brandProfileId: ID!) {
  getBrandProfile(brandProfileId: $brandProfileId) {
    ...BrandDetails
  }
}
    ${BrandDetailsFragmentDoc}`;

/**
 * __useBrandQuery__
 *
 * To run a query within a React component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandQuery({
 *   variables: {
 *      brandProfileId: // value for 'brandProfileId'
 *   },
 * });
 */
export function useBrandQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandQuery, Types.BrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandQuery, Types.BrandQueryVariables>(BrandDocument, options);
      }
export function useBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandQuery, Types.BrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandQuery, Types.BrandQueryVariables>(BrandDocument, options);
        }
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandQueryResult = Apollo.QueryResult<Types.BrandQuery, Types.BrandQueryVariables>;
export const CreateBrandDocument = gql`
    mutation createBrand($brandProfileFields: CreateBrandProfileInput!, $files: BrandProfileFileIdsInput) {
  createBrandProfile(brandProfileFields: $brandProfileFields, files: $files) {
    ...BrandDetails
  }
}
    ${BrandDetailsFragmentDoc}`;
export type CreateBrandMutationFn = Apollo.MutationFunction<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      brandProfileFields: // value for 'brandProfileFields'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<Types.CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<Types.CreateBrandMutation, Types.CreateBrandMutationVariables>;
export const EditBrandDocument = gql`
    mutation editBrand($brandProfileId: ID!, $brandProfileFields: EditBrandProfileInput, $files: BrandProfileFileIdsInput) {
  editBrandProfile(
    brandProfileId: $brandProfileId
    brandProfileFields: $brandProfileFields
    files: $files
  ) {
    ...BrandDetails
  }
}
    ${BrandDetailsFragmentDoc}`;
export type EditBrandMutationFn = Apollo.MutationFunction<Types.EditBrandMutation, Types.EditBrandMutationVariables>;

/**
 * __useEditBrandMutation__
 *
 * To run a mutation, you first call `useEditBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBrandMutation, { data, loading, error }] = useEditBrandMutation({
 *   variables: {
 *      brandProfileId: // value for 'brandProfileId'
 *      brandProfileFields: // value for 'brandProfileFields'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useEditBrandMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditBrandMutation, Types.EditBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditBrandMutation, Types.EditBrandMutationVariables>(EditBrandDocument, options);
      }
export type EditBrandMutationHookResult = ReturnType<typeof useEditBrandMutation>;
export type EditBrandMutationResult = Apollo.MutationResult<Types.EditBrandMutation>;
export type EditBrandMutationOptions = Apollo.BaseMutationOptions<Types.EditBrandMutation, Types.EditBrandMutationVariables>;
export const DeleteBrandDocument = gql`
    mutation deleteBrand($brandProfileId: ID!) {
  destroyBrandProfile(brandProfileId: $brandProfileId) {
    brandProfileId
  }
}
    `;
export type DeleteBrandMutationFn = Apollo.MutationFunction<Types.DeleteBrandMutation, Types.DeleteBrandMutationVariables>;

/**
 * __useDeleteBrandMutation__
 *
 * To run a mutation, you first call `useDeleteBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandMutation, { data, loading, error }] = useDeleteBrandMutation({
 *   variables: {
 *      brandProfileId: // value for 'brandProfileId'
 *   },
 * });
 */
export function useDeleteBrandMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBrandMutation, Types.DeleteBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBrandMutation, Types.DeleteBrandMutationVariables>(DeleteBrandDocument, options);
      }
export type DeleteBrandMutationHookResult = ReturnType<typeof useDeleteBrandMutation>;
export type DeleteBrandMutationResult = Apollo.MutationResult<Types.DeleteBrandMutation>;
export type DeleteBrandMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBrandMutation, Types.DeleteBrandMutationVariables>;
export const DeleteBrandProfileAssetDocument = gql`
    mutation deleteBrandProfileAsset($fileId: ID!) {
  destroyBrandProfileAsset(fileId: $fileId) {
    fileId
  }
}
    `;
export type DeleteBrandProfileAssetMutationFn = Apollo.MutationFunction<Types.DeleteBrandProfileAssetMutation, Types.DeleteBrandProfileAssetMutationVariables>;

/**
 * __useDeleteBrandProfileAssetMutation__
 *
 * To run a mutation, you first call `useDeleteBrandProfileAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandProfileAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandProfileAssetMutation, { data, loading, error }] = useDeleteBrandProfileAssetMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteBrandProfileAssetMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBrandProfileAssetMutation, Types.DeleteBrandProfileAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBrandProfileAssetMutation, Types.DeleteBrandProfileAssetMutationVariables>(DeleteBrandProfileAssetDocument, options);
      }
export type DeleteBrandProfileAssetMutationHookResult = ReturnType<typeof useDeleteBrandProfileAssetMutation>;
export type DeleteBrandProfileAssetMutationResult = Apollo.MutationResult<Types.DeleteBrandProfileAssetMutation>;
export type DeleteBrandProfileAssetMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBrandProfileAssetMutation, Types.DeleteBrandProfileAssetMutationVariables>;
export const DeleteBrandProfileGuidelineDocument = gql`
    mutation deleteBrandProfileGuideline($fileId: ID!) {
  destroyBrandProfileGuideline(fileId: $fileId) {
    fileId
  }
}
    `;
export type DeleteBrandProfileGuidelineMutationFn = Apollo.MutationFunction<Types.DeleteBrandProfileGuidelineMutation, Types.DeleteBrandProfileGuidelineMutationVariables>;

/**
 * __useDeleteBrandProfileGuidelineMutation__
 *
 * To run a mutation, you first call `useDeleteBrandProfileGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandProfileGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandProfileGuidelineMutation, { data, loading, error }] = useDeleteBrandProfileGuidelineMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteBrandProfileGuidelineMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBrandProfileGuidelineMutation, Types.DeleteBrandProfileGuidelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBrandProfileGuidelineMutation, Types.DeleteBrandProfileGuidelineMutationVariables>(DeleteBrandProfileGuidelineDocument, options);
      }
export type DeleteBrandProfileGuidelineMutationHookResult = ReturnType<typeof useDeleteBrandProfileGuidelineMutation>;
export type DeleteBrandProfileGuidelineMutationResult = Apollo.MutationResult<Types.DeleteBrandProfileGuidelineMutation>;
export type DeleteBrandProfileGuidelineMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBrandProfileGuidelineMutation, Types.DeleteBrandProfileGuidelineMutationVariables>;
export const DeleteBrandProfileReferenceDocument = gql`
    mutation deleteBrandProfileReference($fileId: ID!) {
  destroyBrandProfileReference(fileId: $fileId) {
    fileId
  }
}
    `;
export type DeleteBrandProfileReferenceMutationFn = Apollo.MutationFunction<Types.DeleteBrandProfileReferenceMutation, Types.DeleteBrandProfileReferenceMutationVariables>;

/**
 * __useDeleteBrandProfileReferenceMutation__
 *
 * To run a mutation, you first call `useDeleteBrandProfileReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandProfileReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandProfileReferenceMutation, { data, loading, error }] = useDeleteBrandProfileReferenceMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteBrandProfileReferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBrandProfileReferenceMutation, Types.DeleteBrandProfileReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBrandProfileReferenceMutation, Types.DeleteBrandProfileReferenceMutationVariables>(DeleteBrandProfileReferenceDocument, options);
      }
export type DeleteBrandProfileReferenceMutationHookResult = ReturnType<typeof useDeleteBrandProfileReferenceMutation>;
export type DeleteBrandProfileReferenceMutationResult = Apollo.MutationResult<Types.DeleteBrandProfileReferenceMutation>;
export type DeleteBrandProfileReferenceMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBrandProfileReferenceMutation, Types.DeleteBrandProfileReferenceMutationVariables>;
export const EditBrandProfileReferenceDocument = gql`
    mutation editBrandProfileReference($fileId: ID!, $caption: String) {
  editBrandProfileReference(fileId: $fileId, caption: $caption) {
    fileId
    caption
  }
}
    `;
export type EditBrandProfileReferenceMutationFn = Apollo.MutationFunction<Types.EditBrandProfileReferenceMutation, Types.EditBrandProfileReferenceMutationVariables>;

/**
 * __useEditBrandProfileReferenceMutation__
 *
 * To run a mutation, you first call `useEditBrandProfileReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBrandProfileReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBrandProfileReferenceMutation, { data, loading, error }] = useEditBrandProfileReferenceMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      caption: // value for 'caption'
 *   },
 * });
 */
export function useEditBrandProfileReferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditBrandProfileReferenceMutation, Types.EditBrandProfileReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditBrandProfileReferenceMutation, Types.EditBrandProfileReferenceMutationVariables>(EditBrandProfileReferenceDocument, options);
      }
export type EditBrandProfileReferenceMutationHookResult = ReturnType<typeof useEditBrandProfileReferenceMutation>;
export type EditBrandProfileReferenceMutationResult = Apollo.MutationResult<Types.EditBrandProfileReferenceMutation>;
export type EditBrandProfileReferenceMutationOptions = Apollo.BaseMutationOptions<Types.EditBrandProfileReferenceMutation, Types.EditBrandProfileReferenceMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($organizationId: ID!, $projectFields: ProjectFieldsInput!, $fields: CreateProjectBriefFieldsInput!) {
  createProject(
    organizationId: $organizationId
    briefFields: $fields
    projectFields: $projectFields
  ) {
    projectId
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectFields: // value for 'projectFields'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<Types.CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($id: ID!, $fields: ProjectFieldsInput!) {
  editProject(projectId: $id, projectFields: $fields) {
    ...ProjectUpdate
  }
}
    ${ProjectUpdateFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<Types.UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>;
export const ValidateBriefDocument = gql`
    query validateBrief($projectId: ID!) {
  isProjectSubmittable(projectId: $projectId)
}
    `;

/**
 * __useValidateBriefQuery__
 *
 * To run a query within a React component, call `useValidateBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateBriefQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useValidateBriefQuery(baseOptions: Apollo.QueryHookOptions<Types.ValidateBriefQuery, Types.ValidateBriefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ValidateBriefQuery, Types.ValidateBriefQueryVariables>(ValidateBriefDocument, options);
      }
export function useValidateBriefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidateBriefQuery, Types.ValidateBriefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ValidateBriefQuery, Types.ValidateBriefQueryVariables>(ValidateBriefDocument, options);
        }
export type ValidateBriefQueryHookResult = ReturnType<typeof useValidateBriefQuery>;
export type ValidateBriefLazyQueryHookResult = ReturnType<typeof useValidateBriefLazyQuery>;
export type ValidateBriefQueryResult = Apollo.QueryResult<Types.ValidateBriefQuery, Types.ValidateBriefQueryVariables>;
export const EditProductDocument = gql`
    mutation editProduct($id: ID!, $fields: ConceptProductInput!) {
  editConceptProduct(conceptProductId: $id, fields: $fields) {
    ...ProductDetails
  }
}
    ${ProductDetailsFragmentDoc}`;
export type EditProductMutationFn = Apollo.MutationFunction<Types.EditProductMutation, Types.EditProductMutationVariables>;

/**
 * __useEditProductMutation__
 *
 * To run a mutation, you first call `useEditProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductMutation, { data, loading, error }] = useEditProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useEditProductMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProductMutation, Types.EditProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProductMutation, Types.EditProductMutationVariables>(EditProductDocument, options);
      }
export type EditProductMutationHookResult = ReturnType<typeof useEditProductMutation>;
export type EditProductMutationResult = Apollo.MutationResult<Types.EditProductMutation>;
export type EditProductMutationOptions = Apollo.BaseMutationOptions<Types.EditProductMutation, Types.EditProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: ID!) {
  destroyConceptProduct(conceptProductId: $id) {
    id
    concept {
      conceptId
      featuredProducts {
        ...ProductDetails
      }
    }
  }
}
    ${ProductDetailsFragmentDoc}`;
export type DeleteProductMutationFn = Apollo.MutationFunction<Types.DeleteProductMutation, Types.DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProductMutation, Types.DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProductMutation, Types.DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<Types.DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProductMutation, Types.DeleteProductMutationVariables>;
export const AddProductDocument = gql`
    mutation addProduct($id: ID!, $fields: ConceptProductInput) {
  createConceptProduct(conceptId: $id, fields: $fields) {
    id
    concept {
      conceptId
      id
      featuredProducts {
        ...ProductDetails
      }
    }
  }
}
    ${ProductDetailsFragmentDoc}`;
export type AddProductMutationFn = Apollo.MutationFunction<Types.AddProductMutation, Types.AddProductMutationVariables>;

/**
 * __useAddProductMutation__
 *
 * To run a mutation, you first call `useAddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAddProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAddProductMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddProductMutation, Types.AddProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddProductMutation, Types.AddProductMutationVariables>(AddProductDocument, options);
      }
export type AddProductMutationHookResult = ReturnType<typeof useAddProductMutation>;
export type AddProductMutationResult = Apollo.MutationResult<Types.AddProductMutation>;
export type AddProductMutationOptions = Apollo.BaseMutationOptions<Types.AddProductMutation, Types.AddProductMutationVariables>;
export const ServicesDocument = gql`
    query services($filter: GetServicesFilter) {
  getServices(filter: $filter) {
    name
    price
    pricingType
    scopes
    serviceId
    tiers
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ServicesQuery, Types.ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ServicesQuery, Types.ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ServicesQuery, Types.ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ServicesQuery, Types.ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<Types.ServicesQuery, Types.ServicesQueryVariables>;
export const UpdateBriefDocument = gql`
    mutation updateBrief($id: ID!, $fields: SetBriefInput!, $moodboard: SetBriefMoodboardInput, $includeSharedLinks: Boolean = false) {
  editBrief(briefId: $id, briefFields: $fields, moodboard: $moodboard) {
    ...BriefData
  }
}
    ${BriefDataFragmentDoc}`;
export type UpdateBriefMutationFn = Apollo.MutationFunction<Types.UpdateBriefMutation, Types.UpdateBriefMutationVariables>;

/**
 * __useUpdateBriefMutation__
 *
 * To run a mutation, you first call `useUpdateBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBriefMutation, { data, loading, error }] = useUpdateBriefMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *      moodboard: // value for 'moodboard'
 *      includeSharedLinks: // value for 'includeSharedLinks'
 *   },
 * });
 */
export function useUpdateBriefMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBriefMutation, Types.UpdateBriefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBriefMutation, Types.UpdateBriefMutationVariables>(UpdateBriefDocument, options);
      }
export type UpdateBriefMutationHookResult = ReturnType<typeof useUpdateBriefMutation>;
export type UpdateBriefMutationResult = Apollo.MutationResult<Types.UpdateBriefMutation>;
export type UpdateBriefMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBriefMutation, Types.UpdateBriefMutationVariables>;
export const BriefDocument = gql`
    query brief($projectId: String!, $includeSharedLinks: Boolean = false) {
  getBrief(projectId: $projectId) {
    ...BriefData
  }
}
    ${BriefDataFragmentDoc}`;

/**
 * __useBriefQuery__
 *
 * To run a query within a React component, call `useBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBriefQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      includeSharedLinks: // value for 'includeSharedLinks'
 *   },
 * });
 */
export function useBriefQuery(baseOptions: Apollo.QueryHookOptions<Types.BriefQuery, Types.BriefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BriefQuery, Types.BriefQueryVariables>(BriefDocument, options);
      }
export function useBriefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BriefQuery, Types.BriefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BriefQuery, Types.BriefQueryVariables>(BriefDocument, options);
        }
export type BriefQueryHookResult = ReturnType<typeof useBriefQuery>;
export type BriefLazyQueryHookResult = ReturnType<typeof useBriefLazyQuery>;
export type BriefQueryResult = Apollo.QueryResult<Types.BriefQuery, Types.BriefQueryVariables>;
export const CreateConceptDocument = gql`
    mutation createConcept($briefId: ID!, $conceptFields: ConceptFieldsInput!) {
  createConcept(briefId: $briefId, conceptFields: $conceptFields) {
    conceptId
    projectId
  }
}
    `;
export type CreateConceptMutationFn = Apollo.MutationFunction<Types.CreateConceptMutation, Types.CreateConceptMutationVariables>;

/**
 * __useCreateConceptMutation__
 *
 * To run a mutation, you first call `useCreateConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConceptMutation, { data, loading, error }] = useCreateConceptMutation({
 *   variables: {
 *      briefId: // value for 'briefId'
 *      conceptFields: // value for 'conceptFields'
 *   },
 * });
 */
export function useCreateConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateConceptMutation, Types.CreateConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateConceptMutation, Types.CreateConceptMutationVariables>(CreateConceptDocument, options);
      }
export type CreateConceptMutationHookResult = ReturnType<typeof useCreateConceptMutation>;
export type CreateConceptMutationResult = Apollo.MutationResult<Types.CreateConceptMutation>;
export type CreateConceptMutationOptions = Apollo.BaseMutationOptions<Types.CreateConceptMutation, Types.CreateConceptMutationVariables>;
export const EditConceptDocument = gql`
    mutation editConcept($id: ID!, $fields: ConceptFieldsInput!) {
  editConcept(conceptId: $id, conceptFields: $fields) {
    ...ConceptUpdate
  }
}
    ${ConceptUpdateFragmentDoc}`;
export type EditConceptMutationFn = Apollo.MutationFunction<Types.EditConceptMutation, Types.EditConceptMutationVariables>;

/**
 * __useEditConceptMutation__
 *
 * To run a mutation, you first call `useEditConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConceptMutation, { data, loading, error }] = useEditConceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useEditConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditConceptMutation, Types.EditConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditConceptMutation, Types.EditConceptMutationVariables>(EditConceptDocument, options);
      }
export type EditConceptMutationHookResult = ReturnType<typeof useEditConceptMutation>;
export type EditConceptMutationResult = Apollo.MutationResult<Types.EditConceptMutation>;
export type EditConceptMutationOptions = Apollo.BaseMutationOptions<Types.EditConceptMutation, Types.EditConceptMutationVariables>;
export const CreateConceptMoodboardDocument = gql`
    mutation createConceptMoodboard($conceptId: ID!, $fields: [ConceptMoodboardInput!]) {
  createConceptMoodboardItems(conceptId: $conceptId, fields: $fields) {
    ...ConceptMoodboard
    concept {
      conceptId
      moodboard {
        ...ConceptMoodboard
      }
    }
  }
}
    ${ConceptMoodboardFragmentDoc}`;
export type CreateConceptMoodboardMutationFn = Apollo.MutationFunction<Types.CreateConceptMoodboardMutation, Types.CreateConceptMoodboardMutationVariables>;

/**
 * __useCreateConceptMoodboardMutation__
 *
 * To run a mutation, you first call `useCreateConceptMoodboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConceptMoodboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConceptMoodboardMutation, { data, loading, error }] = useCreateConceptMoodboardMutation({
 *   variables: {
 *      conceptId: // value for 'conceptId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateConceptMoodboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateConceptMoodboardMutation, Types.CreateConceptMoodboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateConceptMoodboardMutation, Types.CreateConceptMoodboardMutationVariables>(CreateConceptMoodboardDocument, options);
      }
export type CreateConceptMoodboardMutationHookResult = ReturnType<typeof useCreateConceptMoodboardMutation>;
export type CreateConceptMoodboardMutationResult = Apollo.MutationResult<Types.CreateConceptMoodboardMutation>;
export type CreateConceptMoodboardMutationOptions = Apollo.BaseMutationOptions<Types.CreateConceptMoodboardMutation, Types.CreateConceptMoodboardMutationVariables>;
export const EditConceptMoodboardDocument = gql`
    mutation editConceptMoodboard($fileId: ID!, $fields: ConceptMoodboardItemFieldsInput!) {
  editConceptMoodboardItem(fileId: $fileId, fields: $fields) {
    ...ConceptMoodboard
  }
}
    ${ConceptMoodboardFragmentDoc}`;
export type EditConceptMoodboardMutationFn = Apollo.MutationFunction<Types.EditConceptMoodboardMutation, Types.EditConceptMoodboardMutationVariables>;

/**
 * __useEditConceptMoodboardMutation__
 *
 * To run a mutation, you first call `useEditConceptMoodboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConceptMoodboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConceptMoodboardMutation, { data, loading, error }] = useEditConceptMoodboardMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useEditConceptMoodboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditConceptMoodboardMutation, Types.EditConceptMoodboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditConceptMoodboardMutation, Types.EditConceptMoodboardMutationVariables>(EditConceptMoodboardDocument, options);
      }
export type EditConceptMoodboardMutationHookResult = ReturnType<typeof useEditConceptMoodboardMutation>;
export type EditConceptMoodboardMutationResult = Apollo.MutationResult<Types.EditConceptMoodboardMutation>;
export type EditConceptMoodboardMutationOptions = Apollo.BaseMutationOptions<Types.EditConceptMoodboardMutation, Types.EditConceptMoodboardMutationVariables>;
export const DeleteConceptMoodboardDocument = gql`
    mutation deleteConceptMoodboard($id: ID!) {
  destroyConceptMoodboardItem(fileId: $id) {
    id
    concept {
      conceptId
      moodboard {
        ...ConceptMoodboard
      }
    }
  }
}
    ${ConceptMoodboardFragmentDoc}`;
export type DeleteConceptMoodboardMutationFn = Apollo.MutationFunction<Types.DeleteConceptMoodboardMutation, Types.DeleteConceptMoodboardMutationVariables>;

/**
 * __useDeleteConceptMoodboardMutation__
 *
 * To run a mutation, you first call `useDeleteConceptMoodboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConceptMoodboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConceptMoodboardMutation, { data, loading, error }] = useDeleteConceptMoodboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConceptMoodboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConceptMoodboardMutation, Types.DeleteConceptMoodboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConceptMoodboardMutation, Types.DeleteConceptMoodboardMutationVariables>(DeleteConceptMoodboardDocument, options);
      }
export type DeleteConceptMoodboardMutationHookResult = ReturnType<typeof useDeleteConceptMoodboardMutation>;
export type DeleteConceptMoodboardMutationResult = Apollo.MutationResult<Types.DeleteConceptMoodboardMutation>;
export type DeleteConceptMoodboardMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConceptMoodboardMutation, Types.DeleteConceptMoodboardMutationVariables>;
export const CreateConceptVisualGuideDocument = gql`
    mutation createConceptVisualGuide($conceptId: ID!, $fields: CreateConceptVisualGuideInput) {
  createConceptVisualGuide(conceptId: $conceptId, fields: $fields) {
    id
    concept {
      conceptId
      id
      visualGuides {
        ...VisualGuide
      }
    }
  }
}
    ${VisualGuideFragmentDoc}`;
export type CreateConceptVisualGuideMutationFn = Apollo.MutationFunction<Types.CreateConceptVisualGuideMutation, Types.CreateConceptVisualGuideMutationVariables>;

/**
 * __useCreateConceptVisualGuideMutation__
 *
 * To run a mutation, you first call `useCreateConceptVisualGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConceptVisualGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConceptVisualGuideMutation, { data, loading, error }] = useCreateConceptVisualGuideMutation({
 *   variables: {
 *      conceptId: // value for 'conceptId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateConceptVisualGuideMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateConceptVisualGuideMutation, Types.CreateConceptVisualGuideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateConceptVisualGuideMutation, Types.CreateConceptVisualGuideMutationVariables>(CreateConceptVisualGuideDocument, options);
      }
export type CreateConceptVisualGuideMutationHookResult = ReturnType<typeof useCreateConceptVisualGuideMutation>;
export type CreateConceptVisualGuideMutationResult = Apollo.MutationResult<Types.CreateConceptVisualGuideMutation>;
export type CreateConceptVisualGuideMutationOptions = Apollo.BaseMutationOptions<Types.CreateConceptVisualGuideMutation, Types.CreateConceptVisualGuideMutationVariables>;
export const EditConceptVisualGuideDocument = gql`
    mutation editConceptVisualGuide($id: ID!, $fields: EditConceptVisualGuideInput!) {
  editConceptVisualGuide(conceptVisualGuideId: $id, fields: $fields) {
    ...VisualGuide
  }
}
    ${VisualGuideFragmentDoc}`;
export type EditConceptVisualGuideMutationFn = Apollo.MutationFunction<Types.EditConceptVisualGuideMutation, Types.EditConceptVisualGuideMutationVariables>;

/**
 * __useEditConceptVisualGuideMutation__
 *
 * To run a mutation, you first call `useEditConceptVisualGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConceptVisualGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConceptVisualGuideMutation, { data, loading, error }] = useEditConceptVisualGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useEditConceptVisualGuideMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditConceptVisualGuideMutation, Types.EditConceptVisualGuideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditConceptVisualGuideMutation, Types.EditConceptVisualGuideMutationVariables>(EditConceptVisualGuideDocument, options);
      }
export type EditConceptVisualGuideMutationHookResult = ReturnType<typeof useEditConceptVisualGuideMutation>;
export type EditConceptVisualGuideMutationResult = Apollo.MutationResult<Types.EditConceptVisualGuideMutation>;
export type EditConceptVisualGuideMutationOptions = Apollo.BaseMutationOptions<Types.EditConceptVisualGuideMutation, Types.EditConceptVisualGuideMutationVariables>;
export const DeleteConceptVisualGuideDocument = gql`
    mutation deleteConceptVisualGuide($id: ID!) {
  destroyConceptVisualGuide(conceptVisualGuideId: $id) {
    id
    concept {
      conceptId
      visualGuides {
        id
      }
    }
  }
}
    `;
export type DeleteConceptVisualGuideMutationFn = Apollo.MutationFunction<Types.DeleteConceptVisualGuideMutation, Types.DeleteConceptVisualGuideMutationVariables>;

/**
 * __useDeleteConceptVisualGuideMutation__
 *
 * To run a mutation, you first call `useDeleteConceptVisualGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConceptVisualGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConceptVisualGuideMutation, { data, loading, error }] = useDeleteConceptVisualGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConceptVisualGuideMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConceptVisualGuideMutation, Types.DeleteConceptVisualGuideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConceptVisualGuideMutation, Types.DeleteConceptVisualGuideMutationVariables>(DeleteConceptVisualGuideDocument, options);
      }
export type DeleteConceptVisualGuideMutationHookResult = ReturnType<typeof useDeleteConceptVisualGuideMutation>;
export type DeleteConceptVisualGuideMutationResult = Apollo.MutationResult<Types.DeleteConceptVisualGuideMutation>;
export type DeleteConceptVisualGuideMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConceptVisualGuideMutation, Types.DeleteConceptVisualGuideMutationVariables>;
export const DeleteConceptDocument = gql`
    mutation deleteConcept($conceptId: ID!) {
  destroyConcept(conceptId: $conceptId, sideEffectsProtection: {isActive: false}) {
    conceptId
    brief {
      briefId
      concepts {
        ...ConceptDetails
      }
    }
  }
}
    ${ConceptDetailsFragmentDoc}`;
export type DeleteConceptMutationFn = Apollo.MutationFunction<Types.DeleteConceptMutation, Types.DeleteConceptMutationVariables>;

/**
 * __useDeleteConceptMutation__
 *
 * To run a mutation, you first call `useDeleteConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConceptMutation, { data, loading, error }] = useDeleteConceptMutation({
 *   variables: {
 *      conceptId: // value for 'conceptId'
 *   },
 * });
 */
export function useDeleteConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConceptMutation, Types.DeleteConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConceptMutation, Types.DeleteConceptMutationVariables>(DeleteConceptDocument, options);
      }
export type DeleteConceptMutationHookResult = ReturnType<typeof useDeleteConceptMutation>;
export type DeleteConceptMutationResult = Apollo.MutationResult<Types.DeleteConceptMutation>;
export type DeleteConceptMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConceptMutation, Types.DeleteConceptMutationVariables>;
export const AddDeliverableDocument = gql`
    mutation addDeliverable($fields: CreateOutcomeFieldsInput!) {
  createOutcome(outcomeFields: $fields) {
    id
    outcomeId
    concept {
      conceptId
      outcomes {
        ...ConceptDeliverable
      }
    }
  }
}
    ${ConceptDeliverableFragmentDoc}`;
export type AddDeliverableMutationFn = Apollo.MutationFunction<Types.AddDeliverableMutation, Types.AddDeliverableMutationVariables>;

/**
 * __useAddDeliverableMutation__
 *
 * To run a mutation, you first call `useAddDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeliverableMutation, { data, loading, error }] = useAddDeliverableMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAddDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddDeliverableMutation, Types.AddDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddDeliverableMutation, Types.AddDeliverableMutationVariables>(AddDeliverableDocument, options);
      }
export type AddDeliverableMutationHookResult = ReturnType<typeof useAddDeliverableMutation>;
export type AddDeliverableMutationResult = Apollo.MutationResult<Types.AddDeliverableMutation>;
export type AddDeliverableMutationOptions = Apollo.BaseMutationOptions<Types.AddDeliverableMutation, Types.AddDeliverableMutationVariables>;
export const EditDeliverableDocument = gql`
    mutation editDeliverable($id: ID!, $fields: EditOutcomeFieldsInput!) {
  editOutcome(outcomeId: $id, outcomeFields: $fields) {
    ...ConceptDeliverable
  }
}
    ${ConceptDeliverableFragmentDoc}`;
export type EditDeliverableMutationFn = Apollo.MutationFunction<Types.EditDeliverableMutation, Types.EditDeliverableMutationVariables>;

/**
 * __useEditDeliverableMutation__
 *
 * To run a mutation, you first call `useEditDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDeliverableMutation, { data, loading, error }] = useEditDeliverableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useEditDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditDeliverableMutation, Types.EditDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditDeliverableMutation, Types.EditDeliverableMutationVariables>(EditDeliverableDocument, options);
      }
export type EditDeliverableMutationHookResult = ReturnType<typeof useEditDeliverableMutation>;
export type EditDeliverableMutationResult = Apollo.MutationResult<Types.EditDeliverableMutation>;
export type EditDeliverableMutationOptions = Apollo.BaseMutationOptions<Types.EditDeliverableMutation, Types.EditDeliverableMutationVariables>;
export const DeleteDeliverableDocument = gql`
    mutation deleteDeliverable($id: ID!) {
  destroyOutcome(outcomeId: $id) {
    id
    outcomeId
    concept {
      conceptId
      outcomes {
        ...ConceptDeliverable
      }
    }
  }
}
    ${ConceptDeliverableFragmentDoc}`;
export type DeleteDeliverableMutationFn = Apollo.MutationFunction<Types.DeleteDeliverableMutation, Types.DeleteDeliverableMutationVariables>;

/**
 * __useDeleteDeliverableMutation__
 *
 * To run a mutation, you first call `useDeleteDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliverableMutation, { data, loading, error }] = useDeleteDeliverableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDeliverableMutation, Types.DeleteDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDeliverableMutation, Types.DeleteDeliverableMutationVariables>(DeleteDeliverableDocument, options);
      }
export type DeleteDeliverableMutationHookResult = ReturnType<typeof useDeleteDeliverableMutation>;
export type DeleteDeliverableMutationResult = Apollo.MutationResult<Types.DeleteDeliverableMutation>;
export type DeleteDeliverableMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDeliverableMutation, Types.DeleteDeliverableMutationVariables>;
export const SubmitBriefDocument = gql`
    mutation submitBrief($projectId: ID!) {
  submitProject(projectId: $projectId) {
    projectId
    status
    submittedAt
    organization {
      organizationId
      credits
    }
  }
}
    `;
export type SubmitBriefMutationFn = Apollo.MutationFunction<Types.SubmitBriefMutation, Types.SubmitBriefMutationVariables>;

/**
 * __useSubmitBriefMutation__
 *
 * To run a mutation, you first call `useSubmitBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBriefMutation, { data, loading, error }] = useSubmitBriefMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSubmitBriefMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitBriefMutation, Types.SubmitBriefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitBriefMutation, Types.SubmitBriefMutationVariables>(SubmitBriefDocument, options);
      }
export type SubmitBriefMutationHookResult = ReturnType<typeof useSubmitBriefMutation>;
export type SubmitBriefMutationResult = Apollo.MutationResult<Types.SubmitBriefMutation>;
export type SubmitBriefMutationOptions = Apollo.BaseMutationOptions<Types.SubmitBriefMutation, Types.SubmitBriefMutationVariables>;
export const CreateFilesDocument = gql`
    mutation createFiles($organizationId: ID, $filesFields: [CreateFilesFieldsInput]!) {
  createFiles(organizationId: $organizationId, filesFields: $filesFields) {
    fileId
    uploadUrl {
      url
    }
  }
}
    `;
export type CreateFilesMutationFn = Apollo.MutationFunction<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>;

/**
 * __useCreateFilesMutation__
 *
 * To run a mutation, you first call `useCreateFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilesMutation, { data, loading, error }] = useCreateFilesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filesFields: // value for 'filesFields'
 *   },
 * });
 */
export function useCreateFilesMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>(CreateFilesDocument, options);
      }
export type CreateFilesMutationHookResult = ReturnType<typeof useCreateFilesMutation>;
export type CreateFilesMutationResult = Apollo.MutationResult<Types.CreateFilesMutation>;
export type CreateFilesMutationOptions = Apollo.BaseMutationOptions<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>;
export const GetFilesDocument = gql`
    query getFiles($filesIds: [ID]!) {
  getFiles(filesIds: $filesIds) {
    status
    fileId
  }
}
    `;

/**
 * __useGetFilesQuery__
 *
 * To run a query within a React component, call `useGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesQuery({
 *   variables: {
 *      filesIds: // value for 'filesIds'
 *   },
 * });
 */
export function useGetFilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFilesQuery, Types.GetFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilesQuery, Types.GetFilesQueryVariables>(GetFilesDocument, options);
      }
export function useGetFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilesQuery, Types.GetFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilesQuery, Types.GetFilesQueryVariables>(GetFilesDocument, options);
        }
export type GetFilesQueryHookResult = ReturnType<typeof useGetFilesQuery>;
export type GetFilesLazyQueryHookResult = ReturnType<typeof useGetFilesLazyQuery>;
export type GetFilesQueryResult = Apollo.QueryResult<Types.GetFilesQuery, Types.GetFilesQueryVariables>;
export const DuplicateProjectDocument = gql`
    mutation duplicateProject($projectId: ID!, $overwriteProtection: ProjectDuplicateOverwriteProtectionInput) {
  duplicateProject(
    projectId: $projectId
    overwriteProtection: $overwriteProtection
  ) {
    projectId
  }
}
    `;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<Types.DuplicateProjectMutation, Types.DuplicateProjectMutationVariables>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      overwriteProtection: // value for 'overwriteProtection'
 *   },
 * });
 */
export function useDuplicateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.DuplicateProjectMutation, Types.DuplicateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DuplicateProjectMutation, Types.DuplicateProjectMutationVariables>(DuplicateProjectDocument, options);
      }
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>;
export type DuplicateProjectMutationResult = Apollo.MutationResult<Types.DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<Types.DuplicateProjectMutation, Types.DuplicateProjectMutationVariables>;
export const DuplicateConceptDocument = gql`
    mutation duplicateConcept($conceptId: ID!) {
  duplicateConcept(conceptId: $conceptId) {
    conceptId
  }
}
    `;
export type DuplicateConceptMutationFn = Apollo.MutationFunction<Types.DuplicateConceptMutation, Types.DuplicateConceptMutationVariables>;

/**
 * __useDuplicateConceptMutation__
 *
 * To run a mutation, you first call `useDuplicateConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateConceptMutation, { data, loading, error }] = useDuplicateConceptMutation({
 *   variables: {
 *      conceptId: // value for 'conceptId'
 *   },
 * });
 */
export function useDuplicateConceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.DuplicateConceptMutation, Types.DuplicateConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DuplicateConceptMutation, Types.DuplicateConceptMutationVariables>(DuplicateConceptDocument, options);
      }
export type DuplicateConceptMutationHookResult = ReturnType<typeof useDuplicateConceptMutation>;
export type DuplicateConceptMutationResult = Apollo.MutationResult<Types.DuplicateConceptMutation>;
export type DuplicateConceptMutationOptions = Apollo.BaseMutationOptions<Types.DuplicateConceptMutation, Types.DuplicateConceptMutationVariables>;
export const BriefCommentsDocument = gql`
    query briefComments($briefId: ID!) {
  getBriefCommentsByBriefId(briefId: $briefId) {
    nodes {
      briefCommentId
      briefId
      content
      createdAt
      deletedAt
      organization {
        ...OrganizationDetails
      }
      user {
        ...UserPreview
        role
      }
      replies {
        commentId
        content
        createdAt
        deletedAt
        user {
          ...UserPreview
          role
        }
        organization {
          ...OrganizationDetails
        }
        attachments {
          ...Attachments
        }
      }
      extra
      attachments {
        ...Attachments
      }
    }
  }
}
    ${OrganizationDetailsFragmentDoc}
${UserPreviewFragmentDoc}
${AttachmentsFragmentDoc}`;

/**
 * __useBriefCommentsQuery__
 *
 * To run a query within a React component, call `useBriefCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBriefCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBriefCommentsQuery({
 *   variables: {
 *      briefId: // value for 'briefId'
 *   },
 * });
 */
export function useBriefCommentsQuery(baseOptions: Apollo.QueryHookOptions<Types.BriefCommentsQuery, Types.BriefCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BriefCommentsQuery, Types.BriefCommentsQueryVariables>(BriefCommentsDocument, options);
      }
export function useBriefCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BriefCommentsQuery, Types.BriefCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BriefCommentsQuery, Types.BriefCommentsQueryVariables>(BriefCommentsDocument, options);
        }
export type BriefCommentsQueryHookResult = ReturnType<typeof useBriefCommentsQuery>;
export type BriefCommentsLazyQueryHookResult = ReturnType<typeof useBriefCommentsLazyQuery>;
export type BriefCommentsQueryResult = Apollo.QueryResult<Types.BriefCommentsQuery, Types.BriefCommentsQueryVariables>;
export const BriefCommentDetailsDocument = gql`
    query briefCommentDetails($briefId: String) {
  getBrief(briefId: $briefId) {
    briefId
    project {
      projectId
      status
    }
    concepts {
      conceptId
    }
    organizationId
    productAcquisitionMethod
  }
}
    `;

/**
 * __useBriefCommentDetailsQuery__
 *
 * To run a query within a React component, call `useBriefCommentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBriefCommentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBriefCommentDetailsQuery({
 *   variables: {
 *      briefId: // value for 'briefId'
 *   },
 * });
 */
export function useBriefCommentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.BriefCommentDetailsQuery, Types.BriefCommentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BriefCommentDetailsQuery, Types.BriefCommentDetailsQueryVariables>(BriefCommentDetailsDocument, options);
      }
export function useBriefCommentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BriefCommentDetailsQuery, Types.BriefCommentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BriefCommentDetailsQuery, Types.BriefCommentDetailsQueryVariables>(BriefCommentDetailsDocument, options);
        }
export type BriefCommentDetailsQueryHookResult = ReturnType<typeof useBriefCommentDetailsQuery>;
export type BriefCommentDetailsLazyQueryHookResult = ReturnType<typeof useBriefCommentDetailsLazyQuery>;
export type BriefCommentDetailsQueryResult = Apollo.QueryResult<Types.BriefCommentDetailsQuery, Types.BriefCommentDetailsQueryVariables>;
export const DeleteBriefCommentDocument = gql`
    mutation deleteBriefComment($noteId: ID!) {
  destroyBriefComment(briefCommentId: $noteId) {
    briefCommentId
  }
}
    `;
export type DeleteBriefCommentMutationFn = Apollo.MutationFunction<Types.DeleteBriefCommentMutation, Types.DeleteBriefCommentMutationVariables>;

/**
 * __useDeleteBriefCommentMutation__
 *
 * To run a mutation, you first call `useDeleteBriefCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBriefCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBriefCommentMutation, { data, loading, error }] = useDeleteBriefCommentMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteBriefCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBriefCommentMutation, Types.DeleteBriefCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBriefCommentMutation, Types.DeleteBriefCommentMutationVariables>(DeleteBriefCommentDocument, options);
      }
export type DeleteBriefCommentMutationHookResult = ReturnType<typeof useDeleteBriefCommentMutation>;
export type DeleteBriefCommentMutationResult = Apollo.MutationResult<Types.DeleteBriefCommentMutation>;
export type DeleteBriefCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBriefCommentMutation, Types.DeleteBriefCommentMutationVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($comment: CreateCommentInput!) {
  createComment(comment: $comment) {
    commentId
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<Types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;
export const BriefCommentsIdsDocument = gql`
    query briefCommentsIds($briefId: ID!) {
  getBriefCommentsByBriefId(briefId: $briefId) {
    nodes {
      briefCommentId
      extra
      replies {
        commentId
      }
    }
  }
}
    `;

/**
 * __useBriefCommentsIdsQuery__
 *
 * To run a query within a React component, call `useBriefCommentsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBriefCommentsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBriefCommentsIdsQuery({
 *   variables: {
 *      briefId: // value for 'briefId'
 *   },
 * });
 */
export function useBriefCommentsIdsQuery(baseOptions: Apollo.QueryHookOptions<Types.BriefCommentsIdsQuery, Types.BriefCommentsIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BriefCommentsIdsQuery, Types.BriefCommentsIdsQueryVariables>(BriefCommentsIdsDocument, options);
      }
export function useBriefCommentsIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BriefCommentsIdsQuery, Types.BriefCommentsIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BriefCommentsIdsQuery, Types.BriefCommentsIdsQueryVariables>(BriefCommentsIdsDocument, options);
        }
export type BriefCommentsIdsQueryHookResult = ReturnType<typeof useBriefCommentsIdsQuery>;
export type BriefCommentsIdsLazyQueryHookResult = ReturnType<typeof useBriefCommentsIdsLazyQuery>;
export type BriefCommentsIdsQueryResult = Apollo.QueryResult<Types.BriefCommentsIdsQuery, Types.BriefCommentsIdsQueryVariables>;
export const ActiveProjectsUsageDocument = gql`
    query activeProjectsUsage($organizationId: ID, $filters: GetProjectsFilter) {
  getProjects(organizationId: $organizationId, filters: $filters) {
    nodes {
      ...ActiveProjectUsageDetails
    }
  }
}
    ${ActiveProjectUsageDetailsFragmentDoc}`;

/**
 * __useActiveProjectsUsageQuery__
 *
 * To run a query within a React component, call `useActiveProjectsUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveProjectsUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveProjectsUsageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useActiveProjectsUsageQuery(baseOptions?: Apollo.QueryHookOptions<Types.ActiveProjectsUsageQuery, Types.ActiveProjectsUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActiveProjectsUsageQuery, Types.ActiveProjectsUsageQueryVariables>(ActiveProjectsUsageDocument, options);
      }
export function useActiveProjectsUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActiveProjectsUsageQuery, Types.ActiveProjectsUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActiveProjectsUsageQuery, Types.ActiveProjectsUsageQueryVariables>(ActiveProjectsUsageDocument, options);
        }
export type ActiveProjectsUsageQueryHookResult = ReturnType<typeof useActiveProjectsUsageQuery>;
export type ActiveProjectsUsageLazyQueryHookResult = ReturnType<typeof useActiveProjectsUsageLazyQuery>;
export type ActiveProjectsUsageQueryResult = Apollo.QueryResult<Types.ActiveProjectsUsageQuery, Types.ActiveProjectsUsageQueryVariables>;
export const CustomersDocument = gql`
    query customers($pagination: OffsetPaginationParameters, $filters: GetOrganizationsFilter) {
  getOrganizations(pagination: $pagination, filters: $filters) {
    nodes {
      ...OrganizationDetails
      activeProjectsCount
      subscriptionPlan {
        ...OrganizationSubscriptionPlan
      }
    }
    pageInfo {
      totalPages
      totalItems
    }
  }
}
    ${OrganizationDetailsFragmentDoc}
${OrganizationSubscriptionPlanFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<Types.CustomersQuery, Types.CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomersQuery, Types.CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomersQuery, Types.CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomersQuery, Types.CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<Types.CustomersQuery, Types.CustomersQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($organizationFields: OrganizationFieldsInput!) {
  createOrganization(organizationFields: $organizationFields) {
    cname
    name
    organizationId
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<Types.CreateCustomerMutation, Types.CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      organizationFields: // value for 'organizationFields'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCustomerMutation, Types.CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCustomerMutation, Types.CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<Types.CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<Types.CreateCustomerMutation, Types.CreateCustomerMutationVariables>;
export const SetSubscriptionPlanDocument = gql`
    mutation setSubscriptionPlan($organizationId: String!, $type: SubscriptionPlanType!, $custom: CustomSubscriptionPlanInput, $credits: OrganizationCreditsFieldsInput, $expirationDate: String, $status: SubscriptionPlanStatus, $name: SubscriptionPlanName) {
  setOrganizationSubscriptionPlan(
    organizationId: $organizationId
    type: $type
    custom: $custom
    credits: $credits
    expirationDate: $expirationDate
    status: $status
    name: $name
  ) {
    ...OrganizationSubscriptionPlan
  }
}
    ${OrganizationSubscriptionPlanFragmentDoc}`;
export type SetSubscriptionPlanMutationFn = Apollo.MutationFunction<Types.SetSubscriptionPlanMutation, Types.SetSubscriptionPlanMutationVariables>;

/**
 * __useSetSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionPlanMutation, { data, loading, error }] = useSetSubscriptionPlanMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      type: // value for 'type'
 *      custom: // value for 'custom'
 *      credits: // value for 'credits'
 *      expirationDate: // value for 'expirationDate'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSubscriptionPlanMutation, Types.SetSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSubscriptionPlanMutation, Types.SetSubscriptionPlanMutationVariables>(SetSubscriptionPlanDocument, options);
      }
export type SetSubscriptionPlanMutationHookResult = ReturnType<typeof useSetSubscriptionPlanMutation>;
export type SetSubscriptionPlanMutationResult = Apollo.MutationResult<Types.SetSubscriptionPlanMutation>;
export type SetSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<Types.SetSubscriptionPlanMutation, Types.SetSubscriptionPlanMutationVariables>;
export const EditCustomerDocument = gql`
    mutation editCustomer($organizationId: ID!, $organizationFields: OrganizationFieldsInput!) {
  editOrganization(
    organizationId: $organizationId
    organizationFields: $organizationFields
  ) {
    cname
    name
    organizationId
  }
}
    `;
export type EditCustomerMutationFn = Apollo.MutationFunction<Types.EditCustomerMutation, Types.EditCustomerMutationVariables>;

/**
 * __useEditCustomerMutation__
 *
 * To run a mutation, you first call `useEditCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerMutation, { data, loading, error }] = useEditCustomerMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      organizationFields: // value for 'organizationFields'
 *   },
 * });
 */
export function useEditCustomerMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCustomerMutation, Types.EditCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCustomerMutation, Types.EditCustomerMutationVariables>(EditCustomerDocument, options);
      }
export type EditCustomerMutationHookResult = ReturnType<typeof useEditCustomerMutation>;
export type EditCustomerMutationResult = Apollo.MutationResult<Types.EditCustomerMutation>;
export type EditCustomerMutationOptions = Apollo.BaseMutationOptions<Types.EditCustomerMutation, Types.EditCustomerMutationVariables>;
export const CustomerDocument = gql`
    query customer($id: ID) {
  getOrganization(organizationId: $id) {
    ...OrganizationDetails
    createdBy {
      ...UserDetails
    }
    managedBy {
      ...OrganizationManager
    }
    subscriptionPlan {
      ...OrganizationSubscriptionPlan
    }
  }
}
    ${OrganizationDetailsFragmentDoc}
${UserDetailsFragmentDoc}
${OrganizationManagerFragmentDoc}
${OrganizationSubscriptionPlanFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: Apollo.QueryHookOptions<Types.CustomerQuery, Types.CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerQuery, Types.CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerQuery, Types.CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerQuery, Types.CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<Types.CustomerQuery, Types.CustomerQueryVariables>;
export const CustomerSubscriptionPlanDocument = gql`
    query customerSubscriptionPlan($organizationId: String!) {
  getOrganizationSubscriptionPlan(organizationId: $organizationId) {
    subscriptionPlanId
    organization {
      organizationId
      name
      managedBy {
        userId
        name
        surname
      }
      credits
      subscriptionPlan {
        subscriptionPlanId
        creditsLimit
      }
    }
    name
    status
    type
    expirationDate
    usage {
      assets
      credits
      projects
    }
  }
}
    `;

/**
 * __useCustomerSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useCustomerSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSubscriptionPlanQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCustomerSubscriptionPlanQuery(baseOptions: Apollo.QueryHookOptions<Types.CustomerSubscriptionPlanQuery, Types.CustomerSubscriptionPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerSubscriptionPlanQuery, Types.CustomerSubscriptionPlanQueryVariables>(CustomerSubscriptionPlanDocument, options);
      }
export function useCustomerSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerSubscriptionPlanQuery, Types.CustomerSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerSubscriptionPlanQuery, Types.CustomerSubscriptionPlanQueryVariables>(CustomerSubscriptionPlanDocument, options);
        }
export type CustomerSubscriptionPlanQueryHookResult = ReturnType<typeof useCustomerSubscriptionPlanQuery>;
export type CustomerSubscriptionPlanLazyQueryHookResult = ReturnType<typeof useCustomerSubscriptionPlanLazyQuery>;
export type CustomerSubscriptionPlanQueryResult = Apollo.QueryResult<Types.CustomerSubscriptionPlanQuery, Types.CustomerSubscriptionPlanQueryVariables>;
export const CustomerEventsDocument = gql`
    query customerEvents($organizationId: ID!, $eventNames: [String], $pagination: OffsetPaginationParameters) {
  getOrganizationEvents(
    organizationId: $organizationId
    eventNames: $eventNames
    pagination: $pagination
  ) {
    nodes {
      ...Events
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${EventsFragmentDoc}`;

/**
 * __useCustomerEventsQuery__
 *
 * To run a query within a React component, call `useCustomerEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerEventsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      eventNames: // value for 'eventNames'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCustomerEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.CustomerEventsQuery, Types.CustomerEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerEventsQuery, Types.CustomerEventsQueryVariables>(CustomerEventsDocument, options);
      }
export function useCustomerEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerEventsQuery, Types.CustomerEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerEventsQuery, Types.CustomerEventsQueryVariables>(CustomerEventsDocument, options);
        }
export type CustomerEventsQueryHookResult = ReturnType<typeof useCustomerEventsQuery>;
export type CustomerEventsLazyQueryHookResult = ReturnType<typeof useCustomerEventsLazyQuery>;
export type CustomerEventsQueryResult = Apollo.QueryResult<Types.CustomerEventsQuery, Types.CustomerEventsQueryVariables>;
export const CustomerNotesDocument = gql`
    query customerNotes($includeDeleted: Boolean, $organizationId: String!) {
  getOrganizationInternalNotes(
    includeDeleted: $includeDeleted
    organizationId: $organizationId
  ) {
    ...OrganizationNoteDetails
  }
}
    ${OrganizationNoteDetailsFragmentDoc}`;

/**
 * __useCustomerNotesQuery__
 *
 * To run a query within a React component, call `useCustomerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerNotesQuery({
 *   variables: {
 *      includeDeleted: // value for 'includeDeleted'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCustomerNotesQuery(baseOptions: Apollo.QueryHookOptions<Types.CustomerNotesQuery, Types.CustomerNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerNotesQuery, Types.CustomerNotesQueryVariables>(CustomerNotesDocument, options);
      }
export function useCustomerNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerNotesQuery, Types.CustomerNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerNotesQuery, Types.CustomerNotesQueryVariables>(CustomerNotesDocument, options);
        }
export type CustomerNotesQueryHookResult = ReturnType<typeof useCustomerNotesQuery>;
export type CustomerNotesLazyQueryHookResult = ReturnType<typeof useCustomerNotesLazyQuery>;
export type CustomerNotesQueryResult = Apollo.QueryResult<Types.CustomerNotesQuery, Types.CustomerNotesQueryVariables>;
export const CreateCustomerNoteDocument = gql`
    mutation createCustomerNote($id: ID!, $data: OrganizationInternalNoteFieldsInput!) {
  createOrganizationInternalNote(
    organizationId: $id
    organizationInternalNoteFields: $data
  ) {
    organizationInternalNoteId
    content
  }
}
    `;
export type CreateCustomerNoteMutationFn = Apollo.MutationFunction<Types.CreateCustomerNoteMutation, Types.CreateCustomerNoteMutationVariables>;

/**
 * __useCreateCustomerNoteMutation__
 *
 * To run a mutation, you first call `useCreateCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerNoteMutation, { data, loading, error }] = useCreateCustomerNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCustomerNoteMutation, Types.CreateCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCustomerNoteMutation, Types.CreateCustomerNoteMutationVariables>(CreateCustomerNoteDocument, options);
      }
export type CreateCustomerNoteMutationHookResult = ReturnType<typeof useCreateCustomerNoteMutation>;
export type CreateCustomerNoteMutationResult = Apollo.MutationResult<Types.CreateCustomerNoteMutation>;
export type CreateCustomerNoteMutationOptions = Apollo.BaseMutationOptions<Types.CreateCustomerNoteMutation, Types.CreateCustomerNoteMutationVariables>;
export const EditCustomerNoteDocument = gql`
    mutation editCustomerNote($id: ID!, $data: OrganizationInternalNoteFieldsInput!) {
  editOrganizationInternalNote(
    organizationInternalNoteId: $id
    organizationInternalNoteFields: $data
  ) {
    organizationInternalNoteId
    content
  }
}
    `;
export type EditCustomerNoteMutationFn = Apollo.MutationFunction<Types.EditCustomerNoteMutation, Types.EditCustomerNoteMutationVariables>;

/**
 * __useEditCustomerNoteMutation__
 *
 * To run a mutation, you first call `useEditCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerNoteMutation, { data, loading, error }] = useEditCustomerNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCustomerNoteMutation, Types.EditCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCustomerNoteMutation, Types.EditCustomerNoteMutationVariables>(EditCustomerNoteDocument, options);
      }
export type EditCustomerNoteMutationHookResult = ReturnType<typeof useEditCustomerNoteMutation>;
export type EditCustomerNoteMutationResult = Apollo.MutationResult<Types.EditCustomerNoteMutation>;
export type EditCustomerNoteMutationOptions = Apollo.BaseMutationOptions<Types.EditCustomerNoteMutation, Types.EditCustomerNoteMutationVariables>;
export const DeleteCustomerNoteDocument = gql`
    mutation deleteCustomerNote($noteId: String!) {
  destroyOrganizationInternalNote(organizationInternalNoteId: $noteId) {
    organizationInternalNoteId
  }
}
    `;
export type DeleteCustomerNoteMutationFn = Apollo.MutationFunction<Types.DeleteCustomerNoteMutation, Types.DeleteCustomerNoteMutationVariables>;

/**
 * __useDeleteCustomerNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerNoteMutation, { data, loading, error }] = useDeleteCustomerNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCustomerNoteMutation, Types.DeleteCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCustomerNoteMutation, Types.DeleteCustomerNoteMutationVariables>(DeleteCustomerNoteDocument, options);
      }
export type DeleteCustomerNoteMutationHookResult = ReturnType<typeof useDeleteCustomerNoteMutation>;
export type DeleteCustomerNoteMutationResult = Apollo.MutationResult<Types.DeleteCustomerNoteMutation>;
export type DeleteCustomerNoteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCustomerNoteMutation, Types.DeleteCustomerNoteMutationVariables>;
export const SetOrganizationManagerDocument = gql`
    mutation setOrganizationManager($organizationId: ID!, $userId: ID!) {
  setOrganizationAccountManager(organizationId: $organizationId, userId: $userId) {
    ...OrganizationManager
  }
}
    ${OrganizationManagerFragmentDoc}`;
export type SetOrganizationManagerMutationFn = Apollo.MutationFunction<Types.SetOrganizationManagerMutation, Types.SetOrganizationManagerMutationVariables>;

/**
 * __useSetOrganizationManagerMutation__
 *
 * To run a mutation, you first call `useSetOrganizationManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationManagerMutation, { data, loading, error }] = useSetOrganizationManagerMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetOrganizationManagerMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetOrganizationManagerMutation, Types.SetOrganizationManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetOrganizationManagerMutation, Types.SetOrganizationManagerMutationVariables>(SetOrganizationManagerDocument, options);
      }
export type SetOrganizationManagerMutationHookResult = ReturnType<typeof useSetOrganizationManagerMutation>;
export type SetOrganizationManagerMutationResult = Apollo.MutationResult<Types.SetOrganizationManagerMutation>;
export type SetOrganizationManagerMutationOptions = Apollo.BaseMutationOptions<Types.SetOrganizationManagerMutation, Types.SetOrganizationManagerMutationVariables>;
export const SetCustomerCreditsDocument = gql`
    mutation setCustomerCredits($organizationId: ID!, $organizationCreditsFields: OrganizationCreditsFieldsInput!) {
  setOrganizationCredits(
    organizationId: $organizationId
    organizationCreditsFields: $organizationCreditsFields
  ) {
    credits
    organizationId
  }
}
    `;
export type SetCustomerCreditsMutationFn = Apollo.MutationFunction<Types.SetCustomerCreditsMutation, Types.SetCustomerCreditsMutationVariables>;

/**
 * __useSetCustomerCreditsMutation__
 *
 * To run a mutation, you first call `useSetCustomerCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerCreditsMutation, { data, loading, error }] = useSetCustomerCreditsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      organizationCreditsFields: // value for 'organizationCreditsFields'
 *   },
 * });
 */
export function useSetCustomerCreditsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetCustomerCreditsMutation, Types.SetCustomerCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetCustomerCreditsMutation, Types.SetCustomerCreditsMutationVariables>(SetCustomerCreditsDocument, options);
      }
export type SetCustomerCreditsMutationHookResult = ReturnType<typeof useSetCustomerCreditsMutation>;
export type SetCustomerCreditsMutationResult = Apollo.MutationResult<Types.SetCustomerCreditsMutation>;
export type SetCustomerCreditsMutationOptions = Apollo.BaseMutationOptions<Types.SetCustomerCreditsMutation, Types.SetCustomerCreditsMutationVariables>;
export const CustomerUserDocument = gql`
    query customerUser($userId: ID!) {
  getUser(userId: $userId) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useCustomerUserQuery__
 *
 * To run a query within a React component, call `useCustomerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCustomerUserQuery(baseOptions: Apollo.QueryHookOptions<Types.CustomerUserQuery, Types.CustomerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerUserQuery, Types.CustomerUserQueryVariables>(CustomerUserDocument, options);
      }
export function useCustomerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerUserQuery, Types.CustomerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerUserQuery, Types.CustomerUserQueryVariables>(CustomerUserDocument, options);
        }
export type CustomerUserQueryHookResult = ReturnType<typeof useCustomerUserQuery>;
export type CustomerUserLazyQueryHookResult = ReturnType<typeof useCustomerUserLazyQuery>;
export type CustomerUserQueryResult = Apollo.QueryResult<Types.CustomerUserQuery, Types.CustomerUserQueryVariables>;
export const CreateCustomerUserDocument = gql`
    mutation createCustomerUser($organizationId: ID!, $userFields: CreateUserInput!) {
  createOrganizationOwner(
    organizationId: $organizationId
    userFields: $userFields
  ) {
    userId
  }
}
    `;
export type CreateCustomerUserMutationFn = Apollo.MutationFunction<Types.CreateCustomerUserMutation, Types.CreateCustomerUserMutationVariables>;

/**
 * __useCreateCustomerUserMutation__
 *
 * To run a mutation, you first call `useCreateCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerUserMutation, { data, loading, error }] = useCreateCustomerUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userFields: // value for 'userFields'
 *   },
 * });
 */
export function useCreateCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCustomerUserMutation, Types.CreateCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCustomerUserMutation, Types.CreateCustomerUserMutationVariables>(CreateCustomerUserDocument, options);
      }
export type CreateCustomerUserMutationHookResult = ReturnType<typeof useCreateCustomerUserMutation>;
export type CreateCustomerUserMutationResult = Apollo.MutationResult<Types.CreateCustomerUserMutation>;
export type CreateCustomerUserMutationOptions = Apollo.BaseMutationOptions<Types.CreateCustomerUserMutation, Types.CreateCustomerUserMutationVariables>;
export const EditCustomerUserDocument = gql`
    mutation editCustomerUser($userId: ID!, $userFields: EditUserInput!) {
  editUser(userId: $userId, userFields: $userFields) {
    ...OrganizationUserDetails
  }
}
    ${OrganizationUserDetailsFragmentDoc}`;
export type EditCustomerUserMutationFn = Apollo.MutationFunction<Types.EditCustomerUserMutation, Types.EditCustomerUserMutationVariables>;

/**
 * __useEditCustomerUserMutation__
 *
 * To run a mutation, you first call `useEditCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerUserMutation, { data, loading, error }] = useEditCustomerUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userFields: // value for 'userFields'
 *   },
 * });
 */
export function useEditCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCustomerUserMutation, Types.EditCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCustomerUserMutation, Types.EditCustomerUserMutationVariables>(EditCustomerUserDocument, options);
      }
export type EditCustomerUserMutationHookResult = ReturnType<typeof useEditCustomerUserMutation>;
export type EditCustomerUserMutationResult = Apollo.MutationResult<Types.EditCustomerUserMutation>;
export type EditCustomerUserMutationOptions = Apollo.BaseMutationOptions<Types.EditCustomerUserMutation, Types.EditCustomerUserMutationVariables>;
export const DeleteCustomerUserDocument = gql`
    mutation deleteCustomerUser($userId: ID!) {
  destroyOrganizationUser(userId: $userId) {
    userId
  }
}
    `;
export type DeleteCustomerUserMutationFn = Apollo.MutationFunction<Types.DeleteCustomerUserMutation, Types.DeleteCustomerUserMutationVariables>;

/**
 * __useDeleteCustomerUserMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerUserMutation, { data, loading, error }] = useDeleteCustomerUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCustomerUserMutation, Types.DeleteCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCustomerUserMutation, Types.DeleteCustomerUserMutationVariables>(DeleteCustomerUserDocument, options);
      }
export type DeleteCustomerUserMutationHookResult = ReturnType<typeof useDeleteCustomerUserMutation>;
export type DeleteCustomerUserMutationResult = Apollo.MutationResult<Types.DeleteCustomerUserMutation>;
export type DeleteCustomerUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCustomerUserMutation, Types.DeleteCustomerUserMutationVariables>;
export const OrganizationNamesDocument = gql`
    query organizationNames($pagination: OffsetPaginationParameters, $filters: GetOrganizationsFilter) {
  getOrganizations(pagination: $pagination, filters: $filters) {
    nodes {
      organizationId
      name
    }
    pageInfo {
      totalItems
    }
  }
}
    `;

/**
 * __useOrganizationNamesQuery__
 *
 * To run a query within a React component, call `useOrganizationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationNamesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganizationNamesQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrganizationNamesQuery, Types.OrganizationNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationNamesQuery, Types.OrganizationNamesQueryVariables>(OrganizationNamesDocument, options);
      }
export function useOrganizationNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationNamesQuery, Types.OrganizationNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationNamesQuery, Types.OrganizationNamesQueryVariables>(OrganizationNamesDocument, options);
        }
export type OrganizationNamesQueryHookResult = ReturnType<typeof useOrganizationNamesQuery>;
export type OrganizationNamesLazyQueryHookResult = ReturnType<typeof useOrganizationNamesLazyQuery>;
export type OrganizationNamesQueryResult = Apollo.QueryResult<Types.OrganizationNamesQuery, Types.OrganizationNamesQueryVariables>;
export const OrganizationManagedProjectsDocument = gql`
    query organizationManagedProjects($organizationId: ID, $filters: GetProjectsFilter) {
  getProjects(organizationId: $organizationId, filters: $filters) {
    nodes {
      projectId
      managedByUserId
      title
    }
  }
}
    `;

/**
 * __useOrganizationManagedProjectsQuery__
 *
 * To run a query within a React component, call `useOrganizationManagedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationManagedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationManagedProjectsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganizationManagedProjectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrganizationManagedProjectsQuery, Types.OrganizationManagedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationManagedProjectsQuery, Types.OrganizationManagedProjectsQueryVariables>(OrganizationManagedProjectsDocument, options);
      }
export function useOrganizationManagedProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationManagedProjectsQuery, Types.OrganizationManagedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationManagedProjectsQuery, Types.OrganizationManagedProjectsQueryVariables>(OrganizationManagedProjectsDocument, options);
        }
export type OrganizationManagedProjectsQueryHookResult = ReturnType<typeof useOrganizationManagedProjectsQuery>;
export type OrganizationManagedProjectsLazyQueryHookResult = ReturnType<typeof useOrganizationManagedProjectsLazyQuery>;
export type OrganizationManagedProjectsQueryResult = Apollo.QueryResult<Types.OrganizationManagedProjectsQuery, Types.OrganizationManagedProjectsQueryVariables>;
export const OrganizationCreditsHistoryDocument = gql`
    query organizationCreditsHistory($organizationId: ID!, $pagination: OffsetPaginationParameters) {
  getOrganizationCreditsHistory(
    organizationId: $organizationId
    pagination: $pagination
  ) {
    nodes {
      eventId
      details {
        type
        difference
        timestamp
        publicNotes
        internalNotes
        currentValue
        user {
          ...UserDetails
        }
      }
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useOrganizationCreditsHistoryQuery__
 *
 * To run a query within a React component, call `useOrganizationCreditsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreditsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCreditsHistoryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrganizationCreditsHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationCreditsHistoryQuery, Types.OrganizationCreditsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationCreditsHistoryQuery, Types.OrganizationCreditsHistoryQueryVariables>(OrganizationCreditsHistoryDocument, options);
      }
export function useOrganizationCreditsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationCreditsHistoryQuery, Types.OrganizationCreditsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationCreditsHistoryQuery, Types.OrganizationCreditsHistoryQueryVariables>(OrganizationCreditsHistoryDocument, options);
        }
export type OrganizationCreditsHistoryQueryHookResult = ReturnType<typeof useOrganizationCreditsHistoryQuery>;
export type OrganizationCreditsHistoryLazyQueryHookResult = ReturnType<typeof useOrganizationCreditsHistoryLazyQuery>;
export type OrganizationCreditsHistoryQueryResult = Apollo.QueryResult<Types.OrganizationCreditsHistoryQuery, Types.OrganizationCreditsHistoryQueryVariables>;
export const AssetLibraryDetailsDocument = gql`
    query assetLibraryDetails($assetId: ID!, $search: String, $filters: AssetLibraryFiltersInput, $pagination: OffsetPaginationParameters) {
  getAssetLibraryNeighbours(
    assetId: $assetId
    filters: $filters
    search: $search
    pagination: $pagination
  ) {
    current {
      ...AssetDetails
      ...AssetTags
      latestVersion {
        assetId
        assetVersionId
      }
      comments {
        assetCommentId
        ...AssetCommentDetails
      }
    }
    nextId
    previousId
  }
}
    ${AssetDetailsFragmentDoc}
${AssetTagsFragmentDoc}
${AssetCommentDetailsFragmentDoc}`;

/**
 * __useAssetLibraryDetailsQuery__
 *
 * To run a query within a React component, call `useAssetLibraryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetLibraryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetLibraryDetailsQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAssetLibraryDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.AssetLibraryDetailsQuery, Types.AssetLibraryDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssetLibraryDetailsQuery, Types.AssetLibraryDetailsQueryVariables>(AssetLibraryDetailsDocument, options);
      }
export function useAssetLibraryDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssetLibraryDetailsQuery, Types.AssetLibraryDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssetLibraryDetailsQuery, Types.AssetLibraryDetailsQueryVariables>(AssetLibraryDetailsDocument, options);
        }
export type AssetLibraryDetailsQueryHookResult = ReturnType<typeof useAssetLibraryDetailsQuery>;
export type AssetLibraryDetailsLazyQueryHookResult = ReturnType<typeof useAssetLibraryDetailsLazyQuery>;
export type AssetLibraryDetailsQueryResult = Apollo.QueryResult<Types.AssetLibraryDetailsQuery, Types.AssetLibraryDetailsQueryVariables>;
export const AssetLibraryDocument = gql`
    query assetLibrary($pagination: OffsetPaginationParameters, $filters: AssetLibraryFiltersInput, $search: String, $includeDetails: Boolean = false) {
  getAssetLibrary(pagination: $pagination, filters: $filters, search: $search) {
    nodes {
      ...AssetItem
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${AssetItemFragmentDoc}`;

/**
 * __useAssetLibraryQuery__
 *
 * To run a query within a React component, call `useAssetLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetLibraryQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *      includeDetails: // value for 'includeDetails'
 *   },
 * });
 */
export function useAssetLibraryQuery(baseOptions?: Apollo.QueryHookOptions<Types.AssetLibraryQuery, Types.AssetLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssetLibraryQuery, Types.AssetLibraryQueryVariables>(AssetLibraryDocument, options);
      }
export function useAssetLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssetLibraryQuery, Types.AssetLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssetLibraryQuery, Types.AssetLibraryQueryVariables>(AssetLibraryDocument, options);
        }
export type AssetLibraryQueryHookResult = ReturnType<typeof useAssetLibraryQuery>;
export type AssetLibraryLazyQueryHookResult = ReturnType<typeof useAssetLibraryLazyQuery>;
export type AssetLibraryQueryResult = Apollo.QueryResult<Types.AssetLibraryQuery, Types.AssetLibraryQueryVariables>;
export const TagsDocument = gql`
    query tags($pagination: OffsetPaginationParameters, $filters: GetTagsFilter) {
  getTags(pagination: $pagination, filters: $filters) {
    nodes {
      tagId
      name
      isGenerated
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<Types.TagsQuery, Types.TagsQueryVariables>;
export const AssetLibraryFilterValueDocument = gql`
    query assetLibraryFilterValue($organizationId: ID, $brandFilters: GetBrandProfilesFilter, $projectsFilters: GetProjectsFilter, $tagsFilters: GetTagsFilter, $organizationFilters: GetOrganizationsFilter, $includeBrandFilters: Boolean = false, $includeProjectFilters: Boolean = false, $includeTagFilters: Boolean = false, $includeOrganizationFilters: Boolean = false) {
  getBrandProfiles(organizationId: $organizationId, filters: $brandFilters) @include(if: $includeBrandFilters) {
    nodes {
      brandProfileId
      name
    }
  }
  getProjects(organizationId: $organizationId, filters: $projectsFilters) @include(if: $includeProjectFilters) {
    nodes {
      projectId
      title
    }
  }
  getTags(filters: $tagsFilters) @include(if: $includeTagFilters) {
    nodes {
      name
      tagId
    }
  }
  getOrganizations(filters: $organizationFilters) @include(if: $includeOrganizationFilters) {
    nodes {
      organizationId
      name
    }
  }
}
    `;

/**
 * __useAssetLibraryFilterValueQuery__
 *
 * To run a query within a React component, call `useAssetLibraryFilterValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetLibraryFilterValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetLibraryFilterValueQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      brandFilters: // value for 'brandFilters'
 *      projectsFilters: // value for 'projectsFilters'
 *      tagsFilters: // value for 'tagsFilters'
 *      organizationFilters: // value for 'organizationFilters'
 *      includeBrandFilters: // value for 'includeBrandFilters'
 *      includeProjectFilters: // value for 'includeProjectFilters'
 *      includeTagFilters: // value for 'includeTagFilters'
 *      includeOrganizationFilters: // value for 'includeOrganizationFilters'
 *   },
 * });
 */
export function useAssetLibraryFilterValueQuery(baseOptions?: Apollo.QueryHookOptions<Types.AssetLibraryFilterValueQuery, Types.AssetLibraryFilterValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssetLibraryFilterValueQuery, Types.AssetLibraryFilterValueQueryVariables>(AssetLibraryFilterValueDocument, options);
      }
export function useAssetLibraryFilterValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssetLibraryFilterValueQuery, Types.AssetLibraryFilterValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssetLibraryFilterValueQuery, Types.AssetLibraryFilterValueQueryVariables>(AssetLibraryFilterValueDocument, options);
        }
export type AssetLibraryFilterValueQueryHookResult = ReturnType<typeof useAssetLibraryFilterValueQuery>;
export type AssetLibraryFilterValueLazyQueryHookResult = ReturnType<typeof useAssetLibraryFilterValueLazyQuery>;
export type AssetLibraryFilterValueQueryResult = Apollo.QueryResult<Types.AssetLibraryFilterValueQuery, Types.AssetLibraryFilterValueQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($assetId: ID!, $name: String) {
  createAssetTag(assetId: $assetId, tagName: $name) {
    assetId
    tags {
      isGenerated
      name
      tagId
    }
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<Types.CreateTagMutation, Types.CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTagMutation, Types.CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<Types.CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation deleteTag($assetId: ID!, $tagId: ID!) {
  destroyAssetTag(assetId: $assetId, tagId: $tagId) {
    assetId
    tags {
      isGenerated
      name
      tagId
    }
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<Types.DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>;
export const GetCollectionsDocument = gql`
    query GetCollections($organizationId: ID, $pagination: GetCollectionsPaginationParameters, $filters: GetCollectionsFilters) {
  collections: getCollections(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    pageInfo {
      totalItems
      totalPages
    }
    nodes {
      ...CollectionItemNode
    }
  }
}
    ${CollectionItemNodeFragmentDoc}`;

/**
 * __useGetCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectionsQuery, Types.GetCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionsQuery, Types.GetCollectionsQueryVariables>(GetCollectionsDocument, options);
      }
export function useGetCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionsQuery, Types.GetCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionsQuery, Types.GetCollectionsQueryVariables>(GetCollectionsDocument, options);
        }
export type GetCollectionsQueryHookResult = ReturnType<typeof useGetCollectionsQuery>;
export type GetCollectionsLazyQueryHookResult = ReturnType<typeof useGetCollectionsLazyQuery>;
export type GetCollectionsQueryResult = Apollo.QueryResult<Types.GetCollectionsQuery, Types.GetCollectionsQueryVariables>;
export const GetCollectionDocument = gql`
    query GetCollection($collectionId: ID!) {
  collection: getCollection(collectionId: $collectionId) {
    collectionId
    coverAsset {
      assetId
      latestVersion {
        file {
          ...FileThumbnail
        }
        assetVersionId
      }
    }
    name
    organizationId
    createdAt
    assetsCount
    isPrivate
    createdByUserId
  }
}
    ${FileThumbnailFragmentDoc}`;

/**
 * __useGetCollectionQuery__
 *
 * To run a query within a React component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectionQuery, Types.GetCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionQuery, Types.GetCollectionQueryVariables>(GetCollectionDocument, options);
      }
export function useGetCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionQuery, Types.GetCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionQuery, Types.GetCollectionQueryVariables>(GetCollectionDocument, options);
        }
export type GetCollectionQueryHookResult = ReturnType<typeof useGetCollectionQuery>;
export type GetCollectionLazyQueryHookResult = ReturnType<typeof useGetCollectionLazyQuery>;
export type GetCollectionQueryResult = Apollo.QueryResult<Types.GetCollectionQuery, Types.GetCollectionQueryVariables>;
export const DestroyCollectionDocument = gql`
    mutation DestroyCollection($collectionId: ID!) {
  destroyCollection(collectionId: $collectionId) {
    collectionId
  }
}
    `;
export type DestroyCollectionMutationFn = Apollo.MutationFunction<Types.DestroyCollectionMutation, Types.DestroyCollectionMutationVariables>;

/**
 * __useDestroyCollectionMutation__
 *
 * To run a mutation, you first call `useDestroyCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCollectionMutation, { data, loading, error }] = useDestroyCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useDestroyCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyCollectionMutation, Types.DestroyCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyCollectionMutation, Types.DestroyCollectionMutationVariables>(DestroyCollectionDocument, options);
      }
export type DestroyCollectionMutationHookResult = ReturnType<typeof useDestroyCollectionMutation>;
export type DestroyCollectionMutationResult = Apollo.MutationResult<Types.DestroyCollectionMutation>;
export type DestroyCollectionMutationOptions = Apollo.BaseMutationOptions<Types.DestroyCollectionMutation, Types.DestroyCollectionMutationVariables>;
export const CreateCollectionDocument = gql`
    mutation createCollection($organizationId: ID, $collectionFields: CreateCollectionInput!, $assetsIds: [ID]) {
  createCollection(
    organizationId: $organizationId
    collectionFields: $collectionFields
    assetsIds: $assetsIds
  ) {
    ...CollectionItemNode
  }
}
    ${CollectionItemNodeFragmentDoc}`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<Types.CreateCollectionMutation, Types.CreateCollectionMutationVariables>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      collectionFields: // value for 'collectionFields'
 *      assetsIds: // value for 'assetsIds'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCollectionMutation, Types.CreateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCollectionMutation, Types.CreateCollectionMutationVariables>(CreateCollectionDocument, options);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<Types.CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<Types.CreateCollectionMutation, Types.CreateCollectionMutationVariables>;
export const RemoveAssetsFromCollectionDocument = gql`
    mutation removeAssetsFromCollection($collectionId: ID!, $assetsIds: [ID]!) {
  removeAssetsFromCollection(collectionId: $collectionId, assetsIds: $assetsIds) {
    collectionId
  }
}
    `;
export type RemoveAssetsFromCollectionMutationFn = Apollo.MutationFunction<Types.RemoveAssetsFromCollectionMutation, Types.RemoveAssetsFromCollectionMutationVariables>;

/**
 * __useRemoveAssetsFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveAssetsFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssetsFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssetsFromCollectionMutation, { data, loading, error }] = useRemoveAssetsFromCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      assetsIds: // value for 'assetsIds'
 *   },
 * });
 */
export function useRemoveAssetsFromCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveAssetsFromCollectionMutation, Types.RemoveAssetsFromCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveAssetsFromCollectionMutation, Types.RemoveAssetsFromCollectionMutationVariables>(RemoveAssetsFromCollectionDocument, options);
      }
export type RemoveAssetsFromCollectionMutationHookResult = ReturnType<typeof useRemoveAssetsFromCollectionMutation>;
export type RemoveAssetsFromCollectionMutationResult = Apollo.MutationResult<Types.RemoveAssetsFromCollectionMutation>;
export type RemoveAssetsFromCollectionMutationOptions = Apollo.BaseMutationOptions<Types.RemoveAssetsFromCollectionMutation, Types.RemoveAssetsFromCollectionMutationVariables>;
export const EditCollectionNameDocument = gql`
    mutation EditCollectionName($collectionId: ID!, $collectionFields: EditCollectionInput!) {
  editCollection(collectionId: $collectionId, collectionFields: $collectionFields) {
    name
    collectionId
  }
}
    `;
export type EditCollectionNameMutationFn = Apollo.MutationFunction<Types.EditCollectionNameMutation, Types.EditCollectionNameMutationVariables>;

/**
 * __useEditCollectionNameMutation__
 *
 * To run a mutation, you first call `useEditCollectionNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCollectionNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCollectionNameMutation, { data, loading, error }] = useEditCollectionNameMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      collectionFields: // value for 'collectionFields'
 *   },
 * });
 */
export function useEditCollectionNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCollectionNameMutation, Types.EditCollectionNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCollectionNameMutation, Types.EditCollectionNameMutationVariables>(EditCollectionNameDocument, options);
      }
export type EditCollectionNameMutationHookResult = ReturnType<typeof useEditCollectionNameMutation>;
export type EditCollectionNameMutationResult = Apollo.MutationResult<Types.EditCollectionNameMutation>;
export type EditCollectionNameMutationOptions = Apollo.BaseMutationOptions<Types.EditCollectionNameMutation, Types.EditCollectionNameMutationVariables>;
export const EditCollectionCoverDocument = gql`
    mutation EditCollectionCover($collectionId: ID!, $collectionFields: EditCollectionInput!) {
  editCollection(collectionId: $collectionId, collectionFields: $collectionFields) {
    coverAsset {
      assetId
      latestVersion {
        file {
          ...FileThumbnail
        }
        assetVersionId
      }
    }
    collectionId
  }
}
    ${FileThumbnailFragmentDoc}`;
export type EditCollectionCoverMutationFn = Apollo.MutationFunction<Types.EditCollectionCoverMutation, Types.EditCollectionCoverMutationVariables>;

/**
 * __useEditCollectionCoverMutation__
 *
 * To run a mutation, you first call `useEditCollectionCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCollectionCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCollectionCoverMutation, { data, loading, error }] = useEditCollectionCoverMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      collectionFields: // value for 'collectionFields'
 *   },
 * });
 */
export function useEditCollectionCoverMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCollectionCoverMutation, Types.EditCollectionCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCollectionCoverMutation, Types.EditCollectionCoverMutationVariables>(EditCollectionCoverDocument, options);
      }
export type EditCollectionCoverMutationHookResult = ReturnType<typeof useEditCollectionCoverMutation>;
export type EditCollectionCoverMutationResult = Apollo.MutationResult<Types.EditCollectionCoverMutation>;
export type EditCollectionCoverMutationOptions = Apollo.BaseMutationOptions<Types.EditCollectionCoverMutation, Types.EditCollectionCoverMutationVariables>;
export const EditCollectionTypeDocument = gql`
    mutation EditCollectionType($collectionId: ID!, $collectionFields: EditCollectionInput!) {
  editCollection(collectionId: $collectionId, collectionFields: $collectionFields) {
    collectionId
    isPrivate
  }
}
    `;
export type EditCollectionTypeMutationFn = Apollo.MutationFunction<Types.EditCollectionTypeMutation, Types.EditCollectionTypeMutationVariables>;

/**
 * __useEditCollectionTypeMutation__
 *
 * To run a mutation, you first call `useEditCollectionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCollectionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCollectionTypeMutation, { data, loading, error }] = useEditCollectionTypeMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      collectionFields: // value for 'collectionFields'
 *   },
 * });
 */
export function useEditCollectionTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCollectionTypeMutation, Types.EditCollectionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCollectionTypeMutation, Types.EditCollectionTypeMutationVariables>(EditCollectionTypeDocument, options);
      }
export type EditCollectionTypeMutationHookResult = ReturnType<typeof useEditCollectionTypeMutation>;
export type EditCollectionTypeMutationResult = Apollo.MutationResult<Types.EditCollectionTypeMutation>;
export type EditCollectionTypeMutationOptions = Apollo.BaseMutationOptions<Types.EditCollectionTypeMutation, Types.EditCollectionTypeMutationVariables>;
export const AddAssetsToCollectionsDocument = gql`
    mutation addAssetsToCollections($collectionsIds: [ID]!, $assetsIds: [ID]!) {
  addAssetsToCollections(collectionsIds: $collectionsIds, assetsIds: $assetsIds) {
    ...CollectionItemNode
  }
}
    ${CollectionItemNodeFragmentDoc}`;
export type AddAssetsToCollectionsMutationFn = Apollo.MutationFunction<Types.AddAssetsToCollectionsMutation, Types.AddAssetsToCollectionsMutationVariables>;

/**
 * __useAddAssetsToCollectionsMutation__
 *
 * To run a mutation, you first call `useAddAssetsToCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetsToCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetsToCollectionsMutation, { data, loading, error }] = useAddAssetsToCollectionsMutation({
 *   variables: {
 *      collectionsIds: // value for 'collectionsIds'
 *      assetsIds: // value for 'assetsIds'
 *   },
 * });
 */
export function useAddAssetsToCollectionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddAssetsToCollectionsMutation, Types.AddAssetsToCollectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddAssetsToCollectionsMutation, Types.AddAssetsToCollectionsMutationVariables>(AddAssetsToCollectionsDocument, options);
      }
export type AddAssetsToCollectionsMutationHookResult = ReturnType<typeof useAddAssetsToCollectionsMutation>;
export type AddAssetsToCollectionsMutationResult = Apollo.MutationResult<Types.AddAssetsToCollectionsMutation>;
export type AddAssetsToCollectionsMutationOptions = Apollo.BaseMutationOptions<Types.AddAssetsToCollectionsMutation, Types.AddAssetsToCollectionsMutationVariables>;
export const GetCollectionZipDocument = gql`
    query GetCollectionZip($collectionId: ID) {
  file: getCollectionZip(collectionId: $collectionId) {
    fileId
    download(name: "original") {
      downloadUrl {
        url
      }
    }
  }
}
    `;

/**
 * __useGetCollectionZipQuery__
 *
 * To run a query within a React component, call `useGetCollectionZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionZipQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionZipQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectionZipQuery, Types.GetCollectionZipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionZipQuery, Types.GetCollectionZipQueryVariables>(GetCollectionZipDocument, options);
      }
export function useGetCollectionZipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionZipQuery, Types.GetCollectionZipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionZipQuery, Types.GetCollectionZipQueryVariables>(GetCollectionZipDocument, options);
        }
export type GetCollectionZipQueryHookResult = ReturnType<typeof useGetCollectionZipQuery>;
export type GetCollectionZipLazyQueryHookResult = ReturnType<typeof useGetCollectionZipLazyQuery>;
export type GetCollectionZipQueryResult = Apollo.QueryResult<Types.GetCollectionZipQuery, Types.GetCollectionZipQueryVariables>;
export const GetCollectionSharingLinksDocument = gql`
    query GetCollectionSharingLinks($collectionId: ID!) {
  links: getCollectionSharingLinks(collectionId: $collectionId) {
    canDownloadAssets
    linkCode
    sharingLinkId
  }
}
    `;

/**
 * __useGetCollectionSharingLinksQuery__
 *
 * To run a query within a React component, call `useGetCollectionSharingLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionSharingLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionSharingLinksQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionSharingLinksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectionSharingLinksQuery, Types.GetCollectionSharingLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionSharingLinksQuery, Types.GetCollectionSharingLinksQueryVariables>(GetCollectionSharingLinksDocument, options);
      }
export function useGetCollectionSharingLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionSharingLinksQuery, Types.GetCollectionSharingLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionSharingLinksQuery, Types.GetCollectionSharingLinksQueryVariables>(GetCollectionSharingLinksDocument, options);
        }
export type GetCollectionSharingLinksQueryHookResult = ReturnType<typeof useGetCollectionSharingLinksQuery>;
export type GetCollectionSharingLinksLazyQueryHookResult = ReturnType<typeof useGetCollectionSharingLinksLazyQuery>;
export type GetCollectionSharingLinksQueryResult = Apollo.QueryResult<Types.GetCollectionSharingLinksQuery, Types.GetCollectionSharingLinksQueryVariables>;
export const CreateCollectionSharingLinkDocument = gql`
    mutation CreateCollectionSharingLink($collectionId: ID!, $canDownloadFiles: Boolean!) {
  createCollectionSharingLink(
    collectionId: $collectionId
    canDownloadFiles: $canDownloadFiles
  ) {
    canDownloadAssets
    sharingLinkId
    linkCode
  }
}
    `;
export type CreateCollectionSharingLinkMutationFn = Apollo.MutationFunction<Types.CreateCollectionSharingLinkMutation, Types.CreateCollectionSharingLinkMutationVariables>;

/**
 * __useCreateCollectionSharingLinkMutation__
 *
 * To run a mutation, you first call `useCreateCollectionSharingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionSharingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionSharingLinkMutation, { data, loading, error }] = useCreateCollectionSharingLinkMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      canDownloadFiles: // value for 'canDownloadFiles'
 *   },
 * });
 */
export function useCreateCollectionSharingLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCollectionSharingLinkMutation, Types.CreateCollectionSharingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCollectionSharingLinkMutation, Types.CreateCollectionSharingLinkMutationVariables>(CreateCollectionSharingLinkDocument, options);
      }
export type CreateCollectionSharingLinkMutationHookResult = ReturnType<typeof useCreateCollectionSharingLinkMutation>;
export type CreateCollectionSharingLinkMutationResult = Apollo.MutationResult<Types.CreateCollectionSharingLinkMutation>;
export type CreateCollectionSharingLinkMutationOptions = Apollo.BaseMutationOptions<Types.CreateCollectionSharingLinkMutation, Types.CreateCollectionSharingLinkMutationVariables>;
export const CreateBulkCommentsDocument = gql`
    mutation CreateBulkComments($comments: [CreateCommentInput!]) {
  createBulkComments(comments: $comments) {
    commentId
  }
}
    `;
export type CreateBulkCommentsMutationFn = Apollo.MutationFunction<Types.CreateBulkCommentsMutation, Types.CreateBulkCommentsMutationVariables>;

/**
 * __useCreateBulkCommentsMutation__
 *
 * To run a mutation, you first call `useCreateBulkCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkCommentsMutation, { data, loading, error }] = useCreateBulkCommentsMutation({
 *   variables: {
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function useCreateBulkCommentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBulkCommentsMutation, Types.CreateBulkCommentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBulkCommentsMutation, Types.CreateBulkCommentsMutationVariables>(CreateBulkCommentsDocument, options);
      }
export type CreateBulkCommentsMutationHookResult = ReturnType<typeof useCreateBulkCommentsMutation>;
export type CreateBulkCommentsMutationResult = Apollo.MutationResult<Types.CreateBulkCommentsMutation>;
export type CreateBulkCommentsMutationOptions = Apollo.BaseMutationOptions<Types.CreateBulkCommentsMutation, Types.CreateBulkCommentsMutationVariables>;
export const ProjectsOverviewDocument = gql`
    query projectsOverview($organizationId: ID, $filters: GetProjectsFilter, $pagination: GetProjectsPaginationParameters) {
  getProjects(
    organizationId: $organizationId
    filters: $filters
    pagination: $pagination
  ) {
    nodes {
      projectId
      status
      title
      submittedAt
      feedback {
        projectFeedbackId
        vote
      }
      title
      organization {
        organizationId
        name
      }
      approvedAt
      managedByUserId
      outcomes {
        outcomeId
        serviceId
        assetsCount
        amount
      }
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    `;

/**
 * __useProjectsOverviewQuery__
 *
 * To run a query within a React component, call `useProjectsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsOverviewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProjectsOverviewQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectsOverviewQuery, Types.ProjectsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsOverviewQuery, Types.ProjectsOverviewQueryVariables>(ProjectsOverviewDocument, options);
      }
export function useProjectsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsOverviewQuery, Types.ProjectsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsOverviewQuery, Types.ProjectsOverviewQueryVariables>(ProjectsOverviewDocument, options);
        }
export type ProjectsOverviewQueryHookResult = ReturnType<typeof useProjectsOverviewQuery>;
export type ProjectsOverviewLazyQueryHookResult = ReturnType<typeof useProjectsOverviewLazyQuery>;
export type ProjectsOverviewQueryResult = Apollo.QueryResult<Types.ProjectsOverviewQuery, Types.ProjectsOverviewQueryVariables>;
export const ProjectsSummaryDocument = gql`
    query projectsSummary($managedByUserId: ID) {
  getProjectsSummary(managedByUserId: $managedByUserId) {
    active
    averageRating
    completed
    draft
    unassigned
  }
}
    `;

/**
 * __useProjectsSummaryQuery__
 *
 * To run a query within a React component, call `useProjectsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsSummaryQuery({
 *   variables: {
 *      managedByUserId: // value for 'managedByUserId'
 *   },
 * });
 */
export function useProjectsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectsSummaryQuery, Types.ProjectsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsSummaryQuery, Types.ProjectsSummaryQueryVariables>(ProjectsSummaryDocument, options);
      }
export function useProjectsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsSummaryQuery, Types.ProjectsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsSummaryQuery, Types.ProjectsSummaryQueryVariables>(ProjectsSummaryDocument, options);
        }
export type ProjectsSummaryQueryHookResult = ReturnType<typeof useProjectsSummaryQuery>;
export type ProjectsSummaryLazyQueryHookResult = ReturnType<typeof useProjectsSummaryLazyQuery>;
export type ProjectsSummaryQueryResult = Apollo.QueryResult<Types.ProjectsSummaryQuery, Types.ProjectsSummaryQueryVariables>;
export const ProjectNotesDocument = gql`
    query projectNotes($includeDeleted: Boolean, $projectId: String!) {
  getProjectInternalNotes(includeDeleted: $includeDeleted, projectId: $projectId) {
    ...ProjectNoteDetails
  }
}
    ${ProjectNoteDetailsFragmentDoc}`;

/**
 * __useProjectNotesQuery__
 *
 * To run a query within a React component, call `useProjectNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNotesQuery({
 *   variables: {
 *      includeDeleted: // value for 'includeDeleted'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectNotesQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectNotesQuery, Types.ProjectNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectNotesQuery, Types.ProjectNotesQueryVariables>(ProjectNotesDocument, options);
      }
export function useProjectNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectNotesQuery, Types.ProjectNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectNotesQuery, Types.ProjectNotesQueryVariables>(ProjectNotesDocument, options);
        }
export type ProjectNotesQueryHookResult = ReturnType<typeof useProjectNotesQuery>;
export type ProjectNotesLazyQueryHookResult = ReturnType<typeof useProjectNotesLazyQuery>;
export type ProjectNotesQueryResult = Apollo.QueryResult<Types.ProjectNotesQuery, Types.ProjectNotesQueryVariables>;
export const CreateProjectNoteDocument = gql`
    mutation createProjectNote($id: ID!, $data: ProjectInternalNoteFieldsInput!) {
  createProjectInternalNote(projectId: $id, projectInternalNoteFields: $data) {
    projectInternalNoteId
    content
  }
}
    `;
export type CreateProjectNoteMutationFn = Apollo.MutationFunction<Types.CreateProjectNoteMutation, Types.CreateProjectNoteMutationVariables>;

/**
 * __useCreateProjectNoteMutation__
 *
 * To run a mutation, you first call `useCreateProjectNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectNoteMutation, { data, loading, error }] = useCreateProjectNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectNoteMutation, Types.CreateProjectNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectNoteMutation, Types.CreateProjectNoteMutationVariables>(CreateProjectNoteDocument, options);
      }
export type CreateProjectNoteMutationHookResult = ReturnType<typeof useCreateProjectNoteMutation>;
export type CreateProjectNoteMutationResult = Apollo.MutationResult<Types.CreateProjectNoteMutation>;
export type CreateProjectNoteMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectNoteMutation, Types.CreateProjectNoteMutationVariables>;
export const EditProjectNoteDocument = gql`
    mutation editProjectNote($id: ID!, $data: ProjectInternalNoteFieldsInput!) {
  editProjectInternalNote(
    projectInternalNoteId: $id
    projectInternalNoteFields: $data
  ) {
    projectInternalNoteId
    content
  }
}
    `;
export type EditProjectNoteMutationFn = Apollo.MutationFunction<Types.EditProjectNoteMutation, Types.EditProjectNoteMutationVariables>;

/**
 * __useEditProjectNoteMutation__
 *
 * To run a mutation, you first call `useEditProjectNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectNoteMutation, { data, loading, error }] = useEditProjectNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditProjectNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectNoteMutation, Types.EditProjectNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectNoteMutation, Types.EditProjectNoteMutationVariables>(EditProjectNoteDocument, options);
      }
export type EditProjectNoteMutationHookResult = ReturnType<typeof useEditProjectNoteMutation>;
export type EditProjectNoteMutationResult = Apollo.MutationResult<Types.EditProjectNoteMutation>;
export type EditProjectNoteMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectNoteMutation, Types.EditProjectNoteMutationVariables>;
export const DeleteProjectNoteDocument = gql`
    mutation deleteProjectNote($noteId: ID!) {
  destroyProjectInternalNote(projectInternalNoteId: $noteId) {
    projectInternalNoteId
  }
}
    `;
export type DeleteProjectNoteMutationFn = Apollo.MutationFunction<Types.DeleteProjectNoteMutation, Types.DeleteProjectNoteMutationVariables>;

/**
 * __useDeleteProjectNoteMutation__
 *
 * To run a mutation, you first call `useDeleteProjectNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectNoteMutation, { data, loading, error }] = useDeleteProjectNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteProjectNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectNoteMutation, Types.DeleteProjectNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectNoteMutation, Types.DeleteProjectNoteMutationVariables>(DeleteProjectNoteDocument, options);
      }
export type DeleteProjectNoteMutationHookResult = ReturnType<typeof useDeleteProjectNoteMutation>;
export type DeleteProjectNoteMutationResult = Apollo.MutationResult<Types.DeleteProjectNoteMutation>;
export type DeleteProjectNoteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectNoteMutation, Types.DeleteProjectNoteMutationVariables>;
export const ProjectCommentsDocument = gql`
    query projectComments($includeAsset: Boolean = false, $filters: GetAssetCommentsFilters, $pagination: OffsetPaginationParameters) {
  getAssetComments(filters: $filters, pagination: $pagination) {
    nodes {
      asset @include(if: $includeAsset) {
        assetId
        versions {
          ...AssetThumbnail
        }
        mainOutcome {
          outcomeId
          serviceId
        }
      }
      ...AssetCommentDetails
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${AssetThumbnailFragmentDoc}
${AssetCommentDetailsFragmentDoc}`;

/**
 * __useProjectCommentsQuery__
 *
 * To run a query within a React component, call `useProjectCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCommentsQuery({
 *   variables: {
 *      includeAsset: // value for 'includeAsset'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProjectCommentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectCommentsQuery, Types.ProjectCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectCommentsQuery, Types.ProjectCommentsQueryVariables>(ProjectCommentsDocument, options);
      }
export function useProjectCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectCommentsQuery, Types.ProjectCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectCommentsQuery, Types.ProjectCommentsQueryVariables>(ProjectCommentsDocument, options);
        }
export type ProjectCommentsQueryHookResult = ReturnType<typeof useProjectCommentsQuery>;
export type ProjectCommentsLazyQueryHookResult = ReturnType<typeof useProjectCommentsLazyQuery>;
export type ProjectCommentsQueryResult = Apollo.QueryResult<Types.ProjectCommentsQuery, Types.ProjectCommentsQueryVariables>;
export const SetProjectCoverDocument = gql`
    mutation setProjectCover($projectId: String!, $projectCoverImageFields: SetProjectCoverImageInput!) {
  setProjectCoverImage(
    projectId: $projectId
    projectCoverImageFields: $projectCoverImageFields
  ) {
    projectCoverImageId
    fileId
    project {
      projectId
      coverImage {
        ...CoverImage
      }
    }
  }
}
    ${CoverImageFragmentDoc}`;
export type SetProjectCoverMutationFn = Apollo.MutationFunction<Types.SetProjectCoverMutation, Types.SetProjectCoverMutationVariables>;

/**
 * __useSetProjectCoverMutation__
 *
 * To run a mutation, you first call `useSetProjectCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectCoverMutation, { data, loading, error }] = useSetProjectCoverMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectCoverImageFields: // value for 'projectCoverImageFields'
 *   },
 * });
 */
export function useSetProjectCoverMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetProjectCoverMutation, Types.SetProjectCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetProjectCoverMutation, Types.SetProjectCoverMutationVariables>(SetProjectCoverDocument, options);
      }
export type SetProjectCoverMutationHookResult = ReturnType<typeof useSetProjectCoverMutation>;
export type SetProjectCoverMutationResult = Apollo.MutationResult<Types.SetProjectCoverMutation>;
export type SetProjectCoverMutationOptions = Apollo.BaseMutationOptions<Types.SetProjectCoverMutation, Types.SetProjectCoverMutationVariables>;
export const RemoveProjectCoverDocument = gql`
    mutation removeProjectCover($projectCoverImageId: String!) {
  destroyProjectCoverImage(projectCoverImageId: $projectCoverImageId) {
    fileId
    projectCoverImageId
    project {
      projectId
      coverImage {
        ...CoverImage
      }
    }
  }
}
    ${CoverImageFragmentDoc}`;
export type RemoveProjectCoverMutationFn = Apollo.MutationFunction<Types.RemoveProjectCoverMutation, Types.RemoveProjectCoverMutationVariables>;

/**
 * __useRemoveProjectCoverMutation__
 *
 * To run a mutation, you first call `useRemoveProjectCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectCoverMutation, { data, loading, error }] = useRemoveProjectCoverMutation({
 *   variables: {
 *      projectCoverImageId: // value for 'projectCoverImageId'
 *   },
 * });
 */
export function useRemoveProjectCoverMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveProjectCoverMutation, Types.RemoveProjectCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveProjectCoverMutation, Types.RemoveProjectCoverMutationVariables>(RemoveProjectCoverDocument, options);
      }
export type RemoveProjectCoverMutationHookResult = ReturnType<typeof useRemoveProjectCoverMutation>;
export type RemoveProjectCoverMutationResult = Apollo.MutationResult<Types.RemoveProjectCoverMutation>;
export type RemoveProjectCoverMutationOptions = Apollo.BaseMutationOptions<Types.RemoveProjectCoverMutation, Types.RemoveProjectCoverMutationVariables>;
export const CreateAssetsDocument = gql`
    mutation createAssets($projectId: ID!, $assetsFields: [CreateAssetFieldsInput!]!) {
  createAssets(projectId: $projectId, assetsFields: $assetsFields) {
    assetId
  }
}
    `;
export type CreateAssetsMutationFn = Apollo.MutationFunction<Types.CreateAssetsMutation, Types.CreateAssetsMutationVariables>;

/**
 * __useCreateAssetsMutation__
 *
 * To run a mutation, you first call `useCreateAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetsMutation, { data, loading, error }] = useCreateAssetsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      assetsFields: // value for 'assetsFields'
 *   },
 * });
 */
export function useCreateAssetsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssetsMutation, Types.CreateAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssetsMutation, Types.CreateAssetsMutationVariables>(CreateAssetsDocument, options);
      }
export type CreateAssetsMutationHookResult = ReturnType<typeof useCreateAssetsMutation>;
export type CreateAssetsMutationResult = Apollo.MutationResult<Types.CreateAssetsMutation>;
export type CreateAssetsMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssetsMutation, Types.CreateAssetsMutationVariables>;
export const EditAssetsMainOutcomeDocument = gql`
    mutation editAssetsMainOutcome($assetsMainOutcomeFields: [EditAssetsMainOutcomeFieldsInput]) {
  editAssetsMainOutcome(assetsMainOutcomeFields: $assetsMainOutcomeFields) {
    assetId
    mainOutcomeId
    mainOutcome {
      outcomeId
      amount
      assetsCount
    }
    isDelivered
    isQAValid
  }
}
    `;
export type EditAssetsMainOutcomeMutationFn = Apollo.MutationFunction<Types.EditAssetsMainOutcomeMutation, Types.EditAssetsMainOutcomeMutationVariables>;

/**
 * __useEditAssetsMainOutcomeMutation__
 *
 * To run a mutation, you first call `useEditAssetsMainOutcomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssetsMainOutcomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssetsMainOutcomeMutation, { data, loading, error }] = useEditAssetsMainOutcomeMutation({
 *   variables: {
 *      assetsMainOutcomeFields: // value for 'assetsMainOutcomeFields'
 *   },
 * });
 */
export function useEditAssetsMainOutcomeMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAssetsMainOutcomeMutation, Types.EditAssetsMainOutcomeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAssetsMainOutcomeMutation, Types.EditAssetsMainOutcomeMutationVariables>(EditAssetsMainOutcomeDocument, options);
      }
export type EditAssetsMainOutcomeMutationHookResult = ReturnType<typeof useEditAssetsMainOutcomeMutation>;
export type EditAssetsMainOutcomeMutationResult = Apollo.MutationResult<Types.EditAssetsMainOutcomeMutation>;
export type EditAssetsMainOutcomeMutationOptions = Apollo.BaseMutationOptions<Types.EditAssetsMainOutcomeMutation, Types.EditAssetsMainOutcomeMutationVariables>;
export const DestroyAssetsDocument = gql`
    mutation destroyAssets($assetsIds: [ID!]!) {
  destroyAssets(assetsIds: $assetsIds) {
    assetId
  }
}
    `;
export type DestroyAssetsMutationFn = Apollo.MutationFunction<Types.DestroyAssetsMutation, Types.DestroyAssetsMutationVariables>;

/**
 * __useDestroyAssetsMutation__
 *
 * To run a mutation, you first call `useDestroyAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAssetsMutation, { data, loading, error }] = useDestroyAssetsMutation({
 *   variables: {
 *      assetsIds: // value for 'assetsIds'
 *   },
 * });
 */
export function useDestroyAssetsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DestroyAssetsMutation, Types.DestroyAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DestroyAssetsMutation, Types.DestroyAssetsMutationVariables>(DestroyAssetsDocument, options);
      }
export type DestroyAssetsMutationHookResult = ReturnType<typeof useDestroyAssetsMutation>;
export type DestroyAssetsMutationResult = Apollo.MutationResult<Types.DestroyAssetsMutation>;
export type DestroyAssetsMutationOptions = Apollo.BaseMutationOptions<Types.DestroyAssetsMutation, Types.DestroyAssetsMutationVariables>;
export const DeleteAssetCommentDocument = gql`
    mutation deleteAssetComment($noteId: String!) {
  destroyAssetComment(assetCommentId: $noteId) {
    assetCommentId
  }
}
    `;
export type DeleteAssetCommentMutationFn = Apollo.MutationFunction<Types.DeleteAssetCommentMutation, Types.DeleteAssetCommentMutationVariables>;

/**
 * __useDeleteAssetCommentMutation__
 *
 * To run a mutation, you first call `useDeleteAssetCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetCommentMutation, { data, loading, error }] = useDeleteAssetCommentMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteAssetCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAssetCommentMutation, Types.DeleteAssetCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAssetCommentMutation, Types.DeleteAssetCommentMutationVariables>(DeleteAssetCommentDocument, options);
      }
export type DeleteAssetCommentMutationHookResult = ReturnType<typeof useDeleteAssetCommentMutation>;
export type DeleteAssetCommentMutationResult = Apollo.MutationResult<Types.DeleteAssetCommentMutation>;
export type DeleteAssetCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAssetCommentMutation, Types.DeleteAssetCommentMutationVariables>;
export const SetProjectAccountManagerDocument = gql`
    mutation setProjectAccountManager($projectId: ID!, $userId: ID!) {
  setProjectAccountManager(projectId: $projectId, userId: $userId) {
    userId
  }
}
    `;
export type SetProjectAccountManagerMutationFn = Apollo.MutationFunction<Types.SetProjectAccountManagerMutation, Types.SetProjectAccountManagerMutationVariables>;

/**
 * __useSetProjectAccountManagerMutation__
 *
 * To run a mutation, you first call `useSetProjectAccountManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectAccountManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectAccountManagerMutation, { data, loading, error }] = useSetProjectAccountManagerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetProjectAccountManagerMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetProjectAccountManagerMutation, Types.SetProjectAccountManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetProjectAccountManagerMutation, Types.SetProjectAccountManagerMutationVariables>(SetProjectAccountManagerDocument, options);
      }
export type SetProjectAccountManagerMutationHookResult = ReturnType<typeof useSetProjectAccountManagerMutation>;
export type SetProjectAccountManagerMutationResult = Apollo.MutationResult<Types.SetProjectAccountManagerMutation>;
export type SetProjectAccountManagerMutationOptions = Apollo.BaseMutationOptions<Types.SetProjectAccountManagerMutation, Types.SetProjectAccountManagerMutationVariables>;
export const UnsetProjectAccountManagerDocument = gql`
    mutation unsetProjectAccountManager($projectId: ID!) {
  unsetProjectAccountManager(projectId: $projectId) {
    userId
  }
}
    `;
export type UnsetProjectAccountManagerMutationFn = Apollo.MutationFunction<Types.UnsetProjectAccountManagerMutation, Types.UnsetProjectAccountManagerMutationVariables>;

/**
 * __useUnsetProjectAccountManagerMutation__
 *
 * To run a mutation, you first call `useUnsetProjectAccountManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetProjectAccountManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetProjectAccountManagerMutation, { data, loading, error }] = useUnsetProjectAccountManagerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnsetProjectAccountManagerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnsetProjectAccountManagerMutation, Types.UnsetProjectAccountManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnsetProjectAccountManagerMutation, Types.UnsetProjectAccountManagerMutationVariables>(UnsetProjectAccountManagerDocument, options);
      }
export type UnsetProjectAccountManagerMutationHookResult = ReturnType<typeof useUnsetProjectAccountManagerMutation>;
export type UnsetProjectAccountManagerMutationResult = Apollo.MutationResult<Types.UnsetProjectAccountManagerMutation>;
export type UnsetProjectAccountManagerMutationOptions = Apollo.BaseMutationOptions<Types.UnsetProjectAccountManagerMutation, Types.UnsetProjectAccountManagerMutationVariables>;
export const ProjectsDocument = gql`
    query projects($organizationId: ID, $filters: GetProjectsFilter, $pagination: GetProjectsPaginationParameters) {
  getProjects(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    nodes {
      ...ProjectNode
    }
    pageInfo {
      totalPages
      totalItems
    }
  }
}
    ${ProjectNodeFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<Types.ProjectsQuery, Types.ProjectsQueryVariables>;
export const ProjectDocument = gql`
    query project($projectId: ID!, $includeAssets: Boolean = false, $includeBrief: Boolean = false, $includeOutcomes: Boolean = false, $includeBriefOutcomes: Boolean = false, $includeCompletion: Boolean = false, $includeFinalised: Boolean = false, $includeUnderRevision: Boolean = false) {
  getProject(projectId: $projectId) {
    ...ProjectDetails
    organization {
      managedByUserId
      ...OrganizationName
    }
    isCompletable @include(if: $includeCompletion)
    forcedPartialCompletionReason @include(if: $includeCompletion)
    completedAt @include(if: $includeCompletion)
    partiallyCompletedAt @include(if: $includeCompletion)
    partiallyCompletedBy @include(if: $includeCompletion) {
      name
      userId
      surname
    }
    completedBy @include(if: $includeCompletion) {
      name
      userId
      surname
    }
    finalizedAt @include(if: $includeFinalised)
    finalizedBy @include(if: $includeFinalised) {
      name
      userId
      surname
    }
    underRevisionAt @include(if: $includeUnderRevision)
    underRevisionBy @include(if: $includeUnderRevision) {
      name
      userId
      surname
    }
    assets2 @include(if: $includeAssets) {
      assetId
      status
      mainOutcomeId
      conceptId
      latestVersion {
        ...AssetThumbnail
      }
      versions {
        assetVersionId
      }
      ratings {
        vote
        user {
          ...UserDetails
        }
      }
      mainOutcome {
        outcomeId
        serviceId
      }
    }
    estimatedCost {
      ...CreditsSummary
    }
    coverImage {
      ...CoverImage
    }
    brief @include(if: $includeBrief) {
      ...BriefDetails
      concepts {
        ...ConceptDetails
        estimatedCost {
          total
        }
        outcomes @include(if: $includeBriefOutcomes) {
          amount
          serviceId
        }
      }
    }
    assetsRatingsSummary {
      assetsRatios {
        upvoted
        downvoted
        unvoted
      }
    }
    outcomes @include(if: $includeOutcomes) {
      amount
      assetsCount
      serviceId
      deliveredAssetsCount
      outcomeId
    }
    feedback {
      createdAt
      isFeedbackCallRequested
      note
      projectFeedbackId
      vote
      user {
        ...UserDetails
      }
    }
    feedbackVotesAverage
    createdBy {
      userId
      role
    }
  }
}
    ${ProjectDetailsFragmentDoc}
${OrganizationNameFragmentDoc}
${AssetThumbnailFragmentDoc}
${UserDetailsFragmentDoc}
${CreditsSummaryFragmentDoc}
${CoverImageFragmentDoc}
${BriefDetailsFragmentDoc}
${ConceptDetailsFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      includeAssets: // value for 'includeAssets'
 *      includeBrief: // value for 'includeBrief'
 *      includeOutcomes: // value for 'includeOutcomes'
 *      includeBriefOutcomes: // value for 'includeBriefOutcomes'
 *      includeCompletion: // value for 'includeCompletion'
 *      includeFinalised: // value for 'includeFinalised'
 *      includeUnderRevision: // value for 'includeUnderRevision'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<Types.ProjectQuery, Types.ProjectQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($projectId: ID!) {
  destroyProject(projectId: $projectId) {
    projectId
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<Types.DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>;
export const RevertProjectDocument = gql`
    mutation revertProject($projectId: ID!) {
  regressProjectToDraft(projectId: $projectId) {
    projectId
    status
    approvedAt
  }
}
    `;
export type RevertProjectMutationFn = Apollo.MutationFunction<Types.RevertProjectMutation, Types.RevertProjectMutationVariables>;

/**
 * __useRevertProjectMutation__
 *
 * To run a mutation, you first call `useRevertProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertProjectMutation, { data, loading, error }] = useRevertProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRevertProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevertProjectMutation, Types.RevertProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevertProjectMutation, Types.RevertProjectMutationVariables>(RevertProjectDocument, options);
      }
export type RevertProjectMutationHookResult = ReturnType<typeof useRevertProjectMutation>;
export type RevertProjectMutationResult = Apollo.MutationResult<Types.RevertProjectMutation>;
export type RevertProjectMutationOptions = Apollo.BaseMutationOptions<Types.RevertProjectMutation, Types.RevertProjectMutationVariables>;
export const AcceptProjectDocument = gql`
    mutation acceptProject($projectId: ID!) {
  approveProject(projectId: $projectId) {
    projectId
    status
    approvedAt
  }
}
    `;
export type AcceptProjectMutationFn = Apollo.MutationFunction<Types.AcceptProjectMutation, Types.AcceptProjectMutationVariables>;

/**
 * __useAcceptProjectMutation__
 *
 * To run a mutation, you first call `useAcceptProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptProjectMutation, { data, loading, error }] = useAcceptProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAcceptProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptProjectMutation, Types.AcceptProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptProjectMutation, Types.AcceptProjectMutationVariables>(AcceptProjectDocument, options);
      }
export type AcceptProjectMutationHookResult = ReturnType<typeof useAcceptProjectMutation>;
export type AcceptProjectMutationResult = Apollo.MutationResult<Types.AcceptProjectMutation>;
export type AcceptProjectMutationOptions = Apollo.BaseMutationOptions<Types.AcceptProjectMutation, Types.AcceptProjectMutationVariables>;
export const EnqueueProjectDocument = gql`
    mutation enqueueProject($projectId: ID!) {
  enqueueProject(projectId: $projectId) {
    projectId
    status
    approvedAt
  }
}
    `;
export type EnqueueProjectMutationFn = Apollo.MutationFunction<Types.EnqueueProjectMutation, Types.EnqueueProjectMutationVariables>;

/**
 * __useEnqueueProjectMutation__
 *
 * To run a mutation, you first call `useEnqueueProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnqueueProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enqueueProjectMutation, { data, loading, error }] = useEnqueueProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useEnqueueProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnqueueProjectMutation, Types.EnqueueProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EnqueueProjectMutation, Types.EnqueueProjectMutationVariables>(EnqueueProjectDocument, options);
      }
export type EnqueueProjectMutationHookResult = ReturnType<typeof useEnqueueProjectMutation>;
export type EnqueueProjectMutationResult = Apollo.MutationResult<Types.EnqueueProjectMutation>;
export type EnqueueProjectMutationOptions = Apollo.BaseMutationOptions<Types.EnqueueProjectMutation, Types.EnqueueProjectMutationVariables>;
export const RequestProjectClarificationDocument = gql`
    mutation requestProjectClarification($projectId: ID!) {
  requestProjectClarification(projectId: $projectId) {
    projectId
    status
    approvedAt
  }
}
    `;
export type RequestProjectClarificationMutationFn = Apollo.MutationFunction<Types.RequestProjectClarificationMutation, Types.RequestProjectClarificationMutationVariables>;

/**
 * __useRequestProjectClarificationMutation__
 *
 * To run a mutation, you first call `useRequestProjectClarificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestProjectClarificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestProjectClarificationMutation, { data, loading, error }] = useRequestProjectClarificationMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRequestProjectClarificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestProjectClarificationMutation, Types.RequestProjectClarificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestProjectClarificationMutation, Types.RequestProjectClarificationMutationVariables>(RequestProjectClarificationDocument, options);
      }
export type RequestProjectClarificationMutationHookResult = ReturnType<typeof useRequestProjectClarificationMutation>;
export type RequestProjectClarificationMutationResult = Apollo.MutationResult<Types.RequestProjectClarificationMutation>;
export type RequestProjectClarificationMutationOptions = Apollo.BaseMutationOptions<Types.RequestProjectClarificationMutation, Types.RequestProjectClarificationMutationVariables>;
export const CompleteProjectDocument = gql`
    mutation completeProject($projectId: ID!, $override: OverrideProjectCompletionProtectionInput) {
  completeProject(projectId: $projectId, overrideProtection: $override) {
    projectId
    status
    completedAt
    completedBy {
      name
      userId
      surname
    }
    forcedPartialCompletionReason
  }
}
    `;
export type CompleteProjectMutationFn = Apollo.MutationFunction<Types.CompleteProjectMutation, Types.CompleteProjectMutationVariables>;

/**
 * __useCompleteProjectMutation__
 *
 * To run a mutation, you first call `useCompleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProjectMutation, { data, loading, error }] = useCompleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      override: // value for 'override'
 *   },
 * });
 */
export function useCompleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteProjectMutation, Types.CompleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteProjectMutation, Types.CompleteProjectMutationVariables>(CompleteProjectDocument, options);
      }
export type CompleteProjectMutationHookResult = ReturnType<typeof useCompleteProjectMutation>;
export type CompleteProjectMutationResult = Apollo.MutationResult<Types.CompleteProjectMutation>;
export type CompleteProjectMutationOptions = Apollo.BaseMutationOptions<Types.CompleteProjectMutation, Types.CompleteProjectMutationVariables>;
export const InitialDeliveryProjectDocument = gql`
    mutation initialDeliveryProject($projectId: ID!) {
  partialCompleteProject(projectId: $projectId) {
    projectId
    status
    partiallyCompletedAt
    partiallyCompletedBy {
      name
      userId
      surname
    }
    completedAt
  }
}
    `;
export type InitialDeliveryProjectMutationFn = Apollo.MutationFunction<Types.InitialDeliveryProjectMutation, Types.InitialDeliveryProjectMutationVariables>;

/**
 * __useInitialDeliveryProjectMutation__
 *
 * To run a mutation, you first call `useInitialDeliveryProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialDeliveryProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialDeliveryProjectMutation, { data, loading, error }] = useInitialDeliveryProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useInitialDeliveryProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitialDeliveryProjectMutation, Types.InitialDeliveryProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitialDeliveryProjectMutation, Types.InitialDeliveryProjectMutationVariables>(InitialDeliveryProjectDocument, options);
      }
export type InitialDeliveryProjectMutationHookResult = ReturnType<typeof useInitialDeliveryProjectMutation>;
export type InitialDeliveryProjectMutationResult = Apollo.MutationResult<Types.InitialDeliveryProjectMutation>;
export type InitialDeliveryProjectMutationOptions = Apollo.BaseMutationOptions<Types.InitialDeliveryProjectMutation, Types.InitialDeliveryProjectMutationVariables>;
export const UnderRevisionProjectDocument = gql`
    mutation underRevisionProject($projectId: ID!) {
  moveProjectToUnderRevision(projectId: $projectId) {
    projectId
    status
    underRevisionAt
    underRevisionBy {
      name
      userId
      surname
    }
  }
}
    `;
export type UnderRevisionProjectMutationFn = Apollo.MutationFunction<Types.UnderRevisionProjectMutation, Types.UnderRevisionProjectMutationVariables>;

/**
 * __useUnderRevisionProjectMutation__
 *
 * To run a mutation, you first call `useUnderRevisionProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnderRevisionProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [underRevisionProjectMutation, { data, loading, error }] = useUnderRevisionProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnderRevisionProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnderRevisionProjectMutation, Types.UnderRevisionProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnderRevisionProjectMutation, Types.UnderRevisionProjectMutationVariables>(UnderRevisionProjectDocument, options);
      }
export type UnderRevisionProjectMutationHookResult = ReturnType<typeof useUnderRevisionProjectMutation>;
export type UnderRevisionProjectMutationResult = Apollo.MutationResult<Types.UnderRevisionProjectMutation>;
export type UnderRevisionProjectMutationOptions = Apollo.BaseMutationOptions<Types.UnderRevisionProjectMutation, Types.UnderRevisionProjectMutationVariables>;
export const CancelProjectDocument = gql`
    mutation cancelProject($projectId: ID!) {
  cancelProject(projectId: $projectId) {
    status
    projectId
    canceledAt
  }
}
    `;
export type CancelProjectMutationFn = Apollo.MutationFunction<Types.CancelProjectMutation, Types.CancelProjectMutationVariables>;

/**
 * __useCancelProjectMutation__
 *
 * To run a mutation, you first call `useCancelProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProjectMutation, { data, loading, error }] = useCancelProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCancelProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.CancelProjectMutation, Types.CancelProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelProjectMutation, Types.CancelProjectMutationVariables>(CancelProjectDocument, options);
      }
export type CancelProjectMutationHookResult = ReturnType<typeof useCancelProjectMutation>;
export type CancelProjectMutationResult = Apollo.MutationResult<Types.CancelProjectMutation>;
export type CancelProjectMutationOptions = Apollo.BaseMutationOptions<Types.CancelProjectMutation, Types.CancelProjectMutationVariables>;
export const ProjectEventsDocument = gql`
    query projectEvents($projectId: ID!, $eventNames: [String], $pagination: OffsetPaginationParameters) {
  getProjectEvents(
    projectId: $projectId
    eventNames: $eventNames
    pagination: $pagination
  ) {
    nodes {
      ...Events
    }
    pageInfo {
      totalItems
      totalPages
    }
  }
}
    ${EventsFragmentDoc}`;

/**
 * __useProjectEventsQuery__
 *
 * To run a query within a React component, call `useProjectEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectEventsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      eventNames: // value for 'eventNames'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProjectEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectEventsQuery, Types.ProjectEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectEventsQuery, Types.ProjectEventsQueryVariables>(ProjectEventsDocument, options);
      }
export function useProjectEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectEventsQuery, Types.ProjectEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectEventsQuery, Types.ProjectEventsQueryVariables>(ProjectEventsDocument, options);
        }
export type ProjectEventsQueryHookResult = ReturnType<typeof useProjectEventsQuery>;
export type ProjectEventsLazyQueryHookResult = ReturnType<typeof useProjectEventsLazyQuery>;
export type ProjectEventsQueryResult = Apollo.QueryResult<Types.ProjectEventsQuery, Types.ProjectEventsQueryVariables>;
export const CreateProjectFeedbackDocument = gql`
    mutation createProjectFeedback($projectId: ID!, $projectFeedback: ProjectFeedbackFieldsInput!) {
  createProjectFeedback(
    projectId: $projectId
    projectFeedbackFields: $projectFeedback
  ) {
    projectId
    projectFeedbackId
    note
    vote
    isFeedbackCallRequested
  }
}
    `;
export type CreateProjectFeedbackMutationFn = Apollo.MutationFunction<Types.CreateProjectFeedbackMutation, Types.CreateProjectFeedbackMutationVariables>;

/**
 * __useCreateProjectFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateProjectFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFeedbackMutation, { data, loading, error }] = useCreateProjectFeedbackMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectFeedback: // value for 'projectFeedback'
 *   },
 * });
 */
export function useCreateProjectFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectFeedbackMutation, Types.CreateProjectFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectFeedbackMutation, Types.CreateProjectFeedbackMutationVariables>(CreateProjectFeedbackDocument, options);
      }
export type CreateProjectFeedbackMutationHookResult = ReturnType<typeof useCreateProjectFeedbackMutation>;
export type CreateProjectFeedbackMutationResult = Apollo.MutationResult<Types.CreateProjectFeedbackMutation>;
export type CreateProjectFeedbackMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectFeedbackMutation, Types.CreateProjectFeedbackMutationVariables>;
export const EditProjectFeedbackDocument = gql`
    mutation editProjectFeedback($projectFeedbackId: ID!, $projectFeedback: ProjectFeedbackFieldsInput!) {
  editProjectFeedback(
    projectFeedbackId: $projectFeedbackId
    projectFeedbackFields: $projectFeedback
  ) {
    projectId
    projectFeedbackId
    note
    vote
    isFeedbackCallRequested
  }
}
    `;
export type EditProjectFeedbackMutationFn = Apollo.MutationFunction<Types.EditProjectFeedbackMutation, Types.EditProjectFeedbackMutationVariables>;

/**
 * __useEditProjectFeedbackMutation__
 *
 * To run a mutation, you first call `useEditProjectFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectFeedbackMutation, { data, loading, error }] = useEditProjectFeedbackMutation({
 *   variables: {
 *      projectFeedbackId: // value for 'projectFeedbackId'
 *      projectFeedback: // value for 'projectFeedback'
 *   },
 * });
 */
export function useEditProjectFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditProjectFeedbackMutation, Types.EditProjectFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditProjectFeedbackMutation, Types.EditProjectFeedbackMutationVariables>(EditProjectFeedbackDocument, options);
      }
export type EditProjectFeedbackMutationHookResult = ReturnType<typeof useEditProjectFeedbackMutation>;
export type EditProjectFeedbackMutationResult = Apollo.MutationResult<Types.EditProjectFeedbackMutation>;
export type EditProjectFeedbackMutationOptions = Apollo.BaseMutationOptions<Types.EditProjectFeedbackMutation, Types.EditProjectFeedbackMutationVariables>;
export const CustomerProjectDocument = gql`
    query customerProject($id: ID!) {
  getProject(projectId: $id) {
    projectId
    title
    submittedAt
    status
    brief {
      briefId
      projectId
      concepts {
        conceptId
      }
    }
  }
}
    `;

/**
 * __useCustomerProjectQuery__
 *
 * To run a query within a React component, call `useCustomerProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.CustomerProjectQuery, Types.CustomerProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerProjectQuery, Types.CustomerProjectQueryVariables>(CustomerProjectDocument, options);
      }
export function useCustomerProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerProjectQuery, Types.CustomerProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerProjectQuery, Types.CustomerProjectQueryVariables>(CustomerProjectDocument, options);
        }
export type CustomerProjectQueryHookResult = ReturnType<typeof useCustomerProjectQuery>;
export type CustomerProjectLazyQueryHookResult = ReturnType<typeof useCustomerProjectLazyQuery>;
export type CustomerProjectQueryResult = Apollo.QueryResult<Types.CustomerProjectQuery, Types.CustomerProjectQueryVariables>;
export const ProjectShareLinksDocument = gql`
    query projectShareLinks($projectId: ID!) {
  getProject(projectId: $projectId) {
    projectId
    sharingLinks {
      ...SharedLinksAccess
    }
  }
}
    ${SharedLinksAccessFragmentDoc}`;

/**
 * __useProjectShareLinksQuery__
 *
 * To run a query within a React component, call `useProjectShareLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectShareLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectShareLinksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectShareLinksQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectShareLinksQuery, Types.ProjectShareLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectShareLinksQuery, Types.ProjectShareLinksQueryVariables>(ProjectShareLinksDocument, options);
      }
export function useProjectShareLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectShareLinksQuery, Types.ProjectShareLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectShareLinksQuery, Types.ProjectShareLinksQueryVariables>(ProjectShareLinksDocument, options);
        }
export type ProjectShareLinksQueryHookResult = ReturnType<typeof useProjectShareLinksQuery>;
export type ProjectShareLinksLazyQueryHookResult = ReturnType<typeof useProjectShareLinksLazyQuery>;
export type ProjectShareLinksQueryResult = Apollo.QueryResult<Types.ProjectShareLinksQuery, Types.ProjectShareLinksQueryVariables>;
export const CreateProjectShareLinkDocument = gql`
    mutation createProjectShareLink($projectId: ID!, $input: CreateProjectSharingLinkInput!) {
  createProjectSharingLink(
    projectId: $projectId
    projectSharingLinkFields: $input
  ) {
    projectSharingLinkId
    projectId
    linkCode
  }
}
    `;
export type CreateProjectShareLinkMutationFn = Apollo.MutationFunction<Types.CreateProjectShareLinkMutation, Types.CreateProjectShareLinkMutationVariables>;

/**
 * __useCreateProjectShareLinkMutation__
 *
 * To run a mutation, you first call `useCreateProjectShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectShareLinkMutation, { data, loading, error }] = useCreateProjectShareLinkMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectShareLinkMutation, Types.CreateProjectShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectShareLinkMutation, Types.CreateProjectShareLinkMutationVariables>(CreateProjectShareLinkDocument, options);
      }
export type CreateProjectShareLinkMutationHookResult = ReturnType<typeof useCreateProjectShareLinkMutation>;
export type CreateProjectShareLinkMutationResult = Apollo.MutationResult<Types.CreateProjectShareLinkMutation>;
export type CreateProjectShareLinkMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectShareLinkMutation, Types.CreateProjectShareLinkMutationVariables>;
export const GetFilesDownloadUrlDocument = gql`
    query getFilesDownloadUrl($filesIds: [ID!]!, $name: String!) {
  getFiles(filesIds: $filesIds) {
    fileId
    fileName
    download(name: $name) {
      downloadUrl {
        url
      }
      ...CommonMetadata
    }
  }
}
    ${CommonMetadataFragmentDoc}`;

/**
 * __useGetFilesDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetFilesDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesDownloadUrlQuery({
 *   variables: {
 *      filesIds: // value for 'filesIds'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetFilesDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFilesDownloadUrlQuery, Types.GetFilesDownloadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilesDownloadUrlQuery, Types.GetFilesDownloadUrlQueryVariables>(GetFilesDownloadUrlDocument, options);
      }
export function useGetFilesDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilesDownloadUrlQuery, Types.GetFilesDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilesDownloadUrlQuery, Types.GetFilesDownloadUrlQueryVariables>(GetFilesDownloadUrlDocument, options);
        }
export type GetFilesDownloadUrlQueryHookResult = ReturnType<typeof useGetFilesDownloadUrlQuery>;
export type GetFilesDownloadUrlLazyQueryHookResult = ReturnType<typeof useGetFilesDownloadUrlLazyQuery>;
export type GetFilesDownloadUrlQueryResult = Apollo.QueryResult<Types.GetFilesDownloadUrlQuery, Types.GetFilesDownloadUrlQueryVariables>;
export const GalleryProjectDocument = gql`
    query galleryProject($projectId: ID!) {
  getProject(projectId: $projectId) {
    projectId
    title
    status
    coverImage {
      ...CoverImage
    }
    organization {
      ...OrganizationName
      subscriptionPlan {
        subscriptionPlanId
        type
      }
    }
    brief {
      projectId
      briefId
      category
      concepts {
        name
        conceptId
      }
    }
    myFeedback {
      projectFeedbackId
      isFeedbackCallRequested
      vote
      note
    }
    sharingLinks {
      ...SharedLinksAccess
    }
  }
}
    ${CoverImageFragmentDoc}
${OrganizationNameFragmentDoc}
${SharedLinksAccessFragmentDoc}`;

/**
 * __useGalleryProjectQuery__
 *
 * To run a query within a React component, call `useGalleryProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGalleryProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.GalleryProjectQuery, Types.GalleryProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GalleryProjectQuery, Types.GalleryProjectQueryVariables>(GalleryProjectDocument, options);
      }
export function useGalleryProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GalleryProjectQuery, Types.GalleryProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GalleryProjectQuery, Types.GalleryProjectQueryVariables>(GalleryProjectDocument, options);
        }
export type GalleryProjectQueryHookResult = ReturnType<typeof useGalleryProjectQuery>;
export type GalleryProjectLazyQueryHookResult = ReturnType<typeof useGalleryProjectLazyQuery>;
export type GalleryProjectQueryResult = Apollo.QueryResult<Types.GalleryProjectQuery, Types.GalleryProjectQueryVariables>;
export const SetProjectQueueDocument = gql`
    mutation setProjectQueue($projectId: ID!, $newPosition: Int!) {
  moveProjectInQueue(projectId: $projectId, newPosition: $newPosition) {
    queueOrder
    projectId
  }
}
    `;
export type SetProjectQueueMutationFn = Apollo.MutationFunction<Types.SetProjectQueueMutation, Types.SetProjectQueueMutationVariables>;

/**
 * __useSetProjectQueueMutation__
 *
 * To run a mutation, you first call `useSetProjectQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectQueueMutation, { data, loading, error }] = useSetProjectQueueMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newPosition: // value for 'newPosition'
 *   },
 * });
 */
export function useSetProjectQueueMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetProjectQueueMutation, Types.SetProjectQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetProjectQueueMutation, Types.SetProjectQueueMutationVariables>(SetProjectQueueDocument, options);
      }
export type SetProjectQueueMutationHookResult = ReturnType<typeof useSetProjectQueueMutation>;
export type SetProjectQueueMutationResult = Apollo.MutationResult<Types.SetProjectQueueMutation>;
export type SetProjectQueueMutationOptions = Apollo.BaseMutationOptions<Types.SetProjectQueueMutation, Types.SetProjectQueueMutationVariables>;
export const RemoveProjectFromQueueDocument = gql`
    mutation removeProjectFromQueue($projectId: ID!) {
  removeProjectFromQueue(projectId: $projectId) {
    projectId
    status
  }
}
    `;
export type RemoveProjectFromQueueMutationFn = Apollo.MutationFunction<Types.RemoveProjectFromQueueMutation, Types.RemoveProjectFromQueueMutationVariables>;

/**
 * __useRemoveProjectFromQueueMutation__
 *
 * To run a mutation, you first call `useRemoveProjectFromQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectFromQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectFromQueueMutation, { data, loading, error }] = useRemoveProjectFromQueueMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveProjectFromQueueMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveProjectFromQueueMutation, Types.RemoveProjectFromQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveProjectFromQueueMutation, Types.RemoveProjectFromQueueMutationVariables>(RemoveProjectFromQueueDocument, options);
      }
export type RemoveProjectFromQueueMutationHookResult = ReturnType<typeof useRemoveProjectFromQueueMutation>;
export type RemoveProjectFromQueueMutationResult = Apollo.MutationResult<Types.RemoveProjectFromQueueMutation>;
export type RemoveProjectFromQueueMutationOptions = Apollo.BaseMutationOptions<Types.RemoveProjectFromQueueMutation, Types.RemoveProjectFromQueueMutationVariables>;
export const FinalizeProjectDocument = gql`
    mutation finalizeProject($projectId: ID!, $force: Boolean = false) {
  finalizeProject(projectId: $projectId, ignoreAssetsValidation: $force) {
    projectId
    finalizedAt
    finalizedBy {
      name
      userId
      surname
    }
    status
  }
}
    `;
export type FinalizeProjectMutationFn = Apollo.MutationFunction<Types.FinalizeProjectMutation, Types.FinalizeProjectMutationVariables>;

/**
 * __useFinalizeProjectMutation__
 *
 * To run a mutation, you first call `useFinalizeProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeProjectMutation, { data, loading, error }] = useFinalizeProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useFinalizeProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.FinalizeProjectMutation, Types.FinalizeProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FinalizeProjectMutation, Types.FinalizeProjectMutationVariables>(FinalizeProjectDocument, options);
      }
export type FinalizeProjectMutationHookResult = ReturnType<typeof useFinalizeProjectMutation>;
export type FinalizeProjectMutationResult = Apollo.MutationResult<Types.FinalizeProjectMutation>;
export type FinalizeProjectMutationOptions = Apollo.BaseMutationOptions<Types.FinalizeProjectMutation, Types.FinalizeProjectMutationVariables>;
export const ProjectGalleryZipDocument = gql`
    query projectGalleryZip($projectId: ID!) {
  getProjectGalleryZip(projectId: $projectId) {
    fileId
    download(name: "original") {
      downloadUrl {
        url
      }
    }
  }
}
    `;

/**
 * __useProjectGalleryZipQuery__
 *
 * To run a query within a React component, call `useProjectGalleryZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGalleryZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGalleryZipQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectGalleryZipQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectGalleryZipQuery, Types.ProjectGalleryZipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectGalleryZipQuery, Types.ProjectGalleryZipQueryVariables>(ProjectGalleryZipDocument, options);
      }
export function useProjectGalleryZipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectGalleryZipQuery, Types.ProjectGalleryZipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectGalleryZipQuery, Types.ProjectGalleryZipQueryVariables>(ProjectGalleryZipDocument, options);
        }
export type ProjectGalleryZipQueryHookResult = ReturnType<typeof useProjectGalleryZipQuery>;
export type ProjectGalleryZipLazyQueryHookResult = ReturnType<typeof useProjectGalleryZipLazyQuery>;
export type ProjectGalleryZipQueryResult = Apollo.QueryResult<Types.ProjectGalleryZipQuery, Types.ProjectGalleryZipQueryVariables>;
export const ProjectAssetsZipDocument = gql`
    query projectAssetsZip($projectId: ID!, $assetsIds: [ID!]) {
  getProjectAssetsZip(projectId: $projectId, assetsIds: $assetsIds)
}
    `;

/**
 * __useProjectAssetsZipQuery__
 *
 * To run a query within a React component, call `useProjectAssetsZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssetsZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssetsZipQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      assetsIds: // value for 'assetsIds'
 *   },
 * });
 */
export function useProjectAssetsZipQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectAssetsZipQuery, Types.ProjectAssetsZipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectAssetsZipQuery, Types.ProjectAssetsZipQueryVariables>(ProjectAssetsZipDocument, options);
      }
export function useProjectAssetsZipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectAssetsZipQuery, Types.ProjectAssetsZipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectAssetsZipQuery, Types.ProjectAssetsZipQueryVariables>(ProjectAssetsZipDocument, options);
        }
export type ProjectAssetsZipQueryHookResult = ReturnType<typeof useProjectAssetsZipQuery>;
export type ProjectAssetsZipLazyQueryHookResult = ReturnType<typeof useProjectAssetsZipLazyQuery>;
export type ProjectAssetsZipQueryResult = Apollo.QueryResult<Types.ProjectAssetsZipQuery, Types.ProjectAssetsZipQueryVariables>;
export const ProjectNamesDocument = gql`
    query projectNames($organizationId: ID, $pagination: GetProjectsPaginationParameters, $filters: GetProjectsFilter) {
  getProjects(
    organizationId: $organizationId
    pagination: $pagination
    filters: $filters
  ) {
    pageInfo {
      totalItems
    }
    nodes {
      projectId
      title
    }
  }
}
    `;

/**
 * __useProjectNamesQuery__
 *
 * To run a query within a React component, call `useProjectNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNamesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectNamesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectNamesQuery, Types.ProjectNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectNamesQuery, Types.ProjectNamesQueryVariables>(ProjectNamesDocument, options);
      }
export function useProjectNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectNamesQuery, Types.ProjectNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectNamesQuery, Types.ProjectNamesQueryVariables>(ProjectNamesDocument, options);
        }
export type ProjectNamesQueryHookResult = ReturnType<typeof useProjectNamesQuery>;
export type ProjectNamesLazyQueryHookResult = ReturnType<typeof useProjectNamesLazyQuery>;
export type ProjectNamesQueryResult = Apollo.QueryResult<Types.ProjectNamesQuery, Types.ProjectNamesQueryVariables>;
export const EditCommentDocument = gql`
    mutation editComment($commentId: ID!, $content: String!, $extra: JSON) {
  editComment(commentId: $commentId, content: $content, extra: $extra) {
    commentId
    content
  }
}
    `;
export type EditCommentMutationFn = Apollo.MutationFunction<Types.EditCommentMutation, Types.EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      content: // value for 'content'
 *      extra: // value for 'extra'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCommentMutation, Types.EditCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditCommentMutation, Types.EditCommentMutationVariables>(EditCommentDocument, options);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<Types.EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<Types.EditCommentMutation, Types.EditCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($noteId: ID!) {
  deleteComment(commentId: $noteId) {
    commentId
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;
export const SharedGalleryDocument = gql`
    query sharedGallery($projectId: ID!) {
  getProject(projectId: $projectId) {
    projectId
    title
    brief {
      briefId
      projectId
      concepts {
        conceptId
        name
      }
    }
    coverImage {
      ...CoverImage
    }
    organization {
      organizationId
      name
    }
    deliveredAssets {
      ...AssetShared
    }
  }
}
    ${CoverImageFragmentDoc}
${AssetSharedFragmentDoc}`;

/**
 * __useSharedGalleryQuery__
 *
 * To run a query within a React component, call `useSharedGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedGalleryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSharedGalleryQuery(baseOptions: Apollo.QueryHookOptions<Types.SharedGalleryQuery, Types.SharedGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharedGalleryQuery, Types.SharedGalleryQueryVariables>(SharedGalleryDocument, options);
      }
export function useSharedGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharedGalleryQuery, Types.SharedGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharedGalleryQuery, Types.SharedGalleryQueryVariables>(SharedGalleryDocument, options);
        }
export type SharedGalleryQueryHookResult = ReturnType<typeof useSharedGalleryQuery>;
export type SharedGalleryLazyQueryHookResult = ReturnType<typeof useSharedGalleryLazyQuery>;
export type SharedGalleryQueryResult = Apollo.QueryResult<Types.SharedGalleryQuery, Types.SharedGalleryQueryVariables>;
export const SharedAssetDocument = gql`
    query sharedAsset($assetId: ID!) {
  getSharedAsset(id: $assetId) {
    ...AssetShared
  }
}
    ${AssetSharedFragmentDoc}`;

/**
 * __useSharedAssetQuery__
 *
 * To run a query within a React component, call `useSharedAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedAssetQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useSharedAssetQuery(baseOptions: Apollo.QueryHookOptions<Types.SharedAssetQuery, Types.SharedAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharedAssetQuery, Types.SharedAssetQueryVariables>(SharedAssetDocument, options);
      }
export function useSharedAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharedAssetQuery, Types.SharedAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharedAssetQuery, Types.SharedAssetQueryVariables>(SharedAssetDocument, options);
        }
export type SharedAssetQueryHookResult = ReturnType<typeof useSharedAssetQuery>;
export type SharedAssetLazyQueryHookResult = ReturnType<typeof useSharedAssetLazyQuery>;
export type SharedAssetQueryResult = Apollo.QueryResult<Types.SharedAssetQuery, Types.SharedAssetQueryVariables>;
export const SharingCollectionLinkTokenDocument = gql`
    query sharingCollectionLinkToken($linkCode: String!) {
  getAuthenticationTokensForCollectionSharingLink(linkCode: $linkCode) {
    accessToken
    collectionSharingLink {
      sharingLinkId
      collectionId
      canDownloadAssets
    }
  }
}
    `;

/**
 * __useSharingCollectionLinkTokenQuery__
 *
 * To run a query within a React component, call `useSharingCollectionLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharingCollectionLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharingCollectionLinkTokenQuery({
 *   variables: {
 *      linkCode: // value for 'linkCode'
 *   },
 * });
 */
export function useSharingCollectionLinkTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.SharingCollectionLinkTokenQuery, Types.SharingCollectionLinkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharingCollectionLinkTokenQuery, Types.SharingCollectionLinkTokenQueryVariables>(SharingCollectionLinkTokenDocument, options);
      }
export function useSharingCollectionLinkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharingCollectionLinkTokenQuery, Types.SharingCollectionLinkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharingCollectionLinkTokenQuery, Types.SharingCollectionLinkTokenQueryVariables>(SharingCollectionLinkTokenDocument, options);
        }
export type SharingCollectionLinkTokenQueryHookResult = ReturnType<typeof useSharingCollectionLinkTokenQuery>;
export type SharingCollectionLinkTokenLazyQueryHookResult = ReturnType<typeof useSharingCollectionLinkTokenLazyQuery>;
export type SharingCollectionLinkTokenQueryResult = Apollo.QueryResult<Types.SharingCollectionLinkTokenQuery, Types.SharingCollectionLinkTokenQueryVariables>;
export const SharedCollectionNeighboursDocument = gql`
    query sharedCollectionNeighbours($assetId: ID!, $pagination: OffsetPaginationParameters, $filters: AssetLibraryFiltersInput) {
  getAssetLibraryNeighbours(
    assetId: $assetId
    filters: $filters
    pagination: $pagination
  ) {
    current {
      ...AssetShared
    }
    nextId
    previousId
  }
}
    ${AssetSharedFragmentDoc}`;

/**
 * __useSharedCollectionNeighboursQuery__
 *
 * To run a query within a React component, call `useSharedCollectionNeighboursQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedCollectionNeighboursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedCollectionNeighboursQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSharedCollectionNeighboursQuery(baseOptions: Apollo.QueryHookOptions<Types.SharedCollectionNeighboursQuery, Types.SharedCollectionNeighboursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharedCollectionNeighboursQuery, Types.SharedCollectionNeighboursQueryVariables>(SharedCollectionNeighboursDocument, options);
      }
export function useSharedCollectionNeighboursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharedCollectionNeighboursQuery, Types.SharedCollectionNeighboursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharedCollectionNeighboursQuery, Types.SharedCollectionNeighboursQueryVariables>(SharedCollectionNeighboursDocument, options);
        }
export type SharedCollectionNeighboursQueryHookResult = ReturnType<typeof useSharedCollectionNeighboursQuery>;
export type SharedCollectionNeighboursLazyQueryHookResult = ReturnType<typeof useSharedCollectionNeighboursLazyQuery>;
export type SharedCollectionNeighboursQueryResult = Apollo.QueryResult<Types.SharedCollectionNeighboursQuery, Types.SharedCollectionNeighboursQueryVariables>;
export const GetBrandSharingTokenDocument = gql`
    query GetBrandSharingToken($linkCode: String!) {
  sharedData: getAuthenticationTokensForBrandProfileSharingLink(
    linkCode: $linkCode
  ) {
    accessToken
    brandProfileSharingLink {
      brandProfileId
      sharingLinkId
    }
  }
}
    `;

/**
 * __useGetBrandSharingTokenQuery__
 *
 * To run a query within a React component, call `useGetBrandSharingTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandSharingTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandSharingTokenQuery({
 *   variables: {
 *      linkCode: // value for 'linkCode'
 *   },
 * });
 */
export function useGetBrandSharingTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBrandSharingTokenQuery, Types.GetBrandSharingTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBrandSharingTokenQuery, Types.GetBrandSharingTokenQueryVariables>(GetBrandSharingTokenDocument, options);
      }
export function useGetBrandSharingTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBrandSharingTokenQuery, Types.GetBrandSharingTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBrandSharingTokenQuery, Types.GetBrandSharingTokenQueryVariables>(GetBrandSharingTokenDocument, options);
        }
export type GetBrandSharingTokenQueryHookResult = ReturnType<typeof useGetBrandSharingTokenQuery>;
export type GetBrandSharingTokenLazyQueryHookResult = ReturnType<typeof useGetBrandSharingTokenLazyQuery>;
export type GetBrandSharingTokenQueryResult = Apollo.QueryResult<Types.GetBrandSharingTokenQuery, Types.GetBrandSharingTokenQueryVariables>;
export const GetProjectSharingTokenDocument = gql`
    query GetProjectSharingToken($linkCode: String!) {
  sharedData: getAuthenticationTokensForProjectSharingLink(linkCode: $linkCode) {
    accessToken
    projectSharingLink {
      linkCode
      projectId
      projectSharingLinkId
      canDownloadAssets
      canViewAssetComments
      canViewAssets
      canViewBrief
      isInternal
    }
  }
}
    `;

/**
 * __useGetProjectSharingTokenQuery__
 *
 * To run a query within a React component, call `useGetProjectSharingTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSharingTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSharingTokenQuery({
 *   variables: {
 *      linkCode: // value for 'linkCode'
 *   },
 * });
 */
export function useGetProjectSharingTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProjectSharingTokenQuery, Types.GetProjectSharingTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProjectSharingTokenQuery, Types.GetProjectSharingTokenQueryVariables>(GetProjectSharingTokenDocument, options);
      }
export function useGetProjectSharingTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProjectSharingTokenQuery, Types.GetProjectSharingTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProjectSharingTokenQuery, Types.GetProjectSharingTokenQueryVariables>(GetProjectSharingTokenDocument, options);
        }
export type GetProjectSharingTokenQueryHookResult = ReturnType<typeof useGetProjectSharingTokenQuery>;
export type GetProjectSharingTokenLazyQueryHookResult = ReturnType<typeof useGetProjectSharingTokenLazyQuery>;
export type GetProjectSharingTokenQueryResult = Apollo.QueryResult<Types.GetProjectSharingTokenQuery, Types.GetProjectSharingTokenQueryVariables>;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($userAvatarFields: SetUserAvatarInput!, $userId: ID) {
  setUserAvatar(userAvatarFields: $userAvatarFields, userId: $userId) {
    fileId
  }
}
    `;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<Types.UpdateAvatarMutation, Types.UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      userAvatarFields: // value for 'userAvatarFields'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAvatarMutation, Types.UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAvatarMutation, Types.UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<Types.UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAvatarMutation, Types.UpdateAvatarMutationVariables>;
export const ProfileDocument = gql`
    query profile($includeEmailPreferences: Boolean = false) {
  me {
    ... on OrganizationUser {
      ...OrganizationUserDetails
      organization {
        ...OrganizationDetails
        credits
        draftStatus {
          isDraft
          hasCnameModificationPending
          hasRequiredFieldsPending
          hasOwnerRequiredFieldsPending
        }
        subscriptionPlan {
          name
          creditsLimit
          type
          subscriptionPlanId
        }
      }
      emailPreferences @include(if: $includeEmailPreferences) {
        activityPreferences {
          assetCommentCreated
          assetVersionUpdated
          briefCommentCreated
        }
        projectStatuses {
          projectAccepted
          projectCanceled
          projectDelivery
          projectFinalized
          projectClarification
          projectSubmitted
        }
        projectsPreferences {
          brands
          mode
        }
      }
    }
    ... on InternalUser {
      ...InternalUserDetails
    }
    ... on CreatorUser {
      ...CreatorUserDetails
    }
  }
}
    ${OrganizationUserDetailsFragmentDoc}
${OrganizationDetailsFragmentDoc}
${InternalUserDetailsFragmentDoc}
${CreatorUserDetailsFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      includeEmailPreferences: // value for 'includeEmailPreferences'
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<Types.ProfileQuery, Types.ProfileQueryVariables>;
export const UsersDocument = gql`
    query users($role: [String], $organizationId: ID, $pagination: OffsetPaginationParameters, $search: UsersLikeSearchInput) {
  getUsers(
    role: $role
    pagination: $pagination
    organizationId: $organizationId
    like: $search
    filters: {isDeleted: false}
  ) {
    pageInfo {
      totalItems
      totalPages
    }
    nodes {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      role: // value for 'role'
 *      organizationId: // value for 'organizationId'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export const UserNamesDocument = gql`
    query userNames($organizationId: ID, $role: [String]) {
  getUsers(
    organizationId: $organizationId
    filters: {isDeleted: false}
    role: $role
  ) {
    nodes {
      userId
      name
      surname
    }
  }
}
    `;

/**
 * __useUserNamesQuery__
 *
 * To run a query within a React component, call `useUserNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNamesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUserNamesQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserNamesQuery, Types.UserNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserNamesQuery, Types.UserNamesQueryVariables>(UserNamesDocument, options);
      }
export function useUserNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserNamesQuery, Types.UserNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserNamesQuery, Types.UserNamesQueryVariables>(UserNamesDocument, options);
        }
export type UserNamesQueryHookResult = ReturnType<typeof useUserNamesQuery>;
export type UserNamesLazyQueryHookResult = ReturnType<typeof useUserNamesLazyQuery>;
export type UserNamesQueryResult = Apollo.QueryResult<Types.UserNamesQuery, Types.UserNamesQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($userId: ID!, $userFields: EditUserInput!) {
  editUser(userId: $userId, userFields: $userFields) {
    ...UserDetails
    phone
    country
  }
}
    ${UserDetailsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userFields: // value for 'userFields'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;
export const OrganizationByUserDocument = gql`
    query organizationByUser($userId: ID) {
  getOrganizationUserProfile(userId: $userId) {
    organizationId
  }
}
    `;

/**
 * __useOrganizationByUserQuery__
 *
 * To run a query within a React component, call `useOrganizationByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOrganizationByUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrganizationByUserQuery, Types.OrganizationByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationByUserQuery, Types.OrganizationByUserQueryVariables>(OrganizationByUserDocument, options);
      }
export function useOrganizationByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationByUserQuery, Types.OrganizationByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationByUserQuery, Types.OrganizationByUserQueryVariables>(OrganizationByUserDocument, options);
        }
export type OrganizationByUserQueryHookResult = ReturnType<typeof useOrganizationByUserQuery>;
export type OrganizationByUserLazyQueryHookResult = ReturnType<typeof useOrganizationByUserLazyQuery>;
export type OrganizationByUserQueryResult = Apollo.QueryResult<Types.OrganizationByUserQuery, Types.OrganizationByUserQueryVariables>;
export const GetIntercomUserTokenDocument = gql`
    query GetIntercomUserToken {
  intercom: getIntercomUserToken {
    token
  }
}
    `;

/**
 * __useGetIntercomUserTokenQuery__
 *
 * To run a query within a React component, call `useGetIntercomUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntercomUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntercomUserTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntercomUserTokenQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetIntercomUserTokenQuery, Types.GetIntercomUserTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIntercomUserTokenQuery, Types.GetIntercomUserTokenQueryVariables>(GetIntercomUserTokenDocument, options);
      }
export function useGetIntercomUserTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIntercomUserTokenQuery, Types.GetIntercomUserTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIntercomUserTokenQuery, Types.GetIntercomUserTokenQueryVariables>(GetIntercomUserTokenDocument, options);
        }
export type GetIntercomUserTokenQueryHookResult = ReturnType<typeof useGetIntercomUserTokenQuery>;
export type GetIntercomUserTokenLazyQueryHookResult = ReturnType<typeof useGetIntercomUserTokenLazyQuery>;
export type GetIntercomUserTokenQueryResult = Apollo.QueryResult<Types.GetIntercomUserTokenQuery, Types.GetIntercomUserTokenQueryVariables>;
export const EditEmailPreferencesDocument = gql`
    mutation editEmailPreferences($preferences: UserEmailPreferencesInput) {
  editEmailPreferences(preferences: $preferences) {
    userId
  }
}
    `;
export type EditEmailPreferencesMutationFn = Apollo.MutationFunction<Types.EditEmailPreferencesMutation, Types.EditEmailPreferencesMutationVariables>;

/**
 * __useEditEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useEditEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmailPreferencesMutation, { data, loading, error }] = useEditEmailPreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useEditEmailPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditEmailPreferencesMutation, Types.EditEmailPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditEmailPreferencesMutation, Types.EditEmailPreferencesMutationVariables>(EditEmailPreferencesDocument, options);
      }
export type EditEmailPreferencesMutationHookResult = ReturnType<typeof useEditEmailPreferencesMutation>;
export type EditEmailPreferencesMutationResult = Apollo.MutationResult<Types.EditEmailPreferencesMutation>;
export type EditEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<Types.EditEmailPreferencesMutation, Types.EditEmailPreferencesMutationVariables>;
export const RemoveAttachmentDocument = gql`
    mutation removeAttachment($commentId: ID!, $fileId: ID!) {
  removeAttachment(commentId: $commentId, fileId: $fileId) {
    commentId
    attachments {
      ...Attachments
    }
  }
}
    ${AttachmentsFragmentDoc}`;
export type RemoveAttachmentMutationFn = Apollo.MutationFunction<Types.RemoveAttachmentMutation, Types.RemoveAttachmentMutationVariables>;

/**
 * __useRemoveAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAttachmentMutation, { data, loading, error }] = useRemoveAttachmentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useRemoveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveAttachmentMutation, Types.RemoveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveAttachmentMutation, Types.RemoveAttachmentMutationVariables>(RemoveAttachmentDocument, options);
      }
export type RemoveAttachmentMutationHookResult = ReturnType<typeof useRemoveAttachmentMutation>;
export type RemoveAttachmentMutationResult = Apollo.MutationResult<Types.RemoveAttachmentMutation>;
export type RemoveAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.RemoveAttachmentMutation, Types.RemoveAttachmentMutationVariables>;
export const GetMentionableUsersDocument = gql`
    query GetMentionableUsers($organizationId: ID, $projectId: ID) {
  users: getMentionableUsers(
    organizationId: $organizationId
    projectId: $projectId
  ) {
    name
    surname
    userId
  }
}
    `;

/**
 * __useGetMentionableUsersQuery__
 *
 * To run a query within a React component, call `useGetMentionableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMentionableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMentionableUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetMentionableUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMentionableUsersQuery, Types.GetMentionableUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMentionableUsersQuery, Types.GetMentionableUsersQueryVariables>(GetMentionableUsersDocument, options);
      }
export function useGetMentionableUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMentionableUsersQuery, Types.GetMentionableUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMentionableUsersQuery, Types.GetMentionableUsersQueryVariables>(GetMentionableUsersDocument, options);
        }
export type GetMentionableUsersQueryHookResult = ReturnType<typeof useGetMentionableUsersQuery>;
export type GetMentionableUsersLazyQueryHookResult = ReturnType<typeof useGetMentionableUsersLazyQuery>;
export type GetMentionableUsersQueryResult = Apollo.QueryResult<Types.GetMentionableUsersQuery, Types.GetMentionableUsersQueryVariables>;