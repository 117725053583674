import "./locales";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Switch } from "react-router";
import { digitRegEx } from "utils/regex";

import { CreateBrief } from "./Create";
import { BriefSummary } from "./Summary";

export const BriefRoute = compose(
  ProtectedRoute({
    access: [Roles.organization_owner, Roles.admin, Roles.account_manager],
  }),
  Route(
    {
      id: Route.params.regex(digitRegEx).optional,
      mode: Route.query.oneOf("delete"),
    },
    ({ id }) => `/brief/${id}`
  )
);

export const Brief = BriefRoute(
  lazy(() => import(/* webpackChunkName: "Brief" */ "./Brief"))
);

export const links = {
  Brief,
  CreateBrief,
  BriefSummary,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <CreateBrief exact />
      <BriefSummary />
      <Brief />
    </Switch>
  );
});
