import { Alert, Loader } from "@CreativelySquared/uikit";
import {
  CustomerDocument,
  useCreateCustomerUserMutation,
  useCustomerQuery,
  useCustomerUserQuery,
  useEditCustomerUserMutation,
} from "api/graphql";
import { User } from "api/types";
import { links } from "App";
import { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { UserManagement } from "Users/components/UserManagement";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import type { Object } from "ts-toolbelt";
import { getFullName } from "utils/users";

import styles from "./styles.module.scss";

export const CustomerUserManagementRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      userId: Route.params.regex(idRegEx).optional,
      mode: Route.params.oneOf("create", "edit").optional,
    },
    ({ id, userId, mode }) => `/customers/${id}/users/${mode}/${userId}`
  )
);

export const CustomerUserManagement = CustomerUserManagementRoute(
  ({
    match: {
      params: { mode, id, userId },
    },
    history: { push, replace },
    link,
  }) => {
    const { t } = useTranslation("customers");
    const { data: { getOrganization: customer } = {} } = useCustomerQuery({
      variables: { id },
    });

    const { data: { getUser: user } = {}, loading } = useCustomerUserQuery({
      variables: { userId: userId! },
      skip: !userId || mode !== "edit",
    });

    useEffect(() => {
      if (user === null) {
        replace(links.NotFound());
      }
    }, [user]);

    const [createUser, { error: createError }] = useCreateCustomerUserMutation({
      refetchQueries: [{ query: CustomerDocument, variables: { id } }],
    });

    const [editUser, { error: editError }] = useEditCustomerUserMutation();

    const onSubmit = useCallback(
      async ({ email, name, surname }: Object.Partial<User, "deep">) => {
        const data = {
          email: email ?? "",
          name: name ?? "",
          surname: surname ?? "",
        };

        if (mode === "create") {
          await createUser({
            variables: {
              organizationId: id,
              userFields: { ...data, password: null },
            },
          });
        }
        if (mode === "edit" && userId) {
          await editUser({ variables: { userId, userFields: data } });
        }
        push(links.CustomerUsers({ id }));
        return;
      },
      [mode, createUser, editUser]
    );

    const title = user ? getFullName(user) : customer?.name;

    return (
      <main>
        <Helmet>
          <title>{t(`users.management.${mode}.title`)}</title>
        </Helmet>
        <header className="flex justify-between items-center mb-11">
          <h1 className="shrink-0 mr-4">
            {t(`users.management.${mode}.title`)}
          </h1>
          {!loading && <h2 className={styles.userManagementName}>{title}</h2>}
        </header>
        {(createError ?? editError) && (
          <Alert
            icon
            variant={Alert.variants.Error}
            className="mt-8 mb-11"
            title={(createError ?? editError)?.message as string}
          />
        )}
        {mode !== "create" && loading && (
          <div className="flex justify-center">
            <Loader radius={50} className="mt-6" />
          </div>
        )}
        {!(mode !== "create" && !user) && (
          <UserManagement
            onCancel={() => push(link({ id }))}
            user={user}
            onSubmit={onSubmit}
          />
        )}
      </main>
    );
  }
);
