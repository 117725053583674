import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ProjectsDocument, useDuplicateProjectMutation } from "api/graphql";
import { getOperationName } from "@apollo/client/utilities";
import { useNotification } from "components";
import { ProjectDuplicateOverwriteProtectionInput } from "api/types";
import { ApolloError } from "@apollo/client";
import { BriefErrorCodes } from "Brief/utils/errors";

import styles from "./styles.module.scss";

type FormData = { confirm: string };

type Props = {
  projectId: string;
  overwriteProtection?: ProjectDuplicateOverwriteProtectionInput;
  onClose: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const DuplicateProject = memo<Props>(
  ({ projectId, overwriteProtection, onClose, className, ...props }) => {
    const { t } = useTranslation("brief");
    const { setNotification } = useNotification();

    const [duplicateProject, { loading, error }] = useDuplicateProjectMutation({
      refetchQueries: [getOperationName(ProjectsDocument)!],
    });

    const isOverwriteProtectionError =
      (error as ApolloError)?.graphQLErrors?.[0]?.extensions?.code ===
      BriefErrorCodes.OverwriteProtectionError;

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
    } = useFormik<FormData>({
      async onSubmit() {
        try {
          await duplicateProject({
            variables: {
              projectId,
              overwriteProtection,
            },
          });
          onClose();
          setNotification({
            message: t("duplicate.created"),
          });
        } catch (error) {
          console.log(error);
        }
      },
      validationSchema: object({
        confirm: string()
          .required("common:validation.required")
          .lowercase()
          .oneOf([t("duplicate.validationWord")]),
      }),
      initialErrors: {
        confirm: "",
      },
      initialValues: {
        confirm: "",
      },
    });

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(styles.duplicateProject, className)}
        {...props}
      >
        <h3> {t("duplicate.title")}</h3>
        <h4 className="text-base text-blackberry">
          {t("duplicate.explanation")}
        </h4>
        {error && (
          <p className={styles.error}>
            {isOverwriteProtectionError
              ? t("duplicate.errors.overwrite")
              : error.message ?? t("duplicate.errors.common")}
          </p>
        )}
        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full mt-7 mb-6"
          placeholder={t("duplicate.placeholder")}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t("duplicate.actions.cancel")}
          </Button>
          <Button
            loading={loading}
            type="submit"
            disabled={!isValid || loading}
          >
            {t("duplicate.actions.delete")}
          </Button>
        </section>
      </form>
    );
  }
);
