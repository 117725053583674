import clsx from "clsx";
import { uniqueId } from "lodash";
import {
  DetailedHTMLProps,
  LabelHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

export const createInjection = (id: string = uniqueId(), replace?: boolean) => {
  const renderer = new EventTarget();
  let current = 0;
  return {
    Area: ({
      className,
      onToogle,
      ...props
    }: DetailedHTMLProps<
      LabelHTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > & { onToogle?: (value: boolean) => void }) => {
      const ref = useRef(null);

      useLayoutEffect(() => {
        const observer = new MutationObserver(([mutation, ...rest]) => {
          if (rest.length) {
            return onToogle?.(true);
          }
          if (mutation.addedNodes.length) {
            return onToogle?.(true);
          }
          if (mutation.removedNodes.length) {
            return onToogle?.(false);
          }
        });
        if (ref.current) {
          observer.observe(ref.current, { childList: true });
        }
        return () => observer.disconnect();
      }, []);

      return <div ref={ref} className={clsx(id, className)} {...props} />;
    },
    Inject: ({
      children,
      onClose,
    }: PropsWithChildren<{ onClose?: () => void }>) => {
      const index = useRef<number>();
      const [element, setElement] = useState<Element | null>(null);
      useEffect(() => {
        index.current = ++current;
        renderer.dispatchEvent(new Event("render"));
        const root = document.getElementsByClassName(id)?.[0];
        const handleClose = () => {
          if (replace && index.current) {
            onClose?.();
          }
        };
        renderer.addEventListener("render", handleClose);
        setElement(root);
        return () => renderer.removeEventListener("render", handleClose);
      }, []);

      return element && createPortal(children, element);
    },
  };
};
