import { memo } from "react";
import { Loader, Modal } from "@CreativelySquared/uikit";
import { useBulkCommentsEventQuery } from "api/graphql";
import { Avatar } from "components/Avatar";
import { getFullName } from "utils/users";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Media } from "components";
import { links } from "App";
import { MentionsTextarea } from "components/Note/MentionsTextarea";

import styles from "./styles.module.scss";

type Props = {
  eventId: string;
  onClose: () => void;
};

export const BulkComment = memo<Props>(({ eventId, onClose }) => {
  const { data: { getBulkCommentsEvent } = {}, loading } =
    useBulkCommentsEventQuery({
      variables: {
        eventId,
      },
    });

  const { user, content, createdAt } =
    getBulkCommentsEvent?.comments?.[0] ?? {};

  return (
    <Modal visible onClose={onClose} closable className="min-w-[500px]">
      {loading && (
        <div className="flex justify-center items-center h-full m-10">
          <Loader radius={50} />
        </div>
      )}
      {!loading && (
        <div>
          <div className="flex mb-8">
            <Avatar user={user} className={styles.avatar} />
            <div className="flex-1">
              <p className={styles.name}>{getFullName(user)}</p>
              {createdAt && (
                <p className={styles.time}>
                  {format(new Date(+createdAt), "d MMMM yyyy | HH:mm")}
                </p>
              )}
              <MentionsTextarea
                readOnly
                value={content || undefined}
                inputClassName="whitespace-pre-line outline-none text-blackberry words-break"
              />
            </div>
          </div>
          <div className="flex gap-6 flex-wrap">
            {getBulkCommentsEvent?.comments?.map((comment) => (
              <Link
                key={comment?.commentId}
                to={links.AssetDetails({
                  id: comment?.assetVersion?.assetId ?? "",
                  versionId: comment?.assetVersion?.assetVersionId ?? "",
                  comments: "comments",
                })}
              >
                <Media
                  fileId={comment?.assetVersion?.file?.fileId}
                  type={
                    comment?.assetVersion?.file?.originalMetadata?.common?.type
                  }
                  url={comment?.assetVersion?.file?.thumbnail?.downloadUrl?.url}
                  className={styles.asset}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
});
