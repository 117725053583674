import clsx from "clsx";
import { EventDetails, EventType } from "utils/event";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { OrganizationUser } from "api/types";
import { SubscriptionPlanStatus, SubscriptionPlanType } from "api/enums";
import { cn } from "utils/styles";
import { useTranslation } from "react-i18next";
import { format, formatRelative } from "date-fns";
import { enGB } from "date-fns/locale";
import { Avatar } from "components/Avatar";
import { DividedList } from "components/DividedList";
import { Roles } from "utils/roles";
import { getFullName, User } from "utils/users";
import { links } from "App";
import { Link } from "react-router-dom";
import { Checkbox } from "@CreativelySquared/uikit";
import { useProtect } from "utils/hooks/protection";
import { CamelCase } from "utils/types";
import { contactSalesURL } from "utils/links";
import { useQueryParams } from "utils/hooks/query";
import { useProjectNavigation } from "Projects/utils/navigation";

import * as icons from "./images";

import styles from "./styles.module.scss";

export enum EventVariants {
  Feedback = "feedback",
  Full = "full",
}

type Props = {
  event: EventDetails;
  variant?: EventVariants;
  projectId?: string;
  organizationId?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

enum IconType {
  comment = "comment",
  credit = "credit",
  download = "download",
  like = "like",
  organization = "organization",
  accept = "accept",
  decline = "decline",
  delete = "delete",
  change = "change",
  create = "create",
  upload = "upload",
  revert = "revert",
  prioritise = "prioritise",
  loading = "loading",
  check = "check",
  plus = "plus",
  pending = "pending",
  star = "star",
  warning = "warning",
  calendar = "calendar",
  logo = "logo",
  discuss = "discuss",
  search = "search",
  camera = "camera",
}

const RATES: { [key: number]: string } = {
  1: "very_unsatisfied",
  2: "unsatisfied",
  3: "neutral",
  4: "satisfied",
  5: "very_satisfied",
};

enum LinkType {
  asset = "asset",
  assets = "assets",
  comment = "comment",
  customer = "customer",
  project = "project",
  brief = "brief",
  brand = "brand",
  gallery = "gallery",
  contact = "contact",
}

const eventIcon = {
  [EventType.brand_profile_edited]: IconType.change,
  [EventType.brand_profile_created]: IconType.create,
  [EventType.project_partial_delivery]: IconType.upload,
  [EventType.org_user_deleted]: IconType.delete,
  [EventType.project_regressed_to_draft]: IconType.revert,
  [EventType.new_asset_version_created]: IconType.plus,
  [EventType.project_canceled]: IconType.decline,
  [EventType.project_moved_in_queue]: IconType.prioritise,
  [EventType.project_approved]: IconType.loading,
  [EventType.project_submitted]: IconType.check,
  [EventType.project_moved_to_under_revision]: IconType.change,
  [EventType.project_finalized]: IconType.accept,
  [EventType.project_completed]: IconType.accept,
  [EventType.project_moved_to_queue]: IconType.pending,
  [EventType.project_deleted]: IconType.delete,
  [EventType.org_credits_under_threshold]: IconType.credit,
  [EventType.organization_account_manager_assigned]: IconType.organization,
  [EventType.project_account_manager_assigned]: IconType.organization,
  [EventType.project_gallery_downloaded]: IconType.download,
  [EventType.asset_downloaded]: IconType.download,
  [EventType.asset_comment_created]: IconType.comment,
  [EventType.brief_comment_created]: IconType.comment,
  [EventType.asset_rating_edited]: IconType.like,
  [EventType.project_feedback_created]: IconType.star,
  [EventType.project_feedback_edited]: IconType.star,
  [EventType.subscription_plan_expired]: IconType.warning,
  [EventType.credits_expire_alert]: IconType.calendar,
  [EventType.subscription_plan_renewed]: IconType.accept,
  [EventType.custom_subscription_expire_alert]: IconType.calendar,
  [EventType.auto_renewal_alert]: IconType.revert,
  [EventType.asset_needs_changes]: IconType.change,
  [EventType.brief_updated]: IconType.change,
  [EventType.bulk_comments_created]: IconType.discuss,
  [EventType.project_clarification_requested]: IconType.search,
  [EventType.project_created]: IconType.camera,
};

export const ActivityEvent = memo<Props>(
  ({
    event,
    className,
    variant = EventVariants.Full,
    projectId,
    organizationId,
    ...props
  }) => {
    const { t } = useTranslation("activity");
    const isProtected = useProtect();
    const getProjectNavigationLink = useProjectNavigation();
    const isAdmin = isProtected([Roles.admin, Roles.account_manager]);
    const queryParams = useQueryParams();

    const {
      asset,
      details,
      happenedAt,
      name,
      organization,
      project,
      comment,
      brandProfile,
      doneBy,
      subscriptionPlan,
      eventId,
    } = event;

    if (!(String(name) in EventType)) {
      return null;
    }

    const theSameRating =
      event?.details?.current?.vote === event?.details?.previous?.vote;
    const isPAYG =
      event.subscriptionPlan?.type === SubscriptionPlanType.Credits;
    const subscriptionStatus = event.subscriptionPlan?.status ?? "default";

    const projectLink =
      project?.projectId && !project?.deletedAt
        ? getProjectNavigationLink(project)
        : "";

    const contactSalesLink = {
      type: LinkType.contact,
      link: { pathname: contactSalesURL },
      linkProps: { target: "_blank" },
    };

    const ActivityLink: Record<string, any> = {
      [EventType.subscription_plan_edited]: contactSalesLink,
      [EventType.subscription_plan_renewed]: contactSalesLink,
      [EventType.subscription_plan_expired]: contactSalesLink,
      [EventType.custom_subscription_expire_alert]: contactSalesLink,
      [EventType.auto_renewal_alert]: contactSalesLink,
      [EventType.org_credits_under_threshold]: contactSalesLink,
      [EventType.credits_expire_alert]: contactSalesLink,
      ...(brandProfile?.brandProfileId && {
        [EventType.brand_profile_edited]: {
          type: LinkType.brand,
          link: links.BrandDetails({
            id: brandProfile.brandProfileId,
          }),
        },
        [EventType.brand_profile_created]: {
          type: LinkType.brand,
          link: links.BrandDetails({
            id: brandProfile.brandProfileId,
          }),
        },
      }),
      ...(organization?.organizationId && {
        [EventType.organization_account_manager_assigned]: {
          type: LinkType.customer,
          link: links.CustomerSettings({
            id: organization.organizationId,
          }),
        },
      }),
      ...(comment?.asset?.assetId && {
        [EventType.asset_comment_created]: {
          assetDetails: comment.asset.latestVersion?.file?.fileName,
          type: LinkType.comment,
          link: links.AssetDetails({
            id: comment.asset.assetId,
            comments: "comments",
          }),
        },
      }),
      ...(project && {
        [EventType.project_partial_delivery]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_submitted]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.brief_comment_created]: {
          type: LinkType.brief,
          link: projectLink,
        },
        [EventType.brief_updated]: {
          type: LinkType.brief,
          link: projectLink,
        },
        [EventType.project_regressed_to_draft]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_clarification_requested]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_canceled]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_moved_in_queue]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_approved]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_moved_to_under_revision]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_finalized]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_account_manager_assigned]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_completed]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_moved_to_queue]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_created]: {
          type: LinkType.project,
          link: projectLink,
        },
        [EventType.project_feedback_created]: {
          type: LinkType.project,
          link: isAdmin
            ? links.ProjectFeedback({ id: project.projectId })
            : projectLink,
        },
        [EventType.project_gallery_downloaded]: {
          type: LinkType.gallery,
          link: links.ProjectGallery({ id: project.projectId }),
        },
        [EventType.project_feedback_edited]: {
          type: LinkType.project,
          link: isAdmin
            ? links.ProjectFeedback({ id: project.projectId })
            : projectLink,
        },
      }),
      ...(asset?.assetId && {
        [EventType.asset_downloaded]: {
          assetDetails: asset?.latestVersion?.file?.fileName,
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
        [EventType.asset_rating_edited]: {
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
        [EventType.new_asset_version_created]: {
          assetDetails: asset.latestVersion?.file?.fileName,
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
        [EventType.asset_needs_changes]: {
          type: LinkType.asset,
          link: links.AssetDetails({
            id: asset.assetId,
          }),
        },
      }),
      ...((doneBy as OrganizationUser)?.organization?.organizationId && {
        [EventType.org_user_deleted]: {
          type: LinkType.customer,
          link: links.CustomerSettings({
            id:
              (doneBy as OrganizationUser)?.organization?.organizationId ?? "",
          }),
        },
      }),
      ...(subscriptionPlan?.subscriptionPlanId && {
        ...(isPAYG && {
          [EventType.subscription_plan_expired]: contactSalesLink,
        }),
      }),
      ...(eventId && {
        [EventType.bulk_comments_created]: {
          type: LinkType.assets,
          link: projectId
            ? links.ProjectActivity({
                id: projectId,
                ...queryParams,
                eventId,
              })
            : organizationId
            ? links.CustomerActivity({
                id: organizationId,
                ...queryParams,
                eventId,
              })
            : links.Activity({ ...queryParams, eventId }),
        },
      }),
    };

    const isFeedback = [
      EventType.project_feedback_created,
      EventType.project_feedback_edited,
    ].includes(name as EventType);

    const date =
      happenedAt &&
      formatRelative(+happenedAt, new Date(), {
        locale: {
          ...enGB,
          formatRelative: (token: string) =>
            t(`timeFormat.${token === "today" ? "today" : "other"}`),
        },
      });

    const isProject = variant === EventVariants.Feedback;

    const skipName =
      [
        EventType.brand_profile_edited,
        EventType.organization_account_manager_assigned,
        EventType.project_account_manager_assigned,
        EventType.subscription_plan_expired,
        EventType.org_credits_under_threshold,
        EventType.credits_expire_alert,
        EventType.subscription_plan_renewed,
        EventType.custom_subscription_expire_alert,
        EventType.auto_renewal_alert,
        EventType.subscription_plan_edited,
      ].includes(name as EventType) ||
      (isFeedback && theSameRating && !isProject);

    const organizationName =
      name === EventType.org_user_deleted &&
      (organization?.name ?? (doneBy as OrganizationUser)?.organization?.name);

    const subscriptionPlanIcon = {
      SUSPENDED: IconType.warning,
      TRIAL: IconType.accept,
      CANCELED: IconType.decline,
      ACTIVE: IconType.accept,
      PENDING: IconType.logo,
      EXPIRED: IconType.warning,
      default: IconType.change,
    };

    const Icon =
      icons[
        (event.name === EventType.subscription_plan_edited
          ? subscriptionPlanIcon[subscriptionStatus as SubscriptionPlanStatus]
          : eventIcon[
              name as Exclude<EventType, EventType.subscription_plan_edited>
            ]) as keyof typeof icons
      ];

    const activeLink = ActivityLink[name as keyof typeof ActivityLink];

    const ratingChange = (event.current ?? 0) - (event.previous ?? 0);

    const isDislike =
      name === EventType.asset_rating_edited && ratingChange < 0;

    const isExpiredPlan = name === EventType.subscription_plan_expired;
    const isEditedPlan = name === EventType.subscription_plan_edited;

    const translationPath = `card.${name}${
      isEditedPlan
        ? `.${subscriptionStatus}`
        : isExpiredPlan
        ? isPAYG
          ? ".PAYG"
          : ".subscription"
        : isFeedback
        ? theSameRating
          ? ".feedback"
          : isProject
          ? ".project"
          : ".activity"
        : ""
    }`;

    return (
      <section className={clsx(styles.activityEvent, className)} {...props}>
        <div
          className={clsx(
            styles.icon,
            styles[cn("icon", name as CamelCase<EventType>)],
            {
              [styles.dislike]: isDislike,
            }
          )}
        >
          {isProject ? (
            <Avatar className={styles.avatar} user={doneBy as User} />
          ) : (
            <Icon />
          )}
        </div>
        <div className="flex-1">
          <h3 className={styles.title}>
            {t(translationPath, {
              ...event,
              assetDetails: activeLink?.assetDetails,
              commentDetails: `(${t("common:common.title")})`,
              brandName: brandProfile?.name ?? t("unknown"),
              rate:
                details && details.current?.vote
                  ? t(`rates.${RATES[details.current.vote!]}`)
                  : "",
              ...(event?.subscriptionPlan?.expirationDate && {
                expirationDate: format(
                  new Date(+event.subscriptionPlan.expirationDate),
                  "dd MMMM yyyy"
                ),
              }),
            })}
          </h3>

          <DividedList className={styles.info} separator="|">
            {!skipName &&
              variant === EventVariants.Full &&
              (organizationName ? (
                <span>{organizationName}</span>
              ) : (
                !!getFullName(doneBy) && <span>{getFullName(doneBy)}</span>
              ))}

            {date && <span>{date}</span>}

            {!!activeLink?.link &&
              !isProject &&
              !(organizationId && activeLink.type === LinkType.customer) && (
                <Link to={activeLink.link} {...(activeLink.linkProps ?? {})}>
                  {t(`link.${activeLink.type}`)}
                </Link>
              )}
          </DividedList>

          {isFeedback && (
            <footer>
              {event.details?.current?.note && (
                <div className={styles.note}>
                  {event.details?.current?.note}
                </div>
              )}
              {event.details?.current?.isFeedbackCallRequested && (
                <Checkbox checked className="mt-7 cursor-text" readOnly>
                  {t("common:feedback.requested")}
                </Checkbox>
              )}
            </footer>
          )}
        </div>
      </section>
    );
  }
);
