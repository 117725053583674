import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-hoc";
import { Link } from "react-router-dom";
import { Roles } from "utils/roles";
import { useProtect } from "utils/hooks/protection";

import { links } from "../App";

import { ReactComponent as ArrowIcon } from "./images/arrow.svg";

import styles from "./styles.module.scss";

export const NotFound = Route("/not-found")(() => {
  const { t } = useTranslation();
  const getAccess = useProtect({ suspense: true });
  const isCustomer = getAccess(Roles.organization_owner);

  return (
    <main className={styles.container}>
      <Helmet>
        <title>{t("notFound.title")}</title>
      </Helmet>

      <h2>{t("notFound.title")}</h2>

      <p>
        <Link to={isCustomer ? links.CustomerDashboard() : links.Dashboard()}>
          <ArrowIcon />
          {t(`notFound.link`)}
        </Link>
      </p>
    </main>
  );
});
