import {
  Alert,
  Button,
  Dropdown,
  Loader,
  Tabs,
} from "@CreativelySquared/uikit";
import {
  ProjectAssetsDocument,
  GalleryProjectDocument,
  ProjectDocument,
  useEditAssetsMainOutcomeMutation,
  useProjectQuery,
  useUpdateAssetStatusMutation,
} from "api/graphql";
import { Asset } from "api/types";
import { AssetStatus } from "api/enums";
import { links } from "App";
import { Services } from "utils/services";
import clsx from "clsx";
import { AssetLabel, MediaCard, StatusLabel } from "components";
import { useFormik } from "formik";
import { groupBy, sortBy, xor } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { array, object, string } from "yup";
import { FileStatuses } from "utils/types";
import { ProjectStatuses } from "Projects/utils/types";
import { getOperationName } from "@apollo/client/utilities";
import { getFileMetadata } from "utils/file";

import { ReactComponent as ArrowIcon } from "../images/arrow.svg";

import styles from "./styles.module.scss";

export const ProjectGalleryAssignRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
      conceptId: Route.query.regex(idRegEx),
    },
    ({ id }) => `/projects/${id}/manage/assign`
  )
);

const validationSchema = object({
  assets: array().of(
    object({
      mainOutcomeId: string().required(),
    })
  ),
});

export const ProjectGalleryAssign = ProjectGalleryAssignRoute(
  ({
    match: {
      params: { id },
      query: { conceptId },
    },
    history: { push },
    link,
  }) => {
    const [t] = useTranslation("projects");
    const [selected, setSelected] = useState<number[]>([]);
    const [order, setOrder] = useState<string[]>([]);
    const { data: { getProject: project } = {}, loading } = useProjectQuery({
      variables: {
        projectId: id,
        includeBrief: true,
        includeBriefOutcomes: true,
        includeAssets: true,
      },
    });

    const [editOutcomes, { error, loading: editLoading }] =
      useEditAssetsMainOutcomeMutation({
        refetchQueries: [
          getOperationName(ProjectAssetsDocument)!,
          { query: GalleryProjectDocument, variables: { projectId: id } },
          {
            query: ProjectDocument,
            variables: { projectId: id, includeOutcomes: true },
          },
        ],
      });

    const [updateStatus, { loading: statusLoading }] =
      useUpdateAssetStatusMutation();

    const {
      values: { assets },
      errors,
      touched,
      submitCount,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setValues,
    } = useFormik<{ assets: Asset[] }>({
      initialValues: {
        assets: [],
      },
      validationSchema,
      onSubmit: () => push(links.ProjectGalleryCover({ id })),
    });

    const activeConceptId = useMemo(
      () => conceptId ?? project?.brief?.concepts?.[0]?.conceptId ?? null,
      [conceptId, project?.brief?.concepts]
    );

    useEffect(() => {
      setSelected([]);
    }, [activeConceptId]);

    const activeOutcomes = useMemo(
      () =>
        project?.brief?.concepts
          ?.find((concept) => concept?.conceptId === activeConceptId)
          ?.outcomes?.reduce(
            (
              acc: Array<{
                serviceId: string;
                outcomeId: string;
                amount: number;
              }>,
              outcome
            ) => {
              const serviceId = outcome?.service?.serviceId ?? "";
              const amount = outcome?.amount ?? 0;

              if (!Services[serviceId as Services]) {
                return acc;
              }

              const existingOutcome = acc.find(
                (item) => item.serviceId === serviceId
              );

              return existingOutcome
                ? acc.map((item) =>
                    item.serviceId === serviceId
                      ? {
                          ...item,
                          amount: item.amount + amount,
                        }
                      : item
                  )
                : [
                    ...acc,
                    {
                      serviceId,
                      outcomeId: outcome?.outcomeId ?? "",
                      amount,
                    },
                  ];
            },
            []
          ),
      [project?.brief?.concepts, activeConceptId]
    );

    useEffect(() => {
      const sorted = order.length
        ? order.map((orderId) =>
            project?.assets2?.find((asset) => asset?.assetId === orderId)
          )
        : sortBy(project?.assets2, "mainOutcomeId");

      if (!order.length) {
        setOrder(sorted.map((asset) => asset?.assetId ?? "") ?? []);
      }

      setFieldValue("assets", sorted);
    }, [project?.assets2]);

    const counts = useMemo(() => {
      return groupBy(assets, (item) => item.mainOutcomeId);
    }, [assets]);

    const isEmpty = useMemo(() => {
      return !assets?.filter((asset) => asset.conceptId === activeConceptId)
        ?.length;
    }, [assets, activeConceptId]);

    const allConceptAssetsKeys = useMemo(() => {
      return assets?.reduce((acc, { conceptId }, key) => {
        if (conceptId === activeConceptId) return acc.concat(key);
        return acc;
      }, new Array<number>());
    }, [assets, activeConceptId]);

    const onMultipleAssign = async (value?: string) => {
      if (!value) return;

      const assigned = assets.map((asset, key) =>
        selected.includes(key) ? { ...asset, mainOutcomeId: value } : asset
      );

      const assetsMainOutcomeFields = assigned
        .filter((item) => item.mainOutcomeId)
        .map((item) => ({
          assetId: item.assetId ?? "",
          mainOutcomeId: item.mainOutcomeId ?? "",
        }));

      await editOutcomes({
        variables: {
          assetsMainOutcomeFields,
        },
      });

      setValues({ assets: assigned });
      setSelected([]);
    };

    const onStatusUpdate = async (status?: AssetStatus) => {
      if (!status) return;

      const ids = assets
        .filter((_, key) => selected.includes(key))
        .map(({ assetId }) => assetId!);

      await updateStatus({
        variables: {
          ids,
          status,
        },
      });

      setSelected([]);
    };

    return (
      <form className="flex grow flex-col" onSubmit={handleSubmit}>
        <Tabs className="mt-7 mb-9 flex-wrap" size={Tabs.sizes.Small}>
          {project?.brief?.concepts?.map((concept, key) => (
            <Tabs.Item
              key={concept?.conceptId}
              isActive={() => concept?.conceptId === activeConceptId}
              component={NavLink}
              to={link({
                id,
                conceptId: concept?.conceptId ?? "",
              })}
            >
              {concept?.name || t(`common:concepts.titles.${key + 1}`)}
            </Tabs.Item>
          ))}
        </Tabs>
        {((errors.assets?.length && !!submitCount) || error) && (
          <Alert
            className="mb-9"
            icon
            variant={Alert.variants.Error}
            title={t(
              `gallery.manage.assign.errors.${error ? "general" : "required"}`
            )}
          ></Alert>
        )}
        {!!activeOutcomes?.length && (
          <section className={styles.deliverables}>
            <span className="mr-7 mb-6">
              {t("gallery.manage.assign.deliverables")}
            </span>
            {activeOutcomes.map((deliverable) => (
              <AssetLabel
                selected={counts?.[deliverable?.outcomeId as string]?.length}
                className="mr-5 mb-6"
                type={deliverable?.serviceId as Services}
                count={deliverable?.amount || 0}
                key={deliverable?.serviceId}
              />
            ))}
          </section>
        )}
        {!isEmpty && (
          <section
            className={clsx("flex items-start mt-9", {
              ["justify-end"]: !selected.length,
              ["justify-between"]: selected.length,
            })}
          >
            {!!selected.length && (
              <section className="flex gap-6 flex-wrap">
                <Dropdown
                  variant={Button.variants.Secondary}
                  loading={editLoading}
                  label={t("gallery.manage.assign.options.placeholder")}
                >
                  {activeOutcomes?.map((outcome) => (
                    <Dropdown.Item
                      key={outcome?.outcomeId}
                      onSelect={() => onMultipleAssign(outcome?.outcomeId)}
                    >
                      {t(`common:asset.label.${outcome?.serviceId}.title`, {
                        count: 2,
                      })}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
                {project?.status !== ProjectStatuses.finalized && (
                  <Dropdown
                    variant={Button.variants.Secondary}
                    loading={statusLoading}
                    label={t("gallery.manage.assign.status.placeholder")}
                  >
                    {[AssetStatus.Delivered, AssetStatus.NeedsReview]?.map(
                      (status) => (
                        <Dropdown.Item
                          onSelect={() => onStatusUpdate(status)}
                          key={status}
                        >
                          {t(`common:asset.status.${status}.label`, {
                            count: 2,
                          })}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown>
                )}
              </section>
            )}
            <Button
              type="button"
              className="rounded-full border-2"
              onClick={() =>
                setSelected(selected.length ? [] : allConceptAssetsKeys)
              }
            >
              {t("gallery.manage.assign.bar.actions.select", {
                count: +!!selected.length,
              })}
            </Button>
          </section>
        )}
        <main className="mt-9 flex grow flex-col">
          {loading && (
            <section className="flex w-full items-center justify-center">
              <Loader radius={50} />
            </section>
          )}
          {isEmpty && !loading && (
            <section className={styles.galleryEmpty}>
              <h2>{t("gallery.manage.assign.empty.title")}</h2>
              <span>{t("gallery.manage.assign.empty.description")}</span>
            </section>
          )}
          <section className="grid grid-cols-4 gap-8 mb-10 empty:mb-8 grow">
            {assets?.map((asset, key) => {
              /** Filter assets only during render in order to have correct index for deliverable selection */
              if (asset.conceptId !== activeConceptId) return null;
              const metadata = getFileMetadata(asset?.latestVersion?.file);

              return (
                <section key={asset?.assetId} className="relative">
                  {asset.status && (
                    <StatusLabel
                      size={StatusLabel.sizes.Tiny}
                      className="absolute right-6 top-7 z-[1]"
                      type="asset"
                      status={asset.status}
                    />
                  )}

                  <MediaCard
                    fileId={asset?.latestVersion?.file?.fileId}
                    onSelect={() => setSelected(xor(selected, [key]))}
                    selected={selected.includes(key)}
                    fileName={asset?.latestVersion?.file?.fileName}
                    status={asset.latestVersion?.file?.status as FileStatuses}
                    src={
                      asset?.latestVersion?.file?.thumbnail?.downloadUrl?.url
                    }
                    size={metadata?.common?.size}
                    type={metadata?.common?.type}
                  />
                  <Dropdown
                    onSelect={async (value) => {
                      if (asset.assetId && value) {
                        await editOutcomes({
                          variables: {
                            assetsMainOutcomeFields: {
                              assetId: asset.assetId,
                              mainOutcomeId: value,
                            },
                          },
                        });
                        setFieldValue(`assets[${key}].mainOutcomeId`, value);
                      }
                    }}
                    error={
                      Boolean(touched.assets?.[key] || submitCount) &&
                      Boolean(errors.assets?.[key])
                    }
                    className="w-full mt-5"
                    label={t("gallery.manage.assign.options.placeholder")}
                    value={asset.mainOutcomeId ?? null}
                  >
                    {activeOutcomes?.map((outcome) => (
                      <Dropdown.Item
                        value={outcome?.outcomeId}
                        key={outcome?.outcomeId}
                      >
                        {t(`common:asset.label.${outcome?.serviceId}.title`, {
                          count: 1,
                        })}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </section>
              );
            })}
          </section>
          {!loading && (
            <section className="flex justify-end">
              <Button loading={isSubmitting} type="submit">
                {t("common:navigation.next")}
                <ArrowIcon className="ml-4" />
              </Button>
            </section>
          )}
        </main>
      </form>
    );
  }
);
