import { CanvasMetadata } from "api/types";

export const ratioPercentage = (
  { height, width }: { width: number; height: number },
  canvas?: CanvasMetadata | null
) => {
  const canvasWidth = canvas?.width ?? 0;
  const canvasHeight = canvas?.height ?? 0;
  const calculatedWidth = (canvasHeight * (width / height) * 100) / canvasWidth;
  const calculatedHeight =
    (canvasWidth * (height / width) * 100) / canvasHeight;

  if (width > height) {
    const heightOverSized = calculatedHeight > 100;

    return {
      width: heightOverSized ? calculatedWidth : 100,
      height: heightOverSized ? 100 : calculatedHeight,
    };
  }

  if (width < height) {
    const widthOverSized = calculatedWidth > 100;

    return {
      width: widthOverSized ? 100 : calculatedWidth,
      height: widthOverSized ? calculatedHeight : 100,
    };
  }

  if (width === height) {
    const widthBigger = canvasWidth > canvasHeight;

    return {
      width: widthBigger ? (canvasHeight * 100) / canvasWidth : 100,
      height: widthBigger ? 100 : (canvasWidth * 100) / canvasHeight,
    };
  }
};
