import clsx from "clsx";
import { memo } from "react";
import { GetProps } from "react-router-hoc/lib/types";
import { Label } from "@CreativelySquared/uikit";
import { cn } from "utils/styles";
import { useTranslation } from "react-i18next";
import { ProjectStatuses } from "Projects/utils/types";
import { CamelCase } from "utils/types";
import { AssetStatus } from "api/types";

import styles from "./styles.module.scss";

type Props = {
  loading?: boolean;
} & (
  | {
      type?: "project";
      status: ProjectStatuses | "proposal";
    }
  | {
      type: "asset";
      status: AssetStatus;
    }
) &
  GetProps<typeof Label>;

export const StatusLabel = Object.assign(
  memo<Props>(({ className, type = "project", status, loading, ...props }) => {
    const { t } = useTranslation();

    return (
      <Label
        {...props}
        className={clsx(
          styles.status,
          styles[cn("status", status as CamelCase<ProjectStatuses>)],
          {
            [styles.loading]: loading,
          },
          className
        )}
      >
        {type === "project"
          ? t(`project.statuses.${status}`)
          : t(`asset.status.${status}.label`)}
      </Label>
    );
  }),
  {
    sizes: Label.sizes,
  }
);
