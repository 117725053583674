export const urlToFile = async ({
  url,
  fileName = `media-${Date.now()}`,
  availableFormats,
}: {
  url: string;
  fileName?: string;
  availableFormats?: string[];
}) => {
  const response = await fetch(url);
  const blob = await response.blob();

  if (!availableFormats || availableFormats.includes(blob.type)) {
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } else {
    throw new Error("Invalid file format");
  }
};
