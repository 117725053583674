import { FC } from "react";
import { MediaCard } from "components/MediaCard";
import { Button, Dropdown, Loader } from "@CreativelySquared/uikit";
import { ReactComponent as BinIcon } from "images/bin.svg";
import clsx from "clsx";
import { FileStatuses } from "utils/types";
import { ReactComponent as DownloadIcon } from "images/download.svg";
import { useDownload } from "utils/hooks/download";
import { ReactComponent as ActionsIcon } from "images/actions.svg";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export type AttachmentData = {
  id: string;
  src: string;
  type: string;
  name: string;
  status?: FileStatuses;
};

type Props = {
  files: AttachmentData[];
  loading?: boolean;
  onRemove?: (fileId: string) => void;
  autoPreview?: boolean;
  hasDownload?: boolean;
  className?: string;
};

export const Attachments: FC<Props> = ({
  files,
  onRemove,
  loading,
  autoPreview,
  hasDownload = false,
  className,
}) => {
  const { t } = useTranslation();
  const [download, { downloading }] = useDownload();
  const onDownload = (fileId: string) => download([{ fileId }]);
  const hasOptionsList = hasDownload && onRemove;

  return (
    <div className={clsx(styles.attachments, className)}>
      {files?.map(({ id, src, type, name, status }) => (
        <div className={styles.attachmentsItem} key={id}>
          <MediaCard
            fileId={id}
            src={src}
            className={styles.media}
            type={type}
            status={status}
            autoPreview={autoPreview}
            previewDownload={hasDownload}
          />
          {name && <p className={styles.attachmentsItemName}>{name}</p>}
          {!hasOptionsList && id && !loading && !!onRemove && (
            <Button
              className={clsx(styles.icon, "-top-4 -right-3")}
              onClick={() => onRemove(id)}
              type="button"
              variant={Button.variants.Icon}
            >
              <BinIcon />
            </Button>
          )}
          {!hasOptionsList && hasDownload && (
            <Button
              type="button"
              className={styles.icon}
              variant={Button.variants.Icon}
              onClick={() => onDownload(id)}
              disabled={downloading}
            >
              {downloading ? <Loader /> : <DownloadIcon />}
            </Button>
          )}
          {hasDownload && onRemove && (
            <Dropdown
              render={({ visible }) => (
                <Button
                  variant={Button.variants.Icon}
                  type="button"
                  className={clsx(styles.icon, styles.actions, {
                    [styles.active]: visible,
                  })}
                >
                  <ActionsIcon />
                </Button>
              )}
              placement={Dropdown.placements.BottomEnd}
              popoverClassName={styles.actionsMenu}
            >
              <Dropdown.Item>
                <Button
                  variant={Button.variants.Cancel}
                  onClick={() => onDownload(id)}
                  loading={downloading}
                >
                  {t("actions.download")}
                </Button>
              </Dropdown.Item>
              <Dropdown.Item>
                <Button
                  variant={Button.variants.Cancel}
                  onClick={() => onRemove(id)}
                >
                  {t("actions.delete")}
                </Button>
              </Dropdown.Item>
            </Dropdown>
          )}
        </div>
      ))}
    </div>
  );
};
