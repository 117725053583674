import { memo } from "react";
import type { Object } from "ts-toolbelt";
import { Media } from "components";
import clsx from "clsx";
import { useLocation } from "react-router";
import { links } from "App";
import { Link, LinkProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Asset } from "api/types";
import { deliverableMedia, Services, DeliverableMedias } from "utils/services";

import imageIcon from "./images/ImageIcon";
import motionIcon from "./images/MotionIcon";
import videoIcon from "./images/VideoIcon";

import styles from "./styles.module.scss";

type Props = {
  asset: Object.Partial<Asset, "deep">;
  versionId?: string;
} & Omit<LinkProps, "to">;

const placeholderImages = {
  [DeliverableMedias.image]: imageIcon,
  [DeliverableMedias.video]: videoIcon,
  [DeliverableMedias.motion]: motionIcon,
};

export const AssetPreview = memo<Props>(
  ({ asset, versionId, className, ...props }) => {
    if (!asset.assetId) return null;
    const { t } = useTranslation("projects");
    const { pathname, search } = useLocation();

    const type = deliverableMedia(asset?.mainOutcome?.serviceId as Services);

    const PlaceholderIcon = placeholderImages[type ?? DeliverableMedias.image];
    const file = versionId
      ? asset.versions?.find((version) => version?.assetVersionId === versionId)
          ?.file
      : asset.versions?.at(-1)?.file ?? asset.latestVersion?.file;

    return (
      <Link
        to={{
          pathname: links.AssetDetails({
            id: asset.assetId,
          }),
          search: `versionId=${versionId}`,
          state: {
            assetPath: `${pathname}${search}`,
          },
        }}
        className={clsx(styles.assetPreview, className)}
        {...props}
      >
        <span>{t("assets.preview.label")}</span>
        <PlaceholderIcon className={styles.assetPreviewPlaceholder} />
        <div className={styles.assetPreviewMedia}>
          <Media
            fileId={file?.thumbnail?.fileId}
            url={file?.thumbnail?.downloadUrl?.url}
            type={file?.originalMetadata?.common?.type}
          />
        </div>
      </Link>
    );
  }
);

AssetPreview.displayName = "AssetPreview";
