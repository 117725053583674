import clsx from "clsx";
import React, { FC } from "react";

interface Props {
  message: string;
  className?: string;
}

export const ErrorMessage: FC<Props> = ({ message, className }) => (
  <span className={clsx("text-xs text-red", className)}>{message}</span>
);
