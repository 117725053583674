import { useViewFileQuery } from "api/graphql";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  forwardRef,
  DetailedHTMLProps,
  ImgHTMLAttributes,
  memo,
  ReactEventHandler,
  useState,
  useEffect,
  useRef,
  ReactElement,
} from "react";
import { createPortal } from "react-dom";
import RegionSelect from "react-region-select";
import { useMousePosition } from "utils/hooks/mousePosition";
import { cn } from "utils/styles";
import { isNumber } from "lodash";

import { ReactComponent as PlayIcon } from "./images/play.svg";

import styles from "./styles.module.scss";

export type RegionType = {
  data?: any;
  height: number;
  isChanging: boolean;
  width: number;
  x: number;
  y: number;
  time?: number;
  ratio?: number;
  metadata?: string;
};

export type ActiveRegionType = {
  index?: number;
  time?: number;
} | null;

type Props = Omit<
  // TODO: type is incorrect if we pass video
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  "ref" | "onSelect" | "onClick"
> & {
  fileId?: string;
  url?: string | null;
  type?: string | null;
  preview?: "instant" | "hover";
  onError?: ReactEventHandler<HTMLImageElement | HTMLVideoElement>;
  onLoad?: ReactEventHandler<HTMLImageElement | HTMLVideoElement>;
  onSelect?: (region: RegionType[]) => void;
  selected?: RegionType[];
  areaWidget?: ReactElement | boolean;
  regions?: RegionType[];
  areaLabel?: string | boolean | null;
  activeRegion?: ActiveRegionType;
  setActiveRegion?: (region: ActiveRegionType) => void;
  sectionClassName?: string;
};

enum VideoThumbnails {
  hq_video_small = "hq_video_small",
  dwn_video_medium = "dwn_video_medium",
  hq_video_large = "hq_video_large",
}

export const Media = Object.assign(
  memo(
    forwardRef<HTMLImageElement, Props>(
      (
        {
          fileId,
          url,
          type,
          preview = "hover",
          onError,
          onSelect,
          selected,
          areaWidget,
          regions,
          activeRegion,
          setActiveRegion,
          className,
          areaLabel,
          sectionClassName,
          ...props
        },
        ref
      ) => {
        if (!url) {
          return null;
        }
        const { t } = useTranslation();
        const [hover, setHover] = useState(false);
        const [move, setMove] = useState(false);
        const [previewLoaded, setPreviewLoaded] = useState(false);
        const [initialResizePosition, setInitialResizePosition] = useState<{
          x: number;
          y: number;
        } | null>(null);
        const [widgetPosition, setWidgetPosition] = useState<
          "bottom" | "top" | "left" | "top-left" | "right" | "center"
        >("bottom");
        const previewRef = useRef<HTMLVideoElement>(null);
        const isVideoType = type === "video";
        const hoverPreview = preview === "hover";
        const localFile = url.includes("blob:");
        const mousePosition = useMousePosition();
        const selectable = !!onSelect;
        const initialTime = isVideoType ? 0 : undefined;
        const [time, setTime] = useState<number | undefined>(initialTime);
        useEffect(() => {
          setPreviewLoaded(false);
          setTime(initialTime);
        }, [fileId]);

        const { data: { getFile: video } = {} } = useViewFileQuery({
          variables: {
            fileId: fileId!,
            thumbnail: hoverPreview
              ? VideoThumbnails.hq_video_small
              : VideoThumbnails.dwn_video_medium,
          },
          nextFetchPolicy: "cache-only",
          skip: !isVideoType || !fileId || localFile,
        });

        const handleResize = (data: RegionType[]) => {
          const area = data?.[0];
          const clickOutside = !area.height && !area.width && !area.isChanging;

          const height = area.ratio ? area.width / area.ratio : area.height;
          const width = area.ratio ? height * area.ratio : area.width;
          let x = area.ratio ? initialResizePosition?.x ?? area.x : area.x;
          let y = area.ratio ? initialResizePosition?.y ?? area.y : area.y;

          if (!initialResizePosition && area.ratio) {
            setInitialResizePosition({ x: area.x, y: area.y });
          }

          if ((event as any)?.target?.dataset?.wrapper) {
            // Attach the block to the side when moving nearby
            if (width + x > 99) x = 100 - width;
            if (height + y > 99) y = 100 - height;

            if (initialResizePosition || clickOutside) {
              setInitialResizePosition(null);
            }
          }

          if (width + x <= 100 && height + y <= 100) {
            onSelect?.(
              clickOutside
                ? []
                : [
                    {
                      ...area,
                      x,
                      y,
                      width,
                      height,
                      time,
                    },
                  ]
            );
          }
        };

        useEffect(() => {
          if (selectable) previewRef.current?.pause();
        }, [selectable]);

        useEffect(() => {
          hover ? previewRef?.current?.play() : previewRef?.current?.pause();
        }, [hover]);

        useEffect(() => {
          if (
            activeRegion &&
            previewRef!.current &&
            isVideoType &&
            isNumber(activeRegion.time)
          ) {
            previewRef.current.pause();
            previewRef.current.currentTime = activeRegion.time;
          }
        }, [activeRegion]);

        useEffect(() => {
          const area = selected?.[0];
          const overlapLimit = 70;
          const minSpace = 100 - overlapLimit;

          const rightOverlap =
            Number(area?.width) + Number(area?.x) > overlapLimit;
          const bottomOverlap =
            Number(area?.height ?? 0) + Number(area?.y) > overlapLimit;
          const leftOverlap = Number(area?.x) < minSpace;
          const topOverlap = Number(area?.y) < minSpace;

          setWidgetPosition(
            rightOverlap && bottomOverlap && topOverlap && leftOverlap
              ? "center"
              : rightOverlap && bottomOverlap && !topOverlap && !leftOverlap
              ? "top-left"
              : topOverlap && !rightOverlap
              ? "right"
              : rightOverlap && !leftOverlap
              ? "left"
              : bottomOverlap && !topOverlap
              ? "top"
              : "bottom"
          );
        }, [selected]);

        const videoSrc = localFile ? url : video?.view?.downloadUrl?.url ?? "";

        return (
          <section
            onMouseEnter={() => hoverPreview && setHover(true)}
            onMouseLeave={() => {
              if (selectable) setMove(false);
              if (hoverPreview) setHover(false);
            }}
            onMouseMove={() => selectable && setMove(true)}
            onClick={() => activeRegion?.index && setActiveRegion?.(null)}
            className={clsx(
              "relative m-auto flex items-center justify-center",
              sectionClassName
            )}
          >
            {!previewLoaded &&
              !(preview === "instant" && isVideoType) &&
              !(isVideoType && localFile) && (
                <img
                  src={url}
                  onError={onError}
                  ref={ref}
                  className={clsx(className, styles.media)}
                  {...props}
                />
              )}
            {(video?.view?.downloadUrl?.url || (localFile && isVideoType)) && (
              <>
                <video
                  onError={onError}
                  ref={previewRef}
                  className={clsx(className, styles.media)}
                  loop
                  onLoadedData={(event) => {
                    setPreviewLoaded(true);
                    props?.onLoad?.(event);
                  }}
                  key={videoSrc}
                  onTimeUpdate={() =>
                    setTime(previewRef?.current?.currentTime ?? 0)
                  }
                  controls={!hoverPreview}
                  muted
                >
                  <source src={videoSrc} />
                </video>
                {!hover && hoverPreview && (
                  <div className={styles.play}>
                    <PlayIcon />
                  </div>
                )}
              </>
            )}
            {(onSelect || !!regions?.length) && (
              <RegionSelect
                maxRegions={1}
                regions={(selectable ? selected : regions) ?? []}
                onChange={handleResize}
                className={clsx(styles.selection, {
                  [styles.selectionView]: !selectable,
                  [styles.selectionViewVideo]: isVideoType,
                  [styles.selectionRatio]: !!selected?.[0]?.ratio,
                })}
                regionStyle={{ border: "none", outline: "none" }}
                style={{ position: "absolute" }}
                constraint
                regionRenderer={({
                  data: { index },
                  isChanging,
                }: {
                  data: { index: number };
                  isChanging: boolean;
                }) => {
                  if (
                    !selectable &&
                    (isVideoType
                      ? activeRegion?.index === index &&
                        activeRegion.time === time
                      : true)
                  ) {
                    return (
                      <div
                        className={clsx(styles.selectionIndex, {
                          [styles.selectionIndexActive]:
                            activeRegion?.index === index,
                        })}
                      >
                        <div className={styles.selectionIndexRegion} />
                        <button
                          type="button"
                          onClick={(event) => {
                            setActiveRegion?.({ index });
                            event.stopPropagation();
                          }}
                        >
                          {index}
                        </button>
                      </div>
                    );
                  }

                  if (areaWidget) {
                    return (
                      <div
                        className={clsx(
                          [styles.selectionWidget],
                          styles[cn("selectionWidget", widgetPosition)],
                          { hidden: isChanging }
                        )}
                      >
                        {areaWidget}
                      </div>
                    );
                  }
                }}
              >
                <img
                  src={url}
                  className={clsx(
                    className,
                    styles.media,
                    "z-10 opacity-0 pointer-events-none"
                  )}
                  {...props}
                />
              </RegionSelect>
            )}
            {selectable &&
              move &&
              !selected?.length &&
              createPortal(
                <div
                  className={styles.selectionMessage}
                  style={{
                    left: mousePosition.clientX,
                    top: mousePosition.clientY,
                  }}
                >
                  {areaLabel || t("common:asset.actions.selection.hover")}
                </div>,
                document.body
              )}
          </section>
        );
      }
    )
  ),
  {
    displayName: "Media",
  }
);
