import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CollectionCard,
  ItemSelector,
  ModalDescription,
  ModalTitle,
  Pagination,
  useNotification,
} from "components";
import { Button, Loader, Modal } from "@CreativelySquared/uikit";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import clsx from "clsx";
import { Asset, Collection } from "api/types";
import {
  useAddAssetsToCollectionsMutation,
  useGetCollectionsQuery,
  useProfileQuery,
} from "api/graphql";
import { ReactComponent as ArrowIcon } from "images/pagination-arrow.svg";

import styles from "./styles.module.scss";

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: (collections: Array<Collection>, isNewCollection?: boolean) => void;
  assetsIds: Array<Asset["assetId"]>;
};

const cardsPerPage = 9;

export const Collections = memo<Props>(
  ({ visible, onClose, onSubmit, assetsIds }) => {
    const { t } = useTranslation("dam");
    const { setNotification, notificationTypes } = useNotification();
    const [isNewCollection, setIsNewCollection] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState<
      Array<Collection>
    >([]);
    const selectedIds = selectedCollections.map(
      (collection) => collection.collectionId
    );
    const [page, setPage] = useState(1);
    const firstPage = page === 1;
    const limit = firstPage ? cardsPerPage - 1 : cardsPerPage;
    const offset = (page - 1) * limit;
    const pagination = {
      limit,
      offset: offset <= 0 ? 0 : offset - 1,
    };

    const { data: { me: profile } = {}, loading: profileLoading } =
      useProfileQuery();
    const organizationId =
      profile?.__typename === "OrganizationUser"
        ? profile?.organization?.organizationId
        : undefined;
    const { data: { collections } = {}, loading: collectionsLoading } =
      useGetCollectionsQuery({
        variables: {
          organizationId,
          pagination,
        },
        fetchPolicy: "cache-and-network",
        skip: profileLoading,
      });
    const totalPages = Math.ceil(
      ((collections?.pageInfo?.totalItems ?? 0) + 1) / cardsPerPage
    );

    const [addAssetsToCollections, { loading: addAssetsToCollectionsLoading }] =
      useAddAssetsToCollectionsMutation({
        onError(error) {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
      });

    const handleClose = useCallback(() => {
      setIsNewCollection(false);
      setSelectedCollections([]);
      onClose();
    }, []);

    const loading =
      profileLoading || (collectionsLoading && !collections?.nodes?.length);

    return (
      <Modal
        visible={visible}
        onClose={handleClose}
        className={styles.addToCollection}
        closable
      >
        <ModalTitle>{t("collections.add.heading")}</ModalTitle>
        <ModalDescription>{t("collections.add.description")}</ModalDescription>
        {loading && (
          <div className="flex justify-center items-center h-full">
            <Loader radius={50} />
          </div>
        )}
        {!loading && (
          <>
            <div className="overflow-auto h-full flex flex-col justify-between">
              <div className="grid grid-cols-3 p-3 gap-7 mb-auto">
                {firstPage && (
                  <ItemSelector
                    selected={isNewCollection}
                    onClick={() => setIsNewCollection((prev) => !prev)}
                    className={clsx(styles.card, {
                      [styles.active]: isNewCollection,
                    })}
                  >
                    <PlusIcon className={styles.plus} />
                  </ItemSelector>
                )}
                {collections?.nodes?.map((collection) => {
                  const selected = selectedIds.includes(
                    collection?.collectionId
                  );

                  return (
                    !!collection && (
                      <ItemSelector
                        selected={selected}
                        onClick={() =>
                          setSelectedCollections((prev) => {
                            if (selected) {
                              return prev.filter(
                                (item) =>
                                  collection.collectionId !== item.collectionId
                              );
                            } else {
                              return [...prev, collection as Collection];
                            }
                          })
                        }
                        className={clsx(styles.card)}
                        key={collection.collectionId}
                      >
                        <CollectionCard data={collection} className="w-full" />
                      </ItemSelector>
                    )
                  );
                })}
              </div>
              <Pagination
                className="mt-8 flex items-center justify-center w-full"
                page={page}
                total={totalPages}
                renderPrev={(prevPage, props) => (
                  <button {...props} onClick={() => setPage(prevPage)}>
                    <ArrowIcon />
                  </button>
                )}
                renderNext={(nextPage, props) => (
                  <button {...props} onClick={() => setPage(nextPage)}>
                    <ArrowIcon style={{ transform: "rotate(180deg)" }} />
                  </button>
                )}
                renderPage={(item, props) => (
                  <button {...props} onClick={() => setPage(item)}>
                    {item}
                  </button>
                )}
              />
            </div>
            <Button
              type="button"
              className="mt-8 w-full"
              disabled={!isNewCollection && !selectedCollections.length}
              loading={addAssetsToCollectionsLoading}
              onClick={async () => {
                if (selectedCollections?.length)
                  await addAssetsToCollections({
                    variables: {
                      collectionsIds: selectedIds as string[],
                      assetsIds: assetsIds as string[],
                    },
                  });

                onSubmit(selectedCollections, isNewCollection);
                setIsNewCollection(false);
              }}
            >
              {t(
                `collections.add.actions.${isNewCollection ? "create" : "add"}`
              )}
            </Button>
          </>
        )}
      </Modal>
    );
  }
);
