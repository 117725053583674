import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/time";

interface Props {
  createdAt?: number;
  count?: number;
  className?: string;
}

export const CollectionCaption: FC<Props> = ({
  createdAt,
  className,
  count = 0,
}) => {
  const { t } = useTranslation("dam");

  return (
    <div className={clsx("flex gap-5", className)}>
      {createdAt && (
        <>
          {formatDate(createdAt)}
          <div>|</div>
        </>
      )}
      {count !== undefined && (
        <span>
          {t("collections.assets.count", {
            count,
          })}
        </span>
      )}
    </div>
  );
};
