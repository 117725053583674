import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const CreateAccountRoute = compose(
  ProtectedRoute({ access: Roles.unauthorized }),
  Route("/registration/create-account")
);

export const CreateAccount = CreateAccountRoute(
  lazy(() => import(/* webpackChunkName: "CreateAccount" */ "./CreateAccount"))
);
