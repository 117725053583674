import { Loader, Tabs } from "@CreativelySquared/uikit";
import { useProjectCommentsQuery, useProjectQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Note } from "components/Note";
import { usePagination } from "utils/hooks/pagination";
import { SortFields, SortOrder } from "utils/order";
import { Pagination } from "components";

import { CommentStatuses, isProjectEditable } from "../utils/types";

import styles from "./styles.module.scss";

export const ProjectCommentsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
      show: Route.params.oneOf(...Object.values(CommentStatuses)).optional,
      page: Route.query.number,
    },
    ({ id, show }) => `/projects/${id}/comments/${show}`
  )
);

export const ProjectComments = ProjectCommentsRoute(
  ({
    match: {
      query: { page = 1 },
      params: { id, show = CommentStatuses.all },
    },
    link,
  }) => {
    const { t } = useTranslation("projects");
    const pagination = usePagination({
      page,
      sort: SortFields.createdAt,
      sortOrder: SortOrder.DESC,
    });

    const { data: { getProject: project } = {}, loading: projectLoading } =
      useProjectQuery({
        variables: { projectId: id },
      });

    const { data: { getAssetComments } = {}, loading: getCommentsLoading } =
      useProjectCommentsQuery({
        variables: {
          pagination,
          includeAsset: true,
          filters: {
            projectId: id,
            userRole:
              show === CommentStatuses.admin
                ? [Roles.admin, Roles.account_manager]
                : show === CommentStatuses.customer
                ? [Roles.organization_owner]
                : undefined,
            isDeleted: show === CommentStatuses.deleted,
          },
        },
        fetchPolicy: "cache-and-network",
      });

    const readOnly = !isProjectEditable(project?.status);
    const loading =
      (getCommentsLoading && !getAssetComments?.nodes?.length) ||
      projectLoading;

    return (
      <>
        <Helmet title={t("comments.title")} />

        <Tabs className="-mt-8" size={Tabs.sizes.Small}>
          {Object.values(CommentStatuses).map((status) => (
            <Tabs.Item
              component={NavLink}
              isActive={() => show === status}
              key={status}
              to={link({ id, show: status })}
            >
              {t(`comments.filters.${status}`)}
            </Tabs.Item>
          ))}
        </Tabs>

        {loading && (
          <div className="flex items-center justify-center">
            <Loader radius={50} className="mt-11" />
          </div>
        )}

        {!loading && (
          <ul className="pt-7">
            {getAssetComments?.nodes?.map(
              (comment) =>
                comment && (
                  <Note
                    border
                    data={comment}
                    key={comment?.assetCommentId}
                    organizationId={project?.organization?.organizationId}
                    projectId={id}
                    readOnly={readOnly}
                  />
                )
            )}
          </ul>
        )}

        {!loading && getAssetComments?.nodes?.length === 0 && (
          <section className={styles.projectCommentsEmpty}>
            <h2>{t("comments.empty.title")}</h2>
            <span>{t("comments.empty.description")}</span>
          </section>
        )}

        {getAssetComments?.nodes && !loading && (
          <Pagination
            className="mt-11"
            page={page}
            total={getAssetComments?.pageInfo?.totalPages}
          />
        )}
      </>
    );
  }
);
