import { useTranslation } from "react-i18next";
import { DetailedHTMLProps, Fragment, HTMLAttributes, memo } from "react";
import { Brief } from "api/types";
import { Object } from "ts-toolbelt";
import clsx from "clsx";
import { DividedList } from "components";

import { getBriefCredits } from "../utils/credits";

import styles from "./styles.module.scss";

type Props = {
  brief: Object.Partial<Brief, "deep">;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const BriefCredits = memo<Props>(({ brief, className, ...props }) => {
  const { t } = useTranslation("brief");
  const credits = getBriefCredits(brief);

  return (
    <section className={className} {...props}>
      <section className={styles.briefCredits}>
        <div className={styles.briefCreditsProjects}>
          <p className={styles.briefCreditsTitle}>{t("credits.projects")}</p>
          {credits.concepts?.map((credit, id) => (
            <div
              key={credit?.concept.id}
              className={clsx(styles.briefCreditsItem, {
                ["mt-6"]: id === 0,
              })}
            >
              <DividedList
                separator={<span> | </span>}
                className={styles.briefCreditsOptions}
              >
                {credit?.concept?.name}
                {credit?.outcomes?.map((outcome) => (
                  <Fragment key={outcome.serviceId}>
                    <>{outcome.amount}</>
                    <>
                      &nbsp;
                      {t(
                        `deliverables.outcomes.fields.type.options.${outcome?.serviceId}`
                      )}
                    </>
                  </Fragment>
                ))}
              </DividedList>
              <p className="whitespace-nowrap">
                {t(`credits.count`, { count: credit?.total })}
              </p>
            </div>
          ))}
        </div>
        <div className="flex justify-between">
          <p className={styles.briefCreditsTitle}>{t("credits.total")}</p>
          <p className={styles.briefCreditsTitle}>
            {t("credits.count", {
              count: credits.total,
            })}
          </p>
        </div>
      </section>
    </section>
  );
});
