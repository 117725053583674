import {
  useGetCollectionQuery,
  useGetCollectionZipLazyQuery,
  useSharedCollectionNeighboursQuery,
  useSharingCollectionLinkTokenQuery,
} from "api/graphql";
import { Helmet } from "react-helmet";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { useAuthData } from "Authorization/authorization.hooks";
import { useTranslation } from "react-i18next";
import { useNotification } from "components";
import { Button, Loader } from "@CreativelySquared/uikit";
import { usePagination } from "utils/hooks/pagination";
import { AssetsView } from "DAM/components/AssetsView";
import { AssetListItem } from "DAM/components/AssetListItem";
import { Asset } from "Assets/components";
import { links } from "App";
import { Link } from "react-router-dom";
import ScrollLock from "react-scrolllock";
import { useCallback } from "react";
import { withSuspense } from "utils/hooks/suspense";
import { downloadURI } from "utils/file";

import { Footer } from "../components";

import styles from "./styles.module.scss";

const SharedCollectionRoute = compose(
  ProtectedRoute({ access: Roles.shared }),
  Route(
    {
      sharedCode: Route.params.string,
      page: Route.query.number,
      assetId: Route.params.string.optional,
    },
    ({ sharedCode, assetId }) => `/shared/collection/${sharedCode}/${assetId}`
  )
);

const useSharingCollectionLinkTokenSuspenseQuery = withSuspense(
  useSharingCollectionLinkTokenQuery
);

export const SharedCollection = SharedCollectionRoute(
  ({
    match: {
      params: { sharedCode, assetId },
      query: { page = 1 },
      params,
      query,
    },
  }) => {
    const { t } = useTranslation("shared");
    const { setNotification, notificationTypes } = useNotification();
    const auth = useAuthData();

    const {
      data: {
        getAuthenticationTokensForCollectionSharingLink: sharedData,
      } = {},
      loading: tokenLoading,
    } = useSharingCollectionLinkTokenSuspenseQuery({
      variables: {
        linkCode: sharedCode,
      },
      onCompleted: ({ getAuthenticationTokensForCollectionSharingLink }) => {
        auth.setSessionToken(
          getAuthenticationTokensForCollectionSharingLink?.accessToken
        );
      },
      onError: (error) => {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
      suspense: true,
    });

    const { data: { collection } = {}, loading: collectionLoading } =
      useGetCollectionQuery({
        variables: {
          collectionId: sharedData?.collectionSharingLink?.collectionId ?? "",
        },
        onError: (error) => {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
        skip: !(
          sharedData?.accessToken &&
          sharedData.collectionSharingLink?.collectionId
        ),
      });

    const pagination = usePagination({
      page,
    });
    const filters = {
      collectionIds: [sharedData?.collectionSharingLink?.collectionId ?? ""],
    };

    const { data: { getAssetLibraryNeighbours } = {}, loading: assetLoading } =
      useSharedCollectionNeighboursQuery({
        variables: {
          assetId: assetId ?? "",
          filters,
          pagination,
        },
        onError: (error) => {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
        skip: !assetId || !collection?.collectionId,
      });

    const [downloadCollection, { loading: collectionDownloading }] =
      useGetCollectionZipLazyQuery({
        variables: { collectionId: collection?.collectionId },
        fetchPolicy: "network-only",
        onError: (error) => {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
      });

    const onCollectionDownload = useCallback(async () => {
      const { data } = await downloadCollection();
      const fileUrl = data?.file?.download?.downloadUrl?.url;

      if (fileUrl) {
        downloadURI(fileUrl);
      }
    }, []);

    const loading = collectionLoading || tokenLoading;
    const asset = getAssetLibraryNeighbours?.current;

    if (loading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Loader radius={50} />
        </div>
      );
    }

    return (
      <>
        <main className={styles.sharedCollection}>
          <Helmet>
            <title>{collection?.name}</title>
          </Helmet>
          <header className="flex justify-between items-center mb-10">
            <h1>{collection?.name}</h1>
            {sharedData?.collectionSharingLink?.canDownloadAssets && (
              <Button
                type="button"
                onClick={onCollectionDownload}
                loading={collectionDownloading}
              >
                {t("collection.download")}
              </Button>
            )}
          </header>
          <AssetsView
            filters={filters}
            dataLoading={loading}
            pagination={pagination}
            page={page}
            renderAsset={({ asset }) => (
              <Link
                to={links.SharedCollection({
                  ...params,
                  ...query,
                  assetId: asset.assetId ?? "",
                })}
                key={asset?.assetId}
                className="w-full h-full mb-4"
              >
                <AssetListItem asset={asset} />
              </Link>
            )}
          />
        </main>
        <Footer />
        <ScrollLock isActive={Boolean(assetId)} />
        {assetId && (
          <section className="flex flex-col w-full h-full fixed top-0 left-0 bg-white z-[2]">
            <Asset
              prev={
                getAssetLibraryNeighbours?.previousId &&
                links.SharedCollection({
                  ...params,
                  ...query,
                  assetId: getAssetLibraryNeighbours.previousId,
                })
              }
              next={
                getAssetLibraryNeighbours?.nextId &&
                links.SharedCollection({
                  ...params,
                  ...query,
                  assetId: getAssetLibraryNeighbours.nextId,
                })
              }
              readonly
              sidebar={false}
              backURL={links.SharedCollection({
                ...params,
                ...query,
                assetId: undefined,
              })}
              value={asset}
              loading={assetLoading}
            />
          </section>
        )}
      </>
    );
  }
);
