import {
  DetailedHTMLProps,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Tooltip } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { CommentsBar } from "components";
import clsx from "clsx";
import { Brief } from "api/types";
import { Object } from "ts-toolbelt";
import { isProjectEditable } from "Projects/utils/types";
import { ReactComponent as SpeachIcon } from "images/speach.svg";
import { BriefContext } from "Brief/brief.context";

import styles from "./styles.module.scss";

type Props = {
  brief: Object.Partial<Brief, "deep">;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export const BriefComments = memo<Props>(({ brief, className, ...props }) => {
  const { t } = useTranslation("brief");
  const [open, setOpen] = useState(false);
  const readOnly = !isProjectEditable(brief.project?.status);
  const { activeSection, setActiveSection } = useContext(BriefContext);

  useEffect(() => {
    if (activeSection && !open) setOpen(true);
  }, [activeSection]);

  return (
    <>
      {open ? (
        <section
          className={clsx(styles.briefCommentsDrawer, className)}
          {...props}
        >
          <CommentsBar
            onClose={() => {
              setOpen(false);
              setActiveSection?.(null);
            }}
            className={styles.briefCommentsDrawerMain}
            briefId={brief.briefId ?? ""}
            readOnly={readOnly}
          />
        </section>
      ) : (
        <div className={styles.briefCommentsOpen}>
          <Tooltip
            placement={Tooltip.placements.Top}
            title={t("comments.open.tooltip")}
            variant={Tooltip.variants.Secondary}
          >
            <Button
              variant={Button.variants.Icon}
              onClick={() => setOpen(true)}
            >
              <SpeachIcon />
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  );
});
