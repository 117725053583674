import { ProjectStatusValues } from "api/enums";

// elements' order represents the order of project/customer filter
export enum ProjectStatuses {
  delivered = "delivered",
  queued = "queued",
  "under_revision" = "under_revision",
  "partial_delivery" = "partial_delivery",
  "in_progress" = "in_progress",
  "pending_review" = "pending_review",
  "clarification_requested" = "clarification_requested",
  draft = "draft",
  canceled = "canceled",
  finalized = "finalized",
  deleted = "deleted",
}

const mapProjectStatus = {
  [ProjectStatuses["in_progress"]]: ProjectStatusValues.InProgress,
  [ProjectStatuses["partial_delivery"]]: ProjectStatusValues.PartialDelivery,
  [ProjectStatuses.delivered]: ProjectStatusValues.Delivered,
  [ProjectStatuses["pending_review"]]: ProjectStatusValues.PendingReview,
  [ProjectStatuses["under_revision"]]: ProjectStatusValues.UnderRevision,
  [ProjectStatuses.queued]: ProjectStatusValues.Queued,
  [ProjectStatuses.draft]: ProjectStatusValues.Draft,
  [ProjectStatuses.finalized]: ProjectStatusValues.Finalized,
  [ProjectStatuses.canceled]: ProjectStatusValues.Canceled,
  [ProjectStatuses.clarification_requested]:
    ProjectStatusValues.ClarificationRequested,
} as const;

export const parseStatuses = (
  statuses?: Array<ProjectStatuses | undefined>
): ProjectStatusValues[] | undefined => {
  const data = statuses?.flatMap((status) =>
    status && status !== ProjectStatuses.deleted
      ? [mapProjectStatus[status]]
      : []
  );

  return data?.length ? data : undefined;
};

export const convertStatusesToURLParams = (statuses: string[]) => {
  return Object.entries(mapProjectStatus)
    .filter(([_, value]) => statuses.includes(value))
    .map(([key]) => key) as ProjectStatuses[];
};

export enum CommentStatuses {
  all = "all",
  admin = "admin",
  customer = "customer",
  deleted = "deleted",
}

export const statusOrder = [
  ProjectStatuses["pending_review"],
  ProjectStatuses.queued,
  ProjectStatuses.under_revision,
  ProjectStatuses["in_progress"],
  ProjectStatuses["partial_delivery"],
  ProjectStatuses.delivered,
  ProjectStatuses.finalized,
  ProjectStatuses.clarification_requested,
  ProjectStatuses.draft,
  ProjectStatuses.canceled,
  ProjectStatuses.deleted,
];

export enum ProjectFilter {
  categories = "categories",
  statuses = "statuses",
  brands = "brands",
  users = "users",
  submittedAt = "submittedAt",
  shareable = "shareable",
  shareableOn = "shareableOn",
}

export const isProjectEditable = (status?: ProjectStatuses | null | string) =>
  status &&
  [ProjectStatuses.draft, ProjectStatuses.clarification_requested].includes(
    status as ProjectStatuses
  );

export enum ProjectSort {
  Newest = "newest",
  Oldest = "oldest",
  NameAscending = "nameAscending",
  NameDescending = "nameDescending",
  Active = "active",
}
