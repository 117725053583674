import React, { memo, PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@CreativelySquared/uikit";

import styles from "./styles.module.scss";

type Props = {
  data: Array<
    Array<{
      tooltip: string;
      icon: string | ReactElement;
      onClick: () => void;
    }>
  >;
  count: number;
  onClose: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const ActionBar = memo<PropsWithChildren<Props>>(
  ({ data, count, onClose, className, ...props }) => {
    const { t } = useTranslation("common");

    if (!count) return null;

    return (
      <div className={clsx(styles.actionBar, className)} {...props}>
        <div className="text-l">
          <button type="button" onClick={onClose}>
            <CloseIcon className="mr-4" />
          </button>
          {t("gallery.selected", { count })}
        </div>

        {data.map((item, key) => (
          <div key={key} className="grid grid-flow-col gap-8 empty:hidden">
            {item.map((element, index) => (
              <button type="button" key={index} onClick={element.onClick}>
                <Tooltip
                  placement={Tooltip.placements.Bottom}
                  title={element.tooltip}
                  variant={Tooltip.variants.Secondary}
                  className="whitespace-nowrap"
                >
                  {element.icon}
                </Tooltip>
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  }
);
