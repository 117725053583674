import { lazy } from "react";
import { Route } from "react-router-hoc";

export const ConceptGeneratorRoute = Route(
  {
    organizationId: Route.query.string,
  },
  "/ai/tools/concept"
);

export const ConceptGenerator = ConceptGeneratorRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "ConceptGenerator" */ "./ConceptGenerator")
  )
);
