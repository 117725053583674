import { Button, Dropdown } from "@CreativelySquared/uikit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUpvotyAuthTokenQuery } from "api/graphql";
import { Protect } from "components/Protect";
import { Features } from "api/features";
import { GetProps } from "react-router-hoc/lib/types";
import * as Intercom from "@intercom/messenger-js-sdk";

import { ReactComponent as QuestionIcon } from "./images/question.svg";
import { ReactComponent as LinkIcon } from "./images/link.svg";

import styles from "./styles.module.scss";

type Props = GetProps<typeof Dropdown>;

export const HelpCenter: FC<Props> = ({ className, ...props }) => {
  const { t } = useTranslation("common");
  const { data: { getUpvotyAuthenticationToken } = {} } =
    useUpvotyAuthTokenQuery();
  return (
    <Dropdown
      render={() => (
        <div className={className}>
          <Button outlined borderless variant={Button.variants.Cancel}>
            <QuestionIcon className="mr-5" />
            {t("helpCenter.title")}
          </Button>
        </div>
      )}
      popoverClassName={styles.helpCenterMenu}
      size={Dropdown.sizes.Large}
      placement={Dropdown.placements.RigntEnd}
      pointer
      {...props}
    >
      <Dropdown.Item
        component={Link}
        to={{ pathname: "https://help.creativelysquared.com" }}
        target="_blank"
      >
        {t("helpCenter.menu.help")}
        <LinkIcon />
      </Dropdown.Item>
      <Dropdown.Item onClick={Intercom.show}>
        <button type="button">{t("helpCenter.menu.support")}</button>
      </Dropdown.Item>
      <Dropdown.Item
        component={Link}
        to={{
          pathname:
            "https://www.notion.so/Public-Roadmap-3c3ab8a8d3b047a4acd83454369a7395?pvs=4",
        }}
        target="_blank"
      >
        {t("helpCenter.menu.features")}
        <LinkIcon />
      </Dropdown.Item>
      <Protect feature={Features.upvoty}>
        {getUpvotyAuthenticationToken && (
          <Dropdown.Item
            component={Link}
            to={{
              pathname: `https://feedback.creativelysquared.com/front/handleSSO/${getUpvotyAuthenticationToken}`,
            }}
            target="_blank"
          >
            {t("helpCenter.menu.request")}
            <LinkIcon />
          </Dropdown.Item>
        )}
      </Protect>
    </Dropdown>
  );
};
