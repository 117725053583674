import { FileMetadataFragment } from "api/types";

import { MediaFormats, MediaType } from "./types";
import { UploadType } from "./hooks/upload";

export const fileToBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const downloadURI = (uri: string) => {
  const link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getLocalFileType = (mime: string) =>
  mime.startsWith("video")
    ? "video"
    : mime.startsWith("image")
    ? "image"
    : "document";

export const filterFilesByFormats = (
  files: File[],
  formats: Array<MediaFormats>
) =>
  files.filter(
    (file) =>
      file.type &&
      formats.find((format) => MediaType[format].includes(file.type))
  );

export const getFileMetadata = (file?: FileMetadataFragment | null) => {
  const type = file?.originalMetadata?.common?.type;

  if (type === "image") {
    return file?.imageMetadata?.storedData.metadata;
  }

  if (type === "video") {
    return file?.videoMetadata?.storedData.metadata;
  }

  if (type === "gif") {
    return file?.gifMetadata?.storedData.metadata;
  }
};

enum FileSizes {
  Tiny = 10485760, // 10MB
  Small = 52428800, // 50MB
  Regular = 419430400, // 400MB
  Large = 1048576000, // 1000MB
  XLarge = 1572864000, // 1500MB
}

export const getMaxFileSize = (format: UploadType, fileType: File["type"]) => {
  const type = getLocalFileType(fileType);

  const sizes: Record<
    UploadType,
    {
      image?: FileSizes;
      video?: FileSizes;
      document?: FileSizes;
    }
  > = {
    [UploadType.brief_moodboard]: {
      image: FileSizes.Small,
      video: FileSizes.Regular,
    },
    [UploadType.project_cover]: {
      image: FileSizes.Small,
    },
    [UploadType.brand_reference]: {
      image: FileSizes.Small,
      video: FileSizes.Regular,
    },
    [UploadType.concept_direction]: {
      image: FileSizes.Small,
      video: FileSizes.Regular,
    },
    [UploadType.concept_moodboard]: {
      image: FileSizes.Small,
      video: FileSizes.Regular,
    },
    [UploadType.concept_visual_guide]: {
      image: FileSizes.Small,
      video: FileSizes.Regular,
    },
    [UploadType.asset]: {
      image: FileSizes.Small,
      video: FileSizes.Large,
    },
    [UploadType.user_avatar]: {
      image: FileSizes.Tiny,
    },
    [UploadType.brand_avatar]: {
      image: FileSizes.Tiny,
    },
    [UploadType.comment_attachment]: {
      image: FileSizes.Small,
      video: FileSizes.Small,
      document: FileSizes.Small,
    },
    [UploadType.brand_guideline]: {
      image: FileSizes.XLarge,
      video: FileSizes.XLarge,
      document: FileSizes.XLarge,
    },
    [UploadType.concept_mind_map]: {
      image: FileSizes.XLarge,
      video: FileSizes.XLarge,
      document: FileSizes.XLarge,
    },
    [UploadType.brand_asset]: {
      image: FileSizes.XLarge,
      video: FileSizes.XLarge,
      document: FileSizes.XLarge,
    },
  };

  return sizes[format]?.[type];
};
