import { useGetFilesDownloadUrlLazyQuery } from "api/graphql";
import { File } from "api/types";
import { saveAs } from "file-saver";
import { useState } from "react";
import type { Object } from "ts-toolbelt";

import { DownloadFormat } from "./hooks/download";

type CropOptions = {
  width: number;
  height: number;
  x: number;
  y: number;
  ref?: React.RefObject<HTMLImageElement>;
};

type Options = {
  onError?: (error: string | Event) => void;
};

export const useCrop = ({ onError }: Options = {}) => {
  const [getFilesDownloadUrl] = useGetFilesDownloadUrlLazyQuery();
  const [loading, setLoading] = useState(false);
  const crop = async (
    file: Object.Partial<File, "deep">,
    { height, width, x, y }: CropOptions,
    customCanvas?: { height: number; width: number },
    metaData?: string
  ) => {
    if (!file.view?.downloadUrl?.url) return;
    setLoading(true);

    const type = file.originalMetadata?.common?.type;
    const name =
      type === "image"
        ? DownloadFormat.dwn_image
        : type === "video"
        ? DownloadFormat.dwn_video
        : type === "gif"
        ? DownloadFormat.dwn_gif
        : DownloadFormat.original;

    const downloadUrl = await getFilesDownloadUrl({
      variables: {
        filesIds: [file.fileId ?? ""],
        name,
      },
    });
    const img = new Image();
    img.crossOrigin = "anonymous";
    const url = new URL(
      downloadUrl.data?.getFiles?.[0]?.download?.downloadUrl?.url ?? ""
    );
    url.searchParams.append("", "crop"); // modify the URL to avoid CORS for the cross-origin attribute
    img.src = url.toString();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const pixelWidth = customCanvas?.width ?? (img.width * width) / 100;
      const pixelHeight = customCanvas?.height ?? (img.height * height) / 100;
      canvas.width = pixelWidth;
      canvas.height = pixelHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(
        img,
        (img.width * x) / 100,
        (img.height * y) / 100,
        (img.width * width) / 100,
        (img.height * height) / 100,
        0,
        0,
        pixelWidth,
        pixelHeight
      );

      const dataUrl = canvas.toDataURL("image/jpeg");
      setLoading(false);
      if (file?.fileName) {
        const dotIndex = file.fileName.lastIndexOf(".");
        const fileName =
          `${file.fileName.slice(0, dotIndex)}_` +
          (metaData ?? `${Math.floor(pixelWidth)}x${Math.floor(pixelHeight)}`) +
          file.fileName.slice(dotIndex);

        saveAs(dataUrl, fileName);
      }
    };
    img.onerror = (error) => {
      setLoading(false);
      onError?.(error);
    };
  };

  return {
    loading,
    crop,
  };
};
