import { useTranslation } from "react-i18next";
import { Button, Label } from "@CreativelySquared/uikit";
import { Brief } from "api/types";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import type { Object } from "ts-toolbelt";
import { Link } from "react-router-dom";
import { links } from "App";
import { useProfileQuery } from "api/graphql";
import { ProjectStatuses } from "Projects/utils/types";
import { format } from "date-fns";
import { SectionPopup } from "components";
import { BriefSection } from "Brief/utils/types";
import { getFullName } from "utils/users";

import styles from "./styles.module.scss";

type Props = {
  brief: Object.Partial<Brief, "deep">;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const SummaryOverview = memo<Props>(({ brief, className, ...props }) => {
  const { t } = useTranslation("brief");
  const { project, brandProfile, audienceNotes, purposeObjective, briefId } =
    brief;
  const { data: { me } = {} } = useProfileQuery();

  return (
    <section className={className} {...props}>
      <h2>{t("overview.title")}</h2>
      <div>
        <div className="grid grid-cols-2">
          {!!project?.title && (
            <SectionPopup
              comment={{
                briefId,
                sectionId: BriefSection.title,
                className: "right-[90px] mt-5",
              }}
            >
              <div className={styles.summaryFormField}>
                <label className={styles.summaryFormFieldTitle}>
                  {t("summary.form.name")}
                </label>
                <p className={styles.summaryFormFieldDescription}>
                  {project.title}
                </p>
              </div>
            </SectionPopup>
          )}
          {brandProfile?.name && brandProfile.brandProfileId && (
            <SectionPopup
              comment={{
                briefId,
                sectionId: BriefSection.brand,
                className: "top-[45px]",
              }}
            >
              <div className={styles.summaryFormField}>
                <label className={styles.summaryFormFieldTitle}>
                  {t("summary.form.brand")}
                </label>

                {me?.userId ? (
                  <Link
                    to={links.BrandDetails({ id: brandProfile.brandProfileId })}
                  >
                    <Button outlined borderless type="button" className="p-0">
                      {brandProfile.name}
                    </Button>
                  </Link>
                ) : (
                  <Label
                    variant={Label.variants.Neutral}
                    size={Label.sizes.Small}
                  >
                    {brandProfile.name}
                  </Label>
                )}
              </div>
            </SectionPopup>
          )}
        </div>

        {brief.category && (
          <SectionPopup
            comment={{
              briefId,
              sectionId: BriefSection.category,
            }}
          >
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.category.title")}
              </label>
              <Label variant={Label.variants.Neutral} size={Label.sizes.Small}>
                {t(`summary.form.category.options.${brief.category}`)}
              </Label>
            </div>
          </SectionPopup>
        )}

        {brief?.project?.status !== ProjectStatuses.draft &&
          !!brief.project?.submittedAt && (
            <div className={styles.summaryFormField}>
              <label className={styles.summaryFormFieldTitle}>
                {t("summary.form.submission")}
              </label>
              {`${getFullName(brief.project.submittedBy)} ${format(
                +brief.project.submittedAt,
                "PPP"
              )}`}
            </div>
          )}
      </div>

      {audienceNotes && (
        <SectionPopup
          comment={{
            briefId,
            sectionId: BriefSection.audienceNotes,
          }}
        >
          <div className={styles.summaryFormField}>
            <label className={styles.summaryFormFieldTitle}>
              {t("summary.form.audience")}
            </label>
            <p className={styles.summaryFormFieldDescription}>
              {audienceNotes}
            </p>
          </div>
        </SectionPopup>
      )}

      {purposeObjective && (
        <SectionPopup
          comment={{
            briefId,
            sectionId: BriefSection.purposeObjective,
          }}
        >
          <div className={styles.summaryFormField}>
            <label className={styles.summaryFormFieldTitle}>
              {t("summary.form.objective")}
            </label>
            <p className={styles.summaryFormFieldDescription}>
              {purposeObjective}
            </p>
          </div>
        </SectionPopup>
      )}
    </section>
  );
});
