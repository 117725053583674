import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

import { ProjectGalleryUpload as ProjectGalleryUploadComponent } from "./ProjectGalleryUpload";

export const ProjectGalleryUploadRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.string,
      conceptId: Route.query.regex(idRegEx),
    },
    ({ id }) => `/projects/${id}/manage/upload`
  )
);

export const ProjectGalleryUpload = ProjectGalleryUploadRoute(
  ProjectGalleryUploadComponent
);
