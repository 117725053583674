import clsx from "clsx";
import {
  BaseHTMLAttributes,
  ComponentType,
  DetailedHTMLProps,
  forwardRef,
  isValidElement,
  memo,
} from "react";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import { ReactComponent as ArrowIcon } from "images/angle.svg";

import styles from "./styles.module.scss";

type Props = {
  value?: Array<any>;
  active?: boolean;
  icon?: ComponentType;
  arrow?: boolean;
  error?: string | false;
  placeholder?: string;
  disabled?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  render?: (value: { item: any; onRemove: () => void }) => React.ReactNode;
  onChange?: (value: any[]) => void;
  onDelete?: (value: any) => void;
  format?: (value: any) => string;
} & Omit<
  DetailedHTMLProps<BaseHTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onChange" | "prefix"
>;

export const MultiInput = memo(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        value,
        active,
        icon: Icon,
        arrow,
        error,
        suffix,
        prefix,
        placeholder,
        disabled,
        onChange,
        render,
        format = (value) => value,
        onDelete,
        className,
        ...props
      },
      ref
    ) => {
      return (
        <div
          ref={ref}
          className={clsx(className, styles.multiInput, {
            [styles.active]: active,
            [styles.disabled]: disabled,
            [styles.error]: error,
          })}
          {...props}
        >
          {Icon && (
            <div className="mt-2 mr-4">
              {typeof Icon === "string" ? (
                <img src={Icon} />
              ) : isValidElement(Icon) ? (
                Icon
              ) : (
                Icon && <Icon />
              )}
            </div>
          )}
          <div
            className={clsx("flex flex-wrap flex-1 mr-8 overflow-hidden", {
              "-mb-4": value?.length,
            })}
          >
            {prefix}
            {!value?.length && (
              <span className={styles.placeholder}>{placeholder}</span>
            )}

            {value?.map((record) =>
              render ? (
                render({
                  item: record,
                  onRemove: () =>
                    onChange?.(value.filter((item) => item !== record)),
                })
              ) : (
                <div key={record} className={styles.record}>
                  <span className="overflow-hidden text-ellipsis">
                    {format(record)}
                  </span>

                  <button
                    type="button"
                    className={styles.recordRemove}
                    onClick={(event) => {
                      event.stopPropagation();
                      onChange?.(value.filter((item) => item !== record));
                      onDelete?.(record);
                    }}
                  >
                    <CrossIcon />
                  </button>
                </div>
              )
            )}
            {suffix}
            {error && <span className={styles.errorMessage}>{error}</span>}
          </div>
          {arrow && (
            <ArrowIcon
              className={clsx(styles.arrow, {
                [styles.active]: active,
                [styles.error]: error,
              })}
            />
          )}
        </div>
      );
    }
  )
);
