import clsx from "clsx";
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  ReactElement,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as ImageIcon } from "images/image.svg";
import { Modal, Tabs } from "@CreativelySquared/uikit";
import { UploadMedia } from "components/UploadMedia";
import { assetFormats } from "utils/types";
import { AssetItemFragment } from "api/types";

import { MediaProjects } from "./components/MediaProjects";
import { MediaCollections } from "./components/MediaCollections";
import { MediaAssets } from "./components/MediaAssets";
import { MediaLink } from "./components/MediaLink/MediaLink";

import styles from "./styles.module.scss";

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  organizationId?: string | null;
  render?: ReactElement;
} & (
    | {
        multiple: true;
        onUpload: (media: File[]) => void;
        onAdd: (assetIds: AssetItemFragment[]) => void;
      }
    | {
        multiple?: false;
        onUpload: (media: File) => void;
        onAdd: (assetId: AssetItemFragment) => void;
      }
  );

enum TabList {
  Upload = "upload",
  Assets = "assets",
  Projects = "projects",
  Collections = "collections",
}

export const AddMedia = memo<Props>(
  ({
    multiple,
    onUpload,
    onAdd,
    organizationId,
    render,
    className,
    ...props
  }) => {
    const { t } = useTranslation("common");
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(TabList.Upload);

    const onClose = () => {
      setOpen(false);
    };

    const handleAdd = (assets: AssetItemFragment[]) => {
      if (multiple) {
        onAdd(assets);
      } else {
        onAdd(assets[0]);
      }
      onClose();
    };

    return (
      <>
        {render ? (
          React.cloneElement(render, {
            ...props,
            onClick: (e: any) => {
              setOpen(true);
              props?.onClick?.(e);
            },
          })
        ) : (
          <div
            className={clsx(styles.addMedia, className)}
            onClick={() => setOpen(true)}
            {...props}
          >
            <ImageIcon />
            <p className={styles.addMediaTitle}>
              <Trans
                t={t}
                i18nKey="addMedia.title"
                components={{ span: <span /> }}
              />
            </p>
          </div>
        )}
        <Modal
          visible={open}
          onClose={onClose}
          className={styles.addMediaModal}
          closable
        >
          <Tabs
            size={Tabs.sizes.Small}
            active={activeTab}
            onChange={(tab) => {
              setActiveTab(tab as TabList);
            }}
            className="mb-7"
          >
            {Object.values(TabList).map((tab) => (
              <Tabs.Item key={tab} value={tab}>
                {t(`addMedia.tabs.${tab}`)}
              </Tabs.Item>
            ))}
          </Tabs>
          <div className="h-full overflow-auto flex flex-col p-2">
            {activeTab === TabList.Upload && (
              <>
                <MediaLink
                  formats={assetFormats}
                  onUpload={(file) => {
                    if (multiple) {
                      onUpload([file]);
                    } else {
                      onUpload(file);
                    }
                    onClose();
                  }}
                />
                <UploadMedia
                  formats={assetFormats}
                  className="h-full mb-7"
                  onUpload={(data: any) => {
                    onUpload(data);
                    onClose();
                  }}
                  multiple={multiple}
                />
              </>
            )}
            {activeTab === TabList.Assets && (
              <MediaAssets
                organizationId={organizationId}
                onAdd={handleAdd}
                multiple={multiple}
              />
            )}
            {activeTab === TabList.Projects && (
              <MediaProjects
                organizationId={organizationId}
                onAdd={handleAdd}
                multiple={multiple}
              />
            )}
            {activeTab === TabList.Collections && (
              <MediaCollections
                organizationId={organizationId}
                onAdd={handleAdd}
                multiple={multiple}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
);
