import { GetFilesDownloadUrlDocument } from "api/graphql";
import { File } from "api/types";
import JSZip from "jszip";
import type { Object as ObjectType } from "ts-toolbelt";
import { saveAs } from "file-saver";
import { useState } from "react";
import { groupBy, uniqueId } from "lodash";
import { useApolloClient } from "@apollo/client";

type OptionsType = {
  name?: string;
  format?: DownloadFormat;
};

export enum DownloadFormat {
  dwn_video = "dwn_video_large",
  dwn_image = "dwn_image_large",
  dwn_gif = "dwn_gif_large",
  original = "original",
}

export function useDownload() {
  const [downloading, setDownloading] = useState(false);
  const client = useApolloClient();

  const compressFiles = async (
    files?: Array<ObjectType.Partial<File, "deep"> | null | undefined> | null
  ) => {
    const zip = new JSZip();

    const filesData = files?.map(async (file, key) => {
      const url = file?.download?.downloadUrl?.url;
      if (!url) return;

      const response = await fetch(url.toString());
      const data = await response.blob();

      // create unique name
      const names = Object.keys(Object.values(zip)[0]);
      const fileName = names.includes(String(file.fileName))
        ? `${uniqueId()}${file.fileName}`
        : file.fileName ?? String(key);

      zip.file(fileName, data);

      return data;
    });

    return Promise.all(filesData ?? []).then(() =>
      zip.generateAsync({ type: "blob" })
    );
  };

  const download = async (
    files: Array<ObjectType.Partial<File, "deep"> | undefined | null>,
    options?: OptionsType
  ) => {
    setDownloading(true);

    const { image, video, gif, ...rest } = Object.fromEntries(
      Object.entries(
        groupBy(files, (file) => file?.originalMetadata?.common?.type)
      ).map(([key, value]) => [key, value.map((el) => el?.fileId ?? "")])
    );
    const restFiles = Object.values(rest).flat();

    const downloadUrlFiles = await Promise.all(
      options?.format
        ? [
            ...(files?.length
              ? [
                  client.query({
                    query: GetFilesDownloadUrlDocument,
                    variables: {
                      filesIds: files.map((file) => file?.fileId),
                      name: options?.format,
                    },
                  }),
                ]
              : []),
          ]
        : [
            ...(image?.length
              ? [
                  client.query({
                    query: GetFilesDownloadUrlDocument,
                    variables: {
                      filesIds: image,
                      name: DownloadFormat.dwn_image,
                    },
                  }),
                ]
              : []),
            ...(video?.length
              ? [
                  client.query({
                    query: GetFilesDownloadUrlDocument,
                    variables: {
                      filesIds: video,
                      name: DownloadFormat.dwn_video,
                    },
                  }),
                ]
              : []),
            ...(gif?.length
              ? [
                  client.query({
                    query: GetFilesDownloadUrlDocument,
                    variables: {
                      filesIds: gif,
                      name: DownloadFormat.dwn_gif,
                    },
                  }),
                ]
              : []),
            ...(restFiles?.length
              ? [
                  client.query({
                    query: GetFilesDownloadUrlDocument,
                    variables: {
                      filesIds: restFiles,
                      name: DownloadFormat.original,
                    },
                  }),
                ]
              : []),
          ]
    );

    const filesData = downloadUrlFiles
      .flatMap((file) => file?.data?.getFiles)
      .flat()
      .filter((file) => file?.download?.downloadUrl?.url);

    if (!filesData?.length) return setDownloading(false);

    if (Number(filesData?.length) > 1) {
      const compressed = await compressFiles(filesData);
      saveAs(compressed, `${options?.name}.zip`);
    } else {
      const response = await fetch(
        String(filesData?.[0]?.download?.downloadUrl?.url)
      );
      const data = await response.blob();

      saveAs(data, String(filesData?.[0]?.fileName));
    }

    setDownloading(false);
  };

  return [
    download,
    {
      downloading,
      DownloadFormatType: DownloadFormat,
    },
  ] as const;
}
