import { Button, Input, Loader } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { OrganizationUser } from "api/types";
import {
  CustomerDocument,
  UsersDocument,
  useCustomerUserQuery,
  useDeleteCustomerUserMutation,
} from "api/graphql";
import clsx from "clsx";
import { getOperationName } from "@apollo/client/utilities";
import { getFullName } from "utils/users";

import styles from "./styles.module.scss";

type FormData = { confirm: string };

type Props = {
  userId: OrganizationUser["userId"];
  onClose: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const DeleteUser = memo<Props>(
  ({ userId, onClose, className, ...props }) => {
    const { t } = useTranslation("customers");

    const { data: { getUser: user } = {}, loading: userLoading } =
      useCustomerUserQuery({
        variables: { userId },
      });

    const [deleteCustomerUser, { loading }] = useDeleteCustomerUserMutation({
      refetchQueries: [
        {
          query: CustomerDocument,
          variables: {
            id:
              user?.__typename === "OrganizationUser"
                ? user.organizationId
                : undefined,
          },
        },
        getOperationName(UsersDocument)!,
      ],
      awaitRefetchQueries: true,
    });

    const validationSchema = object({
      confirm: string()
        .required("common:validation.required")
        .lowercase()
        .oneOf([t("users.delete.validationWord")]),
    });

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      values: { confirm },
    } = useFormik<FormData>({
      async onSubmit() {
        try {
          await deleteCustomerUser({ variables: { userId } });

          onClose();
        } catch (error) {
          console.log(error);
        }
      },
      validationSchema,
      initialErrors: {
        confirm: "",
      },
      initialValues: {
        confirm: "",
      },
    });

    if (userLoading) {
      return (
        <div className="flex justify-center">
          <Loader radius={50} className="mt-6" />
        </div>
      );
    }

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(styles.deleteUser, className)}
        {...props}
      >
        <h3 className={styles.title}>{t("users.delete.title")}</h3>
        <h4 className={styles.explanation}>
          {t("users.delete.explanation", {
            name: getFullName(user),
          })}
        </h4>

        <Input
          name="confirm"
          variant={Input.variants.Secondary}
          value={confirm.toUpperCase()}
          className="w-full mt-7 mb-6"
          placeholder={t("users.delete.placeholder")}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <section className="flex justify-end">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t("users.delete.actions.cancel")}
          </Button>
          <Button
            loading={loading}
            type="submit"
            disabled={!isValid || loading}
          >
            {t("users.delete.actions.delete")}
          </Button>
        </section>
      </form>
    );
  }
);
