import { lazy } from "react";
import { Route } from "react-router-hoc";

export const UGCIdeaGeneratorRoute = Route(
  {
    organizationId: Route.query.string,
  },
  "/ai/tools/ugc-idea-generator"
);

export const UGCIdeaGenerator = UGCIdeaGeneratorRoute(
  lazy(
    () =>
      import(/* webpackChunkName: "UGCIdeaGenerator" */ "./UGCIdeaGenerator")
  )
);
