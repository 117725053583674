import { AssetLibraryFiltersInput, BriefCategory } from "api/types";
import { getRange } from "utils/time";
import { AssetRatingType } from "utils/types";

export const getFilters = ({
  activeServiceIds,
  brands,
  projects,
  organizations,
  categories,
  tags,
  rating,
  collections,
  createdAt,
}: {
  activeServiceIds?: string[];
  brands?: string[];
  projects?: string[];
  organizations?: string[];
  categories?: BriefCategory[];
  tags?: string[];
  rating?: AssetRatingType[];
  collections?: string[];
  createdAt?: string[];
}) => {
  const { startDate, endDate } = getRange(createdAt);

  const filters: AssetLibraryFiltersInput = {
    assetTypes: activeServiceIds,
    brandProfileIds: brands,
    projects: projects,
    projectCategories: categories,
    organizations,
    ...(startDate &&
      endDate && {
        dateCreated: {
          startDate: new Date(+startDate).toISOString(),
          endDate: new Date(+endDate).toISOString(),
        },
      }),
    tagIds: tags,
    rating: rating?.map((type) => (type === AssetRatingType.liked ? 1 : -1)),
    collectionIds: collections,
  };

  return filters;
};
