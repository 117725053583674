import { BriefCategory } from "api/types";
import { useState } from "react";
import { usePagination } from "utils/hooks/pagination";
import { SortFields, SortOrder } from "utils/order";
import { Services } from "utils/services";
import { GetProps } from "react-router-hoc/lib/types";
import { AssetRatingType } from "utils/types";

import { AssetListItem, AssetViewType } from "../components/AssetListItem";
import { getFilters } from "../utils/filters";

import { useServices } from "./useServices";

export const useAssetsSettings = () => {
  const [view, setView] = useState(AssetViewType.Gallery);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(SortOrder.DESC);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{
    categories?: BriefCategory[];
    assetTypes?: Services[];
    brands?: string[];
    projects?: string[];
    organizations?: string[];
    tags?: string[];
    rating?: AssetRatingType[];
    createdAt?: string[];
  }>();

  const { activeServiceIds, serviceValues, servicesLoading } = useServices(
    filters?.assetTypes || []
  );

  const pagination = usePagination({
    page,
    sort: SortFields.createdAt,
    sortOrder,
  });

  const assetsFilters = getFilters({
    activeServiceIds,
    brands: filters?.brands,
    projects: filters?.projects,
    organizations: filters?.organizations,
    categories: filters?.categories,
    tags: filters?.tags,
    rating: filters?.rating,
    createdAt: filters?.createdAt,
  });

  const onSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const onSort = (value: SortOrder) => {
    setSortOrder(value);
    setPage(1);
  };

  const onFilter = (value: Record<string, string[]>) => {
    setFilters(value);
    setPage(1);
  };

  const renderAsset = ({
    asset,
    selected,
    onSelect,
    view,
  }: GetProps<typeof AssetListItem>) => (
    <div
      onClick={onSelect as unknown as React.MouseEventHandler<HTMLDivElement>}
      key={asset?.assetId}
      className="w-full h-full mb-4 cursor-pointer"
    >
      <AssetListItem
        asset={asset}
        selected={selected}
        onSelect={onSelect}
        view={view}
      />
    </div>
  );

  return {
    view,
    filters: assetsFilters,
    search,
    sortOrder,
    serviceValues,
    assetTypes: filters?.assetTypes,
    createdAt: filters?.createdAt,
    dataLoading: servicesLoading,
    pagination,
    page,
    onViewChange: setView,
    onPageChange: setPage,
    onSearch,
    onSort,
    onFilter,
    renderAsset,
    compact: true,
  };
};
