import { useBriefQuery, useGetProjectSharingTokenQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { useAuthData } from "Authorization/authorization.hooks";
import { BriefProvider } from "Brief/brief.context";
import { Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { SummaryOverview } from "Brief/Summary/SummaryOverview";
import { SummaryConcept } from "Brief/Summary/SummaryConcept";
import { SummaryLogistics } from "Brief/Summary/SummaryLogistics";
import { BriefCredits } from "Brief/Credits";
import { isProjectEditable } from "Projects/utils/types";
import { withSuspense } from "utils/hooks/suspense";

import styles from "./styles.module.scss";

const SharedBriefRoute = compose(
  ProtectedRoute({ access: Roles.shared }),
  Route(
    {
      sharedCode: Route.params.string,
    },
    ({ sharedCode }) => `/shared/brief/${sharedCode}`
  )
);

const useGetProjectSharingTokenSuspenseQuery = withSuspense(
  useGetProjectSharingTokenQuery
);

export const SharedBrief = SharedBriefRoute(
  ({
    match: {
      params: { sharedCode },
    },
  }) => {
    const { t } = useTranslation("brief");
    const auth = useAuthData();
    const { data: { sharedData } = {} } =
      useGetProjectSharingTokenSuspenseQuery({
        variables: {
          linkCode: sharedCode,
        },
        onCompleted: ({ sharedData }) => {
          auth.setSessionToken(sharedData?.accessToken);
        },
        suspense: true,
      });

    const { data: { getBrief: brief } = {}, loading } = useBriefQuery({
      variables: {
        projectId: sharedData?.projectSharingLink?.projectId ?? "",
      },
      skip: !(
        sharedData?.accessToken && sharedData.projectSharingLink?.projectId
      ),
    });

    return (
      <BriefProvider brief={brief ?? {}}>
        {brief?.project?.title && (
          <Helmet>
            <title>{brief?.project?.title}</title>
          </Helmet>
        )}
        {loading ? (
          <Loader radius={50} className="mx-auto mt-11" />
        ) : (
          brief && (
            <main className={styles.sharedBrief}>
              <h1 className={styles.sharedBriefTitle}>
                {!isProjectEditable(brief?.project?.status) &&
                brief?.project?.title
                  ? brief.project?.title
                  : t("summary.title")}
              </h1>

              <section className={styles.sharedBriefMain}>
                <SummaryOverview brief={brief} />

                <section className="my-10">
                  <h2>{t("concept.title")}</h2>
                  {brief.concepts?.map(
                    (concept) =>
                      concept && (
                        <SummaryConcept
                          briefId={brief.briefId}
                          concept={concept}
                          key={concept.conceptId}
                          className="mt-8"
                        />
                      )
                  )}
                </section>

                <SummaryLogistics brief={brief} />

                <section className="mt-10">
                  <h2>{t("credits.title")}</h2>
                  <BriefCredits brief={brief} />
                </section>
              </section>
            </main>
          )
        )}
      </BriefProvider>
    );
  }
);
