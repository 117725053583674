import { PaginationSortingDirectionValue } from "api/enums";
import { useMemo } from "react";
import { SortOrder } from "utils/order";

type PageOptions<T extends string> = {
  page?: number;
  limit?: number;
  sortOrder?: SortOrder;
  sort?: T;
  priority?: string[];
};

const DEFAULT_LIMIT = 24;
const DEFAULT_PAGE = 1;

const sortOrderMap = {
  [SortOrder.ASC]: PaginationSortingDirectionValue.Asc,
  [SortOrder.DESC]: PaginationSortingDirectionValue.Desc,
};

export const usePagination = <T extends string>(
  {
    limit = DEFAULT_LIMIT,
    page = DEFAULT_PAGE,
    sortOrder,
    sort,
    priority,
  }: PageOptions<T> = {
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  }
) => {
  const currentPage = useMemo(() => (page < 0 ? DEFAULT_PAGE : page), [page]);

  return useMemo(
    () => ({
      limit,
      /** TODO: replace with number */
      offset: (currentPage - 1) * limit,
      sorting: sortOrder && sortOrderMap[sortOrder],
      sortingField: sort as any,
      sortingPriority: priority,
    }),
    [currentPage, limit, sortOrder, sort]
  );
};
