import { EstimatedCreditsCost, EstimatedOutcomeCost } from "api/types";
import groupBy from "lodash/groupBy";
import { DetailedHTMLProps, HTMLAttributes, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Object as ObjectType } from "ts-toolbelt";
import { Services } from "utils/services";

import { conceptNumberGenerator } from "../../utils/generators";

import styles from "./styles.module.scss";

type Props = {
  data?: ObjectType.Partial<EstimatedCreditsCost, "deep"> | undefined | null;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const CreditsSummary = memo<Props>(({ data, ...props }) => {
  const { t } = useTranslation();
  const cost = data;

  const outcomes = useMemo(() => {
    return (cost?.briefCost?.outcomesCosts ?? [])
      ?.concat(
        cost?.conceptsCosts?.flatMap((item) => item?.outcomesCosts as any) ?? []
      )
      ?.concat(
        cost?.projectLevelCosts?.flatMap((item) => item as any) ?? []
      ) as ObjectType.Partial<EstimatedOutcomeCost, "deep">[];
  }, [cost]);

  const numberGenerator = useMemo(() => conceptNumberGenerator(), []);
  const credits = useMemo(() => {
    const groups = groupBy(outcomes, (item) => {
      const concept = cost?.conceptsCosts?.find(
        (costs) => costs?.conceptId === item.outcome?.conceptId
      )?.concept;

      const name = concept?.name
        ? "named"
        : item?.outcome?.conceptId && numberGenerator(item?.outcome?.conceptId);

      return [item?.outcome?.service?.scopes?.at(0)]
        .concat(name?.toString() ?? [])
        .concat(concept?.name || [])
        .join(".");
    });

    const items: typeof groups = Object.entries(groups).reduce(
      (acc, [key, group]) => {
        const { deliverables, rest } = groupBy(group, (item) => {
          const deliverable =
            !!Services[item?.outcome?.serviceId as keyof typeof Services];

          return deliverable ? "deliverables" : "rest";
        });
        const deliverable = deliverables?.reduce(
          (acc, item) => ({
            price: (acc?.price ?? 0) + (item?.price ?? 0),
            outcome: {
              serviceId: "deliverables",
              outcomeId: item?.outcome?.outcomeId ?? "",
            },
          }),
          {}
        );

        const talents = rest?.filter((outcome) => outcome?.price);

        const outcomes = Array<any>()
          .concat(deliverable ?? ([] as any))
          .concat(talents ?? []);

        return {
          ...acc,
          [key]: outcomes,
        };
      },
      {}
    );

    return items;
  }, [outcomes]);

  return (
    <section {...props}>
      {!!Object.values(credits).flat().length &&
        Object.entries(credits).map(([scope, costs]) => {
          if (!costs.length) return null;

          const [heading, number, name] = scope.split(".");
          const title = ["titles"].concat(number ?? []).join(".");

          return (
            <section className={styles.creditsSummaryScope} key={scope}>
              <p className={styles.creditsSummaryScopeTitle}>
                {t(`credits.summary.${heading}.${title}`, { name })}
              </p>
              <ul>
                {costs.map((cost) => {
                  // const options =
                  //   cost?.outcome?.amount &&
                  //   i18n.exists(
                  //     `credits.summary.services.${cost?.outcome?.serviceId}.options`,
                  //     { count: cost?.outcome?.amount }
                  //   );

                  return (
                    <li
                      className={styles.creditsSummaryScopeService}
                      key={cost?.outcome?.outcomeId}
                    >
                      <span>
                        {t(
                          `credits.summary.services.${cost?.outcome?.serviceId}.title`
                        )}
                      </span>
                      {/** Has been remove from updated design */}
                      {/* <span>
                        {!!options &&
                          t(
                            `credits.summary.services.${cost?.outcome?.serviceId}.options`,
                            { count: cost?.outcome?.amount ?? 0 }
                          )}
                      </span> */}
                      <span>
                        {t("credits.summary.amount", {
                          count: cost?.price ?? 0,
                        })}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </section>
          );
        })}

      {cost?.total !== undefined && (
        <section className="flex items-center justify-between">
          <p className={styles.creditsSummaryScopeTitle}>
            {t("common:customers.total.label")}
          </p>
          <p className={styles.creditsSummaryScopeTitle}>
            {t("common:customers.credits.count", {
              count: cost?.total ?? 0,
            })}
          </p>
        </section>
      )}
    </section>
  );
});
