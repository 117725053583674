import {
  Button,
  Label,
  Loader,
  Modal,
  Tabs,
  Tooltip,
} from "@CreativelySquared/uikit";
import { AssetByIdFragment } from "api/types";
import { AssetStatus } from "api/enums";
import { ActiveRegionType, Media, RegionType } from "components/Media";
import { Protect, withProtect } from "components/Protect";
import {
  DetailedHTMLProps,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Roles } from "utils/roles";
import {
  useSetAssetRatingMutation,
  useUpdateAssetStatusMutation,
} from "api/graphql";
import clsx from "clsx";
import { useDownload } from "utils/hooks/download";
import { NoteForm, NoteType } from "components/Note";
import { CommentsBar } from "components/CommentsBar";
import { UploadAssetVersion } from "components/UploadAssetVersion";
import { SelectAssetVersion } from "components/SelectAssetVersion";
import { useProtect } from "utils/hooks/protection";
import { DeleteAsset } from "components/DeleteAsset";
import { useNotification } from "components/Notifications";
import { ApolloError } from "@apollo/client";
import { GetProps } from "react-router-hoc/lib/types";
import { StatusLabel } from "components/StatusLabel";
import { ProjectStatuses } from "Projects/utils/types";
import { Features } from "api/features";
import { formatBytes } from "utils/formatBytes";
import { useCrop } from "utils/image";
import { commentsWithRegions } from "utils/filter";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import { getFileMetadata } from "utils/file";

import { AssetInfo } from "../AssetInfo";

import { ReactComponent as BackIcon } from "./images/back.svg";
import { ReactComponent as LikeIcon } from "./images/like.svg";
import { ReactComponent as DownloadIcon } from "./images/download.svg";
import { ReactComponent as ChevronIcon } from "./images/chevron.svg";
import { ReactComponent as SelectionIcon } from "./images/selection.svg";
import { ZoomControls } from "./ZoomControls";
import { AssetCrop } from "./AssetCrop";

import styles from "./styles.module.scss";

type Props = {
  preview?: boolean;
  backURL?: string;
  readonly?: boolean;
  value?: AssetByIdFragment | null;
  loading?: boolean;
  versionId?: string;
  setVersion?: (assetVersionId: string) => void;
  comments?: string | boolean;
  sidebar?: boolean;
  next?: string | null;
  prev?: string | null;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const Ratings = {
  like: 1,
  dislike: -1,
};

export const assetStatusStyle = {
  [AssetStatus.Delivered]: "bg-blue-steel text-white",
  [AssetStatus.NeedsChanges]: "bg-purple text-white",
  [AssetStatus.NeedsReview]: "bg-orange text-white",
  [AssetStatus.Accepted]: "bg-green text-white",
};

const ProtectTabItem = withProtect(Tabs.Item);

enum Views {
  INFO = "info",
  COMMENTS = "comments",
}

const views = [
  {
    value: Views.INFO,
    feature: Features.dam_asset_details_info,
  },
  {
    value: Views.COMMENTS,
    feature: Features.dam_asset_details_comments,
  },
];

enum SelectionState {
  COMMENT = "comment",
  CROP = "crop",
}

const getDimensionPercentage = (
  dimension: string,
  canvasSize?: number | null
) => {
  return +dimension > (canvasSize ?? 0)
    ? 100
    : (+dimension * 100) / (canvasSize ?? 0);
};

const AssetInner = memo<Props>(
  ({
    backURL,
    value,
    readonly,
    prev,
    next,
    versionId,
    setVersion,
    comments: initialComments = false,
    className,
    sidebar = true,
    loading: assetLoading,
    ...props
  }) => {
    const { t } = useTranslation();
    const isProtected = useProtect();
    const { setNotification, notificationTypes } = useNotification();
    const isAdmin = isProtected([Roles.admin, Roles.account_manager]);
    const [setRating, { loading: ratingLoading }] = useSetAssetRatingMutation();
    const [updateStatus, { loading: updateStatusLoading }] =
      useUpdateAssetStatusMutation();

    const [loading, setLoading] = useState(true);
    useEffect(() => {
      if (assetLoading) {
        setLoading(true);
      }
    }, [assetLoading]);

    const [removeAssetVersion, setRemoveAssetVersion] = useState<string | null>(
      null
    );
    const [download, { downloading }] = useDownload();
    const [selectable, setSelectable] = useState<{
      state: SelectionState;
      region?: RegionType[];
    } | null>();
    const [isVersionUpload, setIsVersionUpload] = useState(false);
    const [selectedRegion, setSelected] = useState<RegionType[]>([]);
    const [activeRegion, setActiveRegion] = useState<ActiveRegionType>(null);
    const [customSelection, setCustomSelection] = useState<{
      width?: string;
      height?: string;
    }>({});
    const { loading: cropLoading, crop } = useCrop({
      onError() {
        setNotification({
          message: t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
    });

    const zoomRef = useRef<ReactZoomPanPinchContentRef>(null);

    const selected = useMemo(() => {
      if (selectedRegion.length) return selectedRegion;
      return selectable?.region ?? [];
    }, [selectedRegion, selectable?.region]);

    const [activeChanges, setActiveChanges] = useState(false);
    const [approveConfirm, setApproveConfirm] = useState(false);
    const noteRef = useRef<HTMLTextAreaElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const [view, setView] = useState<Views | null>(
      initialComments ? Views.COMMENTS : Views.INFO
    );
    const onSetRating = useCallback(
      (vote: number) =>
        value?.assetId &&
        setRating({
          variables: {
            assetId: value.assetId,
            vote,
          },
        }),
      [value, setRating]
    );
    const currentVersion = versionId
      ? value?.versions?.find(
          (version) => version?.assetVersionId === versionId
        )
      : value?.versions?.at(-1)?.file
      ? value?.versions?.at(-1)
      : value?.latestVersion;
    const rating = value?.myRating?.vote;
    const currentVersionFile =
      currentVersion && "file" in currentVersion
        ? currentVersion?.file
        : undefined;
    // TODO: looks like defect, need to check and fix
    const media =
      currentVersionFile?.view ?? (currentVersionFile as any)?.thumbnail;
    const metadata = getFileMetadata(currentVersionFile);

    const { width, height, x, y, time } = selected?.[0] ?? {};
    const commentsList = value?.comments?.filter(
      (comment) => comment?.assetVersionId === currentVersion?.assetVersionId
    );
    const assetVersion = {
      assetId: value?.assetId ?? "",
      assetVersionId: currentVersion?.assetVersionId ?? "",
    };
    const status = value?.status;

    const regions = commentsWithRegions(commentsList)
      .sort(
        (current, next) => Number(current?.createdAt) - Number(next?.createdAt)
      )
      .map((comment, index) => ({
        ...comment?.extra,
        data: { index: index + 1 },
      })) as unknown as RegionType[];

    const onSelect = (region: ActiveRegionType) =>
      setActiveRegion((prev) =>
        prev?.index === region?.index ? null : region
      );
    const handleUploadVersionModal = () => setIsVersionUpload((prev) => !prev);

    const handleSetSelectable = (
      value: {
        state: SelectionState;
        region?: RegionType[];
      } | null
    ) => {
      if (value) {
        const animationTime = 300;
        zoomRef.current?.resetTransform(animationTime);

        // Wait for the animation to finish before setting the selectable state
        // We need it to remove overflow hidden from the transform wrapper
        // Overflow: hidden hides the suggestions menu from the mentioning textarea
        setTimeout(() => {
          setSelectable(value);
        }, animationTime);
      } else {
        setSelectable(null);
      }
    };

    const toggleSelection = (state: SelectionState, region?: RegionType[]) => {
      if (!selectable) {
        return handleSetSelectable({ state, region });
      }
      if (state === selectable.state) {
        return clearState();
      }
      clearState();
      return handleSetSelectable({ state });
    };

    const setSelection = (state: SelectionState, region?: RegionType[]) => {
      clearState();
      return handleSetSelectable({ state, region });
    };

    const clearState = () => {
      setActiveRegion(null);
      setSelected([]);
      handleSetSelectable(null);
      setActiveChanges(false);
      setCustomSelection({});
    };

    const onUpdateStatus = (status: AssetStatus) =>
      updateStatus({
        variables: {
          ids: [value?.assetId ?? ""],
          status,
        },
      }).catch((error: ApolloError) =>
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        })
      );

    const handleChangeRequest = async () => {
      await onUpdateStatus(AssetStatus.NeedsChanges);

      if (view !== Views.COMMENTS) setView(Views.COMMENTS);
      clearState();
    };

    const UpdateStatus = memo<
      { status: AssetStatus; title?: string } & GetProps<typeof Button>
    >(({ status, title, ...props }) => (
      <Button
        className={clsx(assetStatusStyle[status], "border-none")}
        variant={Button.variants.Primary}
        loading={updateStatusLoading}
        type="button"
        onClick={() => onUpdateStatus(status)}
        {...props}
      >
        {title ?? t(`common:asset.status.${status}.label`)}
      </Button>
    ));

    const zoomDisabled = !!selectable?.state;

    const handleCustomSelection = (value: {
      width?: string;
      height?: string;
    }) => {
      setCustomSelection(value);

      if (value.width && value.height) {
        const width = getDimensionPercentage(
          value.width,
          metadata?.canvas?.width
        );
        const height = getDimensionPercentage(
          value.height,
          metadata?.canvas?.height
        );

        setSelected([]);
        handleSetSelectable({
          state: SelectionState.CROP,
          region: [
            {
              data: { index: 0 },
              width,
              height,
              isChanging: true,
              x: 0,
              y: 0,
              ratio: width / height,
            },
          ],
        });
      }
    };

    const handleCrop = () => {
      if (currentVersionFile) {
        crop(
          currentVersionFile,
          {
            width,
            height,
            x,
            y,
          },
          customSelection.width &&
            customSelection.height &&
            selected?.[0]?.ratio
            ? {
                width: Number(customSelection.width),
                height: Number(customSelection.height),
              }
            : undefined,
          selected?.[0]?.metadata
        );
      }
    };

    useEffect(clearState, [value?.assetId, versionId]);

    useEffect(() => {
      noteRef?.current?.focus();
    }, [selected]);

    useEffect(() => {
      const handleResize = () => zoomRef.current?.resetTransform();
      const resizeObserver = new ResizeObserver(handleResize);

      if (contentRef?.current) {
        resizeObserver.observe(contentRef.current);
      }

      return () => {
        if (contentRef?.current) {
          resizeObserver.unobserve(contentRef.current);
        }
      };
    }, []);

    return (
      <TransformWrapper
        doubleClick={{ step: 0.5 }}
        pinch={{ disabled: true }}
        maxScale={4.49}
        disabled={zoomDisabled}
        ref={zoomRef}
      >
        {({ centerView }) => (
          <section className={clsx(styles.asset, className)} {...props}>
            <section className={styles.assetContent}>
              <header className="flex items-start justify-between px-8 pt-8 z-10">
                {backURL && (
                  <Link className="shrink-0" to={backURL}>
                    <Button variant={Button.variants.Icon}>
                      <BackIcon />
                    </Button>
                  </Link>
                )}
                {value?.project?.status !== ProjectStatuses.finalized && (
                  <div className="grid gap-6 grid-flow-col mr-5 mt-5">
                    <Protect access={[Roles.organization_owner]}>
                      {[
                        AssetStatus.Delivered,
                        AssetStatus.NeedsReview,
                      ].includes(status as AssetStatus) && (
                        <div className="relative">
                          <UpdateStatus
                            status={AssetStatus.NeedsChanges}
                            onClick={() => {
                              clearState();

                              if (!activeChanges) {
                                setActiveChanges(true);
                                handleSetSelectable({
                                  state: SelectionState.COMMENT,
                                });
                              }
                            }}
                          />
                          {activeChanges && !selected?.[0] && (
                            <NoteForm
                              targetId={assetVersion.assetVersionId}
                              showControls
                              noteType={NoteType.AssetComment}
                              className="absolute right-0 mt-5"
                              ref={noteRef}
                              onSubmit={handleChangeRequest}
                              onClose={clearState}
                              changeRequest
                              organizationId={
                                value?.organization?.organizationId || ""
                              }
                              projectId={value?.project?.projectId}
                            />
                          )}
                        </div>
                      )}
                      {status === AssetStatus.NeedsReview && (
                        <UpdateStatus
                          status={AssetStatus.Accepted}
                          onClick={() => setApproveConfirm(true)}
                          title={t("asset.accept.action")}
                        />
                      )}
                    </Protect>
                    <Protect access={[Roles.admin, Roles.account_manager]}>
                      {status === AssetStatus.NeedsReview && (
                        <UpdateStatus status={AssetStatus.Delivered} />
                      )}
                      {status === AssetStatus.Delivered && (
                        <UpdateStatus status={AssetStatus.NeedsReview} />
                      )}
                    </Protect>
                  </div>
                )}
              </header>
              <main>
                <aside>
                  {prev && (
                    <Link to={prev}>
                      <Button variant={Button.variants.Icon}>
                        <ChevronIcon />
                      </Button>
                    </Link>
                  )}
                </aside>
                {loading && (
                  <div className="flex h-full w-full items-center justify-center absolute top-[0]">
                    <Loader radius={50} />
                  </div>
                )}
                <div
                  className="w-full h-full flex justify-center items-center"
                  ref={contentRef}
                >
                  <TransformComponent
                    wrapperClass={clsx(
                      "w-full h-full",
                      zoomDisabled && "!overflow-visible"
                    )}
                    key={versionId}
                  >
                    <Media
                      fileId={media?.fileId}
                      url={media?.downloadUrl?.url}
                      type={currentVersionFile?.originalMetadata?.common?.type}
                      preview="instant"
                      onLoad={() => {
                        centerView(1, 0);
                        setLoading(false);
                      }}
                      onError={() => setLoading(false)}
                      className={clsx(styles.assetMedia, {
                        [styles.assetMediaLoading]: loading,
                      })}
                      areaLabel={
                        selectable?.state === SelectionState.CROP &&
                        t("common:asset.actions.crop.hover").toString()
                      }
                      onSelect={
                        selectable
                          ? (region) => {
                              if (!region.length) {
                                // clear selection on click outside
                                setSelectable({
                                  state: selectable?.state,
                                  region: undefined,
                                });
                              }
                              setSelected(region);
                            }
                          : undefined
                      }
                      selected={selected}
                      regions={loading ? [] : regions}
                      activeRegion={activeRegion}
                      setActiveRegion={(region) => {
                        if (view !== Views.COMMENTS) setView(Views.COMMENTS);
                        onSelect(region);
                      }}
                      areaWidget={
                        <>
                          {selectable?.state === SelectionState.COMMENT && (
                            <NoteForm
                              targetId={assetVersion.assetVersionId}
                              showControls
                              noteType={NoteType.AssetComment}
                              className="w-[237px]"
                              ref={noteRef}
                              onSubmit={() => {
                                if (activeChanges) {
                                  handleChangeRequest();
                                } else {
                                  if (view !== Views.COMMENTS)
                                    setView(Views.COMMENTS);
                                  setSelected([]);
                                  handleSetSelectable(null);
                                }
                              }}
                              onClose={() => setSelected([])}
                              commentMetadata={{ width, height, x, y, time }}
                              changeRequest={activeChanges}
                              organizationId={
                                value?.organization?.organizationId || ""
                              }
                              projectId={value?.project?.projectId}
                            />
                          )}
                          {selectable?.state === SelectionState.CROP && (
                            <Button loading={cropLoading} onClick={handleCrop}>
                              {t("common:asset.actions.crop.actions.download")}
                            </Button>
                          )}
                        </>
                      }
                    />
                  </TransformComponent>
                </div>

                <aside>
                  {next && (
                    <Link to={next}>
                      <Button variant={Button.variants.Icon}>
                        <ChevronIcon className="rotate-180" />
                      </Button>
                    </Link>
                  )}
                </aside>
              </main>
              <footer className="flex items-center justify-between px-10 pb-6 mt-7">
                {!readonly && (
                  <>
                    <div className="flex empty:hidden">
                      {!!setVersion && !!value?.versions?.length && (
                        <SelectAssetVersion
                          currentVersion={currentVersion?.assetVersionId ?? ""}
                          setVersion={setVersion}
                          versions={value?.versions ?? []}
                          onRemove={
                            isAdmin && value?.versions?.length > 1
                              ? setRemoveAssetVersion
                              : undefined
                          }
                        />
                      )}
                      <Protect access={[Roles.admin, Roles.account_manager]}>
                        <Button
                          type="button"
                          onClick={handleUploadVersionModal}
                          className="static"
                        >
                          {t("common:asset.actions.versions.label")}
                        </Button>
                      </Protect>
                    </div>
                    <div className={styles.assetDetails}>
                      <p className={styles.assetDetailsName}>
                        {currentVersionFile?.fileName}
                      </p>
                      <div className="flex justify-center">
                        <div className={styles.assetDetailsList}>
                          {value?.concept?.name && (
                            <Label
                              size={Label.sizes.Small}
                              className={styles.assetDetailsLabel}
                            >
                              {value?.concept?.name}
                            </Label>
                          )}
                          {value?.mainOutcome?.serviceId && (
                            <Label
                              size={Label.sizes.Small}
                              className={styles.assetDetailsLabel}
                            >
                              {t(
                                `brief:deliverables.outcomes.fields.type.options.${value.mainOutcome.serviceId}`
                              )}
                            </Label>
                          )}

                          {(metadata?.canvas?.height ||
                            metadata?.canvas?.width) && (
                            <Label
                              size={Label.sizes.Small}
                              className={styles.assetDetailsLabel}
                            >
                              {metadata?.canvas?.width}x
                              {metadata?.canvas?.height}
                            </Label>
                          )}
                          {metadata?.common?.size && (
                            <Label
                              size={Label.sizes.Small}
                              className={styles.assetDetailsLabel}
                            >
                              {formatBytes(metadata?.common?.size)}
                            </Label>
                          )}
                          {status && (
                            <StatusLabel
                              status={status}
                              type="asset"
                              size={Label.sizes.Small}
                            />
                          )}
                          {/* TODO: Add when the Assign process will be changed */}
                          {/* {value?.mainOutcome?.ratio && (
                      <Label
                        size={Label.sizes.Small}
                        className={styles.assetDetailsLabel}
                      >
                        {t(
                          `brief:deliverables.outcomes.fields.ratio.options.${value.mainOutcome.ratio}`
                        )}
                      </Label>
                    )} */}
                        </div>
                      </div>
                    </div>
                    <ul className="flex justify-between items-center gap-5">
                      <ZoomControls
                        buttonClassName={styles.assetAction}
                        disabled={zoomDisabled}
                      />
                      <li>
                        <Tooltip
                          placement={Tooltip.placements.Top}
                          title={t("common:asset.actions.selection.label")}
                          variant={Tooltip.variants.Secondary}
                        >
                          <button
                            onClick={() => {
                              toggleSelection(SelectionState.COMMENT);
                            }}
                            className={clsx(styles.assetAction, {
                              [styles.assetActionActive]:
                                selectable?.state === SelectionState.COMMENT,
                            })}
                            type="button"
                          >
                            <SelectionIcon />
                          </button>
                        </Tooltip>
                      </li>

                      <Protect feature={Features.dam_asset_details_crop}>
                        {currentVersionFile?.originalMetadata?.common?.type ===
                          "image" && (
                          <li>
                            <AssetCrop
                              value={customSelection}
                              metadata={metadata}
                              buttonClassName={clsx(styles.assetAction, {
                                [styles.assetActionActive]:
                                  selectable?.state === SelectionState.CROP,
                              })}
                              onSelect={(region) =>
                                setSelection(SelectionState.CROP, region)
                              }
                              onCustom={() => {
                                clearState();
                                handleSetSelectable({
                                  state: SelectionState.CROP,
                                });
                              }}
                              onChange={handleCustomSelection}
                              onClear={clearState}
                            />
                          </li>
                        )}
                      </Protect>

                      <li>
                        <Tooltip
                          placement={Tooltip.placements.Top}
                          title={t("common:asset.actions.download.label")}
                          variant={Tooltip.variants.Secondary}
                        >
                          <button
                            onClick={() =>
                              currentVersionFile?.view?.fileId &&
                              download([currentVersionFile])
                            }
                            className={clsx(styles.assetAction, {
                              "pointer-events-none": downloading,
                            })}
                            type="button"
                          >
                            {downloading ? (
                              <Loader radius={14} />
                            ) : (
                              <DownloadIcon />
                            )}
                          </button>
                        </Tooltip>
                      </li>
                      <Protect access={Roles.organization_owner}>
                        {Object.entries(Ratings).map(([key, value]) => (
                          <li key={key}>
                            <Tooltip
                              placement={Tooltip.placements.Top}
                              title={t(`common:rating.${key}`)}
                              variant={Tooltip.variants.Secondary}
                            >
                              <button
                                className={clsx([
                                  styles.assetAction,
                                  {
                                    [styles.assetActionSelected]:
                                      rating === value,
                                    "pointer-events-none": ratingLoading,
                                  },
                                ])}
                                onClick={() =>
                                  value !== rating && onSetRating(value)
                                }
                              >
                                {ratingLoading ? (
                                  <Loader radius={14} />
                                ) : (
                                  <LikeIcon
                                    className={clsx({
                                      "scale-y-[-1]": value === Ratings.dislike,
                                    })}
                                  />
                                )}
                              </button>
                            </Tooltip>
                          </li>
                        ))}
                      </Protect>
                      {/** Missing in new UI, but i suppose we will need it in the future */}
                      {/* <li>
                  <button
                    onClick={() => setView(Views.COMMENTS)}
                    className={clsx("flex items-center", [
                      styles.assetAction,
                      {
                        [styles.assetActionSelected]: view === Views.COMMENTS,
                      },
                    ])}
                  >
                    <CommentIcon />
                    {!!commentsList?.length && (
                      <p className={styles.assetActionCount}>
                        {commentsList?.length}
                      </p>
                    )}
                    {view !== Views.COMMENTS && (
                      <p className="ml-4 text-sm font-medium">
                        {t("common:asset.actions.comment.label")}
                      </p>
                    )}
                  </button>
                </li> */}
                    </ul>
                  </>
                )}
              </footer>
            </section>
            {sidebar && (
              <section className={styles.assetComments}>
                <Tabs
                  className="mx-10 mt-10"
                  active={view}
                  onChange={setView as any}
                >
                  {views.map(({ value, feature }) => {
                    return (
                      <ProtectTabItem
                        accessKey={feature}
                        key={value}
                        value={value}
                      >
                        {t(`assets:details.tabs.${value}.title`)}
                      </ProtectTabItem>
                    );
                  })}
                </Tabs>
                {view === Views.COMMENTS && (
                  <CommentsBar
                    header={false}
                    className="flex overflow-hidden flex-col h-full"
                    active={activeRegion?.index}
                    onSelect={onSelect}
                    assetVersion={assetVersion}
                    organizationId={value?.organization?.organizationId || ""}
                    projectId={value?.project?.projectId}
                  />
                )}
                {view === Views.INFO && (
                  <AssetInfo
                    className="px-9 py-10 overflow-y-auto"
                    asset={{ ...value, latestVersion: currentVersion }}
                  />
                )}
              </section>
            )}

            {setVersion && value && (
              <Modal
                visible={isVersionUpload}
                onClose={handleUploadVersionModal}
                className="p-0"
              >
                <UploadAssetVersion
                  organizationId={value?.organization?.organizationId ?? ""}
                  assetId={value.assetId!}
                  onClose={handleUploadVersionModal}
                  onComplete={(assetVersionId) => {
                    setVersion(assetVersionId);
                    handleUploadVersionModal();
                  }}
                />
              </Modal>
            )}
            {removeAssetVersion && value && (
              <Modal
                visible={removeAssetVersion}
                onClose={() => setRemoveAssetVersion(null)}
                className="overflow-visible"
              >
                <DeleteAsset
                  assetId={value.assetId!}
                  currentVersion={removeAssetVersion}
                  onClose={() => setRemoveAssetVersion(null)}
                  onDelete={(versionId) => {
                    if (currentVersion?.assetVersionId === versionId) {
                      setVersion?.("");
                    }
                    setRemoveAssetVersion(null);
                  }}
                />
              </Modal>
            )}
            <Modal
              onClose={() => setApproveConfirm(false)}
              visible={approveConfirm}
              className="w-full max-w-[545px] text-blackberry"
            >
              <h3 className="mb-4">{t("common:asset.accept.heading")}</h3>
              <p className="text-base">
                {t("common:asset.accept.description")}
              </p>
              <section className="flex justify-end mt-8">
                <Button
                  className="mr-5"
                  onClick={() => setApproveConfirm(false)}
                  outlined
                  type="button"
                  variant={Button.variants.Cancel}
                >
                  {t("common:actions.cancel")}
                </Button>
                <Button
                  onClick={async () => {
                    await onUpdateStatus(AssetStatus.Accepted);
                    setApproveConfirm(false);
                  }}
                  loading={updateStatusLoading}
                  type="button"
                >
                  {t("common:asset.accept.confirm")}
                </Button>
              </section>
            </Modal>
          </section>
        )}
      </TransformWrapper>
    );
  }
);

export const Asset = memo<Props>((props) => (
  <AssetInner
    {...props}
    // We have to use this key to force re-render when the asset changes
    key={`${props.value?.assetId}${
      props.versionId ? `/${props.versionId}` : ""
    }`}
  />
));
