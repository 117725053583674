import { tabToUrl } from "AITools/utils/url";
import { lazy } from "react";
import { Route } from "react-router-hoc";

import { SavedItemsTabs } from "./types";

export const SavedItemsRoute = Route(
  {
    tab: Route.params.oneOf(...Object.values(SavedItemsTabs).map(tabToUrl))
      .optional,
  },
  ({ tab }) => `/ai/saved/${tab}`
);

export const SavedItems = SavedItemsRoute(
  lazy(() => import(/* webpackChunkName: "SavedItems" */ "./SavedItems"))
);

export { SavedItemsTabs };
