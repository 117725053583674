import { useTranslation } from "react-i18next";
import { Button, Dropdown, Modal, Tooltip } from "@CreativelySquared/uikit";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { SaveButton } from "components/SaveButton";
import {
  CustomerDocument,
  OrganizationCreditsHistoryDocument,
  useSetSubscriptionPlanMutation,
} from "api/graphql";
import { useFormik } from "formik";
import { CSSTransition } from "react-transition-group";
import { Prompt } from "react-router";
import { number, object, string, StringSchema } from "yup";
import { useClickOutside } from "utils/hooks/events";
import { useHistory } from "react-router-dom";
import {
  OrganizationCreditsEvent,
  SubscriptionPlanName,
  SubscriptionPlanStatus,
  SubscriptionPlanType,
} from "api/enums";
import { flow } from "lodash";
import { add, isAfter, startOfDay } from "date-fns/fp";
import { getOperationName } from "@apollo/client/utilities";
import { SubscriptionErrorCodes } from "utils/subscriptions";

import { UpdateCredits } from "../UpdateCredits";

import { ReactComponent as UpdateIcon } from "./images/update.svg";
import ArrowIcon from "./images/Arrow";

import styles from "./styles.module.scss";

type Props = {
  organizationId: string;
  value?: Partial<FormData> | null;
  plan?: SubscriptionPlanType | null;
  onChange?: (value: Partial<FormData>) => any;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

type FormData = {
  credits?: number | null;
  balance?: number | null;
  reason?: OrganizationCreditsEvent | null;
  plan?: SubscriptionPlanType | null;
  expirationDate: Date;
  name?: SubscriptionPlanName;
  status?: SubscriptionPlanStatus;
};

const MIN = 0;
const MAX = 9999;

const creditReasonEvents = [
  OrganizationCreditsEvent.ExtendedLicense,
  OrganizationCreditsEvent.CreatorRebooking,
  OrganizationCreditsEvent.LocationBooking,
  OrganizationCreditsEvent.PostProduction,
  OrganizationCreditsEvent.PropPurchase,
  OrganizationCreditsEvent.PurchasedCredits,
  OrganizationCreditsEvent.Refund,
  OrganizationCreditsEvent.ResetBalance,
  OrganizationCreditsEvent.Reward,
  OrganizationCreditsEvent.TalentBooking,
  OrganizationCreditsEvent.ProductPurchase,
  OrganizationCreditsEvent.RushTurnaround,
  OrganizationCreditsEvent.AdditionalContent,
  OrganizationCreditsEvent.ProjectCancellation,
];

export const subscriptionStatuses = [
  {
    value: SubscriptionPlanStatus.Pending,
    className: "bg-grey !text-blackberry",
    plans: [SubscriptionPlanType.Custom],
  },
  {
    value: SubscriptionPlanStatus.Trial,
    className: "bg-blue-steel",
    plans: [SubscriptionPlanType.Custom],
  },
  {
    value: SubscriptionPlanStatus.Active,
    className: "bg-green",
    plans: [SubscriptionPlanType.Custom, SubscriptionPlanType.Credits],
  },
  {
    value: SubscriptionPlanStatus.Suspended,
    className: "bg-orange",
    plans: [SubscriptionPlanType.Custom, SubscriptionPlanType.Credits],
  },
  {
    value: SubscriptionPlanStatus.Canceled,
    className: "bg-light-blue-steel",
    plans: [SubscriptionPlanType.Custom],
  },
  {
    value: SubscriptionPlanStatus.Expired,
    className: "bg-red",
    plans: [SubscriptionPlanType.Custom, SubscriptionPlanType.Credits],
  },
];

const inAYear = flow(add({ years: 1 }), startOfDay);

export const CreditBalance = memo<Props>(
  ({ className, organizationId, value, plan, onChange, ...props }) => {
    const { t } = useTranslation("customers");
    const [updateCreditsModal, setUpdateCreditsModal] = useState(false);
    const { location: location, push } = useHistory();
    const [updateCredits, { loading, called, reset, error }] =
      useSetSubscriptionPlanMutation({
        refetchQueries: [
          getOperationName(CustomerDocument)!,
          getOperationName(OrganizationCreditsHistoryDocument)!,
        ],
        awaitRefetchQueries: true,
      });

    const formRef = useRef<HTMLElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [leaveUrl, setLeaveUrl] = useState<string>();

    const validationSchema = object({
      plan: string().oneOf(Object.values(SubscriptionPlanType)),
      credits: number()
        .nullable()
        .min(MIN, t("credits.balance.errors.min"))
        .max(MAX, t("credits.balance.errors.max", { max: MAX }))
        .when("plan", {
          is: SubscriptionPlanType.Credits,
          then: number()
            .test(
              "decimals",
              t("credits.balance.errors.decimals"),
              Number.isSafeInteger
            )
            .required(t("credits.balance.errors.credits")),
          otherwise: number()
            .nullable()
            .test("empty", t("common:validation.required"), (value) => {
              return value !== undefined;
            })
            .test("decimals", t("credits.balance.errors.decimals"), (value) => {
              return value === null || Number.isSafeInteger(value);
            }),
        })
        .not([value]),
      reason: string().when(["plan", "credits"], ((
        plan: SubscriptionPlanType,
        credits: number,
        schema: StringSchema
      ) => {
        return plan === SubscriptionPlanType.Credits &&
          credits !== value?.credits
          ? schema.required()
          : schema.nullable();
      }) as any),
    });

    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      submitForm,
      isValid,
      resetForm,
      setFieldValue,
      values: { credits, reason, expirationDate, status, name },
    } = useFormik<FormData>({
      enableReinitialize: true,
      async onSubmit({ credits, plan, reason, expirationDate, status, name }) {
        const data =
          plan === SubscriptionPlanType.Credits
            ? {
                credits: {
                  credits: credits!,
                  previousValue: value?.credits ?? credits ?? 0,
                  type: reason ?? OrganizationCreditsEvent.SetViaApi,
                },
              }
            : {
                custom: {
                  creditsLimit: credits,
                },
                ...(credits !== value?.credits && {
                  credits: {
                    credits: credits!,
                    previousValue: value?.balance ?? Number(credits) ?? 0,
                    type: OrganizationCreditsEvent.ResetBalance,
                  },
                }),
              };

        const response = await updateCredits({
          variables: {
            organizationId,
            type: plan!,
            expirationDate: expirationDate.toISOString(),
            status,
            name,
            ...data,
          },
        });

        setFieldValue("reason", null);
        return response;
      },
      validationSchema,
      initialValues: {
        credits: value?.credits,
        reason: null,
        plan,
        name: value?.name,
        expirationDate: value?.expirationDate ?? inAYear(new Date()),
        status: value?.status ?? SubscriptionPlanStatus.Pending,
      },
    });

    // Plans are sorted in ascending credit cost to be correctly displayed in the UI
    const planCredits = {
      [SubscriptionPlanName.Launch]: 30,
      [SubscriptionPlanName.Grow]: 60,
      [SubscriptionPlanName.Scale]: 120,
      [SubscriptionPlanName.Custom]: credits,
    };

    const onCancel = (e?: MouseEvent) => {
      e?.stopPropagation();
      resetForm({
        values: {
          credits: value?.credits,
          reason: null,
          plan,
          expirationDate: value?.expirationDate ?? inAYear(new Date()),
          status: value?.status ?? SubscriptionPlanStatus.Pending,
        },
      });
      inputRef.current!.blur();
    };

    const onClick = () => {
      reset();
    };

    useEffect(() => {
      if (!loading) {
        onCancel();
      }
    }, [loading]);

    const creditsChanged = credits !== value?.credits;

    const changed =
      creditsChanged ||
      expirationDate.toString() !== value?.expirationDate?.toString() ||
      status !== value?.status;

    const changeValue = (increase?: boolean, event?: MouseEvent) => {
      event?.stopPropagation();
      setFieldValue(
        "credits",
        increase
          ? Math.min((credits ?? 0) + 1, MAX)
          : Math.max((credits ?? 0) - 1, MIN)
      );
    };

    useEffect(() => {
      onChange?.({ credits, reason, expirationDate, name, status });
    }, [credits, reason, expirationDate, name, status]);

    useClickOutside(formRef, () => !reason && value === credits && onCancel());

    const onDiscardLeave = () => setLeaveUrl(undefined);

    const onLeave = async () => {
      await resetForm();
      push(leaveUrl!);
    };

    const readonly =
      name !== SubscriptionPlanName.Custom &&
      plan === SubscriptionPlanType.Custom;

    return (
      <section
        className={clsx(className, styles.container, {
          [styles.loading]: loading,
          [styles.showControls]: changed,
        })}
        ref={formRef}
        {...props}
      >
        <Modal
          className={styles.modal}
          onClose={onDiscardLeave}
          visible={leaveUrl}
        >
          <h2>{t("credits.balance.discard.heading")}</h2>
          <p>{t("credits.balance.discard.text")}</p>
          <section className="flex justify-end mt-7">
            <Button
              className="mr-5"
              onClick={onDiscardLeave}
              outlined
              type="button"
              variant={Button.variants.Cancel}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button onClick={onLeave} type="button">
              {t("common:actions.yes")}
            </Button>
          </section>
        </Modal>
        <Prompt
          message={(newLocation) => {
            if (dirty && location.pathname !== newLocation.pathname) {
              setLeaveUrl(newLocation.pathname);
            }

            return !dirty;
          }}
          when={dirty}
        />

        {plan === SubscriptionPlanType.Custom && (
          <>
            <section className="flex items-center justify-center mb-6">
              <Dropdown
                value={name}
                size={Dropdown.sizes.Small}
                variant={Button.variants.Secondary}
                label={t(`credits.plans.names.placeholder`)}
                onSelect={(data) => {
                  setFieldValue("name", data);
                  setFieldValue(
                    "credits",
                    planCredits[data as SubscriptionPlanName]
                  );
                }}
              >
                {Object.keys(planCredits).map((plan) => (
                  <Dropdown.Item value={plan} className="text-left" key={plan}>
                    {t(`credits.plans.names.${plan}`)}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </section>
          </>
        )}
        <p>{t("credits.balance.title", { context: plan })}</p>
        <div onClick={onClick} className={styles.inputContainer}>
          <input
            className={styles.balance}
            name="credits"
            placeholder="0"
            onBlur={handleBlur}
            onChange={handleChange}
            onClick={onClick}
            ref={inputRef}
            readOnly={readonly}
            type="number"
            value={credits ?? ""}
          />

          {Number.isFinite(credits) && !readonly && (
            <div className={styles.arrows}>
              <ArrowIcon
                onClick={changeValue.bind(null, true)}
                className={clsx(styles.arrow, {
                  [styles.disabled]: credits === MAX,
                })}
              />
              <ArrowIcon
                onClick={changeValue.bind(null, false)}
                className={clsx(styles.arrow, styles.decrease, {
                  [styles.disabled]: credits === MIN,
                })}
              />
            </div>
          )}
        </div>
        {errors.credits && value !== credits && (
          <div className={styles.error}>{errors.credits}</div>
        )}
        {error?.graphQLErrors?.[0].extensions?.code && (
          <div className={styles.error}>
            {error?.graphQLErrors?.[0].extensions?.code ===
            SubscriptionErrorCodes.SubscriptionPlanExpirationDateError
              ? t(
                  `credits.balance.errors.${error.graphQLErrors?.[0].extensions?.code}`,
                  { context: plan }
                )
              : t("common:validation.general")}
          </div>
        )}
        {plan === SubscriptionPlanType.Credits && creditsChanged && (
          <div className={styles.selectContainer}>
            <Dropdown
              label={t("credits.balance.reason")}
              onSelect={handleChange("reason")}
              popoverClassName="text-left"
              value={reason!}
              shifting={false}
            >
              {creditReasonEvents.map((reason) => (
                <Dropdown.Item key={reason} value={reason}>
                  {t(`credits.balance.reasons.${reason}`)}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        )}

        {plan === SubscriptionPlanType.Custom && (
          <section className="flex justify-between items-center mb-4">
            <p className="ml-4">
              <p>{t("credits.balance.title")}</p>
            </p>
            <section className="grow flex justify-end relative">
              <p className="bg-grey flex items-center rounded-md py-3 px-5">
                {(creditsChanged || !!onChange ? credits : value?.balance) ?? 0}
                <Tooltip
                  variant={Tooltip.variants.Secondary}
                  title={
                    changed
                      ? t("credits.plans.disabled")
                      : t("credits.plans.actions.update")
                  }
                  placement={Tooltip.placements.Top}
                  className="whitespace-nowrap"
                >
                  <UpdateIcon
                    onClick={() => !changed && setUpdateCreditsModal(true)}
                    className={clsx(
                      `ml-4 cursor-${changed ? "not-allowed" : "pointer"}`,
                      {
                        "animate-spin pointer-events-none": loading,
                      }
                    )}
                  />
                </Tooltip>
              </p>
            </section>
          </section>
        )}
        <Dropdown<string>
          className="mt-4 mb-2 w-full flex justify-between"
          variant={Button.variants.Soft}
          value={status}
          onSelect={handleChange("status")}
          popoverClassName="w-full"
          prefix={t("credits.status")}
        >
          {subscriptionStatuses.map(
            ({ value, className, plans }) =>
              plan &&
              plans.includes(plan) && (
                <Dropdown.Item key={value} value={value}>
                  <p
                    className={`${className} grow text-white rounded-full ml-4 py-3 px-5`}
                  >
                    {t(`common:customers.status.${value}`)}
                  </p>
                </Dropdown.Item>
              )
          )}
        </Dropdown>
        <Dropdown<string>
          className="mt-4 mb-2 w-full"
          variant={Button.variants.Soft}
          prefix={t("credits.expirationDate.label", { context: plan })}
          onSelect={(data) => setFieldValue("expirationDate", data)}
          placement={Dropdown.placements.Bottom}
          shifting={false}
        >
          <Dropdown.Datepicker
            filterDate={isAfter(new Date())}
            selected={expirationDate}
            inline
          />
        </Dropdown>
        {(changed || called) && !onChange && (
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.cancelButton}
              onClick={onCancel}
              type="button"
              variant={Button.variants.Action}
            >
              {t("common:actions.discard")}
            </Button>

            <CSSTransition
              classNames={{
                enter: styles.buttonEnter,
                exit: styles.buttonExit,
                enterActive: styles.buttonEnterActive,
                exitActive: styles.buttonExitActive,
              }}
              in={changed}
              timeout={{
                enter: 3500,
                exit: 3500,
              }}
              unmountOnExit
            >
              <SaveButton
                disabled={!isValid}
                loading={loading}
                reset={!loading && credits !== value?.credits}
                onClick={submitForm}
                type="button" // to not trigger parent forms
              />
            </CSSTransition>
          </div>
        )}
        <UpdateCredits
          visible={updateCreditsModal}
          onClose={() => setUpdateCreditsModal(false)}
          max={value?.credits ?? 0}
          onSubmit={async (data) => {
            await updateCredits({
              variables: {
                organizationId,
                type: plan!,
                credits: {
                  credits: Number(data.amount),
                  previousValue: value?.balance ?? Number(data.amount) ?? 0,
                  type: data.reason,
                },
                custom: { creditsLimit: value?.credits ?? 0 },
              },
            });
            setUpdateCreditsModal(false);
          }}
          loading={loading}
        />
      </section>
    );
  }
);
