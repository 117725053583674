import React, { memo, NamedExoticComponent, SVGProps } from "react";

const VideoIcon: NamedExoticComponent<SVGProps<SVGSVGElement>> = memo(
  (props) => (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="28"
        cy="28"
        r="27"
        fill="white"
        stroke="#3A2548"
        strokeWidth="2"
      />
      <path
        d="M37.5129 26.3373C38.6975 27.1293 38.6975 28.8707 37.5129 29.6627L24.1115 38.6216C22.7826 39.51 21 38.5575 21 36.9589L21 19.0411C21 17.4425 22.7826 16.49 24.1115 17.3784L37.5129 26.3373Z"
        fill="#4F3D5C"
      />
    </svg>
  )
);

VideoIcon.displayName = "VideoIcon";

export default VideoIcon;
