import { GrowthBook } from "@growthbook/growthbook-react";
import { useEffect } from "react";

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV === "development",
  subscribeToChanges: true,
});

export const useSetupFeatures = () => {
  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  return {
    client: growthbook,
  };
};
