import { Button, Dropdown, Input, Modal } from "@CreativelySquared/uikit";
import { OrganizationCreditsEvent } from "api/enums";
import { ModalDescription, ModalTitle } from "components";
import { useFormik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formaNumberValue } from "utils/form";
import { object, string } from "yup";

import styles from "./styles.module.scss";

type FormData = {
  amount: string;
  reason: OrganizationCreditsEvent;
};

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
  max: number;
  loading?: boolean;
}

const reasons = [
  OrganizationCreditsEvent.Refund,
  OrganizationCreditsEvent.BriefAssistance,
  OrganizationCreditsEvent.ResetBalance,
  OrganizationCreditsEvent.Reward,
  OrganizationCreditsEvent.AdditionalContent,
  OrganizationCreditsEvent.ProjectCancellation,
];

export const UpdateCredits: FC<Props> = ({
  visible,
  onClose,
  onSubmit,
  loading,
  max,
}) => {
  const { t } = useTranslation("customers");

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values: { amount, reason },
  } = useFormik<FormData>({
    onSubmit,
    initialValues: {
      amount: "",
      reason: OrganizationCreditsEvent.ResetBalance,
    },
    validationSchema: object({
      amount: string().test(
        "max",
        t("credits.balance.errors.max", { max }),
        (value) => Number(value) <= Number(max)
      ),
    }),
  });

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      className="w-full max-w-[545px] pb-9 overflow-visible"
    >
      <form noValidate onSubmit={handleSubmit}>
        <ModalTitle>{t("credits.balance.update.heading")}</ModalTitle>
        <ModalDescription>
          {t("credits.balance.update.description")}
        </ModalDescription>
        <div className="relative pb-8">
          <Input
            name="amount"
            variant={Input.variants.Secondary}
            value={amount}
            className="w-full"
            placeholder={t("credits.balance.update.placeholder")}
            onBlur={handleBlur}
            onChange={({ target: { value } = {} }) =>
              setFieldValue(
                "amount",
                formaNumberValue(value, { allowZero: true })
              )
            }
            error={!!submitCount && errors.amount}
          />
          {!!submitCount && !!errors?.amount && (
            <p className="absolute text-xs text-red bottom-4">
              {errors.amount}
            </p>
          )}
        </div>
        <div className="relative">
          <Dropdown
            value={reason}
            onSelect={handleChange("reason")}
            outlined
            className={styles.updateCreditsReason}
            placement={Dropdown.placements.BottomStart}
            shifting={false}
            popoverClassName="w-full overflow-scroll max-h-[200px]"
          >
            {reasons.map((reason) => (
              <Dropdown.Item key={reason} value={reason}>
                {t(`credits.balance.reasons.${reason}`)}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <section className="flex justify-end mt-8">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-6"
            onClick={onClose}
            outlined
          >
            {t("common:actions.cancel")}
          </Button>
          <Button loading={loading} type="submit" disabled={!amount || loading}>
            {t("common:actions.apply")}
          </Button>
        </section>
      </form>
    </Modal>
  );
};
