import { useBrandQuery, useGetBrandSharingTokenQuery } from "api/graphql";
import { Helmet } from "react-helmet";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { useAuthData } from "Authorization/authorization.hooks";
import { Loader } from "@CreativelySquared/uikit";
import { Trans, useTranslation } from "react-i18next";
import { ColorPicker } from "Brands/components/ColorPicker";
import { saveAs } from "file-saver";
import { BrandGuidelines } from "Brands/components/BrandGuidelines";
import { BrandReferences } from "Brands/components/BrandReferences";
import { Features } from "api/features";
import clsx from "clsx";
import { withSuspense } from "utils/hooks/suspense";

const SharedBrandRoute = compose(
  ProtectedRoute({ access: Roles.shared, feature: Features.brand_share_link }),
  Route(
    {
      sharedCode: Route.params.string,
    },
    ({ sharedCode }) => `/shared/brand/${sharedCode}`
  )
);

const useGetBrandSharingTokenSuspenseQuery = withSuspense(
  useGetBrandSharingTokenQuery
);

export const SharedBrand = SharedBrandRoute(
  ({
    match: {
      params: { sharedCode },
    },
  }) => {
    const { t } = useTranslation("brands");
    const auth = useAuthData();
    const { data: { sharedData } = {}, loading: tokenLoading } =
      useGetBrandSharingTokenSuspenseQuery({
        variables: {
          linkCode: sharedCode,
        },
        onCompleted: ({ sharedData }) => {
          auth.setSessionToken(sharedData?.accessToken);
        },
        suspense: true,
      });

    const { data: { getBrandProfile: brand } = {}, loading: brandLoading } =
      useBrandQuery({
        variables: {
          brandProfileId:
            sharedData?.brandProfileSharingLink?.brandProfileId ?? "",
        },
        errorPolicy: "all",
        skip: !(
          sharedData?.accessToken &&
          sharedData?.brandProfileSharingLink?.brandProfileId
        ),
      });

    const loading = tokenLoading || brandLoading;

    if (loading) {
      return (
        <main className="h-screen flex items-center justify-center">
          <Helmet>
            <title>{t("details.title")}</title>
          </Helmet>
          <Loader radius={50} className="mx-auto mt-11" />;
        </main>
      );
    }

    return (
      <section className="w-full max-w-3xl m-auto px-10 box-content min-h-full flex flex-col">
        <main className="w-full grow pt-10">
          <Helmet>
            <title>{t("details.title")}</title>
          </Helmet>
          <header className="flex justify-between items-center mb-11 w-full">
            <h1>{t("details.title")}</h1>
            <h1 className="text-light-blue-steel max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
              {brand?.name}
            </h1>
          </header>
          {brand?.avatar?.thumbnail?.downloadUrl && (
            <section className="mb-10">
              <img
                className="w-[160px] aspect-square rounded-xl object-cover"
                src={brand.avatar.thumbnail.downloadUrl.url}
              />
            </section>
          )}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-5">
              {t("details.form.overview")}
            </h2>
            {brand?.name && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.name.label")}
                </label>
                <p className="font text-blackberry">{brand?.name}</p>
              </fieldset>
            )}
            {brand?.description && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.about.label")}
                </label>
                <p className="font text-blackberry">{brand?.description}</p>
              </fieldset>
            )}
          </section>
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-5">
              {t("details.form.branding")}
            </h2>
            {!!brand?.guidelines?.length && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.identity.label")}
                </label>
                <ul>
                  {brand.guidelines.map(
                    (guideline) =>
                      guideline?.fileName && (
                        <li
                          onClick={() => {
                            if (!guideline?.download?.downloadUrl?.url) return;
                            saveAs(
                              guideline.download?.downloadUrl.url,
                              guideline.fileName!
                            );
                          }}
                          className={clsx("text-teal font-medium  mb-3", {
                            "cursor-pointer hover:underline":
                              guideline?.download?.downloadUrl?.url,
                          })}
                          key={guideline?.fileId}
                        >
                          {guideline?.fileName}
                        </li>
                      )
                  )}
                </ul>
              </fieldset>
            )}
            {!!brand?.assets?.length && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.assets.label")}
                </label>
                <ul>
                  {brand.assets.map(
                    (asset) =>
                      asset?.fileName && (
                        <li
                          onClick={() => {
                            if (!asset?.download?.downloadUrl?.url) return;
                            saveAs(
                              asset.download?.downloadUrl.url,
                              asset.fileName!
                            );
                          }}
                          className={clsx("text-teal font-medium  mb-3", {
                            "cursor-pointer hover:underline":
                              asset?.download?.downloadUrl?.url,
                          })}
                          key={asset?.fileId}
                        >
                          {asset?.fileName}
                        </li>
                      )
                  )}
                </ul>
              </fieldset>
            )}
            {!!brand?.colors?.length && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.colors.label")}
                </label>
                <div className="mt-4">
                  {brand.colors.map(
                    (color, id) =>
                      color && (
                        <ColorPicker
                          key={color + id}
                          value={color}
                          className="mr-4"
                        />
                      )
                  )}
                </div>
              </fieldset>
            )}
          </section>
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-5">
              {t("details.form.guidelines.title")}
            </h2>
            {brand?.guidelinesRequirements && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.legal.label")}
                </label>
                <p className="font text-blackberry">
                  {brand?.guidelinesRequirements}
                </p>
              </fieldset>
            )}
            {!!brand?.wantedCharacteristics?.length && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.guidelines.do.label")}
                </label>
                <BrandGuidelines
                  type="do"
                  value={brand?.wantedCharacteristics}
                  className="mt-6"
                />
              </fieldset>
            )}
            {!!brand?.unwantedCharacteristics?.length && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.guidelines.dont.label")}
                </label>
                <BrandGuidelines
                  type="dont"
                  value={brand?.unwantedCharacteristics}
                  className="mt-6"
                />
              </fieldset>
            )}
            {!!brand?.references?.length && (
              <fieldset className="mb-7">
                <label className="text-sm text-mid-blue-steel mb-4">
                  {t("details.form.references.label")}
                </label>
                <BrandReferences
                  value={brand?.references}
                  organizationId={brand?.organizationId ?? ""}
                  className="mt-6"
                />
              </fieldset>
            )}
          </section>
        </main>
        <footer className="pb-10">
          <hr className="border-light-blue-steel" />
          <p className="mb-10 mt-7">
            <Trans
              className="text-sm text-blue-steel "
              t={t}
              i18nKey="details.footer.description"
              components={{
                a: (
                  <a
                    href="https://www.creativelysquared.com/"
                    target="_blank"
                    className="text-teal hover:underline"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </p>
          <section className="flex justify-between items-center">
            <a
              href="https://www.creativelysquared.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://d5a8r2gf7c1zu.cloudfront.net/v1/logo.png"
                width="105"
                height="65"
                alt="Creatively Squared"
              />
            </a>

            <ul className="flex">
              <li className="ml-7">
                <a
                  href="https://twitter.com/creativelysqrd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://creativelysquared-email-assets.s3.ap-southeast-1.amazonaws.com/v1/X.png"
                    width="16"
                    height="16"
                    alt="X"
                  />
                </a>
              </li>
              <li className="ml-7">
                <a
                  href="https://www.facebook.com/creativelysquared"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://creativelysquared-email-assets.s3.ap-southeast-1.amazonaws.com/v1/facebook.png"
                    width="16"
                    height="16"
                    alt="Facebook"
                  />
                </a>
              </li>
              <li className="ml-7">
                <a
                  href="https://instagram.com/creativelysquared"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://creativelysquared-email-assets.s3.ap-southeast-1.amazonaws.com/v1/instagram.png"
                    width="16"
                    height="16"
                    alt="Instagram"
                  />
                </a>
              </li>
              <li className="ml-7">
                <a
                  href="https://www.linkedin.com/company/creatively-squared"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://creativelysquared-email-assets.s3.ap-southeast-1.amazonaws.com/v1/linkedin.png"
                    width="16"
                    height="16"
                    alt="Linkedin"
                  />
                </a>
              </li>
            </ul>
          </section>
        </footer>
      </section>
    );
  }
);
