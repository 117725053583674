import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.common.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {},
    },
    fallbackLng: "en",
    supportedLngs: ["en"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: process.env.NODE_ENV === "development",
  });

i18next.addResourceBundle("en", "common", en, true);

export default i18next;
