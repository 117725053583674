import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { useProfileQuery } from "api/graphql";
import { withSuspense } from "utils/hooks/suspense";
import { AuthContext } from "Authorization/authorization.context";
const USER_SETTINGS_KEY = "userSettings";

type UserSettings = {
  levelUpBanner: boolean;
};

interface ContextType {
  userSettings?: UserSettings;
  updateUserSettings: (settings: Partial<UserSettings>) => void;
}

type Settings = { [userId: string]: UserSettings };

const defaultUserSettings: UserSettings = {
  levelUpBanner: true,
};
const UserSettingsContext = createContext<ContextType>({
  userSettings: defaultUserSettings,
  updateUserSettings: () => {},
});

const useProfileSuspenseQuery = withSuspense(useProfileQuery);

const getSettings = () => {
  const settings: Settings = JSON.parse(
    localStorage.getItem(USER_SETTINGS_KEY) || "{}"
  );

  return settings;
};

const getUserSettings = (userId?: string) => {
  if (!userId) return defaultUserSettings;

  const settings = getSettings();

  return settings[userId] || defaultUserSettings;
};

export const UserSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { accessToken } = useContext(AuthContext);

  const { data: { me } = {} } = useProfileSuspenseQuery({
    suspense: true,
    skip: !accessToken,
  });

  const [userSettings, setUserSettings] = useState<UserSettings>(
    getUserSettings(me?.userId)
  );

  const updateUserSettings = useCallback(
    (userSettings: Partial<UserSettings>) => {
      if (!me?.userId) return;

      const settings = getSettings();
      const newUserSettings = { ...settings[me.userId], ...userSettings };

      setUserSettings(newUserSettings);
    },
    [me]
  );

  useEffect(() => {
    if (!me) return;

    const userSettings = getUserSettings(me.userId);

    if (userSettings) setUserSettings(userSettings);
  }, [me]);

  useEffect(() => {
    if (!me) return;

    const settings: Settings = JSON.parse(
      localStorage.getItem(USER_SETTINGS_KEY) || "{}"
    );

    localStorage.setItem(
      USER_SETTINGS_KEY,
      JSON.stringify({ ...settings, [me.userId]: userSettings })
    );
  }, [userSettings]);

  return (
    <UserSettingsContext.Provider value={{ userSettings, updateUserSettings }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettings = () => useContext(UserSettingsContext);
