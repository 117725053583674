import { FC } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ListItemWrapper: FC<Props> = ({ children, className }) => (
  <div className={clsx(styles.container, className)}>{children}</div>
);
