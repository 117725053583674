import { links } from "App";
import { useMemo } from "react";
import { Redirect, useLocation } from "react-router";
import { match } from "path-to-regexp";
import { parseQueryParams } from "utils/hooks/query";

export const LegacyRedirection = () => {
  const paths = useMemo(
    () => ({
      "/share-project": ({ linkCode }: Record<string, string>) =>
        links.SharedGallery({ sharedCode: linkCode }),
    }),
    []
  );
  const { hash } = useLocation();
  const redirection = useMemo(() => {
    try {
      const path = new URL(`${location.origin}${hash.replace("#", "")}`);
      if (!path) return;
      const redirection = Object.entries(paths).find(([pattern]) =>
        match(pattern)(path.pathname)
      );

      if (redirection) {
        const [pattern, link] = redirection;
        const pathParams = match(pattern)(path.pathname);
        const params = Object.assign(
          {},
          pathParams ? pathParams.params : {},
          parseQueryParams(path.search)
        );
        return link(params);
      }
    } catch {
      console.log("Error while redirecting from legacy URL");
    }
  }, [hash]);

  return redirection ? <Redirect to={redirection} /> : null;
};
