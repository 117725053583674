/**
 * Extend child component props by root component props with possibillity to extend callback functions as well
 * @param props - initial props of the root component
 * @param extend - props of the child component
 */
export const mergeProps = <
  P extends { [x: string]: any },
  E extends { [x: string]: any }
>(
  props: P,
  extend: E
): P & E => {
  const callbacks = Object.entries(extend).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, value]) => typeof value === "function"
  );
  return Object.assign(
    {},
    props,
    extend,
    callbacks.reduce((acc, [key, value]) => {
      if (typeof props[key] === "function") {
        return {
          ...acc,
          [key]: (...args: any[]) => {
            props[key](...args);
            value(...args);
          },
        };
      }
      return acc;
    }, {})
  );
};
