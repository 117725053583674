export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum SortFields {
  createdAt = "createdAt",
  name = "name",
  title = "title",
  status = "status",
  submissionAt = "submissionAt",
}
