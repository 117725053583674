import { Button, Input, Loader } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import { compose, Route } from "react-router-hoc";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { parseError } from "utils/form";
import { object, ref, string } from "yup";
import { passwordValidationSchema } from "utils/validation";
import { ReactComponent as BackIcon } from "images/back.svg";
import { Link } from "react-router-dom";
import { links } from "App";
import {
  useChangePasswordUsingCodeMutation,
  useLoginLazyQuery,
  useValidatePasswordRecoveryCodeQuery,
} from "api/graphql";
import { useNotification } from "components";
import { useContext } from "react";
import { AuthContext } from "Authorization/authorization.context";
import { ProtectedRoute } from "utils/route";
import { Roles } from "utils/roles";

import PasswordIcon from "../images/PasswordIcon";

const ActivateAccountRoute = compose(
  ProtectedRoute({ access: Roles.unauthorized }),
  Route(
    {
      userId: Route.query.string,
      code: Route.query.string,
    },
    "/activate-account"
  )
);

const validationSchema = object({
  password: string().withMutation((schema) => passwordValidationSchema(schema)),
  confirmPassword: string()
    .oneOf([ref("password")], "common:validation.passwordMatch")
    .required("common:validation.required"),
});

export const ActivateAccount = ActivateAccountRoute(
  ({
    match: {
      query: { userId, code },
    },
    history: { replace },
  }) => {
    const { t } = useTranslation("authorization");
    const { setNotification, notificationTypes } = useNotification();
    const { setAccessToken } = useContext(AuthContext);

    const {
      data: { validatePasswordRecoveryCode: isCodeValid } = {},
      loading: validateCodeLoading,
    } = useValidatePasswordRecoveryCodeQuery({
      variables: {
        code: code!,
      },
      skip: !code || !userId,
    });

    const [login, { loading: loginLoading }] = useLoginLazyQuery({
      onCompleted: ({ getAuthenticationTokens }) => {
        setAccessToken(getAuthenticationTokens?.accessToken);
        replace("/");
      },
      onError: (error) => {
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
    });
    const [changePasswordUsingCode, { loading: createPasswordLoading }] =
      useChangePasswordUsingCodeMutation({
        onError: (error) => {
          setNotification({
            message: error.message ?? t("common:common.error"),
            type: notificationTypes.Error,
          });
        },
        onCompleted: ({ changePasswordUsingCode }) => {
          login({
            variables: {
              email: changePasswordUsingCode?.email ?? "",
              password: password,
            },
          });
        },
      });

    const {
      values: { password, confirmPassword },
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    } = useFormik<{ password: string; confirmPassword: string }>({
      validationSchema,
      initialValues: { password: "", confirmPassword: "" },
      onSubmit: (data) => {
        if (userId && code) {
          changePasswordUsingCode({
            variables: { userId, code, newPassword: data.password },
          });
        } else {
          setNotification({
            message: t("common:common.error"),
            type: notificationTypes.Error,
          });
        }
      },
    });

    if (validateCodeLoading) {
      return <Loader radius={50} className="m-auto" />;
    }

    if (!isCodeValid || !code || !userId) {
      return (
        <div className="w-[500px]">
          <p className="mb-10 text-l text-blue-steel">
            {t("activateAccount.invalidCode")}
          </p>
          <Link to={links.Login()} className="mt-8">
            <Button type="button" className="w-full">
              <BackIcon className="mr-4" />
              {t("activateAccount.form.actions.back")}
            </Button>
          </Link>
        </div>
      );
    }

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Helmet>
          <title>{t("activateAccount.title")}</title>
        </Helmet>
        <h2 className="mb-10">{t("activateAccount.form.heading")}</h2>
        <Input
          className="w-full mb-7"
          type="password"
          value={password}
          placeholder={t("activateAccount.form.fields.password.placeholder")}
          name="password"
          autoComplete="off"
          icon={<PasswordIcon unlocked={!!password} />}
          error={
            touched.password && t(...parseError(errors.password)).toString()
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          className="w-full"
          type="password"
          value={confirmPassword}
          placeholder={t(
            "activateAccount.form.fields.confirmPassword.placeholder"
          )}
          name="confirmPassword"
          autoComplete="off"
          icon={<PasswordIcon unlocked={!!confirmPassword} />}
          error={
            touched.confirmPassword &&
            t(...parseError(errors.confirmPassword)).toString()
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <section className="mt-10">
          <Button
            className="w-full"
            loading={createPasswordLoading || loginLoading}
            type="submit"
          >
            {t("activateAccount.form.actions.reset")}
          </Button>
          <Link to={links.Login()} className="block mt-4">
            <Button type="button" outlined borderless className="w-full">
              <BackIcon className="mr-4" />
              {t("activateAccount.form.actions.back")}
            </Button>
          </Link>
        </section>
      </form>
    );
  }
);
