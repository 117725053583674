import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const ForgotPasswordRoute = compose(
  ProtectedRoute({ access: Roles.unauthorized }),
  Route("/forgot-password")
);

export const ForgotPassword = ForgotPasswordRoute(
  lazy(
    () => import(/* webpackChunkName: "ForgotPassword" */ "./ForgotPassword")
  )
);
