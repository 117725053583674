import { Button } from "@CreativelySquared/uikit";
import { memo } from "react";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import { ShareLink, ShareLinkProps } from "components";
import clsx from "clsx";

import styles from "./styles.module.scss";

type Props = {
  title: string;
  fields: Array<ShareLinkProps & { label: string }>;
  onClose?: () => void;
  className?: string;
};

export const ShareBanner = memo<Props>(
  ({ onClose, title, fields, className }) => (
    <section className={clsx(styles.container, className)}>
      <Button
        onClick={onClose}
        className={styles.close}
        variant={Button.variants.Icon}
      >
        <CrossIcon />
      </Button>
      <section className="grow m-auto">
        <h3>{title}</h3>
        <main>
          {fields.map(({ label, ...shareLinkProps }) => (
            <fieldset key={label} className="flex flex-col relative">
              <label>{label}</label>
              <ShareLink {...shareLinkProps} />
            </fieldset>
          ))}
        </main>
      </section>
    </section>
  )
);
