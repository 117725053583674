import { DetailedHTMLProps, HTMLAttributes, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  AssetDocument,
  ProjectAssetsDocument,
  GalleryProjectDocument,
  ProjectDocument,
  useAssetQuery,
  useDeleteAssetVersionMutation,
  useDestroyAssetsMutation,
} from "api/graphql";
import { Button, Loader } from "@CreativelySquared/uikit";
import { SelectAssetVersion } from "components/SelectAssetVersion";
import { getOperationName } from "@apollo/client/utilities";

type Props = {
  assetId: string;
  currentVersion?: string;
  onDelete?: (versionId: string) => void;
  onClose: () => void;
  allOption?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const DeleteAsset = memo<Props>(
  ({
    assetId,
    currentVersion,
    onClose,
    onDelete,
    allOption = false,
    className,
    ...props
  }) => {
    const { t } = useTranslation();
    const [versionId, setVersionId] = useState(currentVersion ?? "");

    const [removeAsset, { loading: removeAssetLoading }] =
      useDestroyAssetsMutation();
    const [removeAssetVersion, { loading: removeAssetVersionLoading }] =
      useDeleteAssetVersionMutation();
    const { data: { getAsset: asset } = {}, loading: assetLoading } =
      useAssetQuery({
        variables: {
          assetId,
        },
      });
    const hasManyVersions = (asset?.versions?.length ?? 0) > 1;

    const onRemove = async () => {
      await (versionId && hasManyVersions
        ? removeAssetVersion({
            variables: {
              assetVersionId: versionId,
            },
            refetchQueries: [
              getOperationName(ProjectDocument)!,
              getOperationName(ProjectAssetsDocument)!,
              {
                query: GalleryProjectDocument,
                variables: {
                  projectId: asset?.project?.projectId,
                },
              },
              {
                query: AssetDocument,
                variables: {
                  assetId,
                },
              },
            ],
            awaitRefetchQueries: true,
          })
        : removeAsset({
            variables: { assetsIds: [assetId] },
            refetchQueries: [
              getOperationName(ProjectDocument)!,
              getOperationName(ProjectAssetsDocument)!,
              {
                query: GalleryProjectDocument,
                variables: {
                  projectId: asset?.project?.projectId,
                },
              },
            ],
            awaitRefetchQueries: true,
          }));
      onDelete ? onDelete(hasManyVersions ? versionId : "") : onClose();
    };

    const loading = removeAssetLoading || removeAssetVersionLoading;

    return (
      <section
        className={clsx("text-blackberry w-[465px]", className)}
        {...props}
      >
        <h3>{t("common:asset.delete.title")}</h3>
        <p className="mt-4 mb-8">
          {t("common:asset.delete.description", { count: +allOption })}
        </p>
        {assetLoading ? (
          <Loader radius={50} className="m-auto" />
        ) : (
          <>
            <SelectAssetVersion
              currentVersion={versionId}
              versions={hasManyVersions ? asset?.versions ?? [] : []}
              setVersion={setVersionId}
              allOption={allOption}
            />
            <section className="flex justify-end">
              <Button
                variant={Button.variants.Cancel}
                type="button"
                className="mr-5"
                onClick={onClose}
                outlined
                borderless
              >
                {t("common:asset.delete.actions.cancel")}
              </Button>
              <Button loading={loading} onClick={onRemove}>
                {t("common:asset.delete.actions.delete")}
              </Button>
            </section>
          </>
        )}
      </section>
    );
  }
);
