import { lazy } from "react";
import { Route } from "react-router-hoc";

export const UGCStoryboardCreatorRoute = Route(
  {
    ideaId: Route.query.string,
    organizationId: Route.query.string,
  },
  "/ai/tools/ugc-storyboard"
);

export const UGCStoryboardCreator = UGCStoryboardCreatorRoute(
  lazy(
    () =>
      import(
        /* webpackChunkName: "UGCStoryboardCreator" */ "./UGCStoryboardCreator"
      )
  )
);
