import { Route } from "react-router-hoc";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button } from "@CreativelySquared/uikit";
import { links } from "App";
import { Link } from "react-router-dom";

export const Maintenance = Route("/maintenance")(() => {
  const { t } = useTranslation("authorization");

  return (
    <section>
      <Helmet>
        <title>{t("maintenance.title")}</title>
      </Helmet>
      <h2 className="mb-4">{t("maintenance.heading")}</h2>
      <p className="mb-10 text-l text-blue-steel">
        {t("maintenance.description")}
      </p>
      <Link to={links.Login()} className="block">
        <Button className="w-full" type="button">
          {t("maintenance.actions.return")}
        </Button>
      </Link>
    </section>
  );
});
