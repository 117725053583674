import { useContext, useEffect } from "react";
import { AuthContext } from "Authorization/authorization.context";
import { compose, Route } from "react-router-hoc";
import { ProtectedRoute } from "utils/route";
import { useApolloClient } from "@apollo/client";
import * as Intercom from "@intercom/messenger-js-sdk";
import { clearChameleon } from "utils/chameleon";

const LogoutRoute = compose(ProtectedRoute, Route("/logout"));

export const Logout = LogoutRoute(() => {
  const { accessToken, setAccessToken } = useContext(AuthContext);
  const { cache } = useApolloClient();
  useEffect(() => {
    if (accessToken) {
      setAccessToken(null);
      cache.reset();
      Intercom.shutdown();
      clearChameleon();
    }
  }, []);
  return null;
});
