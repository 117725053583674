import "./locales";
import { lazy } from "react";
import { Switch } from "react-router";
import { compose, Route } from "react-router-hoc";
import { SortOrder } from "utils/order";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";

export const BrandsRoute = compose(
  ProtectedRoute({
    access: [Roles.admin, Roles.organization_owner, Roles.account_manager],
  }),
  Route(
    {
      page: Route.query.number,
      search: Route.query.string,
      sort: Route.query.oneOf("name", "createdAt"),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
    },
    "/brands"
  )
);

export const Brands = BrandsRoute(
  lazy(() => import(/* webpackChunkName: "Brands" */ "./Brands"))
);

export const BrandDetailsRoute = compose(
  ProtectedRoute({
    access: [Roles.admin, Roles.organization_owner, Roles.account_manager],
  }),
  Route(
    {
      id: Route.params.string,
      mode: Route.query.oneOf("delete"),
    },
    ({ id }) => `/brand/${id}`
  )
);

export const BrandDetails = BrandDetailsRoute(
  lazy(() => import(/* webpackChunkName: "BrandDetails" */ "./BrandDetails"))
);

export const links = {
  Brands,
  BrandDetails,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Brands exact />
      <BrandDetails />
    </Switch>
  );
});
