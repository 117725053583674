import { FC } from "react";
import { AssetsView, useAssetsSelect } from "DAM/components/AssetsView";
import { useAssetsSettings } from "DAM/hooks/useAssetsSettings";
import { Button } from "@CreativelySquared/uikit";
import { useTranslation } from "react-i18next";
import {
  AssetItemFragment,
  CollectionItemNodeFragment,
  ProjectDetailsFragment,
} from "api/types";
import { ReactComponent as ArrowIcon } from "images/arrow.svg";

type Props = {
  organizationId?: string | null;
  onAdd: (assets: AssetItemFragment[]) => void;
  multiple?: boolean;
  project?: ProjectDetailsFragment;
  collection?: CollectionItemNodeFragment;
  onClose?: () => void;
};

export const MediaAssets: FC<Props> = ({
  organizationId,
  onAdd,
  multiple,
  project,
  collection,
  onClose,
}) => {
  const { t } = useTranslation("common");
  const { onSelectAsset, onClearSelection, selectedAssetIds, selectedAssets } =
    useAssetsSelect();
  const categoryName = project?.title || collection?.name;

  const assetsSettings = {
    ...useAssetsSettings(),
    organizationId,
    selectedAssets: selectedAssetIds,
    onSelectAsset: (asset: AssetItemFragment) => {
      if (!multiple && !selectedAssets.includes(asset)) {
        onClearSelection();
      }
      onSelectAsset(asset);
    },
    ...(!!categoryName && {
      onFilter: undefined,
      onSort: undefined,
      onSearch: undefined,
      filters: {
        ...(project?.projectId && { projects: [project?.projectId] }),
        ...(collection?.collectionId && {
          collectionIds: [collection.collectionId],
        }),
      },
    }),
  };

  return (
    <div className="flex flex-col h-full">
      <div className="h-full overflow-auto flex flex-col mb-auto px-2">
        <AssetsView
          {...assetsSettings}
          filters={{
            ...assetsSettings.filters,
            ...(organizationId && { organizations: [organizationId] }),
          }}
          actionButton={
            !!categoryName && (
              <Button
                outlined
                borderless
                className="p-0 place-self-start"
                onClick={onClose}
              >
                <ArrowIcon className="mr-4" />
                <p className="flex-1 text-ellipsis overflow-hidden text-nowrap">
                  {categoryName}
                </p>
              </Button>
            )
          }
        />
      </div>
      <Button
        className="mt-8 w-full"
        onClick={() => onAdd(selectedAssets)}
        disabled={!selectedAssets.length}
      >
        {t("addMedia.actions.add")}
      </Button>
    </div>
  );
};
