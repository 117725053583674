import { lazy } from "react";
import { Route } from "react-router-hoc";

export const MindMapRoute = Route(
  {
    organizationId: Route.query.string,
  },
  "/ai/tools/mindmap"
);

export const MindMap = MindMapRoute(
  lazy(() => import(/* webpackChunkName: "MindMap" */ "./MindMap"))
);
