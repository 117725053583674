import { Button, Input } from "@CreativelySquared/uikit";
import { useFormik } from "formik";
import { DetailedHTMLProps, FormHTMLAttributes, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { advancedErrorMessage, parseError } from "utils/form";
import type { Object } from "ts-toolbelt";
import { object, string } from "yup";
import { CreatorUser, InternalUser, OrganizationUser } from "api/types";
import { Protect } from "components";
import { useProfileQuery } from "api/graphql";
import { Roles } from "utils/roles";

import styles from "./styles.module.scss";

const validationSchema = object({
  name: string()
    .required("common:validation.required")
    .max(254, advancedErrorMessage("common:validation.max")),
  surname: string()
    .required("common:validation.required")
    .max(254, advancedErrorMessage("common:validation.max")),
  email: string().when("userId", {
    is: Boolean,
    otherwise: (schema) =>
      schema
        .required("common:validation.required")
        .email("common:validation.email"),
  }),
});

type User = OrganizationUser | InternalUser | CreatorUser;

type Props = {
  onSubmit: (data: Object.Partial<User, "deep">) => void;
  user?: Object.Partial<User, "deep"> | null;
  onCancel?: () => void;
} & Omit<
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  "onSubmit"
>;

export const UserManagement = memo<Props>(({ user, onSubmit, onCancel }) => {
  const { t } = useTranslation("users");
  const [loading, setLoading] = useState(false);
  const { data: { me: profile } = {} } = useProfileQuery();

  const {
    handleSubmit,
    values: { email, name, surname },
    handleChange,
    errors,
    touched,
    handleBlur,
  } = useFormik<Object.Partial<User, "deep">>({
    async onSubmit(data) {
      try {
        setLoading(true);
        await onSubmit(data);
      } finally {
        setLoading(false);
      }
    },
    validationSchema,
    initialValues: {
      userId: user?.userId ?? "",
      email: user?.email ?? "",
      name: user?.name ?? "",
      surname: user?.surname ?? "",
    },
    enableReinitialize: true,
  });

  const isProfile = profile?.userId === user?.userId;

  return (
    <form noValidate className={styles.userManagement} onSubmit={handleSubmit}>
      <section>
        <label className={styles.userManagementLabel}>
          {t("management.form.fields.name.label")}
        </label>
        <Input
          name="name"
          placeholder={t(
            `management.form.fields.name.placeholder.${
              isProfile ? "profile" : "common"
            }`
          )}
          className="w-full mt-5"
          error={touched.name && t(...parseError(errors.name)).toString()}
          onChange={handleChange}
          onBlur={handleBlur}
          value={name!}
        />
      </section>
      <section>
        <label className={styles.userManagementLabel}>
          {t("management.form.fields.surname.label")}
        </label>
        <Input
          name="surname"
          placeholder={t(
            `management.form.fields.surname.placeholder.${
              isProfile ? "profile" : "common"
            }`
          )}
          className="w-full mt-5"
          error={touched.surname && t(...parseError(errors.surname)).toString()}
          onChange={handleChange}
          onBlur={handleBlur}
          value={surname!}
        />
      </section>
      <Protect access={({ id }) => id === user?.userId || !user?.userId}>
        <section>
          <label className={styles.userManagementLabel}>
            {t("management.form.fields.email.label")}
          </label>
          <Input
            disabled={!!user?.userId}
            name="email"
            type="email"
            placeholder={t(
              `management.form.fields.email.placeholder.${
                isProfile ? "profile" : "common"
              }`
            )}
            className="w-full mt-5"
            error={touched.email && t(...parseError(errors.email)).toString()}
            onChange={handleChange}
            onBlur={handleBlur}
            value={email!}
          />
        </section>
      </Protect>
      <Protect access={[Roles.account_manager, Roles.admin]}>
        <Protect access={({ id }) => id === user?.userId}>
          <section>
            <label className={styles.userManagementLabel}>
              {t("management.form.fields.role.label")}
            </label>
            <Input
              disabled
              type="text"
              placeholder={t("management.form.fields.role.label")}
              className="w-full mt-5"
              value={t(`common:user.roles.${user?.role}`).toString()}
            />
          </section>
        </Protect>
      </Protect>
      <Protect access={[Roles.organization_owner]}>
        <Protect access={({ id }) => id === user?.userId}>
          <section>
            <label className={styles.userManagementLabel}>
              {t("management.form.fields.workspace.label")}
            </label>
            <Input
              disabled
              type="text"
              placeholder={t("management.form.fields.workspace.label")}
              className="w-full mt-5"
              value={
                user?.__typename === "OrganizationUser"
                  ? user.organization?.name
                  : undefined
              }
            />
          </section>
        </Protect>
      </Protect>

      <section className="flex items-center justify-end mt-10">
        {onCancel && (
          <Button
            onClick={onCancel}
            className="mr-6"
            variant={Button.variants.Cancel}
            type="button"
          >
            {t("management.form.actions.cancel")}
          </Button>
        )}

        <Button loading={loading} disabled={loading} type="submit">
          {t(`management.form.actions.${user?.userId ? "edit" : "create"}`)}
        </Button>
      </section>
    </form>
  );
});
