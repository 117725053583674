import { FC, memo, ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "clsx";
import { ReactComponent as ArrowIcon } from "images/pagination-arrow.svg";

import styles from "./styles.module.scss";

type Props = {
  total?: number | null;
  page: number;
  renderPrev?: (
    prevPage: number,
    attributes: React.HTMLAttributes<HTMLElement>
  ) => ReactNode;
  renderNext?: (
    nextPage: number,
    attributes: React.HTMLAttributes<HTMLElement>
  ) => ReactNode;
  renderPage?: (
    nextPage: number,
    attributes: React.HTMLAttributes<HTMLElement>
  ) => ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const BOUNDARY_COUNT = 1;
const SIBLING_COUNT = 1;

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export const Pagination: FC<Props> = memo(
  ({ total = 0, page, className, renderPrev, renderPage, renderNext }) => {
    if (!total || total < 2) return null;

    const { search } = useLocation();

    const prevPage = page > 1 ? page - 1 : null;
    const nextPage = page < total ? page + 1 : null;

    const prev = useMemo(() => {
      const prev = page > 1 ? new URLSearchParams(search) : null;
      prev?.set("page", String(prevPage));
      return prev?.toString();
    }, [search, page, total]);

    const next = useMemo(() => {
      const next = page < total ? new URLSearchParams(search) : null;
      next?.set("page", String(nextPage));
      return next?.toString();
    }, [search, page, total]);

    const startPages = useMemo(
      () => range(1, Math.min(BOUNDARY_COUNT, total)),
      [total]
    );

    const endPages = useMemo(
      () =>
        range(Math.max(total - BOUNDARY_COUNT + 1, BOUNDARY_COUNT + 1), total),
      [total]
    );

    const siblingsStart = Math.max(
      Math.min(
        page - SIBLING_COUNT,
        total - BOUNDARY_COUNT - SIBLING_COUNT * 2 - 1
      ),
      BOUNDARY_COUNT + 2
    );

    const siblingsEnd = Math.min(
      Math.max(page + SIBLING_COUNT, BOUNDARY_COUNT + SIBLING_COUNT * 2 + 2),
      endPages.length > 0 ? endPages[0] - 2 : total - 1
    );

    const pages = [
      ...startPages,
      ...(siblingsStart > BOUNDARY_COUNT + 2
        ? [null]
        : BOUNDARY_COUNT + 1 < total - BOUNDARY_COUNT
        ? [BOUNDARY_COUNT + 1]
        : []),
      ...range(siblingsStart, siblingsEnd),
      ...(siblingsEnd < total - BOUNDARY_COUNT - 1
        ? [null]
        : total - BOUNDARY_COUNT > BOUNDARY_COUNT
        ? [total - BOUNDARY_COUNT]
        : []),
      ...endPages,
    ];

    return (
      <section className={cx(styles.pagination, className)}>
        <div className={styles.paginationLinksNavigation}>
          {prev &&
            (renderPrev && prevPage ? (
              renderPrev(prevPage, { className: styles.paginationLinksItem })
            ) : (
              <Link
                className={styles.paginationLinksItem}
                to={{ search: prev }}
              >
                <ArrowIcon />
              </Link>
            ))}
        </div>

        <div className={styles.paginationLinks}>
          {pages.map((item) => {
            if (renderPage && item !== null) {
              return renderPage(item, {
                className: cx(styles.paginationLinksItem, {
                  [styles.paginationLinksItemActive]: item === page,
                }),
              });
            }

            const params = item ? new URLSearchParams(search) : null;
            if (item) {
              params?.set("page", item.toString());
            }

            return item ? (
              <Link
                className={cx(styles.paginationLinksItem, {
                  [styles.paginationLinksItemActive]: item === page,
                })}
                to={{ search: params?.toString() }}
                key={item}
              >
                {item}
              </Link>
            ) : (
              <span className="px-5" key="dots">
                ...
              </span>
            );
          })}
        </div>
        <div className={styles.paginationLinksNavigation}>
          {next &&
            (renderNext && nextPage ? (
              renderNext(nextPage, { className: styles.paginationLinksItem })
            ) : (
              <Link
                className={styles.paginationLinksItem}
                to={{ search: next }}
              >
                <ArrowIcon style={{ transform: "rotate(180deg)" }} />
              </Link>
            ))}
        </div>
      </section>
    );
  }
);

Pagination.displayName = "Pagination";
