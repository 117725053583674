import { ProjectCard } from "components/ProjectCard";
import { Search } from "components/Search";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { galleryStatuses } from "utils/accesses";
import { AssetItemFragment, ProjectDetailsFragment } from "api/types";
import { ProjectsView } from "Projects/components/ProjectsView";
import { useProjectsSettings } from "Projects/hooks/useProjectsSettings";

import { MediaAssets } from "../MediaAssets";

type Props = {
  organizationId?: string | null;
  onAdd: (assets: AssetItemFragment[]) => void;
  multiple?: boolean;
};

export const MediaProjects: FC<Props> = ({
  organizationId,
  onAdd,
  multiple,
}) => {
  const { t } = useTranslation("common");
  const [activeProject, setActiveProject] =
    useState<ProjectDetailsFragment | null>(null);
  const projectsSetting = useProjectsSettings();

  if (activeProject) {
    return (
      <MediaAssets
        organizationId={organizationId}
        onAdd={onAdd}
        multiple={multiple}
        project={activeProject}
        onClose={() => setActiveProject(null)}
      />
    );
  }

  return (
    <>
      <Search
        className="mb-7"
        placeholder={t("addMedia.search")}
        onSearch={projectsSetting.onSearch}
        value={projectsSetting.search}
      />
      <ProjectsView
        onPageChange={projectsSetting.onPageChange}
        page={projectsSetting.page}
        organizationId={organizationId}
        containerClassName="grid grid-cols-4 gap-7 w-full mb-8 !mt-0"
        paginationClassName="justify-center mt-auto mb-3"
        filters={{
          title: projectsSetting.search?.trim() || undefined,
          status: galleryStatuses,
          isDeleted: false,
        }}
        renderCard={({ project }) =>
          !!project && (
            <ProjectCard
              project={project}
              key={project.projectId}
              onClick={() => setActiveProject(project)}
            />
          )
        }
      />
    </>
  );
};
