import { useGetCollectionsQuery } from "api/graphql";
import { Pagination } from "components/Pagination";
import { Search } from "components/Search";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePagination } from "utils/hooks/pagination";
import { ReactComponent as PaginationArrowIcon } from "images/pagination-arrow.svg";
import { AssetItemFragment, CollectionItemNodeFragment } from "api/types";
import { CollectionCard } from "components/CollectionCard";
import { Loader } from "@CreativelySquared/uikit";

import { MediaAssets } from "../MediaAssets";

type Props = {
  onAdd: (assets: AssetItemFragment[]) => void;
  organizationId?: string | null;
  multiple?: boolean;
};

export const MediaCollections: FC<Props> = ({
  onAdd,
  organizationId,
  multiple,
}) => {
  const { t } = useTranslation("common");
  const [activeCollection, setActiveCollection] =
    useState<CollectionItemNodeFragment | null>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const pagination = usePagination({
    page,
  });

  const { data: { collections } = {}, loading: collectionsLoading } =
    useGetCollectionsQuery({
      variables: {
        filters: {
          search: search?.trim() || undefined,
        },
        pagination,
        organizationId,
      },
      fetchPolicy: "cache-and-network",
    });

  const loading = collectionsLoading && !collections?.nodes?.length;
  const isEmpty = !collections?.pageInfo?.totalItems && !loading;

  if (activeCollection) {
    return (
      <MediaAssets
        organizationId={organizationId}
        onAdd={onAdd}
        multiple={multiple}
        collection={activeCollection}
        onClose={() => setActiveCollection(null)}
      />
    );
  }

  return (
    <>
      <Search
        className="mb-7"
        placeholder={t("addMedia.search")}
        onSearch={(value) => {
          setPage(1);
          setSearch(value);
        }}
        value={search}
      />
      {isEmpty && (
        <p className="text-l mt-7 text-center text-blue-steel">
          {t(`addMedia.empty.collections`)}
        </p>
      )}
      {loading && <Loader className="mt-11 mx-auto" radius={50} />}
      <div className="grid grid-cols-4 gap-7 w-full mb-8">
        {collections?.nodes?.map(
          (collection) =>
            !!collection?.collectionId && (
              <CollectionCard
                data={collection}
                key={collection.collectionId}
                onClick={() => setActiveCollection(collection)}
              />
            )
        )}
      </div>
      {!loading && (
        <Pagination
          className="justify-center mt-auto mb-3"
          page={page}
          renderPrev={(prevPage, props) => (
            <button {...props} onClick={() => setPage(prevPage)}>
              <PaginationArrowIcon />
            </button>
          )}
          renderNext={(nextPage, props) => (
            <button {...props} onClick={() => setPage(nextPage)}>
              <PaginationArrowIcon style={{ transform: "rotate(180deg)" }} />
            </button>
          )}
          renderPage={(item, props) => (
            <button {...props} onClick={() => setPage(item)}>
              {item}
            </button>
          )}
          total={collections?.pageInfo?.totalPages}
        />
      )}
    </>
  );
};
