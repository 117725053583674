import "./locales";
import { lazy } from "react";
import { compose, Route } from "react-router-hoc";
import { SortOrder } from "utils/order";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Switch } from "react-router";
import { idRegEx } from "utils/regex";
import { SubscriptionPlanStatus, SubscriptionPlanType } from "api/enums";

import { CustomerManagement } from "./Management";
import { CustomerProjects } from "./Projects";
import { CustomerUsers } from "./Users";
import { CustomerUserManagement } from "./Users/Management";
import { CustomerPlans } from "./Plans";
import { CustomerSettings } from "./Settings";
import { CustomerActivity } from "./Activity";
import { CustomerNotes } from "./Notes";
import { CustomerBrands } from "./Brands";
import {
  CustomerCollectionDetails,
  CustomerCollections,
  CustomerCollectionDetailsAsset,
} from "./CustomerCollections";
import { CustomerSavedItems } from "./SavedItems";

export const CustomersRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.query.string,
      search: Route.query.string,
      page: Route.query.number,
      sort: Route.query.oneOf("name", "credits", "projects"),
      sortOrder: Route.query.oneOf(...Object.values(SortOrder)),
      statuses: Route.query.array(
        Route.query.oneOf(...Object.values(SubscriptionPlanStatus))
      ),
      accountTypes: Route.query.array(
        Route.query.oneOf(...Object.values(SubscriptionPlanType))
      ),
      managers: Route.query.array(Route.query.string),
    },
    "/customers"
  )
);

const Customers = CustomersRoute(
  lazy(() => import(/* webpackChunkName: "Customers" */ "./Customers"))
);

export const CustomerDetailsRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
    },
    ({ id }) => `/customers/${id}`
  )
);

export const CustomerDetails = CustomerDetailsRoute(
  lazy(
    () => import(/* webpackChunkName: "CustomerDetails" */ "./CustomerDetails")
  )
);

export const links = {
  CustomerManagement,
  CustomerDetails,
  Customers,
  CustomerPlans,
  CustomerBrands,
  CustomerProjects,
  CustomerUsers,
  CustomerUserManagement,
  CustomerSettings,
  CustomerActivity,
  CustomerNotes,
  CustomerCollections,
  CustomerCollectionDetails,
  CustomerCollectionDetailsAsset,
  CustomerSavedItems,
};

export const route = Route(
  Object.values(links).map((route) => route.defaultProps?.path as string)
)(() => {
  return (
    <Switch>
      <Customers exact />
      <CustomerManagement />
      <CustomerDetails />
    </Switch>
  );
});
