import {
  useCustomerQuery,
  useOrganizationCreditsHistoryQuery,
  useSetSubscriptionPlanMutation,
} from "api/graphql";
import { compose, Route } from "react-router-hoc";
import { idRegEx } from "utils/regex";
import { Roles } from "utils/roles";
import { ProtectedRoute } from "utils/route";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Bar, Button, Loader } from "@CreativelySquared/uikit";
import {
  OrganizationCreditsEvent,
  SubscriptionPlanName,
  SubscriptionPlanStatus,
  SubscriptionPlanType,
} from "api/enums";
import { useFormik } from "formik";
import { add, startOfDay } from "date-fns/fp";
import { flow } from "lodash";
import { usePagination } from "utils/hooks/pagination";
import { Pagination, useNotification } from "components";
import { SubscriptionErrorCodes } from "utils/subscriptions";

import { CreditDetails } from "./components/CreditDetails";
import { CreditBalance } from "./components/CreditBalance";

import styles from "./styles.module.scss";

export const CustomerPlansRoute = compose(
  ProtectedRoute({ access: [Roles.admin, Roles.account_manager] }),
  Route(
    {
      id: Route.params.regex(idRegEx),
      page: Route.query.number,
    },
    ({ id }) => `/customers/${id}/plans`
  )
);

type FormData = {
  plan?: SubscriptionPlanType | null;
  value: {
    credits?: number | null;
    reason?: OrganizationCreditsEvent | null;
    expirationDate: Date;
    name?: SubscriptionPlanName;
    status: SubscriptionPlanStatus;
  };
};

const inAYear = flow(add({ years: 1 }), startOfDay);

export const CustomerPlans = CustomerPlansRoute(
  ({
    match: {
      params: { id },
      query: { page = 1 },
    },
  }) => {
    const { t } = useTranslation("customers");
    const pagination = usePagination({ page });
    const { setNotification, notificationTypes } = useNotification();

    const {
      data: { getOrganization: customer } = {},
      loading: customerLoading,
      refetch: customerRefetch,
    } = useCustomerQuery({
      variables: { id },
    });
    const {
      data: { getOrganizationCreditsHistory } = {},
      loading: creditsHistoryLoading,
      refetch: creditsHistoryRefetch,
    } = useOrganizationCreditsHistoryQuery({
      variables: { organizationId: id, pagination },
    });

    const [setPlan, { loading: setPlanLoading }] =
      useSetSubscriptionPlanMutation({
        onCompleted() {
          customerRefetch();
          creditsHistoryRefetch();
        },
        onError(error) {
          if (
            error.graphQLErrors?.[0]?.extensions?.code ===
            SubscriptionErrorCodes.SubscriptionPlanExpirationDateError
          ) {
            setNotification({
              message: t("credits.balance.errors.expirationDate"),
              type: notificationTypes.Error,
            });
          } else {
            setNotification({
              message: error.message ?? t("common:common.error"),
              type: notificationTypes.Error,
            });
          }
        },
      });
    const expirationDate =
      ((customer?.subscriptionPlan?.expirationDate &&
        new Date(+customer?.subscriptionPlan?.expirationDate)) as Date) ??
      inAYear(new Date());

    const {
      values: { plan },
      handleChange,
      setFieldValue,
      handleSubmit,
    } = useFormik<FormData>({
      onSubmit(data) {
        const credits =
          data.plan === SubscriptionPlanType.Custom
            ? {
                custom: {
                  creditsLimit: data.value.credits ?? 0,
                },
                credits: {
                  credits: data.value.credits ?? 0,
                  previousValue: customer?.credits ?? 0,
                  type: OrganizationCreditsEvent.ResetBalance,
                },
              }
            : {
                credits: data.value.reason
                  ? {
                      credits: data.value.credits ?? 0,
                      type: data.value.reason,
                      previousValue: customer?.credits ?? 0,
                    }
                  : undefined,
              };

        return setPlan({
          variables: {
            organizationId: id!,
            type: data.plan!,
            expirationDate: data.value.expirationDate.toISOString(),
            name: data.value.name,
            status: data.value?.status,
            ...credits,
          },
        });
      },
      initialValues: {
        plan: customer?.subscriptionPlan?.type as SubscriptionPlanType,
        value: {
          credits:
            customer?.subscriptionPlan?.type === SubscriptionPlanType.Credits
              ? customer.credits
              : customer?.subscriptionPlan?.creditsLimit,
          expirationDate,
          name:
            (customer?.subscriptionPlan?.name as SubscriptionPlanName) ??
            SubscriptionPlanName.Custom,
          status:
            (customer?.subscriptionPlan?.status as SubscriptionPlanStatus) ??
            SubscriptionPlanStatus.Pending,
        },
      },
    });
    const loading =
      customerLoading ||
      (creditsHistoryLoading && !getOrganizationCreditsHistory?.nodes?.length);
    const status =
      customer?.subscriptionPlan?.type !== plan
        ? SubscriptionPlanStatus.Active
        : (customer?.subscriptionPlan?.status as SubscriptionPlanStatus) ??
          SubscriptionPlanStatus.Pending;

    return (
      <form onSubmit={handleSubmit}>
        <Helmet title={t("credits.title")} />
        <section className={styles.credits}>
          <section className={styles.creditsPlans}>
            <section className={styles.creditsCard}>
              <Bar value={plan} onChange={handleChange("plan")}>
                {Object.values(SubscriptionPlanType).map((plan) => (
                  <Bar.Option value={plan} key={plan}>
                    {t(`credits.plans.${plan}`)}
                  </Bar.Option>
                ))}
              </Bar>
              <CreditBalance
                key={plan}
                onChange={
                  plan !== customer?.subscriptionPlan?.type
                    ? (data) => setFieldValue("value", data)
                    : undefined
                }
                plan={plan}
                organizationId={id}
                value={{
                  balance: customer?.credits,
                  expirationDate,
                  status,
                  name:
                    (customer?.subscriptionPlan
                      ?.name as SubscriptionPlanName) ??
                    SubscriptionPlanName.Custom,
                  credits:
                    plan === SubscriptionPlanType.Credits
                      ? customer?.credits ?? 0
                      : customer?.subscriptionPlan?.creditsLimit ?? null,
                }}
              />
            </section>

            {plan !== customer?.subscriptionPlan?.type && (
              <footer className="flex justify-end items-center mt-10">
                <Button
                  onClick={() =>
                    setFieldValue("plan", customer?.subscriptionPlan?.type)
                  }
                  variant={Button.variants.Cancel}
                  className="mr-5"
                >
                  {t("credits.plans.actions.cancel")}
                </Button>
                <Button loading={setPlanLoading} type="submit">
                  {t("credits.plans.actions.apply")}
                </Button>
              </footer>
            )}
          </section>

          <div className={styles.creditsHistory}>
            <h2>{t("credits.history.title")}</h2>
            {loading && <Loader radius={50} className="mx-auto mt-10" />}
            {getOrganizationCreditsHistory?.nodes?.length === 0 && (
              <p>{t("credits.history.empty")}</p>
            )}
            {!!getOrganizationCreditsHistory?.nodes?.length && (
              <ul className="mt-8">
                {getOrganizationCreditsHistory?.nodes?.map(
                  (credit) =>
                    credit?.details && (
                      <CreditDetails
                        key={credit.details?.timestamp}
                        credit={credit.details}
                      />
                    )
                )}
              </ul>
            )}

            {!!getOrganizationCreditsHistory?.nodes?.length && !loading && (
              <Pagination
                className="mt-11"
                page={page}
                total={getOrganizationCreditsHistory?.pageInfo?.totalPages}
              />
            )}
          </div>
        </section>
      </form>
    );
  }
);
