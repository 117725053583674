import { Button, Input } from "@CreativelySquared/uikit";
import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useFinalizeProjectMutation } from "api/graphql";
import { useNotification } from "components";
import { object, string } from "yup";
import { useFormik } from "formik";

import { ProjectErrorCodes } from "../../utils/errors";

type FormData = { confirm?: string; force?: boolean };

type Props = {
  projectId: string;
  onClose: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export const FinaliseProject = memo<Props>(
  ({ projectId, onClose, className, ...props }) => {
    const { t } = useTranslation("projects");
    const { setNotification, notificationTypes } = useNotification();

    const validationSchema = object({
      confirm: string().when("force", {
        is: true,
        then: (schema) =>
          schema
            .required("common:validation.required")
            .lowercase()
            .oneOf([t("finalise.validationWord")]),
      }),
    });
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      setFieldValue,
      values: { confirm, force },
    } = useFormik<FormData>({
      async onSubmit({ force }) {
        await finalizeProject({
          variables: {
            projectId,
            force,
          },
        });
      },
      validationSchema,
      initialValues: {
        confirm: "",
        force: false,
      },
    });
    const [finalizeProject, { loading }] = useFinalizeProjectMutation({
      onCompleted: onClose,
      onError: (error) => {
        if (
          error.graphQLErrors?.[0].extensions.code ===
          ProjectErrorCodes.ProjectHasAssetsNeedsChangesError
        ) {
          return setFieldValue("force", true);
        }
        setNotification({
          message: error.message ?? t("common:common.error"),
          type: notificationTypes.Error,
        });
      },
    });

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx("max-w-[465px]", className)}
        {...props}
      >
        <h3>{t("finalise.title")}</h3>
        <h4 className="text-base text-blackberry">
          {t("finalise.explanation", { context: force && "force" })}
        </h4>

        {force && (
          <Input
            name="confirm"
            variant={Input.variants.Secondary}
            value={confirm?.toUpperCase()}
            className="w-full mt-7 mb-9"
            placeholder={t("finalise.placeholder")}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}

        <section className="flex justify-end mt-8">
          <Button
            variant={Button.variants.Cancel}
            type="button"
            className="mr-5"
            onClick={onClose}
            outlined
          >
            {t("finalise.actions.cancel")}
          </Button>
          <Button type="submit" loading={loading} disabled={!isValid}>
            {t("finalise.actions.submit", { context: force && "force" })}
          </Button>
        </section>
      </form>
    );
  }
);
