import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-hoc";
import { useCustomerSubscriptionPlanQuery, useProfileQuery } from "api/graphql";
import { Button, Label, Loader } from "@CreativelySquared/uikit";
import { isNumber } from "lodash";
import { subscriptionStatuses } from "Customers/Plans/components/CreditBalance";
import clsx from "clsx";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { SubscriptionPlanName, SubscriptionPlanType } from "api/enums";
import { getFullName } from "utils/users";

import styles from "./styles.module.scss";

export const SettingsAccountRoute = Route("/settings/account");

export const SettingsAccount = SettingsAccountRoute(() => {
  const { t } = useTranslation("settings");
  const { data: { me } = {}, loading: profileLoading } = useProfileQuery();
  const organizationId =
    me?.__typename === "OrganizationUser" ? me.organizationId ?? "" : "";
  const stripeURL = `https://billing.stripe.com/p/login/cN24hrgQtcCA5Jm6oo?prefilled_email=${encodeURIComponent(
    me?.email ?? ""
  )}`;

  const {
    data: { getOrganizationSubscriptionPlan: subscriptionPlan } = {},
    loading: subscriptionPlanLoading,
  } = useCustomerSubscriptionPlanQuery({
    variables: {
      organizationId,
    },
    skip: !organizationId,
  });

  const {
    name: planName,
    organization,
    status,
    type,
    usage,
    expirationDate,
  } = subscriptionPlan ?? {};
  const {
    name: organizationName,
    credits,
    managedBy,
    subscriptionPlan: organizationPlan,
  } = organization ?? {};

  const isPAYG = type === SubscriptionPlanType.Credits;

  if (profileLoading || subscriptionPlanLoading) {
    return <Loader radius={50} className="m-auto" />;
  }

  return (
    <section className={styles.settingsAccount}>
      <Helmet>
        <title>{t("account.title")}</title>
      </Helmet>
      <section>
        <h3 className={styles.title}>{t("account.title")}</h3>
        {!!organizationName && (
          <div className={styles.field}>
            <p>{t("account.organization.title")}</p>
            <p>{organizationName}</p>
          </div>
        )}
        {!!managedBy?.name && (
          <div className={styles.field}>
            <p>{t("account.organization.manager")}</p>
            <p className="text-teal">{getFullName(managedBy)}</p>
          </div>
        )}
      </section>
      <section className={styles.billing}>
        <div>
          <h3 className={styles.title}>{t("account.billing.title")}</h3>
          {(!!planName || !!status) && (
            <div className={styles.field}>
              <p>{t("account.billing.plan")}</p>
              <p className="flex items-center">
                {t(
                  `common:customers.plans.${
                    isPAYG ? "PAYG" : planName ?? SubscriptionPlanName.Custom
                  }`
                )}
                <Label
                  className={clsx(
                    subscriptionStatuses.find(
                      (subscriptionStatus) =>
                        subscriptionStatus.value === status
                    )?.className,
                    "text-white ml-4"
                  )}
                  size={Label.sizes.Tiny}
                >
                  {t(`common:customers.status.${status}`)}
                </Label>
              </p>
            </div>
          )}
          {isNumber(credits) && (
            <div className={styles.field}>
              <p>{t("account.billing.credits")}</p>
              <p>{isPAYG ? credits : organizationPlan?.creditsLimit}</p>
            </div>
          )}
          {!!expirationDate && (
            <div className={clsx(styles.field, "mb-0")}>
              <p>{t(`account.billing.${isPAYG ? "expiration" : "renewal"}`)}</p>
              <p>{format(new Date(+expirationDate), "dd MMMM yyyy")}</p>
            </div>
          )}
        </div>
        {!isPAYG && (
          <section className="flex">
            <Link to={{ pathname: stripeURL }} target="_blank">
              <Button
                variant={Button.variants.Secondary}
                type="button"
                className="mr-6"
              >
                {t("account.billing.actions.cancel")}
              </Button>
            </Link>
            <Link to={{ pathname: stripeURL }} target="_blank">
              <Button type="button">
                {t("account.billing.actions.manage")}
              </Button>
            </Link>
          </section>
        )}
      </section>
      <section>
        <h3 className={clsx(styles.title, "mb-0")}>
          {t("account.usage.title")}
        </h3>
        <p className="text-sm text-mid-blue-steel mb-8">
          {t("account.usage.description")}
        </p>
        {isNumber(usage?.credits) && (
          <div className={styles.field}>
            <p>{t("account.usage.credits")}</p>
            <p>{usage?.credits}</p>
          </div>
        )}
        {isNumber(usage?.assets) && (
          <div className={styles.field}>
            <p>{t("account.usage.assets")}</p>
            <p>{usage?.assets}</p>
          </div>
        )}
        {isNumber(usage?.projects) && (
          <div className={styles.field}>
            <p>{t("account.usage.projects")}</p>
            <p>{usage?.projects}</p>
          </div>
        )}
      </section>
    </section>
  );
});
