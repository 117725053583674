import { Asset, CollectionItemNodeFragment } from "api/types";
import { FC, useCallback, useEffect, useState } from "react";

import { SuccessMessage } from "./SuccessMessage";
import { CreateCollection } from "./CreateCollection";
import { Collections } from "./Collections";

interface Props {
  visible: boolean;
  onClose: () => void;
  assetIds: Array<Asset["assetId"]>;
  onFinish: () => void;
}

export const AddToCollection: FC<Props> = ({
  visible,
  assetIds,
  onClose,
  onFinish,
}) => {
  const [isCollectionsVisible, setCollectionsVisible] = useState(visible);
  const [isCreateCollectionVisible, setIsCreateCollectionVisible] =
    useState(false);
  const [updatedCollections, setUpdatedCollections] = useState<
    Array<CollectionItemNodeFragment>
  >([]);
  const [selectedCollections, setSelectedCollections] = useState<
    Array<CollectionItemNodeFragment>
  >([]);

  const handleFinish = useCallback(() => {
    setCollectionsVisible(false);
    setIsCreateCollectionVisible(false);
    setUpdatedCollections([]);
    onFinish();
  }, []);

  useEffect(() => {
    setCollectionsVisible(visible);
  }, [visible]);

  return (
    <>
      <Collections
        visible={isCollectionsVisible}
        onClose={onClose}
        onSubmit={(collections, isNewCollection) => {
          if (isNewCollection) {
            setIsCreateCollectionVisible(true);
            setSelectedCollections(collections);
          } else {
            setUpdatedCollections(collections);
          }

          setCollectionsVisible(false);
        }}
        assetsIds={assetIds}
      />
      <CreateCollection
        assetsIds={assetIds}
        visible={isCreateCollectionVisible}
        onClose={() => {
          setIsCreateCollectionVisible(false);
          if (selectedCollections.length) {
            setUpdatedCollections(selectedCollections);
          } else {
            onClose();
          }
        }}
        onSubmit={(newCollection) => {
          setIsCreateCollectionVisible(false);
          setUpdatedCollections([newCollection, ...selectedCollections]);
        }}
      />
      <SuccessMessage collections={updatedCollections} onClose={handleFinish} />
    </>
  );
};
