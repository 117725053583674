import {
  AssetVotedEvent,
  GenericAssetCommentEvent,
  GenericAssetEvent,
  GenericBrandProfileEvent,
  GenericEvent,
  GenericOrganizationEvent,
  GenericOrganizationUserEvent,
  GenericProjectEvent,
  GenericProjectFeedbackEvent,
  GenericSubscriptionPlanEvent,
  GenericUserEvent,
  ProjectGalleryDownloadedEvent,
} from "api/types";

export enum EventType {
  brand_profile_edited = "brand_profile_edited",
  brand_profile_created = "brand_profile_created",
  project_partial_delivery = "project_partial_delivery",
  org_user_deleted = "org_user_deleted",
  new_asset_version_created = "new_asset_version_created",
  project_regressed_to_draft = "project_regressed_to_draft",
  project_canceled = "project_canceled",
  project_moved_in_queue = "project_moved_in_queue",
  project_approved = "project_approved",
  project_submitted = "project_submitted",
  project_moved_to_under_revision = "project_moved_to_under_revision",
  project_finalized = "project_finalized",
  project_completed = "project_completed",
  project_moved_to_queue = "project_moved_to_queue",
  project_deleted = "project_deleted",
  org_credits_under_threshold = "org_credits_under_threshold",
  organization_account_manager_assigned = "organization_account_manager_assigned",
  project_account_manager_assigned = "project_account_manager_assigned",
  project_gallery_downloaded = "project_gallery_downloaded",
  asset_downloaded = "asset_downloaded",
  asset_rating_edited = "asset_rating_edited",
  asset_comment_created = "asset_comment_created",
  brief_comment_created = "brief_comment_created",
  project_feedback_created = "project_feedback_created",
  project_feedback_edited = "project_feedback_edited",
  subscription_plan_expired = "subscription_plan_expired",
  credits_expire_alert = "credits_expire_alert",
  subscription_plan_renewed = "subscription_plan_renewed",
  custom_subscription_expire_alert = "custom_subscription_expire_alert",
  auto_renewal_alert = "auto_renewal_alert",
  subscription_plan_edited = "subscription_plan_edited",
  asset_needs_changes = "asset_needs_changes",
  brief_updated = "brief_updated",
  bulk_comments_created = "bulk_comments_created",
  project_clarification_requested = "project_clarification_requested",
  project_created = "project_created",
}

export type EventDetails = GenericAssetCommentEvent &
  GenericBrandProfileEvent &
  ProjectGalleryDownloadedEvent &
  GenericAssetEvent &
  GenericEvent &
  GenericOrganizationEvent &
  GenericOrganizationUserEvent &
  GenericProjectEvent &
  GenericProjectFeedbackEvent &
  GenericUserEvent &
  AssetVotedEvent &
  GenericSubscriptionPlanEvent;

export const adminEvents = [
  EventType.asset_comment_created,
  EventType.project_deleted,
  EventType.project_submitted,
  EventType.brief_comment_created,
  EventType.project_feedback_created,
  EventType.project_feedback_edited,
  EventType.asset_downloaded,
  EventType.asset_rating_edited,
  EventType.organization_account_manager_assigned,
  EventType.project_account_manager_assigned,
  EventType.project_gallery_downloaded,
  EventType.brand_profile_edited,
  EventType.brand_profile_created,
  EventType.org_user_deleted,
  EventType.project_finalized,
  EventType.project_moved_in_queue,
  EventType.asset_needs_changes,
  EventType.bulk_comments_created,
  EventType.project_created,
];

export const customerEvents = [
  EventType.asset_comment_created,
  EventType.brief_comment_created,
  EventType.organization_account_manager_assigned,
  EventType.new_asset_version_created,
  EventType.project_account_manager_assigned,
  EventType.org_credits_under_threshold,
  EventType.brand_profile_edited,
  EventType.project_completed,
  EventType.project_approved,
  EventType.project_canceled,
  EventType.project_regressed_to_draft,
  EventType.project_partial_delivery,
  EventType.project_moved_to_queue,
  EventType.project_moved_to_under_revision,
  EventType.project_finalized,
  EventType.project_moved_in_queue,
  EventType.subscription_plan_expired,
  EventType.credits_expire_alert,
  EventType.subscription_plan_renewed,
  EventType.custom_subscription_expire_alert,
  EventType.auto_renewal_alert,
  EventType.subscription_plan_edited,
  EventType.brief_updated,
  EventType.bulk_comments_created,
  EventType.project_clarification_requested,
];

export const filterOptions = {
  account_notifications: [
    EventType.subscription_plan_renewed,
    EventType.custom_subscription_expire_alert,
    EventType.auto_renewal_alert,
    EventType.subscription_plan_edited,
  ],
  asset_comments: [
    EventType.asset_comment_created,
    EventType.bulk_comments_created,
  ],
  asset_downloaded: [EventType.asset_downloaded],
  asset_versions: [EventType.new_asset_version_created],
  asset_ratings: [EventType.asset_rating_edited],
  brand_notifications: [
    EventType.brand_profile_edited,
    EventType.brand_profile_created,
  ],
  brief_comments: [EventType.brief_comment_created],
  credit_notifications: [
    EventType.org_credits_under_threshold,
    EventType.credits_expire_alert,
    EventType.subscription_plan_expired,
  ],
  downloads: [EventType.project_gallery_downloaded],
  organization_notifications: [
    EventType.organization_account_manager_assigned,
    EventType.org_user_deleted,
  ],
  project_feedback: [
    EventType.project_feedback_created,
    EventType.project_feedback_edited,
  ],
  project_notifications: [
    EventType.project_account_manager_assigned,
    EventType.project_finalized,
    EventType.project_moved_in_queue,
    EventType.project_deleted,
    EventType.project_submitted,
    EventType.asset_needs_changes,
    EventType.project_created,
    EventType.project_completed,
    EventType.project_approved,
    EventType.project_canceled,
    EventType.project_regressed_to_draft,
    EventType.project_partial_delivery,
    EventType.project_moved_to_queue,
    EventType.project_moved_to_under_revision,
    EventType.brief_updated,
    EventType.project_clarification_requested,
  ],
};
