import { useProfileQuery } from "api/graphql";
import { Link, NavLink } from "react-router-dom";
import { FC, memo, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Access, Roles } from "utils/roles";
import { Button, Label } from "@CreativelySquared/uikit";
import pick from "lodash/pick";
import { Protect } from "components/Protect/Protect";
import logo from "images/logo.svg";
import { links } from "App";
import clsx from "clsx";
import { createInjection } from "components/Injector";
import { HelpCenter } from "components/HelpCenter";
import { SubscriptionPlanType } from "api/enums";
import { useProtect } from "utils/hooks/protection";
import { Features } from "api/features";
import { ReactComponent as ArrowIcon } from "images/arrow.svg";

import { Profile } from "../Profile";

import { ReactComponent as SettingsIcon } from "./images/settings.svg";
import * as icons from "./images";

import styles from "./styles.module.scss";

const accesses: {
  [P in keyof typeof links]?: { access: Access; feature?: Features };
} = {
  Activity: {
    access: [Roles.admin, Roles.account_manager, Roles.organization_owner],
  },
  Customers: { access: [Roles.admin, Roles.account_manager] },
  Dashboard: { access: [Roles.admin, Roles.account_manager] },
  CustomerDashboard: { access: [Roles.organization_owner] },
  DAM: {
    access: [Roles.admin, Roles.account_manager, Roles.organization_owner],
    feature: Features.dam_page,
  },
  Projects: {
    access: [Roles.admin, Roles.account_manager, Roles.organization_owner],
  },
  Brands: {
    access: [Roles.admin, Roles.account_manager, Roles.organization_owner],
  },
  Users: { access: [Roles.admin, Roles.account_manager] },
  AITools: {
    access: [Roles.admin, Roles.account_manager, Roles.organization_owner],
  },
};

const SidebarInjection = createInjection("sidebar");

const SidebarBackButton: FC<{ to?: string }> = ({ to = links.Projects() }) => {
  const { t } = useTranslation("common");

  return (
    <Link
      className="self-start mb-8 block -ml-[20px] sticky top-0 bg-white"
      to={to}
    >
      <Button outlined borderless>
        <ArrowIcon className="mr-5" /> {t("navigation.back")}
      </Button>
    </Link>
  );
};

export const Sidebar = Object.assign(
  memo(() => {
    const { data } = useProfileQuery({ fetchPolicy: "cache-only" });
    const isProtected = useProtect();
    const isAdmin = isProtected([Roles.admin, Roles.account_manager]);

    const { t } = useTranslation();

    const routes = useMemo(
      () =>
        pick(links, [
          "Dashboard",
          "CustomerDashboard",
          "Customers",
          "Projects",
          "Brands",
          "DAM",
          "AITools",
          "Activity",
          "Users",
        ]),
      []
    );

    const navLinks = useMemo(
      () =>
        Object.entries(routes).map(([title, link]) => ({
          title: t(`navigation.${title.toLowerCase()}`),
          link,
          Icon:
            title === "CustomerDashboard"
              ? icons["dashboard"]
              : icons[title.toLowerCase() as keyof typeof icons],
          access: accesses[title as keyof typeof links],
          key: title,
        })),
      [t]
    );

    const subscriptionPlan = useMemo(() => {
      if (data?.me?.__typename !== "OrganizationUser") return;

      if (
        data?.me.organization?.subscriptionPlan?.type ===
          SubscriptionPlanType.Custom &&
        data?.me.organization.subscriptionPlan.creditsLimit === null
      ) {
        return "unlimited";
      }
      return data?.me.organization?.subscriptionPlan?.type?.toLowerCase();
    }, [data?.me]);

    return (
      <div className={styles.sidebar}>
        <Link to="/">
          <img alt={t("common.title")} className={styles.logo} src={logo} />
        </Link>
        <SidebarInjection.Area className={styles.sidebarInjected} />
        <nav className={styles.listWrapper}>
          <ul className={styles.list}>
            <Suspense
              fallback={
                <li className={styles.listItem}>
                  <div className={styles.sidebarLoading}>
                    <div className={styles.sidebarLoadingIcon} />
                    <div className={clsx(styles.sidebarLoadingText, "ml-7")} />
                  </div>
                </li>
              }
            >
              {navLinks.map(
                ({
                  title,
                  key,
                  link,
                  Icon,
                  access: { access, feature } = {},
                }) => (
                  <Protect access={access} feature={feature} key={key} suspense>
                    <li className={styles.listItem}>
                      <NavLink
                        activeClassName={styles.listLinkActive}
                        className={styles.listLink}
                        tabIndex={1}
                        to={link()}
                      >
                        <Icon className={styles.icon} />
                        <span className="ml-6">{title}</span>
                      </NavLink>
                    </li>
                  </Protect>
                )
              )}
            </Suspense>
          </ul>
        </nav>
        <Protect access={[Roles.organization_owner]}>
          <HelpCenter className={styles.additionalItem} />
        </Protect>

        <div className={styles.additionalItem}>
          <Link
            to={isAdmin ? links.SettingsProfile() : links.SettingsAccount()}
            className="flex items-center"
          >
            <SettingsIcon className="mr-5" />
            {t("navigation.settings")}
          </Link>
        </div>
        {data?.me && (
          <Profile
            className="border-t-[1px] border-light-blue-steel mt-[55px]"
            user={data.me}
          >
            <Protect access={[Roles.account_manager, Roles.admin]}>
              <span className="font-xs text-blue-steel">
                {t(`user.roles.${data.me.role}`)}
              </span>
            </Protect>
            <Protect access={[Roles.organization_owner]}>
              {data.me.__typename === "OrganizationUser" && (
                <Label
                  className="text-blue-steel mt-3"
                  variant={Label.variants.Neutral}
                  size={Label.sizes.Small}
                >
                  {t("navigation.profile.credits", {
                    context: subscriptionPlan,

                    credits: data.me.organization?.credits,
                    count:
                      (data.me.organization?.subscriptionPlan?.type ===
                      SubscriptionPlanType.Credits
                        ? data.me.organization?.credits
                        : data.me.organization?.subscriptionPlan
                            ?.creditsLimit) ?? 0,
                  })}
                </Label>
              )}
            </Protect>
          </Profile>
        )}
      </div>
    );
  }),
  {
    display: "Sidebar",
    Inject: SidebarInjection.Inject,
    BackButton: SidebarBackButton,
  }
);
