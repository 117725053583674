import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { Alert } from "@CreativelySquared/uikit";

import styles from "./styles.module.scss";

const DEFAULT_DELAY = 5000;

export type NotificationAction = {
  message: string;
  type?: typeof Alert.variants[keyof typeof Alert.variants];
  options?: {
    delay?: number;
  };
};

export const NotificationsContext = createContext<{
  notification?: NotificationAction;
  setNotification: (notification?: NotificationAction) => void;
}>({
  setNotification() {},
});

export const useNotification = () => {
  const { setNotification, notification } = useContext(NotificationsContext);
  return {
    notificationTypes: Alert.variants,
    notification,
    setNotification: ({ message, options, type }: NotificationAction) => {
      setNotification(
        message
          ? {
              message,
              options,
              type,
            }
          : undefined
      );
    },
  };
};

export const Notifications: FC = () => {
  const { notification } = useContext(NotificationsContext);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (notification?.message) {
      setVisible(true);
    }
  }, [notification]);

  useEffect(() => {
    if (visible) {
      // If delay is 0 don't hide notification
      if (notification?.options?.delay === 0) return;
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => setVisible(false), DEFAULT_DELAY);
    }
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [notification?.options?.delay, visible]);

  return (
    <CSSTransition
      in={visible && !!notification?.message}
      unmountOnExit
      timeout={200}
      classNames={{
        enter: styles.notificationsAnimationEnter,
        enterActive: styles.notificationsAnimationEnterActive,
        exit: styles.notificationsAnimationExit,
        exitActive: styles.notificationsAnimationExitActive,
      }}
    >
      <Alert
        className={styles.notificationsAlert}
        variant={notification?.type}
        banner
        icon
        title={notification?.message ?? ""}
      />
    </CSSTransition>
  );
};
